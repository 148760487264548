/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as attribution000 from '../../../../src/main/proto/attribution/advertiser.pb';
export enum MetricType {
  METRIC_TYPE_UNSPECIFIED = 0,
  METRIC_TYPE_COUNT = 1,
  METRIC_TYPE_AMOUNT = 3,
  METRIC_TYPE_NUM_UNITS = 4,
}
export enum AttributionAlgorithm {
  ATTRIBUTION_ALGORITHM_UNSPECIFIED = 0,
  ATTRIBUTION_ALGORITHM_NON_DP = 1,
  ATTRIBUTION_ALGORITHM_DP = 2,
}
/**
 * Message implementation for attribution.GroupConfig
 */
export class GroupConfig implements GrpcMessage {
  static id = 'attribution.GroupConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GroupConfig();
    GroupConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GroupConfig) {
    _instance.publisherAttributes = _instance.publisherAttributes || [];
    _instance.isEventLevel = _instance.isEventLevel || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GroupConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.publisherAttributes =
            _instance.publisherAttributes || []).push(_reader.readString());
          break;
        case 2:
          _instance.isEventLevel = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    GroupConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GroupConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherAttributes && _instance.publisherAttributes.length) {
      _writer.writeRepeatedString(1, _instance.publisherAttributes);
    }
    if (_instance.isEventLevel) {
      _writer.writeBool(2, _instance.isEventLevel);
    }
  }

  private _publisherAttributes: string[];
  private _isEventLevel: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GroupConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<GroupConfig.AsObject>) {
    _value = _value || {};
    this.publisherAttributes = (_value.publisherAttributes || []).slice();
    this.isEventLevel = _value.isEventLevel;
    GroupConfig.refineValues(this);
  }
  get publisherAttributes(): string[] {
    return this._publisherAttributes;
  }
  set publisherAttributes(value: string[]) {
    this._publisherAttributes = value;
  }
  get isEventLevel(): boolean {
    return this._isEventLevel;
  }
  set isEventLevel(value: boolean) {
    this._isEventLevel = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GroupConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GroupConfig.AsObject {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
      isEventLevel: this.isEventLevel,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GroupConfig.AsProtobufJSON {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
      isEventLevel: this.isEventLevel,
    };
  }
}
export module GroupConfig {
  /**
   * Standard JavaScript object representation for GroupConfig
   */
  export interface AsObject {
    publisherAttributes: string[];
    isEventLevel: boolean;
  }

  /**
   * Protobuf JSON representation for GroupConfig
   */
  export interface AsProtobufJSON {
    publisherAttributes: string[];
    isEventLevel: boolean;
  }
}

/**
 * Message implementation for attribution.Metric
 */
export class Metric implements GrpcMessage {
  static id = 'attribution.Metric';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Metric();
    Metric.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Metric) {
    _instance.advertiserEventType = _instance.advertiserEventType || 0;
    _instance.metricType = _instance.metricType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Metric, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserEventType = _reader.readEnum();
          break;
        case 2:
          _instance.metricType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    Metric.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Metric, _writer: BinaryWriter) {
    if (_instance.advertiserEventType) {
      _writer.writeEnum(1, _instance.advertiserEventType);
    }
    if (_instance.metricType) {
      _writer.writeEnum(2, _instance.metricType);
    }
  }

  private _advertiserEventType: attribution000.AdvertiserEventType;
  private _metricType: MetricType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Metric to deeply clone from
   */
  constructor(_value?: RecursivePartial<Metric.AsObject>) {
    _value = _value || {};
    this.advertiserEventType = _value.advertiserEventType;
    this.metricType = _value.metricType;
    Metric.refineValues(this);
  }
  get advertiserEventType(): attribution000.AdvertiserEventType {
    return this._advertiserEventType;
  }
  set advertiserEventType(value: attribution000.AdvertiserEventType) {
    this._advertiserEventType = value;
  }
  get metricType(): MetricType {
    return this._metricType;
  }
  set metricType(value: MetricType) {
    this._metricType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Metric.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Metric.AsObject {
    return {
      advertiserEventType: this.advertiserEventType,
      metricType: this.metricType,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Metric.AsProtobufJSON {
    return {
      advertiserEventType:
        attribution000.AdvertiserEventType[
          this.advertiserEventType === null ||
          this.advertiserEventType === undefined
            ? 0
            : this.advertiserEventType
        ],
      metricType:
        MetricType[
          this.metricType === null || this.metricType === undefined
            ? 0
            : this.metricType
        ],
    };
  }
}
export module Metric {
  /**
   * Standard JavaScript object representation for Metric
   */
  export interface AsObject {
    advertiserEventType: attribution000.AdvertiserEventType;
    metricType: MetricType;
  }

  /**
   * Protobuf JSON representation for Metric
   */
  export interface AsProtobufJSON {
    advertiserEventType: string;
    metricType: string;
  }
}

/**
 * Message implementation for attribution.AttributionEstimationConfig
 */
export class AttributionEstimationConfig implements GrpcMessage {
  static id = 'attribution.AttributionEstimationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AttributionEstimationConfig();
    AttributionEstimationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AttributionEstimationConfig) {
    _instance.attributionAlgorithm = _instance.attributionAlgorithm || 0;
    _instance.privacyConfig = _instance.privacyConfig || undefined;
    _instance.sensitivityConfig = _instance.sensitivityConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AttributionEstimationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 3:
          _instance.attributionAlgorithm = _reader.readEnum();
          break;
        case 4:
          _instance.privacyConfig = new PrivacyConfig();
          _reader.readMessage(
            _instance.privacyConfig,
            PrivacyConfig.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.sensitivityConfig = new SensitivityConfig();
          _reader.readMessage(
            _instance.sensitivityConfig,
            SensitivityConfig.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AttributionEstimationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AttributionEstimationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.attributionAlgorithm) {
      _writer.writeEnum(3, _instance.attributionAlgorithm);
    }
    if (_instance.privacyConfig) {
      _writer.writeMessage(
        4,
        _instance.privacyConfig as any,
        PrivacyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.sensitivityConfig) {
      _writer.writeMessage(
        5,
        _instance.sensitivityConfig as any,
        SensitivityConfig.serializeBinaryToWriter
      );
    }
  }

  private _attributionAlgorithm: AttributionAlgorithm;
  private _privacyConfig?: PrivacyConfig;
  private _sensitivityConfig?: SensitivityConfig;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AttributionEstimationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<AttributionEstimationConfig.AsObject>) {
    _value = _value || {};
    this.attributionAlgorithm = _value.attributionAlgorithm;
    this.privacyConfig = _value.privacyConfig
      ? new PrivacyConfig(_value.privacyConfig)
      : undefined;
    this.sensitivityConfig = _value.sensitivityConfig
      ? new SensitivityConfig(_value.sensitivityConfig)
      : undefined;
    AttributionEstimationConfig.refineValues(this);
  }
  get attributionAlgorithm(): AttributionAlgorithm {
    return this._attributionAlgorithm;
  }
  set attributionAlgorithm(value: AttributionAlgorithm) {
    this._attributionAlgorithm = value;
  }
  get privacyConfig(): PrivacyConfig | undefined {
    return this._privacyConfig;
  }
  set privacyConfig(value: PrivacyConfig | undefined) {
    this._privacyConfig = value;
  }
  get sensitivityConfig(): SensitivityConfig | undefined {
    return this._sensitivityConfig;
  }
  set sensitivityConfig(value: SensitivityConfig | undefined) {
    this._sensitivityConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AttributionEstimationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AttributionEstimationConfig.AsObject {
    return {
      attributionAlgorithm: this.attributionAlgorithm,
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toObject()
        : undefined,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AttributionEstimationConfig.AsProtobufJSON {
    return {
      attributionAlgorithm:
        AttributionAlgorithm[
          this.attributionAlgorithm === null ||
          this.attributionAlgorithm === undefined
            ? 0
            : this.attributionAlgorithm
        ],
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toProtobufJSON(options)
        : null,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toProtobufJSON(options)
        : null,
    };
  }
}
export module AttributionEstimationConfig {
  /**
   * Standard JavaScript object representation for AttributionEstimationConfig
   */
  export interface AsObject {
    attributionAlgorithm: AttributionAlgorithm;
    privacyConfig?: PrivacyConfig.AsObject;
    sensitivityConfig?: SensitivityConfig.AsObject;
  }

  /**
   * Protobuf JSON representation for AttributionEstimationConfig
   */
  export interface AsProtobufJSON {
    attributionAlgorithm: string;
    privacyConfig: PrivacyConfig.AsProtobufJSON | null;
    sensitivityConfig: SensitivityConfig.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for attribution.PrivacyConfig
 */
export class PrivacyConfig implements GrpcMessage {
  static id = 'attribution.PrivacyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PrivacyConfig();
    PrivacyConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PrivacyConfig) {
    _instance.rho = _instance.rho || 0;
    _instance.rhoDivision = _instance.rhoDivision || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PrivacyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rho = _reader.readFloat();
          break;
        case 2:
          (_instance.rhoDivision = _instance.rhoDivision || []).push(
            ...(_reader.readPackedFloat() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PrivacyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PrivacyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.rho) {
      _writer.writeFloat(1, _instance.rho);
    }
    if (_instance.rhoDivision && _instance.rhoDivision.length) {
      _writer.writePackedFloat(2, _instance.rhoDivision);
    }
  }

  private _rho: number;
  private _rhoDivision: number[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PrivacyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<PrivacyConfig.AsObject>) {
    _value = _value || {};
    this.rho = _value.rho;
    this.rhoDivision = (_value.rhoDivision || []).slice();
    PrivacyConfig.refineValues(this);
  }
  get rho(): number {
    return this._rho;
  }
  set rho(value: number) {
    this._rho = value;
  }
  get rhoDivision(): number[] {
    return this._rhoDivision;
  }
  set rhoDivision(value: number[]) {
    this._rhoDivision = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PrivacyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PrivacyConfig.AsObject {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PrivacyConfig.AsProtobufJSON {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }
}
export module PrivacyConfig {
  /**
   * Standard JavaScript object representation for PrivacyConfig
   */
  export interface AsObject {
    rho: number;
    rhoDivision: number[];
  }

  /**
   * Protobuf JSON representation for PrivacyConfig
   */
  export interface AsProtobufJSON {
    rho: number;
    rhoDivision: number[];
  }
}

/**
 * Message implementation for attribution.SensitivityConfig
 */
export class SensitivityConfig implements GrpcMessage {
  static id = 'attribution.SensitivityConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SensitivityConfig();
    SensitivityConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SensitivityConfig) {
    _instance.percentile = _instance.percentile || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SensitivityConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.percentile = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    SensitivityConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SensitivityConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.percentile) {
      _writer.writeFloat(1, _instance.percentile);
    }
  }

  private _percentile: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SensitivityConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<SensitivityConfig.AsObject>) {
    _value = _value || {};
    this.percentile = _value.percentile;
    SensitivityConfig.refineValues(this);
  }
  get percentile(): number {
    return this._percentile;
  }
  set percentile(value: number) {
    this._percentile = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SensitivityConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SensitivityConfig.AsObject {
    return {
      percentile: this.percentile,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SensitivityConfig.AsProtobufJSON {
    return {
      percentile: this.percentile,
    };
  }
}
export module SensitivityConfig {
  /**
   * Standard JavaScript object representation for SensitivityConfig
   */
  export interface AsObject {
    percentile: number;
  }

  /**
   * Protobuf JSON representation for SensitivityConfig
   */
  export interface AsProtobufJSON {
    percentile: number;
  }
}

/**
 * Message implementation for attribution.AggregationConfig
 */
export class AggregationConfig implements GrpcMessage {
  static id = 'attribution.AggregationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AggregationConfig();
    AggregationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AggregationConfig) {
    _instance.groups = _instance.groups || [];
    _instance.metrics = _instance.metrics || [];
    _instance.attributionEstimationConfigs =
      _instance.attributionEstimationConfigs || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AggregationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new GroupConfig();
          _reader.readMessage(
            messageInitializer1,
            GroupConfig.deserializeBinaryFromReader
          );
          (_instance.groups = _instance.groups || []).push(messageInitializer1);
          break;
        case 2:
          const messageInitializer2 = new Metric();
          _reader.readMessage(
            messageInitializer2,
            Metric.deserializeBinaryFromReader
          );
          (_instance.metrics = _instance.metrics || []).push(
            messageInitializer2
          );
          break;
        case 3:
          const messageInitializer3 = new AttributionEstimationConfig();
          _reader.readMessage(
            messageInitializer3,
            AttributionEstimationConfig.deserializeBinaryFromReader
          );
          (_instance.attributionEstimationConfigs =
            _instance.attributionEstimationConfigs || []).push(
            messageInitializer3
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AggregationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AggregationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.groups && _instance.groups.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.groups as any,
        GroupConfig.serializeBinaryToWriter
      );
    }
    if (_instance.metrics && _instance.metrics.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.metrics as any,
        Metric.serializeBinaryToWriter
      );
    }
    if (
      _instance.attributionEstimationConfigs &&
      _instance.attributionEstimationConfigs.length
    ) {
      _writer.writeRepeatedMessage(
        3,
        _instance.attributionEstimationConfigs as any,
        AttributionEstimationConfig.serializeBinaryToWriter
      );
    }
  }

  private _groups?: GroupConfig[];
  private _metrics?: Metric[];
  private _attributionEstimationConfigs?: AttributionEstimationConfig[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AggregationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<AggregationConfig.AsObject>) {
    _value = _value || {};
    this.groups = (_value.groups || []).map((m) => new GroupConfig(m));
    this.metrics = (_value.metrics || []).map((m) => new Metric(m));
    this.attributionEstimationConfigs = (
      _value.attributionEstimationConfigs || []
    ).map((m) => new AttributionEstimationConfig(m));
    AggregationConfig.refineValues(this);
  }
  get groups(): GroupConfig[] | undefined {
    return this._groups;
  }
  set groups(value: GroupConfig[] | undefined) {
    this._groups = value;
  }
  get metrics(): Metric[] | undefined {
    return this._metrics;
  }
  set metrics(value: Metric[] | undefined) {
    this._metrics = value;
  }
  get attributionEstimationConfigs():
    | AttributionEstimationConfig[]
    | undefined {
    return this._attributionEstimationConfigs;
  }
  set attributionEstimationConfigs(
    value: AttributionEstimationConfig[] | undefined
  ) {
    this._attributionEstimationConfigs = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AggregationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AggregationConfig.AsObject {
    return {
      groups: (this.groups || []).map((m) => m.toObject()),
      metrics: (this.metrics || []).map((m) => m.toObject()),
      attributionEstimationConfigs: (
        this.attributionEstimationConfigs || []
      ).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AggregationConfig.AsProtobufJSON {
    return {
      groups: (this.groups || []).map((m) => m.toProtobufJSON(options)),
      metrics: (this.metrics || []).map((m) => m.toProtobufJSON(options)),
      attributionEstimationConfigs: (
        this.attributionEstimationConfigs || []
      ).map((m) => m.toProtobufJSON(options)),
    };
  }
}
export module AggregationConfig {
  /**
   * Standard JavaScript object representation for AggregationConfig
   */
  export interface AsObject {
    groups?: GroupConfig.AsObject[];
    metrics?: Metric.AsObject[];
    attributionEstimationConfigs?: AttributionEstimationConfig.AsObject[];
  }

  /**
   * Protobuf JSON representation for AggregationConfig
   */
  export interface AsProtobufJSON {
    groups: GroupConfig.AsProtobufJSON[] | null;
    metrics: Metric.AsProtobufJSON[] | null;
    attributionEstimationConfigs:
      | AttributionEstimationConfig.AsProtobufJSON[]
      | null;
  }
}
