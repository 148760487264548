import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import {
  PrivacyConfig,
  Subsample,
  TiktokTargetingConfig,
} from 'app/services/generated/src/main/proto/tiktok-targeting/tiktok-targeting-config.pb';

import { Project } from '../../../services/generated/src/main/proto/storage/project.pb';

@Component({
  selector: 'app-tiktok-targeting',
  templateUrl: './tiktok-targeting.component.html',
  styleUrls: ['./tiktok-targeting.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class TiktokTargetingComponent implements OnChanges {
  @Input() project: Project | undefined;
  @Input() inputJobLog: JobLog | undefined;
  @Output() tiktokTargetingConfig = new EventEmitter<TiktokTargetingConfig>();
  pubCustomerId = '';
  pubCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  advCustomerId = '';
  advCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;

  form = this.fb.group({
    matchingColumns: '',
    reach: '',
    eventType: '',
    pubFracToRemove: 0,
    advFracToRemove: 0,
    maxCohorts: 0,
    privacyConfigs: this.fb.array([
      this.fb.group({
        rho: '',
      }),
    ]),
    enableDebugLogging: false,
    experimental: false,
  });

  eventTypes: AdvertiserEventType[] = [];

  constructor(private fb: FormBuilder) {
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      this.eventTypes.push(parseInt(value));
    }
    this.form.valueChanges.subscribe(() => this.emitTiktokTargetingInfo());
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  get privacyConfigs() {
    return this.form.controls['privacyConfigs'] as FormArray;
  }

  addPrivacyConfig() {
    this.privacyConfigs.push(
      this.fb.group({
        rho: '',
      })
    );
  }

  deletePrivacyConfig(index: number) {
    this.privacyConfigs.removeAt(index);
  }

  eventTypeName(index: number) {
    return AdvertiserEventType[index];
  }

  emitTiktokTargetingInfo() {
    this.tiktokTargetingConfig.emit(this.emitTiktokTargetingConfig());
  }

  toTimestamp(
    timestampField: string | undefined | null
  ): Timestamp | undefined {
    if (!timestampField) {
      return undefined;
    }
    const time_millis = Date.parse(timestampField);
    return new Timestamp({
      seconds: Math.trunc(time_millis / 1000).toString(),
    });
  }

  emitTiktokTargetingConfig(): TiktokTargetingConfig {
    const form = this.form.value;
    return new TiktokTargetingConfig({
      subsample: new Subsample({
        pubFracToRemove: form.pubFracToRemove!,
        advFracToRemove: form.advFracToRemove!,
        maxCohorts: form.maxCohorts!,
      }),
      matchingConfig: new MatchingConfig({
        matchingColumns: this.form.value
          .matchingColumns!.split(',')
          .map((x) => x.trim()),
      }),
      advertiserEventType: parseInt(this.form.value.eventType!),
      privacyConfig: this.form.value.privacyConfigs?.map(
        (x) =>
          new PrivacyConfig({
            rho: parseFloat(x.rho!),
          })
      ),
      reach: parseInt(this.form.value.reach!),
      enableDebugLogging: this.form.value.enableDebugLogging!,
      experimental: this.form.value.experimental!,
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const tiktokTargetingConfig =
        inputJobLog.binaryConfig.unpack<TiktokTargetingConfig>(
          GRPC_MESSAGE_POOL
        );
      if (!tiktokTargetingConfig) {
        return;
      }
      const controls = this.form.controls;

      controls.matchingColumns.setValue(
        tiktokTargetingConfig.matchingConfig!.matchingColumns!.join(',')
      );
      controls.reach.setValue('' + tiktokTargetingConfig.reach);
      if (tiktokTargetingConfig.advertiserEventType) {
        controls.eventType.setValue(
          '' + tiktokTargetingConfig.advertiserEventType
        );
      }
      if (tiktokTargetingConfig.subsample) {
        controls.pubFracToRemove.setValue(
          tiktokTargetingConfig.subsample.pubFracToRemove
        );
        controls.advFracToRemove.setValue(
          tiktokTargetingConfig.subsample.advFracToRemove
        );
        controls.maxCohorts.setValue(
          tiktokTargetingConfig.subsample.maxCohorts
        );
      }
      this.privacyConfigs.clear();
      tiktokTargetingConfig.privacyConfig!.forEach((v) => {
        this.privacyConfigs.push(
          this.fb.group({
            rho: v.rho,
          })
        );
      });
      controls.experimental.setValue(tiktokTargetingConfig.experimental!);
      controls.enableDebugLogging.setValue(
        tiktokTargetingConfig.enableDebugLogging!
      );
    }
  }
}
