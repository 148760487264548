/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as attribution001 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as matching002 from '../../../../src/main/proto/matching/matching-config.pb';
import * as storage003 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
export enum MetricType {
  METRIC_TYPE_UNSPECIFIED = 0,
  METRIC_TYPE_COUNT = 1,
  METRIC_TYPE_USERS = 2,
  METRIC_TYPE_AMOUNT = 3,
  METRIC_TYPE_NUM_UNITS = 4,
}
export enum LiftAlgorithm {
  LIFT_ALGORITHM_UNSPECIFIED = 0,
  LIFT_ALGORITHM_NON_DP = 1,
  LIFT_ALGORITHM_DP_SD = 2,
  LIFT_ALGORITHM_DP_AAD = 3,
}
export enum LiftOutputSet {
  LIFT_OUTPUT_SET_UNSPECIFIED = 0,
  LIFT_OUTPUT_SET_ALL = 4,
  LIFT_OUTPUT_SET_RELATIVE_LIFT = 5,
  LIFT_OUTPUT_SET_ABSOLUTE_LIFT = 6,
}
/**
 * Message implementation for lift.LiftConfig
 */
export class LiftConfig implements GrpcMessage {
  static id = 'lift.LiftConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LiftConfig();
    LiftConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LiftConfig) {
    _instance.studyId = _instance.studyId || '';
    _instance.publisherCustomerDataSet =
      _instance.publisherCustomerDataSet || undefined;
    _instance.advertiserCustomerDataSet =
      _instance.advertiserCustomerDataSet || undefined;
    _instance.matchingConfig = _instance.matchingConfig || undefined;
    _instance.timeWindow = _instance.timeWindow || undefined;
    _instance.dataCleaningConfig = _instance.dataCleaningConfig || undefined;
    _instance.eligibility = _instance.eligibility || undefined;
    _instance.groups = _instance.groups || [];
    _instance.metrics = _instance.metrics || [];
    _instance.liftEstimationConfigs = _instance.liftEstimationConfigs || [];
    _instance.enableDebugLogging = _instance.enableDebugLogging || false;
    _instance.shardingConfig = _instance.shardingConfig || undefined;
    _instance.dropDuplicateRecordIds =
      _instance.dropDuplicateRecordIds || false;
    _instance.loadRecordId = _instance.loadRecordId || false;
    _instance.piiScore = _instance.piiScore || false;
    _instance.matchTestMode = _instance.matchTestMode || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LiftConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.studyId = _reader.readString();
          break;
        case 17:
          _instance.publisherCustomerDataSet =
            new storage003.CustomerDataSetReference();
          _reader.readMessage(
            _instance.publisherCustomerDataSet,
            storage003.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 18:
          _instance.advertiserCustomerDataSet =
            new storage003.CustomerDataSetReference();
          _reader.readMessage(
            _instance.advertiserCustomerDataSet,
            storage003.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 19:
          _instance.matchingConfig = new matching002.MatchingConfig();
          _reader.readMessage(
            _instance.matchingConfig,
            matching002.MatchingConfig.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.timeWindow = new TimeWindow();
          _reader.readMessage(
            _instance.timeWindow,
            TimeWindow.deserializeBinaryFromReader
          );
          break;
        case 10:
          _instance.dataCleaningConfig = new DataCleaningConfig();
          _reader.readMessage(
            _instance.dataCleaningConfig,
            DataCleaningConfig.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.eligibility = new AdvertiserEventEligibility();
          _reader.readMessage(
            _instance.eligibility,
            AdvertiserEventEligibility.deserializeBinaryFromReader
          );
          break;
        case 6:
          const messageInitializer6 = new GroupConfig();
          _reader.readMessage(
            messageInitializer6,
            GroupConfig.deserializeBinaryFromReader
          );
          (_instance.groups = _instance.groups || []).push(messageInitializer6);
          break;
        case 7:
          const messageInitializer7 = new Metric();
          _reader.readMessage(
            messageInitializer7,
            Metric.deserializeBinaryFromReader
          );
          (_instance.metrics = _instance.metrics || []).push(
            messageInitializer7
          );
          break;
        case 12:
          const messageInitializer12 = new LiftEstimationConfig();
          _reader.readMessage(
            messageInitializer12,
            LiftEstimationConfig.deserializeBinaryFromReader
          );
          (_instance.liftEstimationConfigs =
            _instance.liftEstimationConfigs || []).push(messageInitializer12);
          break;
        case 15:
          _instance.enableDebugLogging = _reader.readBool();
          break;
        case 16:
          _instance.shardingConfig = new ShardingConfig();
          _reader.readMessage(
            _instance.shardingConfig,
            ShardingConfig.deserializeBinaryFromReader
          );
          break;
        case 20:
          _instance.dropDuplicateRecordIds = _reader.readBool();
          break;
        case 21:
          _instance.loadRecordId = _reader.readBool();
          break;
        case 23:
          _instance.piiScore = _reader.readBool();
          break;
        case 24:
          _instance.matchTestMode = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    LiftConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: LiftConfig, _writer: BinaryWriter) {
    if (_instance.studyId) {
      _writer.writeString(1, _instance.studyId);
    }
    if (_instance.publisherCustomerDataSet) {
      _writer.writeMessage(
        17,
        _instance.publisherCustomerDataSet as any,
        storage003.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserCustomerDataSet) {
      _writer.writeMessage(
        18,
        _instance.advertiserCustomerDataSet as any,
        storage003.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.matchingConfig) {
      _writer.writeMessage(
        19,
        _instance.matchingConfig as any,
        matching002.MatchingConfig.serializeBinaryToWriter
      );
    }
    if (_instance.timeWindow) {
      _writer.writeMessage(
        4,
        _instance.timeWindow as any,
        TimeWindow.serializeBinaryToWriter
      );
    }
    if (_instance.dataCleaningConfig) {
      _writer.writeMessage(
        10,
        _instance.dataCleaningConfig as any,
        DataCleaningConfig.serializeBinaryToWriter
      );
    }
    if (_instance.eligibility) {
      _writer.writeMessage(
        5,
        _instance.eligibility as any,
        AdvertiserEventEligibility.serializeBinaryToWriter
      );
    }
    if (_instance.groups && _instance.groups.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.groups as any,
        GroupConfig.serializeBinaryToWriter
      );
    }
    if (_instance.metrics && _instance.metrics.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.metrics as any,
        Metric.serializeBinaryToWriter
      );
    }
    if (
      _instance.liftEstimationConfigs &&
      _instance.liftEstimationConfigs.length
    ) {
      _writer.writeRepeatedMessage(
        12,
        _instance.liftEstimationConfigs as any,
        LiftEstimationConfig.serializeBinaryToWriter
      );
    }
    if (_instance.enableDebugLogging) {
      _writer.writeBool(15, _instance.enableDebugLogging);
    }
    if (_instance.shardingConfig) {
      _writer.writeMessage(
        16,
        _instance.shardingConfig as any,
        ShardingConfig.serializeBinaryToWriter
      );
    }
    if (_instance.dropDuplicateRecordIds) {
      _writer.writeBool(20, _instance.dropDuplicateRecordIds);
    }
    if (_instance.loadRecordId) {
      _writer.writeBool(21, _instance.loadRecordId);
    }
    if (_instance.piiScore) {
      _writer.writeBool(23, _instance.piiScore);
    }
    if (_instance.matchTestMode) {
      _writer.writeBool(24, _instance.matchTestMode);
    }
  }

  private _studyId: string;
  private _publisherCustomerDataSet?: storage003.CustomerDataSetReference;
  private _advertiserCustomerDataSet?: storage003.CustomerDataSetReference;
  private _matchingConfig?: matching002.MatchingConfig;
  private _timeWindow?: TimeWindow;
  private _dataCleaningConfig?: DataCleaningConfig;
  private _eligibility?: AdvertiserEventEligibility;
  private _groups?: GroupConfig[];
  private _metrics?: Metric[];
  private _liftEstimationConfigs?: LiftEstimationConfig[];
  private _enableDebugLogging: boolean;
  private _shardingConfig?: ShardingConfig;
  private _dropDuplicateRecordIds: boolean;
  private _loadRecordId: boolean;
  private _piiScore: boolean;
  private _matchTestMode: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LiftConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<LiftConfig.AsObject>) {
    _value = _value || {};
    this.studyId = _value.studyId;
    this.publisherCustomerDataSet = _value.publisherCustomerDataSet
      ? new storage003.CustomerDataSetReference(_value.publisherCustomerDataSet)
      : undefined;
    this.advertiserCustomerDataSet = _value.advertiserCustomerDataSet
      ? new storage003.CustomerDataSetReference(
          _value.advertiserCustomerDataSet
        )
      : undefined;
    this.matchingConfig = _value.matchingConfig
      ? new matching002.MatchingConfig(_value.matchingConfig)
      : undefined;
    this.timeWindow = _value.timeWindow
      ? new TimeWindow(_value.timeWindow)
      : undefined;
    this.dataCleaningConfig = _value.dataCleaningConfig
      ? new DataCleaningConfig(_value.dataCleaningConfig)
      : undefined;
    this.eligibility = _value.eligibility
      ? new AdvertiserEventEligibility(_value.eligibility)
      : undefined;
    this.groups = (_value.groups || []).map((m) => new GroupConfig(m));
    this.metrics = (_value.metrics || []).map((m) => new Metric(m));
    this.liftEstimationConfigs = (_value.liftEstimationConfigs || []).map(
      (m) => new LiftEstimationConfig(m)
    );
    this.enableDebugLogging = _value.enableDebugLogging;
    this.shardingConfig = _value.shardingConfig
      ? new ShardingConfig(_value.shardingConfig)
      : undefined;
    this.dropDuplicateRecordIds = _value.dropDuplicateRecordIds;
    this.loadRecordId = _value.loadRecordId;
    this.piiScore = _value.piiScore;
    this.matchTestMode = _value.matchTestMode;
    LiftConfig.refineValues(this);
  }
  get studyId(): string {
    return this._studyId;
  }
  set studyId(value: string) {
    this._studyId = value;
  }
  get publisherCustomerDataSet():
    | storage003.CustomerDataSetReference
    | undefined {
    return this._publisherCustomerDataSet;
  }
  set publisherCustomerDataSet(
    value: storage003.CustomerDataSetReference | undefined
  ) {
    this._publisherCustomerDataSet = value;
  }
  get advertiserCustomerDataSet():
    | storage003.CustomerDataSetReference
    | undefined {
    return this._advertiserCustomerDataSet;
  }
  set advertiserCustomerDataSet(
    value: storage003.CustomerDataSetReference | undefined
  ) {
    this._advertiserCustomerDataSet = value;
  }
  get matchingConfig(): matching002.MatchingConfig | undefined {
    return this._matchingConfig;
  }
  set matchingConfig(value: matching002.MatchingConfig | undefined) {
    this._matchingConfig = value;
  }
  get timeWindow(): TimeWindow | undefined {
    return this._timeWindow;
  }
  set timeWindow(value: TimeWindow | undefined) {
    this._timeWindow = value;
  }
  get dataCleaningConfig(): DataCleaningConfig | undefined {
    return this._dataCleaningConfig;
  }
  set dataCleaningConfig(value: DataCleaningConfig | undefined) {
    this._dataCleaningConfig = value;
  }
  get eligibility(): AdvertiserEventEligibility | undefined {
    return this._eligibility;
  }
  set eligibility(value: AdvertiserEventEligibility | undefined) {
    this._eligibility = value;
  }
  get groups(): GroupConfig[] | undefined {
    return this._groups;
  }
  set groups(value: GroupConfig[] | undefined) {
    this._groups = value;
  }
  get metrics(): Metric[] | undefined {
    return this._metrics;
  }
  set metrics(value: Metric[] | undefined) {
    this._metrics = value;
  }
  get liftEstimationConfigs(): LiftEstimationConfig[] | undefined {
    return this._liftEstimationConfigs;
  }
  set liftEstimationConfigs(value: LiftEstimationConfig[] | undefined) {
    this._liftEstimationConfigs = value;
  }
  get enableDebugLogging(): boolean {
    return this._enableDebugLogging;
  }
  set enableDebugLogging(value: boolean) {
    this._enableDebugLogging = value;
  }
  get shardingConfig(): ShardingConfig | undefined {
    return this._shardingConfig;
  }
  set shardingConfig(value: ShardingConfig | undefined) {
    this._shardingConfig = value;
  }
  get dropDuplicateRecordIds(): boolean {
    return this._dropDuplicateRecordIds;
  }
  set dropDuplicateRecordIds(value: boolean) {
    this._dropDuplicateRecordIds = value;
  }
  get loadRecordId(): boolean {
    return this._loadRecordId;
  }
  set loadRecordId(value: boolean) {
    this._loadRecordId = value;
  }
  get piiScore(): boolean {
    return this._piiScore;
  }
  set piiScore(value: boolean) {
    this._piiScore = value;
  }
  get matchTestMode(): boolean {
    return this._matchTestMode;
  }
  set matchTestMode(value: boolean) {
    this._matchTestMode = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LiftConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LiftConfig.AsObject {
    return {
      studyId: this.studyId,
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toObject()
        : undefined,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toObject()
        : undefined,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toObject()
        : undefined,
      timeWindow: this.timeWindow ? this.timeWindow.toObject() : undefined,
      dataCleaningConfig: this.dataCleaningConfig
        ? this.dataCleaningConfig.toObject()
        : undefined,
      eligibility: this.eligibility ? this.eligibility.toObject() : undefined,
      groups: (this.groups || []).map((m) => m.toObject()),
      metrics: (this.metrics || []).map((m) => m.toObject()),
      liftEstimationConfigs: (this.liftEstimationConfigs || []).map((m) =>
        m.toObject()
      ),
      enableDebugLogging: this.enableDebugLogging,
      shardingConfig: this.shardingConfig
        ? this.shardingConfig.toObject()
        : undefined,
      dropDuplicateRecordIds: this.dropDuplicateRecordIds,
      loadRecordId: this.loadRecordId,
      piiScore: this.piiScore,
      matchTestMode: this.matchTestMode,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LiftConfig.AsProtobufJSON {
    return {
      studyId: this.studyId,
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toProtobufJSON(options)
        : null,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toProtobufJSON(options)
        : null,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toProtobufJSON(options)
        : null,
      timeWindow: this.timeWindow
        ? this.timeWindow.toProtobufJSON(options)
        : null,
      dataCleaningConfig: this.dataCleaningConfig
        ? this.dataCleaningConfig.toProtobufJSON(options)
        : null,
      eligibility: this.eligibility
        ? this.eligibility.toProtobufJSON(options)
        : null,
      groups: (this.groups || []).map((m) => m.toProtobufJSON(options)),
      metrics: (this.metrics || []).map((m) => m.toProtobufJSON(options)),
      liftEstimationConfigs: (this.liftEstimationConfigs || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      enableDebugLogging: this.enableDebugLogging,
      shardingConfig: this.shardingConfig
        ? this.shardingConfig.toProtobufJSON(options)
        : null,
      dropDuplicateRecordIds: this.dropDuplicateRecordIds,
      loadRecordId: this.loadRecordId,
      piiScore: this.piiScore,
      matchTestMode: this.matchTestMode,
    };
  }
}
export module LiftConfig {
  /**
   * Standard JavaScript object representation for LiftConfig
   */
  export interface AsObject {
    studyId: string;
    publisherCustomerDataSet?: storage003.CustomerDataSetReference.AsObject;
    advertiserCustomerDataSet?: storage003.CustomerDataSetReference.AsObject;
    matchingConfig?: matching002.MatchingConfig.AsObject;
    timeWindow?: TimeWindow.AsObject;
    dataCleaningConfig?: DataCleaningConfig.AsObject;
    eligibility?: AdvertiserEventEligibility.AsObject;
    groups?: GroupConfig.AsObject[];
    metrics?: Metric.AsObject[];
    liftEstimationConfigs?: LiftEstimationConfig.AsObject[];
    enableDebugLogging: boolean;
    shardingConfig?: ShardingConfig.AsObject;
    dropDuplicateRecordIds: boolean;
    loadRecordId: boolean;
    piiScore: boolean;
    matchTestMode: boolean;
  }

  /**
   * Protobuf JSON representation for LiftConfig
   */
  export interface AsProtobufJSON {
    studyId: string;
    publisherCustomerDataSet: storage003.CustomerDataSetReference.AsProtobufJSON | null;
    advertiserCustomerDataSet: storage003.CustomerDataSetReference.AsProtobufJSON | null;
    matchingConfig: matching002.MatchingConfig.AsProtobufJSON | null;
    timeWindow: TimeWindow.AsProtobufJSON | null;
    dataCleaningConfig: DataCleaningConfig.AsProtobufJSON | null;
    eligibility: AdvertiserEventEligibility.AsProtobufJSON | null;
    groups: GroupConfig.AsProtobufJSON[] | null;
    metrics: Metric.AsProtobufJSON[] | null;
    liftEstimationConfigs: LiftEstimationConfig.AsProtobufJSON[] | null;
    enableDebugLogging: boolean;
    shardingConfig: ShardingConfig.AsProtobufJSON | null;
    dropDuplicateRecordIds: boolean;
    loadRecordId: boolean;
    piiScore: boolean;
    matchTestMode: boolean;
  }
}

/**
 * Message implementation for lift.ShardingConfig
 */
export class ShardingConfig implements GrpcMessage {
  static id = 'lift.ShardingConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ShardingConfig();
    ShardingConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ShardingConfig) {
    _instance.publisherNpartitions = _instance.publisherNpartitions || 0;
    _instance.advertiserNpartitions = _instance.advertiserNpartitions || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ShardingConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.publisherNpartitions = _reader.readInt32();
          break;
        case 2:
          _instance.advertiserNpartitions = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ShardingConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ShardingConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherNpartitions) {
      _writer.writeInt32(1, _instance.publisherNpartitions);
    }
    if (_instance.advertiserNpartitions) {
      _writer.writeInt32(2, _instance.advertiserNpartitions);
    }
  }

  private _publisherNpartitions: number;
  private _advertiserNpartitions: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ShardingConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ShardingConfig.AsObject>) {
    _value = _value || {};
    this.publisherNpartitions = _value.publisherNpartitions;
    this.advertiserNpartitions = _value.advertiserNpartitions;
    ShardingConfig.refineValues(this);
  }
  get publisherNpartitions(): number {
    return this._publisherNpartitions;
  }
  set publisherNpartitions(value: number) {
    this._publisherNpartitions = value;
  }
  get advertiserNpartitions(): number {
    return this._advertiserNpartitions;
  }
  set advertiserNpartitions(value: number) {
    this._advertiserNpartitions = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ShardingConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ShardingConfig.AsObject {
    return {
      publisherNpartitions: this.publisherNpartitions,
      advertiserNpartitions: this.advertiserNpartitions,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ShardingConfig.AsProtobufJSON {
    return {
      publisherNpartitions: this.publisherNpartitions,
      advertiserNpartitions: this.advertiserNpartitions,
    };
  }
}
export module ShardingConfig {
  /**
   * Standard JavaScript object representation for ShardingConfig
   */
  export interface AsObject {
    publisherNpartitions: number;
    advertiserNpartitions: number;
  }

  /**
   * Protobuf JSON representation for ShardingConfig
   */
  export interface AsProtobufJSON {
    publisherNpartitions: number;
    advertiserNpartitions: number;
  }
}

/**
 * Message implementation for lift.TimeWindow
 */
export class TimeWindow implements GrpcMessage {
  static id = 'lift.TimeWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeWindow();
    TimeWindow.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeWindow) {
    _instance.publisherStartTime = _instance.publisherStartTime || undefined;
    _instance.publisherEndTime = _instance.publisherEndTime || undefined;
    _instance.advertiserStartTime = _instance.advertiserStartTime || undefined;
    _instance.advertiserEndTime = _instance.advertiserEndTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.publisherStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.publisherEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.advertiserStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.advertiserEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TimeWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeWindow, _writer: BinaryWriter) {
    if (_instance.publisherStartTime) {
      _writer.writeMessage(
        1,
        _instance.publisherStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.publisherEndTime) {
      _writer.writeMessage(
        2,
        _instance.publisherEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserStartTime) {
      _writer.writeMessage(
        3,
        _instance.advertiserStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserEndTime) {
      _writer.writeMessage(
        4,
        _instance.advertiserEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _publisherStartTime?: googleProtobuf000.Timestamp;
  private _publisherEndTime?: googleProtobuf000.Timestamp;
  private _advertiserStartTime?: googleProtobuf000.Timestamp;
  private _advertiserEndTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeWindow.AsObject>) {
    _value = _value || {};
    this.publisherStartTime = _value.publisherStartTime
      ? new googleProtobuf000.Timestamp(_value.publisherStartTime)
      : undefined;
    this.publisherEndTime = _value.publisherEndTime
      ? new googleProtobuf000.Timestamp(_value.publisherEndTime)
      : undefined;
    this.advertiserStartTime = _value.advertiserStartTime
      ? new googleProtobuf000.Timestamp(_value.advertiserStartTime)
      : undefined;
    this.advertiserEndTime = _value.advertiserEndTime
      ? new googleProtobuf000.Timestamp(_value.advertiserEndTime)
      : undefined;
    TimeWindow.refineValues(this);
  }
  get publisherStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherStartTime;
  }
  set publisherStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherStartTime = value;
  }
  get publisherEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherEndTime;
  }
  set publisherEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherEndTime = value;
  }
  get advertiserStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserStartTime;
  }
  set advertiserStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserStartTime = value;
  }
  get advertiserEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserEndTime;
  }
  set advertiserEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserEndTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeWindow.AsObject {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toObject()
        : undefined,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toObject()
        : undefined,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toObject()
        : undefined,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeWindow.AsProtobufJSON {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toProtobufJSON(options)
        : null,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toProtobufJSON(options)
        : null,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toProtobufJSON(options)
        : null,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module TimeWindow {
  /**
   * Standard JavaScript object representation for TimeWindow
   */
  export interface AsObject {
    publisherStartTime?: googleProtobuf000.Timestamp.AsObject;
    publisherEndTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserStartTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserEndTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for TimeWindow
   */
  export interface AsProtobufJSON {
    publisherStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    publisherEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for lift.DataCleaningConfig
 */
export class DataCleaningConfig implements GrpcMessage {
  static id = 'lift.DataCleaningConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DataCleaningConfig();
    DataCleaningConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DataCleaningConfig) {
    _instance.maximumImpressionsPerUser =
      _instance.maximumImpressionsPerUser || 0;
    _instance.advertiserEventLimits = _instance.advertiserEventLimits || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DataCleaningConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.maximumImpressionsPerUser = _reader.readInt32();
          break;
        case 3:
          const messageInitializer3 =
            new DataCleaningConfig.ConversionMaximumSpecifier();
          _reader.readMessage(
            messageInitializer3,
            DataCleaningConfig.ConversionMaximumSpecifier
              .deserializeBinaryFromReader
          );
          (_instance.advertiserEventLimits =
            _instance.advertiserEventLimits || []).push(messageInitializer3);
          break;
        default:
          _reader.skipField();
      }
    }

    DataCleaningConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DataCleaningConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.maximumImpressionsPerUser) {
      _writer.writeInt32(1, _instance.maximumImpressionsPerUser);
    }
    if (
      _instance.advertiserEventLimits &&
      _instance.advertiserEventLimits.length
    ) {
      _writer.writeRepeatedMessage(
        3,
        _instance.advertiserEventLimits as any,
        DataCleaningConfig.ConversionMaximumSpecifier.serializeBinaryToWriter
      );
    }
  }

  private _maximumImpressionsPerUser: number;
  private _advertiserEventLimits?: DataCleaningConfig.ConversionMaximumSpecifier[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DataCleaningConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<DataCleaningConfig.AsObject>) {
    _value = _value || {};
    this.maximumImpressionsPerUser = _value.maximumImpressionsPerUser;
    this.advertiserEventLimits = (_value.advertiserEventLimits || []).map(
      (m) => new DataCleaningConfig.ConversionMaximumSpecifier(m)
    );
    DataCleaningConfig.refineValues(this);
  }
  get maximumImpressionsPerUser(): number {
    return this._maximumImpressionsPerUser;
  }
  set maximumImpressionsPerUser(value: number) {
    this._maximumImpressionsPerUser = value;
  }
  get advertiserEventLimits():
    | DataCleaningConfig.ConversionMaximumSpecifier[]
    | undefined {
    return this._advertiserEventLimits;
  }
  set advertiserEventLimits(
    value: DataCleaningConfig.ConversionMaximumSpecifier[] | undefined
  ) {
    this._advertiserEventLimits = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DataCleaningConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DataCleaningConfig.AsObject {
    return {
      maximumImpressionsPerUser: this.maximumImpressionsPerUser,
      advertiserEventLimits: (this.advertiserEventLimits || []).map((m) =>
        m.toObject()
      ),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DataCleaningConfig.AsProtobufJSON {
    return {
      maximumImpressionsPerUser: this.maximumImpressionsPerUser,
      advertiserEventLimits: (this.advertiserEventLimits || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module DataCleaningConfig {
  /**
   * Standard JavaScript object representation for DataCleaningConfig
   */
  export interface AsObject {
    maximumImpressionsPerUser: number;
    advertiserEventLimits?: DataCleaningConfig.ConversionMaximumSpecifier.AsObject[];
  }

  /**
   * Protobuf JSON representation for DataCleaningConfig
   */
  export interface AsProtobufJSON {
    maximumImpressionsPerUser: number;
    advertiserEventLimits:
      | DataCleaningConfig.ConversionMaximumSpecifier.AsProtobufJSON[]
      | null;
  }

  /**
   * Message implementation for lift.DataCleaningConfig.ConversionMaximumSpecifier
   */
  export class ConversionMaximumSpecifier implements GrpcMessage {
    static id = 'lift.DataCleaningConfig.ConversionMaximumSpecifier';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ConversionMaximumSpecifier();
      ConversionMaximumSpecifier.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ConversionMaximumSpecifier) {
      _instance.advertiserEventType = _instance.advertiserEventType || 0;
      _instance.maximumAllowedEvents = _instance.maximumAllowedEvents || 0;
      _instance.maximumConversionAmount =
        _instance.maximumConversionAmount || 0;
      _instance.maximumTotalConversionAmount =
        _instance.maximumTotalConversionAmount || 0;
      _instance.maximumUnits = _instance.maximumUnits || 0;
      _instance.maximumTotalUnits = _instance.maximumTotalUnits || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ConversionMaximumSpecifier,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.advertiserEventType = _reader.readEnum();
            break;
          case 2:
            _instance.maximumAllowedEvents = _reader.readInt32();
            break;
          case 3:
            _instance.maximumConversionAmount = _reader.readFloat();
            break;
          case 4:
            _instance.maximumTotalConversionAmount = _reader.readFloat();
            break;
          case 5:
            _instance.maximumUnits = _reader.readInt32();
            break;
          case 6:
            _instance.maximumTotalUnits = _reader.readInt32();
            break;
          default:
            _reader.skipField();
        }
      }

      ConversionMaximumSpecifier.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ConversionMaximumSpecifier,
      _writer: BinaryWriter
    ) {
      if (_instance.advertiserEventType) {
        _writer.writeEnum(1, _instance.advertiserEventType);
      }
      if (_instance.maximumAllowedEvents) {
        _writer.writeInt32(2, _instance.maximumAllowedEvents);
      }
      if (_instance.maximumConversionAmount) {
        _writer.writeFloat(3, _instance.maximumConversionAmount);
      }
      if (_instance.maximumTotalConversionAmount) {
        _writer.writeFloat(4, _instance.maximumTotalConversionAmount);
      }
      if (_instance.maximumUnits) {
        _writer.writeInt32(5, _instance.maximumUnits);
      }
      if (_instance.maximumTotalUnits) {
        _writer.writeInt32(6, _instance.maximumTotalUnits);
      }
    }

    private _advertiserEventType: attribution001.AdvertiserEventType;
    private _maximumAllowedEvents: number;
    private _maximumConversionAmount: number;
    private _maximumTotalConversionAmount: number;
    private _maximumUnits: number;
    private _maximumTotalUnits: number;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ConversionMaximumSpecifier to deeply clone from
     */
    constructor(
      _value?: RecursivePartial<ConversionMaximumSpecifier.AsObject>
    ) {
      _value = _value || {};
      this.advertiserEventType = _value.advertiserEventType;
      this.maximumAllowedEvents = _value.maximumAllowedEvents;
      this.maximumConversionAmount = _value.maximumConversionAmount;
      this.maximumTotalConversionAmount = _value.maximumTotalConversionAmount;
      this.maximumUnits = _value.maximumUnits;
      this.maximumTotalUnits = _value.maximumTotalUnits;
      ConversionMaximumSpecifier.refineValues(this);
    }
    get advertiserEventType(): attribution001.AdvertiserEventType {
      return this._advertiserEventType;
    }
    set advertiserEventType(value: attribution001.AdvertiserEventType) {
      this._advertiserEventType = value;
    }
    get maximumAllowedEvents(): number {
      return this._maximumAllowedEvents;
    }
    set maximumAllowedEvents(value: number) {
      this._maximumAllowedEvents = value;
    }
    get maximumConversionAmount(): number {
      return this._maximumConversionAmount;
    }
    set maximumConversionAmount(value: number) {
      this._maximumConversionAmount = value;
    }
    get maximumTotalConversionAmount(): number {
      return this._maximumTotalConversionAmount;
    }
    set maximumTotalConversionAmount(value: number) {
      this._maximumTotalConversionAmount = value;
    }
    get maximumUnits(): number {
      return this._maximumUnits;
    }
    set maximumUnits(value: number) {
      this._maximumUnits = value;
    }
    get maximumTotalUnits(): number {
      return this._maximumTotalUnits;
    }
    set maximumTotalUnits(value: number) {
      this._maximumTotalUnits = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ConversionMaximumSpecifier.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ConversionMaximumSpecifier.AsObject {
      return {
        advertiserEventType: this.advertiserEventType,
        maximumAllowedEvents: this.maximumAllowedEvents,
        maximumConversionAmount: this.maximumConversionAmount,
        maximumTotalConversionAmount: this.maximumTotalConversionAmount,
        maximumUnits: this.maximumUnits,
        maximumTotalUnits: this.maximumTotalUnits,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ConversionMaximumSpecifier.AsProtobufJSON {
      return {
        advertiserEventType:
          attribution001.AdvertiserEventType[
            this.advertiserEventType === null ||
            this.advertiserEventType === undefined
              ? 0
              : this.advertiserEventType
          ],
        maximumAllowedEvents: this.maximumAllowedEvents,
        maximumConversionAmount: this.maximumConversionAmount,
        maximumTotalConversionAmount: this.maximumTotalConversionAmount,
        maximumUnits: this.maximumUnits,
        maximumTotalUnits: this.maximumTotalUnits,
      };
    }
  }
  export module ConversionMaximumSpecifier {
    /**
     * Standard JavaScript object representation for ConversionMaximumSpecifier
     */
    export interface AsObject {
      advertiserEventType: attribution001.AdvertiserEventType;
      maximumAllowedEvents: number;
      maximumConversionAmount: number;
      maximumTotalConversionAmount: number;
      maximumUnits: number;
      maximumTotalUnits: number;
    }

    /**
     * Protobuf JSON representation for ConversionMaximumSpecifier
     */
    export interface AsProtobufJSON {
      advertiserEventType: string;
      maximumAllowedEvents: number;
      maximumConversionAmount: number;
      maximumTotalConversionAmount: number;
      maximumUnits: number;
      maximumTotalUnits: number;
    }
  }
}

/**
 * Message implementation for lift.AdvertiserEventEligibility
 */
export class AdvertiserEventEligibility implements GrpcMessage {
  static id = 'lift.AdvertiserEventEligibility';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AdvertiserEventEligibility();
    AdvertiserEventEligibility.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AdvertiserEventEligibility) {
    _instance.maximumLookbackWindowSeconds =
      _instance.maximumLookbackWindowSeconds || 0;
    _instance.windowUsesFirstOpportunity =
      _instance.windowUsesFirstOpportunity || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AdvertiserEventEligibility,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.maximumLookbackWindowSeconds = _reader.readInt32();
          break;
        case 3:
          _instance.windowUsesFirstOpportunity = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    AdvertiserEventEligibility.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AdvertiserEventEligibility,
    _writer: BinaryWriter
  ) {
    if (_instance.maximumLookbackWindowSeconds) {
      _writer.writeInt32(1, _instance.maximumLookbackWindowSeconds);
    }
    if (_instance.windowUsesFirstOpportunity) {
      _writer.writeBool(3, _instance.windowUsesFirstOpportunity);
    }
  }

  private _maximumLookbackWindowSeconds: number;
  private _windowUsesFirstOpportunity: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AdvertiserEventEligibility to deeply clone from
   */
  constructor(_value?: RecursivePartial<AdvertiserEventEligibility.AsObject>) {
    _value = _value || {};
    this.maximumLookbackWindowSeconds = _value.maximumLookbackWindowSeconds;
    this.windowUsesFirstOpportunity = _value.windowUsesFirstOpportunity;
    AdvertiserEventEligibility.refineValues(this);
  }
  get maximumLookbackWindowSeconds(): number {
    return this._maximumLookbackWindowSeconds;
  }
  set maximumLookbackWindowSeconds(value: number) {
    this._maximumLookbackWindowSeconds = value;
  }
  get windowUsesFirstOpportunity(): boolean {
    return this._windowUsesFirstOpportunity;
  }
  set windowUsesFirstOpportunity(value: boolean) {
    this._windowUsesFirstOpportunity = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AdvertiserEventEligibility.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AdvertiserEventEligibility.AsObject {
    return {
      maximumLookbackWindowSeconds: this.maximumLookbackWindowSeconds,
      windowUsesFirstOpportunity: this.windowUsesFirstOpportunity,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AdvertiserEventEligibility.AsProtobufJSON {
    return {
      maximumLookbackWindowSeconds: this.maximumLookbackWindowSeconds,
      windowUsesFirstOpportunity: this.windowUsesFirstOpportunity,
    };
  }
}
export module AdvertiserEventEligibility {
  /**
   * Standard JavaScript object representation for AdvertiserEventEligibility
   */
  export interface AsObject {
    maximumLookbackWindowSeconds: number;
    windowUsesFirstOpportunity: boolean;
  }

  /**
   * Protobuf JSON representation for AdvertiserEventEligibility
   */
  export interface AsProtobufJSON {
    maximumLookbackWindowSeconds: number;
    windowUsesFirstOpportunity: boolean;
  }
}

/**
 * Message implementation for lift.GroupConfig
 */
export class GroupConfig implements GrpcMessage {
  static id = 'lift.GroupConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GroupConfig();
    GroupConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GroupConfig) {
    _instance.publisherAttributes = _instance.publisherAttributes || [];
    _instance.advertiserAttributes = _instance.advertiserAttributes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GroupConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.publisherAttributes =
            _instance.publisherAttributes || []).push(_reader.readString());
          break;
        case 2:
          (_instance.advertiserAttributes =
            _instance.advertiserAttributes || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    GroupConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GroupConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherAttributes && _instance.publisherAttributes.length) {
      _writer.writeRepeatedString(1, _instance.publisherAttributes);
    }
    if (
      _instance.advertiserAttributes &&
      _instance.advertiserAttributes.length
    ) {
      _writer.writeRepeatedString(2, _instance.advertiserAttributes);
    }
  }

  private _publisherAttributes: string[];
  private _advertiserAttributes: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GroupConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<GroupConfig.AsObject>) {
    _value = _value || {};
    this.publisherAttributes = (_value.publisherAttributes || []).slice();
    this.advertiserAttributes = (_value.advertiserAttributes || []).slice();
    GroupConfig.refineValues(this);
  }
  get publisherAttributes(): string[] {
    return this._publisherAttributes;
  }
  set publisherAttributes(value: string[]) {
    this._publisherAttributes = value;
  }
  get advertiserAttributes(): string[] {
    return this._advertiserAttributes;
  }
  set advertiserAttributes(value: string[]) {
    this._advertiserAttributes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GroupConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GroupConfig.AsObject {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
      advertiserAttributes: (this.advertiserAttributes || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GroupConfig.AsProtobufJSON {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
      advertiserAttributes: (this.advertiserAttributes || []).slice(),
    };
  }
}
export module GroupConfig {
  /**
   * Standard JavaScript object representation for GroupConfig
   */
  export interface AsObject {
    publisherAttributes: string[];
    advertiserAttributes: string[];
  }

  /**
   * Protobuf JSON representation for GroupConfig
   */
  export interface AsProtobufJSON {
    publisherAttributes: string[];
    advertiserAttributes: string[];
  }
}

/**
 * Message implementation for lift.Metric
 */
export class Metric implements GrpcMessage {
  static id = 'lift.Metric';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Metric();
    Metric.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Metric) {
    _instance.advertiserEventType = _instance.advertiserEventType || 0;
    _instance.metricType = _instance.metricType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Metric, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserEventType = _reader.readEnum();
          break;
        case 2:
          _instance.metricType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    Metric.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Metric, _writer: BinaryWriter) {
    if (_instance.advertiserEventType) {
      _writer.writeEnum(1, _instance.advertiserEventType);
    }
    if (_instance.metricType) {
      _writer.writeEnum(2, _instance.metricType);
    }
  }

  private _advertiserEventType: attribution001.AdvertiserEventType;
  private _metricType: MetricType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Metric to deeply clone from
   */
  constructor(_value?: RecursivePartial<Metric.AsObject>) {
    _value = _value || {};
    this.advertiserEventType = _value.advertiserEventType;
    this.metricType = _value.metricType;
    Metric.refineValues(this);
  }
  get advertiserEventType(): attribution001.AdvertiserEventType {
    return this._advertiserEventType;
  }
  set advertiserEventType(value: attribution001.AdvertiserEventType) {
    this._advertiserEventType = value;
  }
  get metricType(): MetricType {
    return this._metricType;
  }
  set metricType(value: MetricType) {
    this._metricType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Metric.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Metric.AsObject {
    return {
      advertiserEventType: this.advertiserEventType,
      metricType: this.metricType,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Metric.AsProtobufJSON {
    return {
      advertiserEventType:
        attribution001.AdvertiserEventType[
          this.advertiserEventType === null ||
          this.advertiserEventType === undefined
            ? 0
            : this.advertiserEventType
        ],
      metricType:
        MetricType[
          this.metricType === null || this.metricType === undefined
            ? 0
            : this.metricType
        ],
    };
  }
}
export module Metric {
  /**
   * Standard JavaScript object representation for Metric
   */
  export interface AsObject {
    advertiserEventType: attribution001.AdvertiserEventType;
    metricType: MetricType;
  }

  /**
   * Protobuf JSON representation for Metric
   */
  export interface AsProtobufJSON {
    advertiserEventType: string;
    metricType: string;
  }
}

/**
 * Message implementation for lift.LiftEstimationConfig
 */
export class LiftEstimationConfig implements GrpcMessage {
  static id = 'lift.LiftEstimationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LiftEstimationConfig();
    LiftEstimationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LiftEstimationConfig) {
    _instance.threshold = _instance.threshold || 0;
    _instance.thresholdConverters = _instance.thresholdConverters || 0;
    _instance.liftAlgorithm = _instance.liftAlgorithm || 0;
    _instance.privacyConfig = _instance.privacyConfig || undefined;
    _instance.sensitivityConfig = _instance.sensitivityConfig || undefined;
    _instance.liftOutputSet = _instance.liftOutputSet || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LiftEstimationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 8:
          _instance.threshold = _reader.readInt32();
          break;
        case 10:
          _instance.thresholdConverters = _reader.readInt32();
          break;
        case 3:
          _instance.liftAlgorithm = _reader.readEnum();
          break;
        case 4:
          _instance.privacyConfig = new PrivacyConfig();
          _reader.readMessage(
            _instance.privacyConfig,
            PrivacyConfig.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.sensitivityConfig = new SensitivityConfig();
          _reader.readMessage(
            _instance.sensitivityConfig,
            SensitivityConfig.deserializeBinaryFromReader
          );
          break;
        case 9:
          _instance.liftOutputSet = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    LiftEstimationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LiftEstimationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.threshold) {
      _writer.writeInt32(8, _instance.threshold);
    }
    if (_instance.thresholdConverters) {
      _writer.writeInt32(10, _instance.thresholdConverters);
    }
    if (_instance.liftAlgorithm) {
      _writer.writeEnum(3, _instance.liftAlgorithm);
    }
    if (_instance.privacyConfig) {
      _writer.writeMessage(
        4,
        _instance.privacyConfig as any,
        PrivacyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.sensitivityConfig) {
      _writer.writeMessage(
        5,
        _instance.sensitivityConfig as any,
        SensitivityConfig.serializeBinaryToWriter
      );
    }
    if (_instance.liftOutputSet) {
      _writer.writeEnum(9, _instance.liftOutputSet);
    }
  }

  private _threshold: number;
  private _thresholdConverters: number;
  private _liftAlgorithm: LiftAlgorithm;
  private _privacyConfig?: PrivacyConfig;
  private _sensitivityConfig?: SensitivityConfig;
  private _liftOutputSet: LiftOutputSet;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LiftEstimationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<LiftEstimationConfig.AsObject>) {
    _value = _value || {};
    this.threshold = _value.threshold;
    this.thresholdConverters = _value.thresholdConverters;
    this.liftAlgorithm = _value.liftAlgorithm;
    this.privacyConfig = _value.privacyConfig
      ? new PrivacyConfig(_value.privacyConfig)
      : undefined;
    this.sensitivityConfig = _value.sensitivityConfig
      ? new SensitivityConfig(_value.sensitivityConfig)
      : undefined;
    this.liftOutputSet = _value.liftOutputSet;
    LiftEstimationConfig.refineValues(this);
  }
  get threshold(): number {
    return this._threshold;
  }
  set threshold(value: number) {
    this._threshold = value;
  }
  get thresholdConverters(): number {
    return this._thresholdConverters;
  }
  set thresholdConverters(value: number) {
    this._thresholdConverters = value;
  }
  get liftAlgorithm(): LiftAlgorithm {
    return this._liftAlgorithm;
  }
  set liftAlgorithm(value: LiftAlgorithm) {
    this._liftAlgorithm = value;
  }
  get privacyConfig(): PrivacyConfig | undefined {
    return this._privacyConfig;
  }
  set privacyConfig(value: PrivacyConfig | undefined) {
    this._privacyConfig = value;
  }
  get sensitivityConfig(): SensitivityConfig | undefined {
    return this._sensitivityConfig;
  }
  set sensitivityConfig(value: SensitivityConfig | undefined) {
    this._sensitivityConfig = value;
  }
  get liftOutputSet(): LiftOutputSet {
    return this._liftOutputSet;
  }
  set liftOutputSet(value: LiftOutputSet) {
    this._liftOutputSet = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LiftEstimationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LiftEstimationConfig.AsObject {
    return {
      threshold: this.threshold,
      thresholdConverters: this.thresholdConverters,
      liftAlgorithm: this.liftAlgorithm,
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toObject()
        : undefined,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toObject()
        : undefined,
      liftOutputSet: this.liftOutputSet,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LiftEstimationConfig.AsProtobufJSON {
    return {
      threshold: this.threshold,
      thresholdConverters: this.thresholdConverters,
      liftAlgorithm:
        LiftAlgorithm[
          this.liftAlgorithm === null || this.liftAlgorithm === undefined
            ? 0
            : this.liftAlgorithm
        ],
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toProtobufJSON(options)
        : null,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toProtobufJSON(options)
        : null,
      liftOutputSet:
        LiftOutputSet[
          this.liftOutputSet === null || this.liftOutputSet === undefined
            ? 0
            : this.liftOutputSet
        ],
    };
  }
}
export module LiftEstimationConfig {
  /**
   * Standard JavaScript object representation for LiftEstimationConfig
   */
  export interface AsObject {
    threshold: number;
    thresholdConverters: number;
    liftAlgorithm: LiftAlgorithm;
    privacyConfig?: PrivacyConfig.AsObject;
    sensitivityConfig?: SensitivityConfig.AsObject;
    liftOutputSet: LiftOutputSet;
  }

  /**
   * Protobuf JSON representation for LiftEstimationConfig
   */
  export interface AsProtobufJSON {
    threshold: number;
    thresholdConverters: number;
    liftAlgorithm: string;
    privacyConfig: PrivacyConfig.AsProtobufJSON | null;
    sensitivityConfig: SensitivityConfig.AsProtobufJSON | null;
    liftOutputSet: string;
  }
}

/**
 * Message implementation for lift.PrivacyConfig
 */
export class PrivacyConfig implements GrpcMessage {
  static id = 'lift.PrivacyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PrivacyConfig();
    PrivacyConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PrivacyConfig) {
    _instance.rho = _instance.rho || 0;
    _instance.rhoDivision = _instance.rhoDivision || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PrivacyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rho = _reader.readFloat();
          break;
        case 2:
          (_instance.rhoDivision = _instance.rhoDivision || []).push(
            ...(_reader.readPackedFloat() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PrivacyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PrivacyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.rho) {
      _writer.writeFloat(1, _instance.rho);
    }
    if (_instance.rhoDivision && _instance.rhoDivision.length) {
      _writer.writePackedFloat(2, _instance.rhoDivision);
    }
  }

  private _rho: number;
  private _rhoDivision: number[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PrivacyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<PrivacyConfig.AsObject>) {
    _value = _value || {};
    this.rho = _value.rho;
    this.rhoDivision = (_value.rhoDivision || []).slice();
    PrivacyConfig.refineValues(this);
  }
  get rho(): number {
    return this._rho;
  }
  set rho(value: number) {
    this._rho = value;
  }
  get rhoDivision(): number[] {
    return this._rhoDivision;
  }
  set rhoDivision(value: number[]) {
    this._rhoDivision = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PrivacyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PrivacyConfig.AsObject {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PrivacyConfig.AsProtobufJSON {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }
}
export module PrivacyConfig {
  /**
   * Standard JavaScript object representation for PrivacyConfig
   */
  export interface AsObject {
    rho: number;
    rhoDivision: number[];
  }

  /**
   * Protobuf JSON representation for PrivacyConfig
   */
  export interface AsProtobufJSON {
    rho: number;
    rhoDivision: number[];
  }
}

/**
 * Message implementation for lift.SensitivityConfig
 */
export class SensitivityConfig implements GrpcMessage {
  static id = 'lift.SensitivityConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SensitivityConfig();
    SensitivityConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SensitivityConfig) {
    _instance.percentile = _instance.percentile || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SensitivityConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.percentile = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    SensitivityConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SensitivityConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.percentile) {
      _writer.writeFloat(1, _instance.percentile);
    }
  }

  private _percentile: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SensitivityConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<SensitivityConfig.AsObject>) {
    _value = _value || {};
    this.percentile = _value.percentile;
    SensitivityConfig.refineValues(this);
  }
  get percentile(): number {
    return this._percentile;
  }
  set percentile(value: number) {
    this._percentile = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SensitivityConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SensitivityConfig.AsObject {
    return {
      percentile: this.percentile,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SensitivityConfig.AsProtobufJSON {
    return {
      percentile: this.percentile,
    };
  }
}
export module SensitivityConfig {
  /**
   * Standard JavaScript object representation for SensitivityConfig
   */
  export interface AsObject {
    percentile: number;
  }

  /**
   * Protobuf JSON representation for SensitivityConfig
   */
  export interface AsProtobufJSON {
    percentile: number;
  }
}
