import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DownloadSourceCodeComponent } from 'app/modals/download-source-code-modal/download-source-code-modal.component';
import { AppRoutingModule } from 'app/routing/app-routing.module';
import {
  AddApprovalDecisionArgs,
  ApprovalService,
} from 'app/services/approval.service';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { DocumentService } from 'app/services/document.service';
import { FormatService } from 'app/services/format.service';
import { ApiApprovalRequest } from 'app/services/generated/src/main/proto/api/approval-service.pb';
import {
  ApprovalDecision,
  AuditArea,
  AuditAreaApprovalState,
} from 'app/services/generated/src/main/proto/storage/approval.pb';
import { LoggerService } from 'app/services/logger.service';
import { SourceCodeService } from 'app/services/source-code.service';
import { UserService } from 'app/services/user.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';
import { firstValueFrom } from 'rxjs';
import { DocumentType } from 'types/document';

import { MessageBoxComponent } from '../../shared/components/message-box/message-box.component';
import {
  APPROVAL_STATE,
  APPROVAL_STATE_ICONS,
  APPROVAL_STATE_LABEL,
  AUDIT_AREA,
  AUDIT_AREA_DOCS_URL,
} from '../constants';
import { BinaryDetailsDialogComponent } from './binary-details-dialog/binary-details-dialog.component';
import { DecisionFlagComponent } from './decision-flag/decision-flag.component';

const START_NOTES_COMMENT = '[//]: # (--start-notes-displayed-in-portal--)';
const END_NOTES_COMMENT = '[//]: # (--end-notes-displayed-in-portal--)';
const PAGE_SIZE = 25;

@Component({
  selector: 'app-approve',
  templateUrl: './approval-detail.component.html',
  styleUrls: [
    './approval-detail.component.scss',
    '../audit-approval.component.scss',
  ],
  imports: [
    AppRoutingModule,
    CommonModule,
    DecisionFlagComponent,
    MarkdownModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MessageBoxComponent,
  ],
  standalone: true,
})
export class ApprovalDetailComponent implements OnInit {
  binaryDescription: string | undefined;
  binaryDocumentation: DocumentType | undefined;
  binaryVersions: string[] = [];
  releaseNotes: string | undefined;
  changeLogText: string | null = null;
  customerId: string | undefined;
  displayVersionDropdown: boolean = false;
  state =
    APPROVAL_STATE[
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED
    ];
  stateIcon =
    APPROVAL_STATE_ICONS[
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED
    ];
  area = AUDIT_AREA[AuditArea.AUDIT_AREA_UNSPECIFIED];
  areaDocsUrl = AUDIT_AREA_DOCS_URL[AuditArea.AUDIT_AREA_UNSPECIFIED];
  stateLabel =
    APPROVAL_STATE_LABEL[
      AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED
    ];
  decisionTime: string | undefined;
  isApproved = false;
  isDisapproved = false;
  isUpdating = false;
  isLoading = false;
  isReleaseText = false;

  @HostBinding('class') get className() {
    return this.state;
  }
  @Input() displaySourceIcon = false;
  @Input() isApprover = false;
  @Input() approval: ApiApprovalRequest | undefined;
  @Input() isOpen = false;
  @Input() onToggle: ((id: AuditArea | undefined) => void) | undefined;
  @Input()
  set decisions(decisions: ApprovalDecision[] | undefined) {
    this._decisions = decisions;

    if (decisions) {
      const approvalState = decisions[0].auditAreaApprovalState;
      this.decisionTime = this.formatService.formatProtoDateTime(
        decisions[0].time
      );

      if (approvalState) {
        this.state = APPROVAL_STATE[approvalState];
        this.stateIcon = APPROVAL_STATE_ICONS[approvalState];
        this.stateLabel = APPROVAL_STATE_LABEL[approvalState];

        this.isApproved =
          approvalState ===
          AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED;
        this.isDisapproved =
          approvalState ===
          AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED;
      }

      this.area =
        AUDIT_AREA[decisions[0].auditArea ?? AuditArea.AUDIT_AREA_UNSPECIFIED];
      this.areaDocsUrl =
        AUDIT_AREA_DOCS_URL[
          decisions[0].auditArea ?? AuditArea.AUDIT_AREA_UNSPECIFIED
        ];
    }
  }
  get decision() {
    if (!this._decisions) {
      return undefined;
    } else {
      return this._decisions[0];
    }
  }
  private _decisions: ApprovalDecision[] | undefined;
  @Output() approvalUpdatedEvent = new EventEmitter<ApiApprovalRequest>();

  constructor(
    private analytics: AngularFireAnalytics,
    private approvalService: ApprovalService,
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private documentService: DocumentService,
    private domSanitizer: DomSanitizer,
    private formatService: FormatService,
    private http: HttpClient,
    private logger: LoggerService,
    private markdownService: MarkdownService,
    private matIconRegistry: MatIconRegistry,
    private messageBox: MessageBoxProvider,
    private router: Router,
    private sourceCodeService: SourceCodeService,
    private userService: UserService
  ) {
    this.matIconRegistry.addSvgIcon(
      'knowledge_base',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `assets/knowledge_base.svg`
      )
    );
  }

  async ngOnInit() {
    this.getCustomerId();
    this.getBinaryDescription();
    this.getChangelogText();
    this.getDocumentationLink();
  }

  approve() {
    this.addApprovalDecision({
      auditAppprovalState:
        AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_APPROVED,
      reason: 'User approved.',
    });
  }

  async addApprovalDecision(
    args: Pick<AddApprovalDecisionArgs, 'auditAppprovalState' | 'reason'>
  ) {
    const approval = this.approval;
    if (
      !approval ||
      !this._decisions ||
      !approval.decisionRollup?.customerId ||
      !this.isApprover
    ) {
      this.logger.error('Error adding approval decision', {
        approval,
        decisions: this._decisions,
        isApprover: this.isApprover,
      });
      return;
    }

    this.isLoading = true;
    try {
      for (const decision of this._decisions) {
        await this.approvalService.addApprovalDecision({
          approvalRequestId: approval.id,
          auditArea: decision.auditArea,
          customerId: decision.customerId,
          ...args,
        });
      }
    } catch (error) {
      this.logger.error('Error adding approval decision', error);
      this.messageBox.error('Error adding approval decision');
    } finally {
      this.isLoading = false;
      const reload = await this.approvalService.getApproval(approval.id);
      this.approvalUpdatedEvent.emit(reload.approvalRequests?.[0]);
    }
  }

  async disapprove() {
    this.addApprovalDecision({
      auditAppprovalState:
        AuditAreaApprovalState.AUDIT_AREA_APPROVAL_STATE_DISAPPROVED,
      reason: 'User revoked.',
    });
  }

  getBinaryDescription() {
    this.binaryTypeService.getBinaryTypes().then((res) => {
      res.binaryTypeInfos?.forEach((binaryType) => {
        if (
          this.approval?.approvalRequestDetail?.change?.binaryInfo
            ?.binaryType === binaryType.binaryType
        ) {
          this.binaryDescription = binaryType.description;
        }
      });
    });
  }

  async getChangelogText() {
    const link = await this.sourceCodeService.getReleaseNotesLink(
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType,
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.version
    );
    if (link) {
      this.http.get(link, { responseType: 'text' }).subscribe(async (res) => {
        this.changeLogText = await this.markdownService.parse(
          this.formatMarkdown(res)
        );
        this.isReleaseText = true;
      });
    } else {
      this.isReleaseText = false;
      this.logger.info('No release notes link');
    }
  }

  async getCustomerId() {
    this.customerId = (await this.customerService.getCustomer()).customer?.id;
  }

  formatMarkdown(markdown: string) {
    const startIndex = markdown.indexOf(START_NOTES_COMMENT);
    const endIndex = markdown.indexOf(END_NOTES_COMMENT);

    if (startIndex > endIndex || endIndex === -1 || startIndex === -1) {
      return markdown;
    } else {
      return markdown.substring(startIndex, endIndex);
    }
  }

  openDetails() {
    this.dialog.open(BinaryDetailsDialogComponent, {
      data: {
        approval: this.approval,
      },
    });
  }

  prepareDownloadSourceCode() {
    this.isUpdating = true;
    this.userService
      .getSourceCodeAgreement()
      .then((data) => {
        if (data?.exists && data.get('accepted')) {
          this.downloadSourceCode();
        } else {
          this.showAgreementDialog();
        }
      })
      .catch((error) => {
        this.isUpdating = false;
        this.logger.error(error);
      })
      .finally(() => {
        this.isUpdating = false;
      });
  }

  showAgreementDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialog = this.dialog.open(DownloadSourceCodeComponent, dialogConfig);

    dialog.afterClosed().subscribe((result) => {
      const { accepted } = result;
      this.userService.setSourceCodeAgreement(accepted);
      if (accepted) {
        this.downloadSourceCode();
      }
    });
  }

  downloadSourceCode() {
    if (
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType &&
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.version
    ) {
      this.sourceCodeService
        .downloadSourceFileLink(
          this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType,
          this.approval?.approvalRequestDetail?.change?.binaryInfo?.version
        )
        .then((signedUrl: string | void) => {
          if (signedUrl) {
            window.open(signedUrl, '_blank');
          } else {
            this.logger.error('Source code is not available for download.');
          }
        });
    } else {
      this.logger.error('Binary Type and Version should not be empty');
    }
  }

  async showDiffDropdown() {
    this.isLoading = true;
    try {
      const binaryType =
        this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType;

      if (binaryType) {
        const response = await this.approvalService.getApprovals(
          PAGE_SIZE,
          null,
          binaryType,
          [this.customerId!]
        );

        if (response.approvalRequests) {
          this.binaryVersions.splice(0);
          response.approvalRequests.forEach((approval: ApiApprovalRequest) => {
            const version =
              approval.approvalRequestDetail?.change?.binaryInfo?.version;
            if (
              version &&
              version !==
                this.approval?.approvalRequestDetail?.change?.binaryInfo
                  ?.version
            ) {
              this.binaryVersions.push(version);
            }
          });
          this.binaryVersions.sort((a, b) => this.compareVersions(a, b));
          this.displayVersionDropdown = true;
          if (this.binaryVersions.length == 0) {
            this.binaryVersions.push('None');
          }
        }
      }
    } catch (error) {
      this.logger.error('Failed to load available file versions.', error);
      this.messageBox.error('Failed to load available file versions.');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * Compares two version numbers and returns a comparison result.
   *
   * @param version1 - The first version string to compare.
   * @param version2 - The second version string to compare.
   * @returns -1 if a < b, 1 if a > b, or 0 if equal.
   */
  compareVersions(version1: string, version2: string): number {
    const aParts = version1.split('.').map(Number);
    const bParts = version2.split('.').map(Number);
    const maxLength = Math.max(aParts.length, bParts.length);

    for (let i = 0; i < maxLength; i++) {
      const aPart = aParts[i] || 0; // Default to 0 if no more parts in a
      const bPart = bParts[i] || 0; // Default to 0 if no more parts in b

      if (aPart < bPart) return 1; // Swap return values for descending order
      if (aPart > bPart) return -1;
    }

    return 0; // Versions are equal
  }

  redirectToDocumentation() {
    if (this.binaryDocumentation) {
      try {
        const urlWithParams = new URL(location.origin);
        urlWithParams.pathname = 'documentation-view';
        urlWithParams.searchParams.set('get', this.binaryDocumentation.path);
        window.open(urlWithParams, '_blank');
        this.analytics.logEvent('opened-html', {
          name: this.binaryDocumentation.title,
          version: this.binaryDocumentation.version,
        });
      } catch (error) {
        this.analytics.logEvent('error-loading-pdf', {
          path: this.binaryDocumentation.title,
          version: this.binaryDocumentation.version,
        });
        this.logger.error('Unable to open file', error);
        this.messageBox.error('Unable to find file.');
      }
    }
  }

  async getDocumentationLink() {
    this.isLoading = true;

    if (
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType &&
      this.approval?.approvalRequestDetail?.change?.binaryInfo?.version
    ) {
      try {
        const results = await firstValueFrom(
          this.documentService.getBinaryDocumentationLink(
            this.approval?.approvalRequestDetail?.change?.binaryInfo
              ?.binaryType,
            this.approval?.approvalRequestDetail?.change?.binaryInfo?.version
          )
        );
        if (results && results.length > 0) {
          const document = results[0] as DocumentType;
          this.binaryDocumentation = document;
        }
      } catch (error) {
        this.logger.error('Unable to load document', error);
        this.messageBox.error('Unable to load document list.');
      } finally {
        this.isLoading = false;
      }
    }
  }

  onVersionSelect($event: Event) {
    this.router.navigate([
      `source-code-diff/${this.approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType}/${($event.target as HTMLSelectElement).value}/${this.approval?.approvalRequestDetail?.change?.binaryInfo?.version}/diff`,
    ]);
  }
}
