/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/commons.pb';
import * as storage003 from '../../../../src/main/proto/storage/schema-annotations.pb';
export enum KeyFileStructure {
  KEY_FILE_STRUCTURE_UNSPECIFIED = 0,
  KEY_FILE_STRUCTURE_SINGLE_KEY = 1,
  KEY_FILE_STRUCTURE_ONE_KEY_PER_DATA_FILE = 2,
}
/**
 * Message implementation for storage.CustomerDataSet
 */
export class CustomerDataSet implements GrpcMessage {
  static id = 'storage.CustomerDataSet';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSet();
    CustomerDataSet.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSet) {
    _instance.id = _instance.id || '';
    _instance.name = _instance.name || '';
    _instance.etag = _instance.etag || '';
    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.creationTimestamp = _instance.creationTimestamp || undefined;
    _instance.archived = _instance.archived || false;
    _instance.location = _instance.location || 0;
    _instance.customerId = _instance.customerId || '';
    _instance.state = _instance.state || 0;

    _instance.dated = _instance.dated || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSet,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        case 4:
          _instance.lastUpdate = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 15:
          _instance.creationTimestamp = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.creationTimestamp,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.archived = _reader.readBool();
          break;
        case 14:
          _instance.location = _reader.readEnum();
          break;
        case 6:
          _instance.customerId = _reader.readString();
          break;
        case 7:
          _instance.state = _reader.readEnum();
          break;
        case 13:
          _instance.externalBlobStorage = new ExternalBlobStorage();
          _reader.readMessage(
            _instance.externalBlobStorage,
            ExternalBlobStorage.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.encryptedData = new EncryptedDataConfig();
          _reader.readMessage(
            _instance.encryptedData,
            EncryptedDataConfig.deserializeBinaryFromReader
          );
          break;
        case 16:
          _instance.formatterConfig = new FormatterConfig();
          _reader.readMessage(
            _instance.formatterConfig,
            FormatterConfig.deserializeBinaryFromReader
          );
          break;
        case 17:
          _instance.dated = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSet.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSet,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        4,
        _instance.lastUpdate as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.creationTimestamp) {
      _writer.writeMessage(
        15,
        _instance.creationTimestamp as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(5, _instance.archived);
    }
    if (_instance.location) {
      _writer.writeEnum(14, _instance.location);
    }
    if (_instance.customerId) {
      _writer.writeString(6, _instance.customerId);
    }
    if (_instance.state) {
      _writer.writeEnum(7, _instance.state);
    }
    if (_instance.externalBlobStorage) {
      _writer.writeMessage(
        13,
        _instance.externalBlobStorage as any,
        ExternalBlobStorage.serializeBinaryToWriter
      );
    }
    if (_instance.encryptedData) {
      _writer.writeMessage(
        8,
        _instance.encryptedData as any,
        EncryptedDataConfig.serializeBinaryToWriter
      );
    }
    if (_instance.formatterConfig) {
      _writer.writeMessage(
        16,
        _instance.formatterConfig as any,
        FormatterConfig.serializeBinaryToWriter
      );
    }
    if (_instance.dated) {
      _writer.writeBool(17, _instance.dated);
    }
  }

  private _id: string;
  private _name: string;
  private _etag: string;
  private _lastUpdate?: googleProtobuf001.Timestamp;
  private _creationTimestamp?: googleProtobuf001.Timestamp;
  private _archived: boolean;
  private _location: storage002.Location;
  private _customerId: string;
  private _state: CustomerDataSet.DatasetState;
  private _externalBlobStorage?: ExternalBlobStorage;
  private _encryptedData?: EncryptedDataConfig;
  private _formatterConfig?: FormatterConfig;
  private _dated: boolean;

  private _externalDataSource: CustomerDataSet.ExternalDataSourceCase =
    CustomerDataSet.ExternalDataSourceCase.none;
  private _dataType: CustomerDataSet.DataTypeCase =
    CustomerDataSet.DataTypeCase.none;
  private _parserConfig: CustomerDataSet.ParserConfigCase =
    CustomerDataSet.ParserConfigCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSet to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerDataSet.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.name = _value.name;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf001.Timestamp(_value.lastUpdate)
      : undefined;
    this.creationTimestamp = _value.creationTimestamp
      ? new googleProtobuf001.Timestamp(_value.creationTimestamp)
      : undefined;
    this.archived = _value.archived;
    this.location = _value.location;
    this.customerId = _value.customerId;
    this.state = _value.state;
    this.externalBlobStorage = _value.externalBlobStorage
      ? new ExternalBlobStorage(_value.externalBlobStorage)
      : undefined;
    this.encryptedData = _value.encryptedData
      ? new EncryptedDataConfig(_value.encryptedData)
      : undefined;
    this.formatterConfig = _value.formatterConfig
      ? new FormatterConfig(_value.formatterConfig)
      : undefined;
    this.dated = _value.dated;
    CustomerDataSet.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf001.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf001.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get creationTimestamp(): googleProtobuf001.Timestamp | undefined {
    return this._creationTimestamp;
  }
  set creationTimestamp(value: googleProtobuf001.Timestamp | undefined) {
    this._creationTimestamp = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get location(): storage002.Location {
    return this._location;
  }
  set location(value: storage002.Location) {
    this._location = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get state(): CustomerDataSet.DatasetState {
    return this._state;
  }
  set state(value: CustomerDataSet.DatasetState) {
    this._state = value;
  }
  get externalBlobStorage(): ExternalBlobStorage | undefined {
    return this._externalBlobStorage;
  }
  set externalBlobStorage(value: ExternalBlobStorage | undefined) {
    if (value !== undefined && value !== null) {
      this._externalDataSource =
        CustomerDataSet.ExternalDataSourceCase.externalBlobStorage;
    }
    this._externalBlobStorage = value;
  }
  get encryptedData(): EncryptedDataConfig | undefined {
    return this._encryptedData;
  }
  set encryptedData(value: EncryptedDataConfig | undefined) {
    if (value !== undefined && value !== null) {
      this._dataType = CustomerDataSet.DataTypeCase.encryptedData;
    }
    this._encryptedData = value;
  }
  get formatterConfig(): FormatterConfig | undefined {
    return this._formatterConfig;
  }
  set formatterConfig(value: FormatterConfig | undefined) {
    if (value !== undefined && value !== null) {
      this._parserConfig = CustomerDataSet.ParserConfigCase.formatterConfig;
    }
    this._formatterConfig = value;
  }
  get dated(): boolean {
    return this._dated;
  }
  set dated(value: boolean) {
    this._dated = value;
  }
  get externalDataSource() {
    return this._externalDataSource;
  }
  get dataType() {
    return this._dataType;
  }
  get parserConfig() {
    return this._parserConfig;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSet.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSet.AsObject {
    return {
      id: this.id,
      name: this.name,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toObject()
        : undefined,
      archived: this.archived,
      location: this.location,
      customerId: this.customerId,
      state: this.state,
      externalBlobStorage: this.externalBlobStorage
        ? this.externalBlobStorage.toObject()
        : undefined,
      encryptedData: this.encryptedData
        ? this.encryptedData.toObject()
        : undefined,
      formatterConfig: this.formatterConfig
        ? this.formatterConfig.toObject()
        : undefined,
      dated: this.dated,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSet.AsProtobufJSON {
    return {
      id: this.id,
      name: this.name,
      etag: this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      creationTimestamp: this.creationTimestamp
        ? this.creationTimestamp.toProtobufJSON(options)
        : null,
      archived: this.archived,
      location:
        storage002.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
      customerId: this.customerId,
      state:
        CustomerDataSet.DatasetState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      externalBlobStorage: this.externalBlobStorage
        ? this.externalBlobStorage.toProtobufJSON(options)
        : null,
      encryptedData: this.encryptedData
        ? this.encryptedData.toProtobufJSON(options)
        : null,
      formatterConfig: this.formatterConfig
        ? this.formatterConfig.toProtobufJSON(options)
        : null,
      dated: this.dated,
    };
  }
}
export module CustomerDataSet {
  /**
   * Standard JavaScript object representation for CustomerDataSet
   */
  export interface AsObject {
    id: string;
    name: string;
    etag: string;
    lastUpdate?: googleProtobuf001.Timestamp.AsObject;
    creationTimestamp?: googleProtobuf001.Timestamp.AsObject;
    archived: boolean;
    location: storage002.Location;
    customerId: string;
    state: CustomerDataSet.DatasetState;
    externalBlobStorage?: ExternalBlobStorage.AsObject;
    encryptedData?: EncryptedDataConfig.AsObject;
    formatterConfig?: FormatterConfig.AsObject;
    dated: boolean;
  }

  /**
   * Protobuf JSON representation for CustomerDataSet
   */
  export interface AsProtobufJSON {
    id: string;
    name: string;
    etag: string;
    lastUpdate: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    creationTimestamp: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    archived: boolean;
    location: string;
    customerId: string;
    state: string;
    externalBlobStorage: ExternalBlobStorage.AsProtobufJSON | null;
    encryptedData: EncryptedDataConfig.AsProtobufJSON | null;
    formatterConfig: FormatterConfig.AsProtobufJSON | null;
    dated: boolean;
  }
  export enum ExternalDataSourceCase {
    none = 0,
    externalBlobStorage = 1,
  }
  export enum DataTypeCase {
    none = 0,
    encryptedData = 1,
  }
  export enum ParserConfigCase {
    none = 0,
    formatterConfig = 1,
  }
  export enum DatasetState {
    DATASET_STATE_UNSPECIFIED = 0,
    DATASET_STATE_DRAFT = 1,
    DATASET_STATE_FINALIZED = 2,
  }
}

/**
 * Message implementation for storage.ExternalBlobStorage
 */
export class ExternalBlobStorage implements GrpcMessage {
  static id = 'storage.ExternalBlobStorage';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ExternalBlobStorage();
    ExternalBlobStorage.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ExternalBlobStorage) {
    _instance.dataPathPrefix = _instance.dataPathPrefix || '';
    _instance.encryptedSymmetricKeyPath =
      _instance.encryptedSymmetricKeyPath || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ExternalBlobStorage,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.dataPathPrefix = _reader.readString();
          break;
        case 4:
          _instance.encryptedSymmetricKeyPath = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ExternalBlobStorage.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ExternalBlobStorage,
    _writer: BinaryWriter
  ) {
    if (_instance.dataPathPrefix) {
      _writer.writeString(1, _instance.dataPathPrefix);
    }
    if (_instance.encryptedSymmetricKeyPath) {
      _writer.writeString(4, _instance.encryptedSymmetricKeyPath);
    }
  }

  private _dataPathPrefix: string;
  private _encryptedSymmetricKeyPath: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ExternalBlobStorage to deeply clone from
   */
  constructor(_value?: RecursivePartial<ExternalBlobStorage.AsObject>) {
    _value = _value || {};
    this.dataPathPrefix = _value.dataPathPrefix;
    this.encryptedSymmetricKeyPath = _value.encryptedSymmetricKeyPath;
    ExternalBlobStorage.refineValues(this);
  }
  get dataPathPrefix(): string {
    return this._dataPathPrefix;
  }
  set dataPathPrefix(value: string) {
    this._dataPathPrefix = value;
  }
  get encryptedSymmetricKeyPath(): string {
    return this._encryptedSymmetricKeyPath;
  }
  set encryptedSymmetricKeyPath(value: string) {
    this._encryptedSymmetricKeyPath = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ExternalBlobStorage.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ExternalBlobStorage.AsObject {
    return {
      dataPathPrefix: this.dataPathPrefix,
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ExternalBlobStorage.AsProtobufJSON {
    return {
      dataPathPrefix: this.dataPathPrefix,
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
    };
  }
}
export module ExternalBlobStorage {
  /**
   * Standard JavaScript object representation for ExternalBlobStorage
   */
  export interface AsObject {
    dataPathPrefix: string;
    encryptedSymmetricKeyPath: string;
  }

  /**
   * Protobuf JSON representation for ExternalBlobStorage
   */
  export interface AsProtobufJSON {
    dataPathPrefix: string;
    encryptedSymmetricKeyPath: string;
  }
}

/**
 * Message implementation for storage.UnencryptedDataConfig
 */
export class UnencryptedDataConfig implements GrpcMessage {
  static id = 'storage.UnencryptedDataConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UnencryptedDataConfig();
    UnencryptedDataConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UnencryptedDataConfig) {
    _instance.dataPathPrefix = _instance.dataPathPrefix || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UnencryptedDataConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 3:
          _instance.dataPathPrefix = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UnencryptedDataConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UnencryptedDataConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.dataPathPrefix) {
      _writer.writeString(3, _instance.dataPathPrefix);
    }
  }

  private _dataPathPrefix: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UnencryptedDataConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<UnencryptedDataConfig.AsObject>) {
    _value = _value || {};
    this.dataPathPrefix = _value.dataPathPrefix;
    UnencryptedDataConfig.refineValues(this);
  }
  get dataPathPrefix(): string {
    return this._dataPathPrefix;
  }
  set dataPathPrefix(value: string) {
    this._dataPathPrefix = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UnencryptedDataConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UnencryptedDataConfig.AsObject {
    return {
      dataPathPrefix: this.dataPathPrefix,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UnencryptedDataConfig.AsProtobufJSON {
    return {
      dataPathPrefix: this.dataPathPrefix,
    };
  }
}
export module UnencryptedDataConfig {
  /**
   * Standard JavaScript object representation for UnencryptedDataConfig
   */
  export interface AsObject {
    dataPathPrefix: string;
  }

  /**
   * Protobuf JSON representation for UnencryptedDataConfig
   */
  export interface AsProtobufJSON {
    dataPathPrefix: string;
  }
}

/**
 * Message implementation for storage.EncryptedDataConfig
 */
export class EncryptedDataConfig implements GrpcMessage {
  static id = 'storage.EncryptedDataConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EncryptedDataConfig();
    EncryptedDataConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EncryptedDataConfig) {
    _instance.keyId = _instance.keyId || '';
    _instance.keyVersion = _instance.keyVersion || '';
    _instance.dataPathPrefix = _instance.dataPathPrefix || '';
    _instance.encryptedSymmetricKeyPath =
      _instance.encryptedSymmetricKeyPath || '';
    _instance.keyFileStructure = _instance.keyFileStructure || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EncryptedDataConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.keyId = _reader.readString();
          break;
        case 2:
          _instance.keyVersion = _reader.readString();
          break;
        case 3:
          _instance.dataPathPrefix = _reader.readString();
          break;
        case 4:
          _instance.encryptedSymmetricKeyPath = _reader.readString();
          break;
        case 5:
          _instance.keyFileStructure = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    EncryptedDataConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EncryptedDataConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.keyId) {
      _writer.writeString(1, _instance.keyId);
    }
    if (_instance.keyVersion) {
      _writer.writeString(2, _instance.keyVersion);
    }
    if (_instance.dataPathPrefix) {
      _writer.writeString(3, _instance.dataPathPrefix);
    }
    if (_instance.encryptedSymmetricKeyPath) {
      _writer.writeString(4, _instance.encryptedSymmetricKeyPath);
    }
    if (_instance.keyFileStructure) {
      _writer.writeEnum(5, _instance.keyFileStructure);
    }
  }

  private _keyId: string;
  private _keyVersion: string;
  private _dataPathPrefix: string;
  private _encryptedSymmetricKeyPath: string;
  private _keyFileStructure: KeyFileStructure;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EncryptedDataConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<EncryptedDataConfig.AsObject>) {
    _value = _value || {};
    this.keyId = _value.keyId;
    this.keyVersion = _value.keyVersion;
    this.dataPathPrefix = _value.dataPathPrefix;
    this.encryptedSymmetricKeyPath = _value.encryptedSymmetricKeyPath;
    this.keyFileStructure = _value.keyFileStructure;
    EncryptedDataConfig.refineValues(this);
  }
  get keyId(): string {
    return this._keyId;
  }
  set keyId(value: string) {
    this._keyId = value;
  }
  get keyVersion(): string {
    return this._keyVersion;
  }
  set keyVersion(value: string) {
    this._keyVersion = value;
  }
  get dataPathPrefix(): string {
    return this._dataPathPrefix;
  }
  set dataPathPrefix(value: string) {
    this._dataPathPrefix = value;
  }
  get encryptedSymmetricKeyPath(): string {
    return this._encryptedSymmetricKeyPath;
  }
  set encryptedSymmetricKeyPath(value: string) {
    this._encryptedSymmetricKeyPath = value;
  }
  get keyFileStructure(): KeyFileStructure {
    return this._keyFileStructure;
  }
  set keyFileStructure(value: KeyFileStructure) {
    this._keyFileStructure = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EncryptedDataConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EncryptedDataConfig.AsObject {
    return {
      keyId: this.keyId,
      keyVersion: this.keyVersion,
      dataPathPrefix: this.dataPathPrefix,
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
      keyFileStructure: this.keyFileStructure,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EncryptedDataConfig.AsProtobufJSON {
    return {
      keyId: this.keyId,
      keyVersion: this.keyVersion,
      dataPathPrefix: this.dataPathPrefix,
      encryptedSymmetricKeyPath: this.encryptedSymmetricKeyPath,
      keyFileStructure:
        KeyFileStructure[
          this.keyFileStructure === null || this.keyFileStructure === undefined
            ? 0
            : this.keyFileStructure
        ],
    };
  }
}
export module EncryptedDataConfig {
  /**
   * Standard JavaScript object representation for EncryptedDataConfig
   */
  export interface AsObject {
    keyId: string;
    keyVersion: string;
    dataPathPrefix: string;
    encryptedSymmetricKeyPath: string;
    keyFileStructure: KeyFileStructure;
  }

  /**
   * Protobuf JSON representation for EncryptedDataConfig
   */
  export interface AsProtobufJSON {
    keyId: string;
    keyVersion: string;
    dataPathPrefix: string;
    encryptedSymmetricKeyPath: string;
    keyFileStructure: string;
  }
}

/**
 * Message implementation for storage.FormatterConfig
 */
export class FormatterConfig implements GrpcMessage {
  static id = 'storage.FormatterConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FormatterConfig();
    FormatterConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FormatterConfig) {
    _instance.columnNameMap = _instance.columnNameMap || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FormatterConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            FormatterConfig.ColumnNameMapEntry.deserializeBinaryFromReader
          );
          _instance.columnNameMap = _instance.columnNameMap || {};
          _instance.columnNameMap[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    FormatterConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FormatterConfig,
    _writer: BinaryWriter
  ) {
    if (!!_instance.columnNameMap) {
      const keys_1 = Object.keys(_instance.columnNameMap as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({
            key: key,
            value: (_instance.columnNameMap as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          FormatterConfig.ColumnNameMapEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _columnNameMap: { [prop: string]: FieldInfo };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FormatterConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<FormatterConfig.AsObject>) {
    _value = _value || {};
    (this.columnNameMap = _value!.columnNameMap
      ? Object.keys(_value!.columnNameMap).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.columnNameMap![k]
              ? new FieldInfo(_value!.columnNameMap![k])
              : undefined,
          }),
          {}
        )
      : {}),
      FormatterConfig.refineValues(this);
  }
  get columnNameMap(): { [prop: string]: FieldInfo } {
    return this._columnNameMap;
  }
  set columnNameMap(value: { [prop: string]: FieldInfo }) {
    this._columnNameMap = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FormatterConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FormatterConfig.AsObject {
    return {
      columnNameMap: this.columnNameMap
        ? Object.keys(this.columnNameMap).reduce(
            (r, k) => ({
              ...r,
              [k]: this.columnNameMap![k]
                ? this.columnNameMap![k].toObject()
                : undefined,
            }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FormatterConfig.AsProtobufJSON {
    return {
      columnNameMap: this.columnNameMap
        ? Object.keys(this.columnNameMap).reduce(
            (r, k) => ({
              ...r,
              [k]: this.columnNameMap![k]
                ? this.columnNameMap![k].toJSON()
                : null,
            }),
            {}
          )
        : {},
    };
  }
}
export module FormatterConfig {
  /**
   * Standard JavaScript object representation for FormatterConfig
   */
  export interface AsObject {
    columnNameMap: { [prop: string]: FieldInfo };
  }

  /**
   * Protobuf JSON representation for FormatterConfig
   */
  export interface AsProtobufJSON {
    columnNameMap: { [prop: string]: FieldInfo };
  }

  /**
   * Message implementation for storage.FormatterConfig.ColumnNameMapEntry
   */
  export class ColumnNameMapEntry implements GrpcMessage {
    static id = 'storage.FormatterConfig.ColumnNameMapEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ColumnNameMapEntry();
      ColumnNameMapEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ColumnNameMapEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ColumnNameMapEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new FieldInfo();
            _reader.readMessage(
              _instance.value,
              FieldInfo.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      ColumnNameMapEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ColumnNameMapEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          FieldInfo.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: FieldInfo;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ColumnNameMapEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ColumnNameMapEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value ? new FieldInfo(_value.value) : undefined;
      ColumnNameMapEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): FieldInfo | undefined {
      return this._value;
    }
    set value(value: FieldInfo | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ColumnNameMapEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ColumnNameMapEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ColumnNameMapEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module ColumnNameMapEntry {
    /**
     * Standard JavaScript object representation for ColumnNameMapEntry
     */
    export interface AsObject {
      key: string;
      value?: FieldInfo.AsObject;
    }

    /**
     * Protobuf JSON representation for ColumnNameMapEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: FieldInfo.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for storage.FieldInfo
 */
export class FieldInfo implements GrpcMessage {
  static id = 'storage.FieldInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FieldInfo();
    FieldInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FieldInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FieldInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.column = _reader.readInt32();
          break;
        case 3:
          _instance.timeFormat = new TimeFormat();
          _reader.readMessage(
            _instance.timeFormat,
            TimeFormat.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.categoryFormat = new CategoryFormat();
          _reader.readMessage(
            _instance.categoryFormat,
            CategoryFormat.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    FieldInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: FieldInfo, _writer: BinaryWriter) {
    if (_instance.name || _instance.name === '') {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.column || _instance.column === 0) {
      _writer.writeInt32(2, _instance.column);
    }
    if (_instance.timeFormat) {
      _writer.writeMessage(
        3,
        _instance.timeFormat as any,
        TimeFormat.serializeBinaryToWriter
      );
    }
    if (_instance.categoryFormat) {
      _writer.writeMessage(
        4,
        _instance.categoryFormat as any,
        CategoryFormat.serializeBinaryToWriter
      );
    }
  }

  private _name: string;
  private _column: number;
  private _timeFormat?: TimeFormat;
  private _categoryFormat?: CategoryFormat;

  private _fieldLocator: FieldInfo.FieldLocatorCase =
    FieldInfo.FieldLocatorCase.none;
  private _typeFormat: FieldInfo.TypeFormatCase = FieldInfo.TypeFormatCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FieldInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<FieldInfo.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.column = _value.column;
    this.timeFormat = _value.timeFormat
      ? new TimeFormat(_value.timeFormat)
      : undefined;
    this.categoryFormat = _value.categoryFormat
      ? new CategoryFormat(_value.categoryFormat)
      : undefined;
    FieldInfo.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    if (value !== undefined && value !== null) {
      this._column = undefined;
      this._fieldLocator = FieldInfo.FieldLocatorCase.name;
    }
    this._name = value;
  }
  get column(): number {
    return this._column;
  }
  set column(value: number) {
    if (value !== undefined && value !== null) {
      this._name = undefined;
      this._fieldLocator = FieldInfo.FieldLocatorCase.column;
    }
    this._column = value;
  }
  get timeFormat(): TimeFormat | undefined {
    return this._timeFormat;
  }
  set timeFormat(value: TimeFormat | undefined) {
    if (value !== undefined && value !== null) {
      this._categoryFormat = undefined;
      this._typeFormat = FieldInfo.TypeFormatCase.timeFormat;
    }
    this._timeFormat = value;
  }
  get categoryFormat(): CategoryFormat | undefined {
    return this._categoryFormat;
  }
  set categoryFormat(value: CategoryFormat | undefined) {
    if (value !== undefined && value !== null) {
      this._timeFormat = undefined;
      this._typeFormat = FieldInfo.TypeFormatCase.categoryFormat;
    }
    this._categoryFormat = value;
  }
  get fieldLocator() {
    return this._fieldLocator;
  }
  get typeFormat() {
    return this._typeFormat;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FieldInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FieldInfo.AsObject {
    return {
      name: this.name,
      column: this.column,
      timeFormat: this.timeFormat ? this.timeFormat.toObject() : undefined,
      categoryFormat: this.categoryFormat
        ? this.categoryFormat.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FieldInfo.AsProtobufJSON {
    return {
      name: this.name === null || this.name === undefined ? null : this.name,
      column:
        this.column === null || this.column === undefined ? null : this.column,
      timeFormat: this.timeFormat
        ? this.timeFormat.toProtobufJSON(options)
        : null,
      categoryFormat: this.categoryFormat
        ? this.categoryFormat.toProtobufJSON(options)
        : null,
    };
  }
}
export module FieldInfo {
  /**
   * Standard JavaScript object representation for FieldInfo
   */
  export interface AsObject {
    name: string;
    column: number;
    timeFormat?: TimeFormat.AsObject;
    categoryFormat?: CategoryFormat.AsObject;
  }

  /**
   * Protobuf JSON representation for FieldInfo
   */
  export interface AsProtobufJSON {
    name: string | null;
    column: number | null;
    timeFormat: TimeFormat.AsProtobufJSON | null;
    categoryFormat: CategoryFormat.AsProtobufJSON | null;
  }
  export enum FieldLocatorCase {
    none = 0,
    name = 1,
    column = 2,
  }
  export enum TypeFormatCase {
    none = 0,
    timeFormat = 1,
    categoryFormat = 2,
  }
}

/**
 * Message implementation for storage.TimeFormat
 */
export class TimeFormat implements GrpcMessage {
  static id = 'storage.TimeFormat';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeFormat();
    TimeFormat.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeFormat) {
    _instance.timeFormat = _instance.timeFormat || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeFormat,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 3:
          _instance.timeFormat = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TimeFormat.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeFormat, _writer: BinaryWriter) {
    if (_instance.timeFormat) {
      _writer.writeString(3, _instance.timeFormat);
    }
  }

  private _timeFormat: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeFormat to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeFormat.AsObject>) {
    _value = _value || {};
    this.timeFormat = _value.timeFormat;
    TimeFormat.refineValues(this);
  }
  get timeFormat(): string {
    return this._timeFormat;
  }
  set timeFormat(value: string) {
    this._timeFormat = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeFormat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeFormat.AsObject {
    return {
      timeFormat: this.timeFormat,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeFormat.AsProtobufJSON {
    return {
      timeFormat: this.timeFormat,
    };
  }
}
export module TimeFormat {
  /**
   * Standard JavaScript object representation for TimeFormat
   */
  export interface AsObject {
    timeFormat: string;
  }

  /**
   * Protobuf JSON representation for TimeFormat
   */
  export interface AsProtobufJSON {
    timeFormat: string;
  }
}

/**
 * Message implementation for storage.CategoryFormat
 */
export class CategoryFormat implements GrpcMessage {
  static id = 'storage.CategoryFormat';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CategoryFormat();
    CategoryFormat.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CategoryFormat) {
    _instance.categoryMap = _instance.categoryMap || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CategoryFormat,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            CategoryFormat.CategoryMapEntry.deserializeBinaryFromReader
          );
          _instance.categoryMap = _instance.categoryMap || {};
          _instance.categoryMap[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    CategoryFormat.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CategoryFormat,
    _writer: BinaryWriter
  ) {
    if (!!_instance.categoryMap) {
      const keys_1 = Object.keys(_instance.categoryMap as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({
            key: key,
            value: (_instance.categoryMap as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          CategoryFormat.CategoryMapEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _categoryMap: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CategoryFormat to deeply clone from
   */
  constructor(_value?: RecursivePartial<CategoryFormat.AsObject>) {
    _value = _value || {};
    (this.categoryMap = _value!.categoryMap
      ? Object.keys(_value!.categoryMap).reduce(
          (r, k) => ({ ...r, [k]: _value!.categoryMap![k] }),
          {}
        )
      : {}),
      CategoryFormat.refineValues(this);
  }
  get categoryMap(): { [prop: string]: string } {
    return this._categoryMap;
  }
  set categoryMap(value: { [prop: string]: string }) {
    this._categoryMap = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CategoryFormat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CategoryFormat.AsObject {
    return {
      categoryMap: this.categoryMap
        ? Object.keys(this.categoryMap).reduce(
            (r, k) => ({ ...r, [k]: this.categoryMap![k] }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CategoryFormat.AsProtobufJSON {
    return {
      categoryMap: this.categoryMap
        ? Object.keys(this.categoryMap).reduce(
            (r, k) => ({ ...r, [k]: this.categoryMap![k] }),
            {}
          )
        : {},
    };
  }
}
export module CategoryFormat {
  /**
   * Standard JavaScript object representation for CategoryFormat
   */
  export interface AsObject {
    categoryMap: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for CategoryFormat
   */
  export interface AsProtobufJSON {
    categoryMap: { [prop: string]: string };
  }

  /**
   * Message implementation for storage.CategoryFormat.CategoryMapEntry
   */
  export class CategoryMapEntry implements GrpcMessage {
    static id = 'storage.CategoryFormat.CategoryMapEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new CategoryMapEntry();
      CategoryMapEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: CategoryMapEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: CategoryMapEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      CategoryMapEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: CategoryMapEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of CategoryMapEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<CategoryMapEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      CategoryMapEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      CategoryMapEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): CategoryMapEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): CategoryMapEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module CategoryMapEntry {
    /**
     * Standard JavaScript object representation for CategoryMapEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for CategoryMapEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}
