<form [formGroup]="form">
  <mat-form-field class="input-half-width">
    <mat-label>Clicks lookback window (days)</mat-label>
    <input formControlName="lookbackWindowClicks" type="number" matInput />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Engaged views lookback window (days)</mat-label>
    <input
      formControlName="lookbackWindowEngagedViews"
      type="number"
      matInput
    />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Views lookback window (days)</mat-label>
    <input formControlName="lookbackWindowViews" type="number" matInput />
  </mat-form-field>
  <h2>Prioritized identity matching columns (comma-separated)</h2>
  <mat-form-field class="input-full-width">
    <mat-label>E.g., email,phone</mat-label>
    <input matInput formControlName="matchingColumns" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Max PII keys per impressions</mat-label>
    <input matInput type="number" formControlName="maxPiiPerImpression" />
  </mat-form-field>
  <h2>Filtering</h2>
  <br /><br />
  Tip: Use commas to separate ids.
  <mat-form-field class="input-full-width">
    <mat-label>Campaign Ids</mat-label>
    <input
      matInput
      type="text"
      formControlName="campaignIds"
      matTooltip="csv of campaign id's"
    />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Ad Group Ids</mat-label>
    <input
      matInput
      type="text"
      formControlName="adgroupIds"
      matTooltip="csv of ad group id's"
    />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Ad Ids</mat-label>
    <input
      matInput
      type="text"
      formControlName="adIds"
      matTooltip="csv of ad id's"
    />
  </mat-form-field>
  <h2>Groups</h2>
  <br /><br />
  Tip: Use commas to separate attributes. For example:
  <span class="code">age,gender</span>
  <div formArrayName="attributionGroups">
    <div *ngFor="let groupForm of attributionGroups.controls; let i = index">
      <div [formGroupName]="i">
        <mat-form-field class="attribution-input-group">
          <mat-label>Publisher attributes</mat-label>
          <input formControlName="publisherAttributes" matInput />
        </mat-form-field>
        <button (click)="deleteAttributionGroup(i)" mat-button color="primary">
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addAttributionGroup()" mat-button color="primary">
    Add Group
  </button>
  <h2>Metrics</h2>
  <table
    mat-table
    [dataSource]="metricsDataSource"
    formArrayName="metrics"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Event type</th>
      <td mat-cell *matCellDef="let index = index">
        {{ metricName(index + 1) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="count">
      <th mat-header-cell *matHeaderCellDef>Counts</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="counts"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amounts</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="amounts"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="numUnits">
      <th mat-header-cell *matHeaderCellDef>Num units</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="numUnits"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="metricsColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: metricsColumns"
    ></tr>
  </table>
  <h2>Attribution estimation configuration</h2>
  <div formArrayName="attributionEstimationConfigs">
    <div
      *ngFor="
        let groupForm of attributionEstimationConfigs.controls;
        let index = index
      "
    >
      <div [formGroupName]="index">
        <mat-form-field class="input-full-width">
          <mat-label>Attribution algorithm</mat-label>
          <mat-select formControlName="algorithm">
            <mat-option
              *ngFor="let ordinal of algorithmTypes"
              [value]="ordinal"
            >
              {{ attributionAlgorithmName(ordinal) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Rho</mat-label>
          <input type="number" matInput formControlName="rho" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Budget divisions</mat-label>
          <input matInput formControlName="rhoDivision" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Sensitivity percentile</mat-label>
          <input type="number" matInput formControlName="sensitivity" />
        </mat-form-field>
        <br />
        <button
          (click)="deleteAttributionEstimationConfig(index)"
          mat-button
          color="primary"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addAttributionEstimationConfig()" mat-button color="primary">
    Add Attribution estimation configuration
  </button>
  <mat-checkbox formControlName="enableDebugLogging">
    Enable debug logging (not differentially private)
  </mat-checkbox>
</form>
