<app-container>
  <app-page-header
    title="Binary Approval"
    lastUpdated="TODAY {{ lastUpdate }}"
  ></app-page-header>

  <div
    class="binary-type"
    *ngIf="!isFetchingAllowedBinaryTypes || allowedBinaryTypes!.length > 1"
  >
    <app-binary-type-dropdown
      [displayTitle]="true"
      [displayBinaryDescription]="true"
      title="Filter by binary"
      (selectBinaryTypeEvent)="onBinarySelect($event)"
    ></app-binary-type-dropdown>
  </div>

  <div *ngIf="!isFetching || approvals" class="grid">
    <div class="header row">
      <div>Binary State</div>
      <div>Name</div>
      <div>Binary Version</div>
      <div>Approval State</div>
      <div></div>
    </div>

    <!-- ### APPROVALS #### -->

    <app-binary
      *ngFor="let approval of approvals ?? []"
      [approval]="approval"
      [isOpen]="activeId === approval.id"
      [onToggle]="handleRowToggle"
      [onApprovalUpdate]="onApprovalUpdate"
      [updateInProgress]="updateInProgress"
      [setUpdateInProgress]="setUpdateInProgress"
      [isApprover]="isApprover"
      [displaySourceIcon]="
        displaySourceCodeIcon(
          approval?.approvalRequestDetail?.change?.binaryInfo?.version,
          approval?.approvalRequestDetail?.change?.binaryInfo?.binaryType
        )
      "
    >
    </app-binary>
  </div>

  <div class="footer">
    <div *ngIf="isFetching" class="loader">
      <mat-spinner [diameter]="approvals ? 24 : 42"></mat-spinner>
    </div>
    <mat-paginator
      *ngIf="(!isFetching || approvals) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isFetching"
    ></mat-paginator>
  </div>
</app-container>
