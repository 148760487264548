<app-container>
  <app-page-header title="External Storage Viewer" lastUpdated="{{ time }}">
  </app-page-header>
  <app-message-box></app-message-box>
  <span *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="24"></mat-spinner>
  </span>
  <section class="team-table-container" tabindex="0">
    <div class="title">
      <app-location-dropdown
        [allowedLocations]="allowedLocations"
        [selectedLocation]="location"
        (selectLocationEvent)="onLocationSelect($event)"
      ></app-location-dropdown>
    </div>
    <app-file-viewer
      [storageMetadata]="storageMetadata"
      [isInternal]="false"
      [location]="location"
      [customerId]="customerId"
    ></app-file-viewer>
  </section>
</app-container>
