import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  LocationCodeList,
  LocationCodeStorageList,
} from 'app/constants/lookups';
import { FormatService } from 'app/services/format.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { StorageMetadata } from 'app/services/generated/src/main/proto/storage/storage-metadata.pb';
import { LoggerService } from 'app/services/logger.service';
import { environment } from 'environments/environment';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  metadata?: StorageMetadata;
}

@Component({
  standalone: false,
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class FileViewerComponent implements OnChanges {
  @Input() storageMetadata: StorageMetadata[] | undefined;
  @Input() customerDataSet: CustomerDataSet | undefined;
  @Input() customerId: string | undefined;
  @Input() location: Location | undefined;
  @Input() isInternal: boolean = true;

  childrenAccessor = (node: TreeNode) => node.children ?? [];
  hasChild = (_: number, node: TreeNode) =>
    !!node.children && node.children.length > 0;
  configs: any;
  dataSource: TreeNode[] = [];
  isLoading = false;
  pageSize: number = 10;
  nodeCount: number = 0;
  pageIndex: number = 0;
  totalFileSize: number = 0;

  constructor(
    private formatService: FormatService,
    private logger: LoggerService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(_changes: SimpleChanges): void {
    this.processData();
  }

  processData() {
    this.isLoading = true;
    if (this.storageMetadata) {
      const nodes: TreeNode[] = [];
      this.storageMetadata.sort((a: StorageMetadata, b: StorageMetadata) =>
        a.url.localeCompare(b.url)
      );
      this.storageMetadata.forEach((metadata) => {
        this.totalFileSize += parseInt(metadata.fileBytes);
        const path = metadata.url.includes('/')
          ? metadata.url
          : `/${metadata.url}`;
        const tokens = path.split('/');
        const root = tokens[0];
        const index = nodes.findIndex((node) => node.name == root);
        if (index > -1) {
          const node = nodes[index];
          nodes.splice(index, 1);
          const leaf: TreeNode = {
            name: tokens.slice(1).join('/'),
            metadata: metadata,
          };
          if (!node.children) {
            node.children = [];
          }
          node.children.push(leaf);
          nodes.push(node);
        } else {
          const leaf: TreeNode = {
            name: tokens.slice(1).join('/'),
            metadata: metadata,
          };
          const node: TreeNode = {
            name: tokens[0],
            children: [leaf],
          };
          this.nodeCount++;
          nodes.push(node);
        }
      });
      this.dataSource = nodes;
    }
    this.isLoading = false;
  }

  formatBytes(value: string | undefined) {
    if (value) {
      return this.formatService.formatBytes(parseInt(value));
    } else {
      return '-';
    }
  }

  formatDateTime(value: Timestamp | undefined) {
    return this.formatService.formatProtoDateTime(value);
  }

  getDirectoryFileSize(children: TreeNode[] | undefined) {
    return children
      ? children
          .reduce(
            (a, c) =>
              a + parseInt(c.metadata?.fileBytes ? c.metadata?.fileBytes : '0'),
            0
          )
          .toString()
      : '0';
  }

  handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getNextPage();
  }

  hideToggle(node: TreeNode) {
    return node.name === '';
  }

  getFileLink(path: string) {
    if (this.isInternal) {
      if (this.customerDataSet) {
        return `https://${environment.internalBlobStoragePrefix}${this.getLocationCode(this.customerDataSet.location)}.${environment.blobStorageBasePath}/${this.customerDataSet.id}/${path}`;
      } else {
        this.logger.error('Customer data set is undefined.');
        return '#';
      }
    } else {
      if (this.customerId && this.location) {
        return `https://${environment.externalBlobStoragePrefix}${this.getLocationCode(this.location)}.${environment.blobStorageBasePath}/${this.customerId}-${this.getLocationStorageCode(this.location)}/${path}`;
      } else {
        this.logger.error('Customer or location are undefined.');
        return '#';
      }
    }
  }

  getFileStorageAccount() {
    return this.isInternal
      ? environment.internalBlobStoragePrefix
      : environment.externalBlobStoragePrefix;
  }

  getLocationCode(location: Location) {
    if (location == Location.LOCATION_UNSPECIFIED) {
      return '';
    }

    return LocationCodeList.find((item) => {
      return item.value == location;
    })!.name;
  }

  getLocationStorageCode(location: Location) {
    if (location == Location.LOCATION_UNSPECIFIED) {
      return '';
    }

    return LocationCodeStorageList.find((item) => {
      return item.value == location;
    })!.name;
  }

  getNextPage() {
    const startIndex = this.pageIndex * this.pageSize;
    return this.dataSource.slice(startIndex, startIndex + this.pageSize);
  }

  onOpenPanel() {
    this.isLoading = true;
  }

  onAfterExpandPanel() {
    this.isLoading = false;
  }
}
