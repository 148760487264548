import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CustomerService } from 'app/services/customer.service';
import {
  Customer,
  CustomerType,
} from 'app/services/generated/src/main/proto/storage/customer.pb';

@Component({
  selector: 'app-customer-dropdown',
  templateUrl: './customer-dropdown.component.html',
  styleUrls: ['./customer-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
  ],
})
export class CustomerDropdownComponent implements OnChanges {
  @Input() selectedCustomer: Customer | undefined;
  @Input() displayAsBanner: boolean = true;
  @Input() displayLabel: boolean = false;
  @Input() displayTitle: boolean = false;
  @Input() label: string = 'Select a Customer';
  @Input() readOnly: boolean = false;
  @Input() showArchived: boolean = false;
  @Input() showSearchBox: boolean = false;
  @Input() showCustomerId: boolean = false;
  @Input() showArchiveToggle: boolean | undefined;
  @Input() mustBeAdvertiser: boolean = false;
  @Input() mustBePublisher: boolean = false;
  @Input() title: string = 'Customer ID';
  @Output() selectCustomerEvent = new EventEmitter<Customer>();
  @ViewChild('search') search!: ElementRef;

  customers: Customer[] = [];
  filterArchived: boolean = true;
  isLoading: boolean = false;

  constructor(public customerService: CustomerService) {
    this.getCustomers();
  }

  ngOnChanges(): void {
    this.setup();
  }

  setup() {
    if (this.selectedCustomer) {
      this.selectCustomerEvent.emit(this.selectedCustomer);
    }
  }

  async getCustomers() {
    this.isLoading = true;
    let disabled: boolean | undefined = false;

    // Show non disabled records or all of them.
    if (this.showArchived || !this.filterArchived) {
      disabled = undefined;
    }

    const customers = await this.customerService.readAllCustomers(disabled);
    this.customers.splice(0);
    for (const customer of customers) {
      if (
        this.mustBeAdvertiser &&
        !customer.customerTypes.includes(CustomerType.CUSTOMER_TYPE_ADVERTISER)
      ) {
        continue;
      }
      if (
        this.mustBePublisher &&
        !customer.customerTypes.includes(
          CustomerType.CUSTOMER_TYPE_MEDIA_PLATFORM
        )
      ) {
        continue;
      }
      this.customers.push(customer);
    }
    this.setup();

    this.isLoading = false;
  }

  getSelectedCustomerId() {
    if (this.selectedCustomer) {
      return this.selectedCustomer.id;
    } else {
      return;
    }
  }

  onCustomerSelect(customerId: string) {
    this.selectedCustomer = this.customers?.find(
      (customer) => customer.id == customerId
    );
    this.selectCustomerEvent.emit(this.selectedCustomer);
  }

  onFilterArchivedChange() {
    this.filterArchived = !this.filterArchived;
    this.getCustomers();
    this.setup();
  }

  onTextSearch() {
    if (this.customers) {
      const matchingCustomer = this.customers.find((customer) =>
        customer.companyName
          .toLowerCase()
          .trim()
          .startsWith(this.search.nativeElement.value.toLowerCase().trim())
      );

      if (matchingCustomer) {
        this.selectedCustomer = matchingCustomer;
        this.setup();
      }
    }
  }
}
