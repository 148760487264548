import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private generateReportFunction;

  constructor(
    private http: HttpClient,
    private functions: Functions,
    private logger: LoggerService
  ) {
    this.generateReportFunction = httpsCallable(
      this.functions,
      'generateResultsReport'
    );
  }

  async createReport(
    jobId: string,
    data: string,
    recipients: string,
    specs: string,
    definitions: string,
    notifications: boolean,
    currency: string
  ) {
    if (environment.localReportDebug) {
      data = await this.loadCsvFromAssets();
    }

    let recipientList = [];
    if (recipients.includes(',')) {
      recipientList = recipients.split(',');
    } else {
      recipientList.push(recipients);
    }

    try {
      return await this.generateReportFunction({
        jobId: jobId,
        data: data,
        specs: specs,
        definitions: definitions,
        recipients: recipientList,
        notifications: notifications,
        currency: currency,
      });
    } catch (error) {
      this.logger.error('Error generating report:', error);
      throw error;
    }
  }

  /**
   * Loads a CSV file from the assets directory and returns its contents
   * Remove when postprocessing is done and use file instead.
   * @param filename - The name of the CSV file in the assets directory
   * @returns Promise<string> - The contents of the CSV file
   */
  private async loadCsvFromAssets(): Promise<string> {
    try {
      return await firstValueFrom(
        this.http.get(`assets/report-template.csv`, { responseType: 'text' })
      );
    } catch (error) {
      this.logger.error('Error loading CSV file:', error);
      throw error;
    }
  }
}
