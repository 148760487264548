import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormatService } from 'app/services/format.service';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from 'app/services/logger.service';
import { environment } from 'environments/environment';

import { ContainerComponent } from '../shared/components/container/container.component';
import { CustomerDropdownComponent } from '../shared/components/customer-dropdown/customer-dropdown.component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

@Component({
  selector: 'app-tiktok-app-page',
  templateUrl: './tiktok-app-page.component.html',
  styleUrls: ['./tiktok-app-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
    ContainerComponent,
    MatCardModule,
    MatIconModule,
    CustomerDropdownComponent,
    MatTooltipModule,
  ],
})
export class TikTokAppPageComponent {
  lastUpdate = this.formatService.getLastUpdate();
  selectedCustomer: Customer | undefined;
  tikTokAppLink: string | undefined;

  constructor(
    public dialog: MatDialog,
    private formatService: FormatService,
    private logger: LoggerService,
    private clipboard: Clipboard,
    private messageBox: MessageBoxProvider
  ) {}

  onCustomerSelect(customer: Customer) {
    this.selectedCustomer = customer;
    this.getTikTokAppLink();
  }

  getTikTokAppLink() {
    if (!this.selectedCustomer) {
      return;
    }

    this.tikTokAppLink = environment.tikTokBaseAppLink
      .replace('{tikTokAppId}', encodeURIComponent(environment.tikTokAppId))
      .replace('{state}', encodeURIComponent(this.selectedCustomer.id))
      .replace(
        '{redirectUrl}',
        encodeURIComponent(environment.tikTokRedirectUrl)
      );
  }

  copyToClipboard() {
    if (this.tikTokAppLink) {
      this.clipboard.copy(this.tikTokAppLink);
      this.messageBox.success('TikTok App link copied to clipboard');
    }
  }
}
