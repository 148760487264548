import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import {
  AdvertiserEventEligibility,
  GroupConfig,
  LiftAlgorithm,
  LiftConfig,
  LiftEstimationConfig,
  LiftOutputSet,
  Metric,
  MetricType,
  PrivacyConfig,
  SensitivityConfig,
} from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { BehaviorSubject } from 'rxjs';

import { Project } from '../../../services/generated/src/main/proto/storage/project.pb';

@Component({
  selector: 'app-lift',
  templateUrl: './lift.component.html',
  styleUrls: ['./lift.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    ReactiveFormsModule,
  ],
})
export class LiftComponent implements OnChanges {
  @Input() project: Project | undefined;
  @Input() inputJobLog: JobLog | undefined;
  @Output() liftConfig = new EventEmitter<LiftConfig>();
  readonly BinaryType = BinaryType;
  // Source for the metrics table.
  liftMetricsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  liftMetricsColumns: string[] = [
    'type',
    'counts',
    'users',
    'amount',
    'numUnits',
  ];

  liftMetricsRows = this.fb.array([
    this.fb.group({
      counts: false,
      users: false,
      amount: false,
      numUnits: false,
    }),
  ]);
  form = this.fb.group({
    studyId: '',
    matchingColumns: '',
    maximumLookbackWindowDays: 0,
    liftEstimationConfigs: this.fb.array([
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_NON_DP,
        rho: 0,
        sensitivity: 0,
      }),
    ]),
    liftMetrics: this.liftMetricsRows,
    enableDebugLogging: false,
    loadRecordId: false,
    dropDuplicateRecordIds: false,
    piiScore: false,
    matchTestMode: false,
  });
  liftAlgorithmTypes: LiftAlgorithm[] = [];

  constructor(private fb: FormBuilder) {
    this.liftMetrics.removeAt(0);
    this.liftMetricsDataSource.next(this.liftMetricsRows.controls);
    for (const value in LiftAlgorithm) {
      if ((parseInt(value) || 0) < 1) {
        // Skip LIFT_ALGORITHM_UNSPECIFIED
        continue;
      }
      this.liftAlgorithmTypes.push(parseInt(value));
    }
    // Populate the metrics table with one row for every possible event_type.
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      const row = this.fb.group({
        counts: false,
        users: false,
        amount: false,
        numUnits: false,
      });
      this.liftMetricsRows.push(row);
    }
    this.form.valueChanges.subscribe(() => this.emitLiftInfo());
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  get liftEstimationConfigs() {
    return this.form.controls['liftEstimationConfigs'] as FormArray;
  }

  get liftMetrics() {
    return this.form.controls['liftMetrics'] as FormArray;
  }

  addLiftEstimationConfig() {
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_NON_DP,
        rho: 0,
        sensitivity: 0,
      })
    );
  }

  deleteLiftEstimationConfig(index: number) {
    this.liftEstimationConfigs.removeAt(index);
  }

  algorithmName(index: number) {
    return LiftAlgorithm[index];
  }

  liftOutputSetName(index: number) {
    return LiftOutputSet[index];
  }

  emitLiftInfo() {
    this.liftConfig.emit(this.emitLiftConfig());
  }

  emitLiftConfig(): LiftConfig {
    const form = this.form.value;
    const liftConfig = new LiftConfig({
      studyId: form.studyId!,
      groups: [new GroupConfig()],
      matchingConfig: new MatchingConfig({
        matchingColumns: form.matchingColumns!.split(',').map((x) => x.trim()),
      }),
      eligibility: new AdvertiserEventEligibility({
        maximumLookbackWindowSeconds: form.maximumLookbackWindowDays! * 86400,
      }),
      liftEstimationConfigs: form.liftEstimationConfigs
        ? form.liftEstimationConfigs?.map((value) => {
            return new LiftEstimationConfig({
              thresholdConverters: 5,
              liftAlgorithm: value.algorithm!,
              privacyConfig: new PrivacyConfig({
                rho: value.rho!,
              }),
              sensitivityConfig: new SensitivityConfig({
                percentile: value.sensitivity!,
              }),
              liftOutputSet: LiftOutputSet.LIFT_OUTPUT_SET_ALL,
            });
          })
        : [],
      enableDebugLogging: form.enableDebugLogging!,
      loadRecordId: form.loadRecordId!,
      dropDuplicateRecordIds: form.dropDuplicateRecordIds!,
      piiScore: form.piiScore!,
      matchTestMode: form.matchTestMode!,
    });

    if (form.liftMetrics) {
      for (let i = 0; i < form.liftMetrics.length; i++) {
        const row = form.liftMetrics[i];
        if (row.counts) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_COUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.users) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_USERS,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.amount) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_AMOUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.numUnits) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_NUM_UNITS,
              advertiserEventType: i + 1,
            })
          );
        }
      }
    }

    return liftConfig;
  }

  async updateFormWithProject(project: Project) {
    const controls = this.form.controls;
    this.liftEstimationConfigs.clear();
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_NON_DP,
        rho: 0,
        sensitivity: 0,
      })
    );
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_DP_SD,
        rho: 10,
        sensitivity: 100,
      })
    );
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_DP_SD,
        rho: 3,
        sensitivity: 100,
      })
    );
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_DP_SD,
        rho: 0.5,
        sensitivity: 100,
      })
    );

    project.advertiserEvents!.forEach((v) => {
      const index = v.advertiserEventType - 1;
      controls.liftMetrics.at(index).controls.counts.setValue(true);
      controls.liftMetrics.at(index).controls.users.setValue(true);
      if (v.includeAmounts) {
        controls.liftMetrics.at(index).controls.amount.setValue(true);
      }
      if (v.includeNumUnits) {
        controls.liftMetrics.at(index).controls.numUnits.setValue(true);
      }
    });
    if (project.studyId) {
      controls.studyId.setValue(project.studyId);
    }

    // Default priority for Lift. Always match by device ID before user PII.
    // When matching by user PII, match on email and phone together before separately.
    const matchKeys = [];
    if (project.matchKeys.includes('idfa')) {
      matchKeys.push('idfa');
    }
    if (project.matchKeys.includes('gaid')) {
      matchKeys.push('gaid');
    }
    if (
      project.matchKeys.includes('email') &&
      project.matchKeys.includes('phone')
    ) {
      matchKeys.push('email+phone');
    }
    if (project.matchKeys.includes('email')) {
      matchKeys.push('email');
    }
    if (project.matchKeys.includes('phone')) {
      matchKeys.push('phone');
    }

    controls.matchingColumns.setValue(matchKeys.join(','));
    controls.enableDebugLogging.setValue(true);
  }

  async updateFormWithLiftConfig(liftConfig: LiftConfig) {
    const controls = this.form.controls;
    this.liftEstimationConfigs.clear();
    liftConfig.liftEstimationConfigs!.forEach((v) => {
      this.liftEstimationConfigs.push(
        this.fb.group({
          algorithm: v.liftAlgorithm!,
          rho: v.privacyConfig ? v.privacyConfig.rho : 0,
          sensitivity: v.sensitivityConfig ? v.sensitivityConfig.percentile : 0,
        })
      );
    });
    liftConfig.metrics!.forEach((v) => {
      const index = v.advertiserEventType - 1;
      if (v.metricType == MetricType.METRIC_TYPE_COUNT) {
        controls.liftMetrics.at(index).controls.counts.setValue(true);
      }
      if (v.metricType == MetricType.METRIC_TYPE_USERS) {
        controls.liftMetrics.at(index).controls.users.setValue(true);
      }
      if (v.metricType == MetricType.METRIC_TYPE_AMOUNT) {
        controls.liftMetrics.at(index).controls.amount.setValue(true);
      }
      if (v.metricType == MetricType.METRIC_TYPE_NUM_UNITS) {
        controls.liftMetrics.at(index).controls.numUnits.setValue(true);
      }
    });

    if (liftConfig.studyId) {
      controls.studyId.setValue(liftConfig.studyId);
    }
    controls.matchingColumns.setValue(
      liftConfig.matchingConfig!.matchingColumns!.join(',')
    );
    if (liftConfig.eligibility?.maximumLookbackWindowSeconds) {
      controls.maximumLookbackWindowDays.setValue(
        Math.round(liftConfig.eligibility.maximumLookbackWindowSeconds / 86400)
      );
    }
    controls.enableDebugLogging.setValue(liftConfig.enableDebugLogging!);
    controls.loadRecordId.setValue(liftConfig.loadRecordId!);
    controls.dropDuplicateRecordIds.setValue(
      liftConfig.dropDuplicateRecordIds!
    );
    controls.piiScore.setValue(liftConfig.piiScore!);
    controls.matchTestMode.setValue(liftConfig.matchTestMode!);
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['project']) {
      const project: Project | undefined = changes['project'].currentValue;
      if (project) {
        await this.updateFormWithProject(project);
      }
    }

    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const liftConfig =
        inputJobLog.binaryConfig.unpack<LiftConfig>(GRPC_MESSAGE_POOL);
      await this.updateFormWithLiftConfig(liftConfig);
    }
    this.emitLiftInfo();
  }
}
