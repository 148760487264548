import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { CustomerStorageCredentialService } from 'app/services/customer-storage-credential.service';
import {
  AwsS3Storage,
  EnclaveEncryptionConfig,
  GoogleCloudStorage,
} from 'app/services/generated/src/main/proto/enclaveencryption/enclave-encryption-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerStorageCredential } from 'app/services/generated/src/main/proto/storage/customer-storage-credential.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { CustomerDropdownComponent } from 'app/views/shared/components/customer-dropdown/customer-dropdown.component';

export interface EnclaveEncryptionInfo {
  enclaveEncryptionConfig: EnclaveEncryptionConfig;
  customerId: string | undefined;
}
@Component({
  selector: 'app-enclave-encryption',
  templateUrl: './enclave-encryption.component.html',
  styleUrls: ['./enclave-encryption.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CustomerDropdownComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class EnclaveEncryptionComponent implements OnChanges {
  @Output() enclaveEncryptionInfo = new EventEmitter<EnclaveEncryptionInfo>();
  @Input() inputJobLog: JobLog | undefined;
  inputCustomer: Customer | undefined;
  selectedCustomerId: string | undefined;

  readonly BinaryType = BinaryType;

  form = this.fb.group({
    storage_type: ['aws'],
    enclave_encryption_dataset_id: '',
    customer_data_set_id: '',
    s3_bucket_name: '',
    s3_prefixes: this.fb.control<string[]>([]),
    gcs_bucket_name: '',
    gcs_prefixes: this.fb.control<string[]>([]),
    key_prefix: '',
    storage_credential_id: '',
    isEncrypted: false,
    scanOnly: false,
  });
  storage_credentials: CustomerStorageCredential[] = [];

  constructor(
    private fb: FormBuilder,
    private customerStorageCredentialService: CustomerStorageCredentialService,
    private customerDataService: CustomerDataService
  ) {
    this.form.valueChanges.subscribe(() => this.emitEnclaveEncryptionInfo());
  }

  onStorageTypeChange() {
    this.updateStorageCredentialsSelect();
    this.emitEnclaveEncryptionInfo();
  }

  addPrefix(type: 'gcs' | 's3', value: string): void {
    if (!value.trim()) return;

    const controlName = type === 's3' ? 's3_prefixes' : 'gcs_prefixes';
    const control = this.form.get(controlName);
    if (control) {
      const currentPrefixes = control.value || [];
      control.setValue([...currentPrefixes, value.trim()]);
    }
  }

  removePrefix(type: 'gcs' | 's3', index: number): void {
    const controlName = type === 's3' ? 's3_prefixes' : 'gcs_prefixes';
    const control = this.form.get(controlName);
    if (control) {
      const currentPrefixes = control.value || [];
      control.setValue(currentPrefixes.filter((_, i) => i !== index));
    }
  }

  emitEnclaveEncryptionInfo() {
    this.enclaveEncryptionInfo.emit({
      enclaveEncryptionConfig: this.createEnclaveEncryptionConfig(),
      customerId: this.selectedCustomerId,
    });
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.selectedCustomerId = customer.id;
      this.updateStorageCredentialsSelect();
      this.emitEnclaveEncryptionInfo();
    }
  }

  createEnclaveEncryptionConfig(): EnclaveEncryptionConfig {
    const form = this.form.value;
    const storageType = form.storage_type;

    const isEncrypted = form.isEncrypted ?? false;
    const scanOnly = form.scanOnly ?? false;

    if (storageType === 'aws') {
      return new EnclaveEncryptionConfig({
        customerStorageCredentialId: form.storage_credential_id || '',
        isEncrypted: isEncrypted,
        scanOnly: scanOnly,
        customerDataSetId: form.customer_data_set_id || '',
        awsS3Storage: new AwsS3Storage({
          bucketName: form.s3_bucket_name || '',
          prefixes: form.s3_prefixes || [],
          encryptedSymmetricKeyPath: form.key_prefix || '',
        }),
      });
    } else if (storageType === 'gcs') {
      return new EnclaveEncryptionConfig({
        isEncrypted: isEncrypted,
        scanOnly: scanOnly,
        customerDataSetId: form.customer_data_set_id || '',
        googleCloudStorage: new GoogleCloudStorage({
          bucketName: form.gcs_bucket_name || '',
          prefixes: form.gcs_prefixes || [],
          encryptedSymmetricKeyPath: form.key_prefix || '',
        }),
      });
    }
    return new EnclaveEncryptionConfig();
  }

  async updateStorageCredentialsSelect() {
    if (this.selectedCustomerId) {
      this.storage_credentials.length = 0;
      const resp =
        await this.customerStorageCredentialService.listStorageCredentials(
          this.selectedCustomerId!
        );
      this.storage_credentials = resp.customerStorageCredentials!;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const enclaveEncryptionConfig =
        inputJobLog.binaryConfig.unpack<EnclaveEncryptionConfig>(
          GRPC_MESSAGE_POOL
        );
      const controls = this.form.controls;

      if (enclaveEncryptionConfig.customerDataSetId) {
        const resp = await this.customerDataService.get(
          enclaveEncryptionConfig.customerDataSetId
        );
        this.inputCustomer = new Customer({
          id: resp.customerDataSet?.customerId,
        });
      }

      controls.isEncrypted.setValue(enclaveEncryptionConfig.isEncrypted);
      controls.scanOnly.setValue(enclaveEncryptionConfig.scanOnly);
      if (enclaveEncryptionConfig.awsS3Storage) {
        controls.storage_type.setValue('aws');
        if (enclaveEncryptionConfig.customerStorageCredentialId) {
          controls.storage_credential_id.setValue(
            enclaveEncryptionConfig.customerStorageCredentialId
          );
        }
        controls.customer_data_set_id.setValue(
          enclaveEncryptionConfig.customerDataSetId
        );
        controls.s3_bucket_name.setValue(
          enclaveEncryptionConfig.awsS3Storage.bucketName
        );
        controls.s3_prefixes.setValue(
          enclaveEncryptionConfig.awsS3Storage.prefixes
        );
        controls.key_prefix.setValue(
          enclaveEncryptionConfig.awsS3Storage.encryptedSymmetricKeyPath
        );
      }
      if (enclaveEncryptionConfig.googleCloudStorage) {
        controls.storage_type.setValue('gcs');
        controls.gcs_bucket_name.setValue(
          enclaveEncryptionConfig.googleCloudStorage.bucketName
        );
        controls.gcs_prefixes.setValue(
          enclaveEncryptionConfig.googleCloudStorage.prefixes
        );
        controls.key_prefix.setValue(
          enclaveEncryptionConfig.googleCloudStorage.encryptedSymmetricKeyPath
        );
      }
    }
  }
}
