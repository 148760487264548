<div class="container">
  <div class="title">
    <p>
      Total storage file size:
      <b>{{ formatBytes(totalFileSize.toString()) }}</b>
    </p>
    <p>
      Storage account: <b>{{ getFileStorageAccount() }}</b>
    </p>
  </div>
  <mat-accordion>
    <mat-expansion-panel
      (opened)="onOpenPanel()"
      (afterExpand)="onAfterExpandPanel()"
      [hideToggle]="hideToggle(node)"
      [expanded]="hideToggle(node)"
      *ngFor="let node of getNextPage()"
    >
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
      <mat-expansion-panel-header *ngIf="node.children">
        <mat-panel-title class="title">{{ node.name }}/</mat-panel-title>
        <mat-panel-description>
          Total file size:
          {{ formatBytes(getDirectoryFileSize(node.children)) }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <table cellPadding="0" cellSpacing="0" *ngIf="node.children">
        <tr>
          <th>Path</th>
          <th>File size</th>
          <th>Creation time (UTC)</th>
          <th>Last modified (UTC)</th>
          <th>MD5 hash</th>
        </tr>
        <tr *ngFor="let child of node.children">
          <td>
            <button
              (click)="tooltipTarget.show()"
              class="text"
              mat-icon-button
              color="primary"
              (matTooltip)="getFileLink(child.metadata!.url)"
              #tooltipTarget="matTooltip"
            >
              {{ child.name }}
            </button>
          </td>
          <td>
            <b>{{ formatBytes(child.metadata?.fileBytes) }}</b>
          </td>
          <td>{{ formatDateTime(child.metadata?.creationTime) }}</td>
          <td>{{ formatDateTime(child.metadata?.lastModified) }}</td>
          <td>
            <i>{{ child.metadata?.md5Hash }}</i>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-paginator
    #paginator
    class="demo-paginator"
    (page)="handlePageEvent($event)"
    [length]="nodeCount"
    [pageSize]="pageSize"
    [showFirstLastButtons]="true"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="[10, 50, 100]"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
