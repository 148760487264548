import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { LocationList } from 'app/constants/lookups';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { LoggerService } from 'app/services/logger.service';

@Component({
  selector: 'app-location-dropdown',
  templateUrl: './location-dropdown.component.html',
  styleUrls: ['./location-dropdown.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule],
})
export class LocationDropdownComponent implements OnInit, OnChanges {
  @Input() allowedLocations: Location[] = [];
  @Input() selectedLocation: Location | undefined;
  @Input() multiple: boolean = false;
  @Output() selectLocationEvent = new EventEmitter<Location>();

  defaultLocation = Location.LOCATION_CUS;
  locations: any = [];

  constructor(private logger: LoggerService) {}

  ngOnInit(): void {
    this.locations = [];
    if (this.allowedLocations && this.allowedLocations.length > 0) {
      this.allowedLocations.forEach((allowedLocation) => {
        const matchingLocation = LocationList.find(
          (location) => allowedLocation === location.value
        );
        if (matchingLocation) {
          this.locations.push(matchingLocation);
        }
      });
    }

    if (this.selectedLocation && this.allowedLocations) {
      if (
        this.allowedLocations.find(
          (location) => this.selectedLocation === location
        )
      ) {
        this.selectLocationEvent.emit(this.selectedLocation);
      } else {
        this.logger.error('Location not allowed for this customer.');
      }
    }
  }

  ngOnChanges(): void {
    this.ngOnInit();
  }

  onLocationSelect(location: Location) {
    this.selectedLocation = location;
    this.selectLocationEvent.emit(this.selectedLocation);
  }
}
