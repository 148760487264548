<app-container>
  <app-page-header
    title="{{ title }}"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <form
    ngNativeValidate
    (ngSubmit)="process()"
    [formGroup]="projectForm"
    id="customerForm"
  >
    <mat-form-field class="input-full-width">
      <mat-label>Project name</mat-label>
      <input matInput formControlName="name" required
    /></mat-form-field>

    <ng-container>
      <app-binary-type-dropdown
        [displayBinaryDescription]="true"
        (selectBinaryTypeEvent)="onBinaryTypeSelect($event)"
        [selectedBinaryType]="selectedBinaryType"
      ></app-binary-type-dropdown>
    </ng-container>

    <ng-container>
      <app-location-dropdown
        [allowedLocations]="allowedCustomerLocations"
        [selectedLocation]="selectedLocation"
        (selectLocationEvent)="onLocationSelect($event)"
      ></app-location-dropdown>
    </ng-container>

    <app-dataset-picker
      [binaryType]="selectedBinaryType"
      [location]="selectedLocation!"
      [inputCustomerDataSetReferenceConfig]="
        project?.customerDataSetReferenceConfig
      "
      (customerDataSetReferenceConfig)="onSelectDatasetsInfo($event)"
      [showPartitions]="false"
      [showDatasetVersions]="false"
    >
    </app-dataset-picker>

    <ng-container>
      <div class="event-metrics-title">
        <div class="event-metric-title-left">
          <label class="field-title">Advertiser event metrics</label>
        </div>
        <button
          type="button"
          matTooltip="Add event"
          class="new-project-button"
          (click)="addEventMetric()"
        >
          <mat-icon>add_box</mat-icon>
        </button>
      </div>

      <div class="metrics-table">
        <table
          mat-table
          [dataSource]="metricsDataSource"
          formArrayName="metrics"
          class="mat-elevation-z1"
        >
          <ng-container matColumnDef="advertiserEventType">
            <th mat-header-cell *matHeaderCellDef>Event type</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field>
                <mat-select
                  class="select"
                  data-testid="eventMetricSelect"
                  placeholder="--Select--"
                  [value]="index + 1"
                  formControlName="advertiserEventType"
                >
                  <mat-option
                    *ngFor="let eventType of eventTypes"
                    [value]="eventType"
                  >
                    {{ getAdvertiserEventName(eventType) }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>Amounts</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-checkbox formControlName="amounts"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="numUnits">
            <th mat-header-cell *matHeaderCellDef>Num units</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-checkbox formControlName="numUnits"></mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef>Notes</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <textarea rows="3" cols="30" formControlName="notes"></textarea>
            </td>
          </ng-container>
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let index = index">
              <mat-icon class="delete-icon" (click)="removeEventMetric(index)"
                >delete</mat-icon
              >
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="metricsColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; let i = index; columns: metricsColumns"
          ></tr>
        </table>
      </div>
    </ng-container>

    <ng-container>
      <mat-form-field class="input-half-width">
        <mat-label class="title">Lookback window click (days)</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          formControlName="lookbackWindowClickDays"
          required
          size="40"
      /></mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayLookbackWindowViewDays()">
      <mat-form-field class="input-half-width">
        <mat-label class="title">Lookback window view (days)</mat-label>
        <input
          matInput
          type="number"
          placeholder="0"
          formControlName="lookbackWindowViewDays"
          required
          size="40"
      /></mat-form-field>
    </ng-container>

    <ng-container *ngIf="displayStudyId()">
      <mat-form-field class="input-half-width">
        <mat-label class="title">Study Id</mat-label>
        <input
          matInput
          type="text"
          placeholder="Study Id"
          formControlName="studyId"
          required
          size="40"
        />
      </mat-form-field>
    </ng-container>

    <app-match-keys-selector
      [selectedMatchKeys]="selectedMatchKeys"
      (selectMatchKeysEvent)="onMatchKeysEvent($event)"
    ></app-match-keys-selector>

    <ng-container>
      <mat-form-field class="input-full-width">
        <mat-label class="title">Notes</mat-label>
        <textarea
          matInput
          placeholder="Notes"
          data-testid="notes"
          formControlName="notes"
          rows="6"
          cols="60"
        ></textarea>
      </mat-form-field>
    </ng-container>

    <app-message-box></app-message-box>
    <ng-container>
      <button
        class="process-button"
        mat-flat-button
        (click)="process()"
        color="primary"
        form="projectForm"
        type="submit"
        data-testid="saveBtn"
      >
        {{ isLoading ? null : 'Save' }}
        <span *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="24"></mat-spinner>
        </span>
      </button>
    </ng-container>
  </form>
</app-container>
