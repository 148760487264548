<form [formGroup]="form">
  <app-dataset-picker
    name="Publisher"
    [inputCustomerId]="pubCustomerId"
    [customerDataSetReference]="pubCustomerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receivePublisherCustomerDataSetInfo($event)"
  >
  </app-dataset-picker>
  <app-dataset-picker
    name="Advertiser"
    [inputCustomerId]="advCustomerId"
    [customerDataSetReference]="advCustomerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receiveAdvertiserCustomerDataSetInfo($event)"
  >
  </app-dataset-picker>
  <mat-form-field class="input-half-width">
    <mat-label>Advertiser start date (inclusive)</mat-label>
    <input type="date" matInput formControlName="advertiserStartDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Advertiser end date (exclusive)</mat-label>
    <input type="date" matInput formControlName="advertiserEndDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Publisher start date (inclusive)</mat-label>
    <input type="date" matInput formControlName="publisherStartDate" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Publisher end date (exclusive)</mat-label>
    <input type="date" matInput formControlName="publisherEndDate" />
  </mat-form-field>
  <br />
  <mat-form-field class="input-full-width">
    <mat-label>Study ID</mat-label>
    <input matInput formControlName="studyId" />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Lookback window (days)</mat-label>
    <input matInput formControlName="maximumLookbackWindowDays" />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Match keys</mat-label>
    <input matInput formControlName="matchingColumns" />
  </mat-form-field>
  <h2>Metrics</h2>
  <table
    mat-table
    [dataSource]="liftMetricsDataSource"
    formArrayName="liftMetrics"
    class="mat-elevation-z1"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Event type</th>
      <td mat-cell *matCellDef="let index = index">
        {{ metricName(index + 1) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="counts">
      <th mat-header-cell *matHeaderCellDef>Counts</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="counts"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef>Users</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="users"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="amount"></mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="numUnits">
      <th mat-header-cell *matHeaderCellDef>Num units</th>
      <td mat-cell *matCellDef="let index = index" [formGroupName]="index">
        <mat-checkbox formControlName="numUnits"></mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="liftMetricsColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; let i = index; columns: liftMetricsColumns"
    ></tr>
  </table>

  <h2>Lift estimation configuration</h2>
  <div formArrayName="liftEstimationConfigs">
    <div
      *ngFor="
        let groupForm of liftEstimationConfigs.controls;
        let index = index
      "
    >
      <div [formGroupName]="index">
        <mat-form-field class="input-full-width">
          <mat-label>Lift algorithm</mat-label>
          <mat-select formControlName="algorithm">
            <mat-option
              *ngFor="let ordinal of liftAlgorithmTypes"
              [value]="ordinal"
            >
              {{ algorithmName(ordinal) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Rho</mat-label>
          <input matInput formControlName="rho" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Sensitivity percentile</mat-label>
          <input matInput formControlName="sensitivity" />
        </mat-form-field>
        <br />
        <button
          (click)="deleteLiftEstimationConfig(index)"
          mat-button
          color="primary"
        >
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addLiftEstimationConfig()" mat-button color="primary">
    Add Lift estimation configuration
  </button>
  <mat-checkbox formControlName="enableDebugLogging">
    Enable debug logging (not differentially private)
  </mat-checkbox>
  <mat-checkbox formControlName="loadRecordId"> Load record ids </mat-checkbox>
  <mat-checkbox formControlName="dropDuplicateRecordIds">
    Drop duplicate record ids
  </mat-checkbox>
</form>
