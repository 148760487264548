<app-container>
  <app-page-header title="Project Manager" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <div *ngIf="isLoading" class="loader">
    <mat-spinner [diameter]="projectRows.length ? 24 : 42"></mat-spinner>
  </div>

  <div class="filter-block">
    <div class="filter-row">
      <app-customer-dropdown
        class="customer-input"
        [showArchiveToggle]="false"
        [mustBePublisher]="true"
        [showSearchBox]="false"
        [displayAsBanner]="false"
        [displayLabel]="true"
        [displayTitle]="false"
        label="Publisher"
        (selectCustomerEvent)="onPublisherSelect($event)"
      ></app-customer-dropdown>
    </div>
    <div class="filter-row">
      <app-customer-dropdown
        class="customer-input"
        [showArchiveToggle]="false"
        [mustBeAdvertiser]="true"
        [showSearchBox]="false"
        [displayAsBanner]="false"
        [displayLabel]="true"
        [displayTitle]="false"
        label="Advertiser"
        (selectCustomerEvent)="onAdvertiserSelect($event)"
      ></app-customer-dropdown>
    </div>
  </div>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Projects</h2>
      <button
        data-testid="create"
        class="add-project-button"
        (click)="newProject()"
      >
        New Project
      </button>
    </div>

    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>
    <div *ngFor="let projectRow of projectRows" class="project-card">
      <div class="header-row">
        <div class="left card-title">
          {{ projectRow.name !== '' ? projectRow.name : 'Untitled Project' }}
        </div>

        <div class="right">
          <div class="chip {{ projectRow.statusColor }}">
            {{ projectRow.statusMessage }}
          </div>
          <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu>
            <button mat-menu-item (click)="new(projectRow.id)">
              <mat-icon>add_circle</mat-icon>
              <span>Create job</span>
            </button>
            <button mat-menu-item (click)="viewAllJobs(projectRow.id)">
              <mat-icon>list</mat-icon>
              <span>View all jobs</span>
            </button>
            <button mat-menu-item (click)="edit(projectRow.id)">
              <mat-icon>edit</mat-icon>
              <span>Edit project</span>
            </button>
            <button mat-menu-item (click)="archive(projectRow.id)">
              <mat-icon>delete</mat-icon>
              <span>Archive project</span>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="card-subheader">Configuration</div>
      <table>
        <tr>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.publisherId }"
            >
              <div class="label">Publisher</div>
              <div class="value">
                {{
                  projectRow.publisherId !== ''
                    ? projectRow.publisherId
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.advertiserId }"
            >
              <div class="label">Advertiser</div>
              <div class="value">
                {{
                  projectRow.advertiserId !== ''
                    ? projectRow.advertiserId
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
          <td>
            <div class="meta-item">
              <div class="label">Location</div>
              <div class="value">{{ projectRow.location }}</div>
            </div>
          </td>
          <td>
            <div class="meta-item">
              <div class="label">Binary type</div>
              <div class="value">
                {{ binaryTypeToName.get(projectRow.binaryType) }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': projectRow.matchKeys.length === 0 }"
            >
              <div class="label">Match keys</div>
              <div class="value">
                {{
                  projectRow.matchKeys.length > 0
                    ? projectRow.matchKeys.join(', ')
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{
                'missing-cell': projectRow.advertiserEventTypes.length === 0,
              }"
            >
              <div class="label">Converison events</div>
              <div class="value">
                {{
                  projectRow.advertiserEventTypes.length > 0
                    ? projectRow.advertiserEventTypes.join(', ')
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{
                'missing-cell': projectRow.advertiserEventMetrics.length === 0,
              }"
            >
              <div class="label">Converison metrics</div>
              <div class="value">
                {{
                  projectRow.advertiserEventMetrics.length > 0
                    ? projectRow.advertiserEventMetrics.join(', ')
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.publisherStartDate }"
            >
              <div class="label">Campaign first day (UTC)</div>
              <div class="value">
                {{ projectRow.publisherStartDate ?? 'Missing' }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.publisherEndDate }"
            >
              <div class="label">Campaign last day (UTC)</div>
              <div class="value">
                {{ projectRow.publisherEndDate ?? 'Missing' }}
              </div>
            </div>
          </td>
          <td>
            <div class="meta-item">
              <div class="label">Campaign length</div>
              <div class="value">
                {{ projectRow.publisherDuration ?? '' }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.advertiserStartDate }"
            >
              <div class="label">Conversions first day (UTC)</div>
              <div class="value">
                {{ projectRow.advertiserStartDate ?? 'Missing' }}
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.advertiserEndDate }"
            >
              <div class="label">Conversions last day (UTC)</div>
              <div class="value">
                {{ projectRow.advertiserEndDate ?? 'Missing' }}
              </div>
            </div>
          </td>
          <td>
            <div class="meta-item">
              <div class="label">Post-period</div>
              <div class="value">
                {{ projectRow.postPeriodDuration ?? '' }}
              </div>
            </div>
          </td>
          <td>
            <div
              *ngIf="projectRow.showLookbackWindow"
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.lookbackWindow }"
            >
              <div class="label">Lookback windows</div>
              <div class="value">
                {{ projectRow.lookbackWindow ?? 'Missing' }}
              </div>
            </div>
          </td>
        </tr>
      </table>

      <br />
      <div class="card-subheader">Data</div>
      <table>
        <tr>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.publisherDatasetName }"
            >
              <div class="label">Publisher dataset</div>
              <div class="value">
                {{
                  projectRow.publisherDatasetName
                    ? projectRow.publisherDatasetName
                    : 'Missing'
                }}
                <a
                  [routerLink]="[
                    '/dataset',
                    projectRow.publisherDatasetId,
                    'edit',
                  ]"
                  target="_blank"
                >
                  <mat-icon
                    class="cell-icon"
                    *ngIf="projectRow.publisherDatasetId"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.publisherDatasetStatus }"
            >
              <div class="label">Publisher dataset status</div>
              <div class="value">
                {{
                  projectRow.publisherDatasetStatus
                    ? projectRow.publisherDatasetStatus
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              class="meta-item"
              [ngClass]="{ 'missing-cell': !projectRow.advertiserDatasetName }"
            >
              <div class="label">Advertiser dataset</div>
              <div class="value">
                {{
                  projectRow.advertiserDatasetName
                    ? projectRow.advertiserDatasetName
                    : 'Missing'
                }}
                <a
                  [routerLink]="[
                    '/dataset',
                    projectRow.advertiserDatasetId,
                    'edit',
                  ]"
                  target="_blank"
                >
                  <mat-icon
                    class="cell-icon"
                    *ngIf="projectRow.advertiserDatasetName"
                    >open_in_new</mat-icon
                  >
                </a>
              </div>
            </div>
          </td>
          <td>
            <div
              class="meta-item"
              [ngClass]="{
                'missing-cell': !projectRow.advertiserDatasetStatus,
              }"
            >
              <div class="label">Advertiser dataset status</div>
              <div class="value">
                {{
                  projectRow.advertiserDatasetStatus
                    ? projectRow.advertiserDatasetStatus
                    : 'Missing'
                }}
              </div>
            </div>
          </td>
        </tr>
      </table>
      <br />
      <ng-container *ngIf="projectRow.notes">
        <div class="card-subheader">Notes</div>
        <div class="project-notes">
          <pre class="project-notes-text">{{ projectRow.notes }}</pre>
        </div>
      </ng-container>
      <div class="last-update-row">
        {{ projectRow.id }} | Last updated {{ projectRow.lastUpdate }} by
        {{ projectRow.lastUpdater }}
      </div>
    </div>
  </section>
</app-container>
