<form [formGroup]="tiktokAudienceInferenceForm">
  <div class="section">
    <h3>Job ID</h3>
    <mat-form-field>
      <input matInput formControlName="jobId" required />
      <mat-error
        *ngIf="tiktokAudienceInferenceForm.get('jobId')?.hasError('required')"
      >
        Job ID is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="section">
    <h3>Advertiser ID</h3>
    <mat-form-field>
      <input matInput formControlName="advertiserId" required />
      <mat-error
        *ngIf="
          tiktokAudienceInferenceForm.get('advertiserId')?.hasError('required')
        "
      >
        Advertiser ID is required
      </mat-error>
    </mat-form-field>
  </div>

  <div class="section">
    <h3>Reach</h3>
    <input type="number" formControlName="reach" required min="1" />
    <div
      class="error-message"
      *ngIf="tiktokAudienceInferenceForm.get('reach')?.hasError('required')"
    >
      Reach is required
    </div>
    <div
      class="error-message"
      *ngIf="tiktokAudienceInferenceForm.get('reach')?.hasError('min')"
    >
      Reach must be at least 1
    </div>
  </div>

  <mat-checkbox formControlName="enableDebugLogging">
    Enable Debug Logging
  </mat-checkbox>
</form>
