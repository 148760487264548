<div class="header">
  <h2 class="modal-title">{{ title }}</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<section class="team-table-container" tabindex="0">
  <table mat-table [dataSource]="customerApprovals" matSort class="table-data">
    <ng-container matColumnDef="Id">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="id"
      >
        ID
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span>{{ user.id }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="name"
      >
        Name
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span>{{ user.name }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Managed">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="managed"
      >
        Managed
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span *ngIf="user.managed">
            <i class="material-icons">check</i>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Assigned">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="assigned"
      >
        Assigned
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span *ngIf="user.assigned">
            <i class="material-icons">check</i>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Approved">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="approved"
      >
        Approved
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span *ngIf="user.approved">
            <i class="material-icons">check</i>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Policy updated">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="policyUpdated"
      >
        Policy Updated
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <span *ngIf="user.policyUpdated">
            <i class="material-icons">check</i>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Menu">
      <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu>
            <button
              mat-menu-item
              *ngIf="!isAssigned(user.id)"
              (click)="assign(user.id)"
            >
              <mat-icon>add_circle</mat-icon>
              <span>Assign</span>
            </button>
            <button
              mat-menu-item
              *ngIf="isAssigned(user.id)"
              (click)="unAssign(user.id)"
            >
              <mat-icon>remove_circle</mat-icon>
              <span>Unassign</span>
            </button>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      class="table-row"
    ></tr>
  </table>
</section>
<app-message-box></app-message-box>
