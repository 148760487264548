<form [formGroup]="form">
  <mat-form-field class="input-half-width">
    <mat-label>Mach keys (comma-separated)</mat-label>
    <input matInput formControlName="matchingColumns" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Reach</mat-label>
    <input matInput formControlName="reach" />
  </mat-form-field>
  <mat-form-field class="input-full-width">
    <mat-label>Event type</mat-label>
    <mat-select formControlName="eventType">
      <mat-option *ngFor="let ordinal of eventTypes" [value]="ordinal">
        {{ eventTypeName(ordinal) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <mat-form-field class="input-half-width">
    <mat-label>Publisher fraction of data to remove [0-1] </mat-label>
    <input type="number" matInput formControlName="pubFracToRemove" />
  </mat-form-field>
  <mat-form-field class="input-half-width">
    <mat-label>Advertiser fraction of data to remove [0-1]</mat-label>
    <input type="number" matInput formControlName="advFracToRemove" />
  </mat-form-field>
  <br />
  <mat-form-field class="input-half-width">
    <mat-label>Max cohorts</mat-label>
    <input type="number" matInput formControlName="maxCohorts" />
  </mat-form-field>
  <h2>Privacy configuration</h2>
  <div formArrayName="privacyConfigs">
    <div *ngFor="let groupForm of privacyConfigs.controls; let index = index">
      <div [formGroupName]="index">
        <mat-form-field class="input-half-width">
          <mat-label>Rho</mat-label>
          <input matInput formControlName="rho" />
        </mat-form-field>
        <button (click)="deletePrivacyConfig(index)" mat-button color="primary">
          Delete
        </button>
      </div>
    </div>
  </div>
  <button (click)="addPrivacyConfig()" mat-button color="primary">
    Add privacy configuration
  </button>
  <br />
  <mat-checkbox formControlName="experimental">
    Experimental Mode
  </mat-checkbox>
  <br />
  <mat-checkbox formControlName="enableDebugLogging">
    Enable debug logging (not differentially private)
  </mat-checkbox>
</form>
