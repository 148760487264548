import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { Auth, getIdTokenResult } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AddCustomerModalComponent } from 'app/modals/add-customer-modal/add-customer-modal.component';
import {
  ReadCustomersFilter,
  ReadCustomersRequest,
} from 'app/services/generated/src/main/proto/api/customer-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import {
  Customer,
  CustomerType,
} from 'app/services/generated/src/main/proto/storage/customer.pb';
import { LoggerService } from 'app/services/logger.service';

import { BinaryTypeService } from '../../services/binary-type.service';
import { CustomerService } from '../../services/customer.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

const RECORDS_PER_PAGE = 50;

interface Page {
  continuationToken: string | null;
}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  public columnsToDisplay: string[] = [
    'CompanyName',
    'Domains',
    'CustomerType',
    'BinaryType',
    'Tenant',
    'Status',
    'Actions',
  ];
  public customerType: { [key: string]: string } = {
    1: 'Advertiser',
    2: 'Platform',
    3: 'Other',
  };
  public showCreateCustomer!: boolean;

  anonymTenantId: string | null | undefined = undefined;
  binaryTypeInfos?: BinaryTypeInfo[] = [];
  currentPageIndex = 0;
  customers: MatTableDataSource<Customer> = new MatTableDataSource();
  displayAllCustomers = false;
  isLoading = false;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomerType = CustomerType.CUSTOMER_TYPE_UNSPECIFIED;
  time: string = new Date().toLocaleString();
  totalRecords = 0;

  constructor(
    public binaryTypeService: BinaryTypeService,
    public customerService: CustomerService,
    @Optional() private auth: Auth,
    private logger: LoggerService,
    private dialog: MatDialog,
    private liveAnnouncer: LiveAnnouncer,
    private messageBox: MessageBoxProvider,
    private router: Router
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user !== null) {
        getIdTokenResult(user).then((result) => {
          const {
            claims: { Roles },
          } = result;
          this.showCreateCustomer = (Roles as string)
            .split(',')
            .includes('AnonymAdmin');
        });
      }
    });
  }

  ngOnInit(): void {
    this.anonymTenantId = this.auth.currentUser?.tenantId;
    this.dialog.afterAllClosed.subscribe(() => this.loadCustomers(0));
    this.binaryTypeService.getBinaryTypes().then((getBinaryTypeResponse) => {
      this.binaryTypeInfos = getBinaryTypeResponse.binaryTypeInfos;
    });
  }

  loadCustomers(pageIndex = 0, token: string | null = null) {
    this.isLoading = true;

    const readCustomersRequest = new ReadCustomersRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && readCustomersRequest.paginated) {
      readCustomersRequest.paginated.continuationToken = token;
    }

    const readCustomerFilter = new ReadCustomersFilter();

    if (this.selectedCustomerType != CustomerType.CUSTOMER_TYPE_UNSPECIFIED) {
      readCustomerFilter.customerType = this.selectedCustomerType;
    }

    // We either want all customers or non-disabled customers. Skipping only disabled customers.
    if (this.displayAllCustomers === false) {
      readCustomerFilter.disabled = false;
    }

    readCustomersRequest.filter = readCustomerFilter;

    this.customerService
      .readCustomers(readCustomersRequest)
      .then((response) => {
        if (response.customers !== undefined) {
          this.customers.data = response.customers;
          if (response.paginatedResponse?.continuationToken) {
            this.totalRecords = response.paginatedResponse?.count;
            this.pages[pageIndex + 1] = {
              continuationToken: response.paginatedResponse.continuationToken,
            };
          }
        }
      })
      .catch((error) => this.logger.error('Error loading company data', error))
      .finally(() => {
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.customers.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction} ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  async disableCustomer(customer: Customer) {
    const state = customer.disabled ? 'Enable' : 'Disable';
    const confirmationMessage = `${state} customer ${customer.companyName} ?`;

    if (confirm(confirmationMessage)) {
      customer.disabled = !customer.disabled;
      this.updateCustomer(customer);
    }
  }

  async updateCustomerBinaryTypes(
    customer: Customer,
    binaryTypes: BinaryType[]
  ) {
    const binaryTypeText = binaryTypes.join(', ');
    // This is going to need a lookup from BinaryInfoType to be of more use.
    const confirmationMessage = `Assign ${binaryTypeText} to customer ${customer.companyName} ?`;

    if (confirm(confirmationMessage)) {
      customer.allowedBinaryTypes = binaryTypes;
      this.updateCustomer(customer);
    }
  }

  async updateCustomer(customer: Customer) {
    this.isLoading = true;
    await this.customerService
      .updateCustomer(customer)
      .then(() => {
        this.messageBox.success('Customer state changed successfully.');
      })
      .catch((e) => {
        console.error(e);
        this.messageBox.error('Error updating customer state.');
      })
      .finally(() => {
        this.isLoading = false;
        this.loadCustomers(0);
      });
  }

  createCustomer(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  editCustomer(customer: Customer): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = customer;

    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  setBinaryType(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(AddCustomerModalComponent, dialogConfig);
  }

  displayCustomerTypes(customerTypes: number[]): string {
    const result: string[] = [];
    customerTypes.forEach((customerType) => {
      result.push(this.customerType[customerType]);
    });
    return result.join(', ');
  }

  displayBinaryTypes(binaryTypes: BinaryType[]) {
    const result: string[] = [];
    binaryTypes.forEach((binaryType) => {
      this.binaryTypeInfos?.forEach((binaryTypeInfo) => {
        if (binaryTypeInfo.binaryType === binaryType) {
          result.push(binaryTypeInfo.name);
        }
      });
    });
    return result;
  }

  setCustomerDisplay() {
    if (this.displayAllCustomers) {
      this.displayAllCustomers = false;
    } else {
      this.displayAllCustomers = true;
    }
    this.loadCustomers();
  }

  filterByCustomerType(customerType: number) {
    this.selectedCustomerType = customerType;
    this.loadCustomers();
  }

  async onPageChange(event: PageEvent) {
    if (event.pageIndex == 0) {
      this.loadCustomers(0);
      this.currentPageIndex = 0;
    } else {
      const page = this.pages[event.pageIndex];
      if (page.continuationToken) {
        this.loadCustomers(event.pageIndex, page.continuationToken);
        this.currentPageIndex = event.pageIndex;
      }
    }
  }

  openStorageMetadataViewer(customerId: string) {
    this.router.navigate([
      `external-storage-metadata-viewer/${customerId}/view`,
    ]);
  }
}
