/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for storage.StorageMetadata
 */
export class StorageMetadata implements GrpcMessage {
  static id = 'storage.StorageMetadata';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StorageMetadata();
    StorageMetadata.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StorageMetadata) {
    _instance.url = _instance.url || '';
    _instance.creationTime = _instance.creationTime || undefined;
    _instance.lastModified = _instance.lastModified || undefined;

    _instance.fileBytes = _instance.fileBytes || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StorageMetadata,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.url = _reader.readString();
          break;
        case 2:
          _instance.creationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.lastModified = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastModified,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.md5Hash = _reader.readString();
          break;
        case 5:
          _instance.fileBytes = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    StorageMetadata.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StorageMetadata,
    _writer: BinaryWriter
  ) {
    if (_instance.url) {
      _writer.writeString(1, _instance.url);
    }
    if (_instance.creationTime) {
      _writer.writeMessage(
        2,
        _instance.creationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.lastModified) {
      _writer.writeMessage(
        3,
        _instance.lastModified as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.md5Hash !== undefined && _instance.md5Hash !== null) {
      _writer.writeString(4, _instance.md5Hash);
    }
    if (_instance.fileBytes) {
      _writer.writeInt64String(5, _instance.fileBytes);
    }
  }

  private _url: string;
  private _creationTime?: googleProtobuf000.Timestamp;
  private _lastModified?: googleProtobuf000.Timestamp;
  private _md5Hash?: string;
  private _fileBytes: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StorageMetadata to deeply clone from
   */
  constructor(_value?: RecursivePartial<StorageMetadata.AsObject>) {
    _value = _value || {};
    this.url = _value.url;
    this.creationTime = _value.creationTime
      ? new googleProtobuf000.Timestamp(_value.creationTime)
      : undefined;
    this.lastModified = _value.lastModified
      ? new googleProtobuf000.Timestamp(_value.lastModified)
      : undefined;
    this.md5Hash = _value.md5Hash;
    this.fileBytes = _value.fileBytes;
    StorageMetadata.refineValues(this);
  }
  get url(): string {
    return this._url;
  }
  set url(value: string) {
    this._url = value;
  }
  get creationTime(): googleProtobuf000.Timestamp | undefined {
    return this._creationTime;
  }
  set creationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTime = value;
  }
  get lastModified(): googleProtobuf000.Timestamp | undefined {
    return this._lastModified;
  }
  set lastModified(value: googleProtobuf000.Timestamp | undefined) {
    this._lastModified = value;
  }
  get md5Hash(): string | undefined {
    return this._md5Hash;
  }
  set md5Hash(value?: string) {
    this._md5Hash = value;
  }
  get fileBytes(): string {
    return this._fileBytes;
  }
  set fileBytes(value: string) {
    this._fileBytes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StorageMetadata.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StorageMetadata.AsObject {
    return {
      url: this.url,
      creationTime: this.creationTime
        ? this.creationTime.toObject()
        : undefined,
      lastModified: this.lastModified
        ? this.lastModified.toObject()
        : undefined,
      md5Hash: this.md5Hash,
      fileBytes: this.fileBytes,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StorageMetadata.AsProtobufJSON {
    return {
      url: this.url,
      creationTime: this.creationTime
        ? this.creationTime.toProtobufJSON(options)
        : null,
      lastModified: this.lastModified
        ? this.lastModified.toProtobufJSON(options)
        : null,
      md5Hash:
        this.md5Hash === null || this.md5Hash === undefined
          ? null
          : this.md5Hash,
      fileBytes: this.fileBytes,
    };
  }
}
export module StorageMetadata {
  /**
   * Standard JavaScript object representation for StorageMetadata
   */
  export interface AsObject {
    url: string;
    creationTime?: googleProtobuf000.Timestamp.AsObject;
    lastModified?: googleProtobuf000.Timestamp.AsObject;
    md5Hash?: string;
    fileBytes: string;
  }

  /**
   * Protobuf JSON representation for StorageMetadata
   */
  export interface AsProtobufJSON {
    url: string;
    creationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    lastModified: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    md5Hash: string | null;
    fileBytes: string;
  }
}
