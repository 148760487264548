<div class="modal">
  <div class="header">
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="modal-content">
    <div class="icon-container">
      <mat-icon class="warning-icon">warning</mat-icon>
    </div>

    <h1 class="mat-custom-dialog-title">
      {{ data.title }}
    </h1>

    <div mat-dialog-content class="mat-custom-dialog-content">
      <p>
        {{ data.message }}
      </p>
    </div>

    <div mat-dialog-actions class="dialog-actions">
      <button
        *ngIf="data.showCancelButton !== false"
        mat-flat-button
        color="warn"
        mat-dialog-close
        (click)="close()"
      >
        {{ data.cancelButtonText || 'Cancel' }}
      </button>
      <button mat-flat-button color="primary" type="submit" (click)="save()">
        {{ data.buttonText }}
      </button>
    </div>
  </div>
</div>
