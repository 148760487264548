<div class="field-dropdown-label" *ngIf="displayTitle">
  <span class="field-title">{{ title }}</span>
</div>
<div class="field-row">
  <mat-form-field class="field-name-container" [appearance]="getAppearance()">
    <mat-select
      [placeholder]="placeholderText"
      name="binaryType"
      data-testid="binaryType"
      (selectionChange)="onBinaryTypeSelect($event.value)"
      required
      [(value)]="selectedBinaryType"
    >
      <mat-option [value]="binaryTypeUnspecified">All</mat-option>
      <mat-option
        *ngFor="let binaryType of binaryTypesInfo"
        [value]="binaryType.binaryType"
      >
        {{ binaryType.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="field-dropdown-description" *ngIf="displayBinaryDescription">
    {{ currentBinaryTypeLabel }}
  </div>
</div>
