<app-container class="background">
  <div class="app-header">
    <div class="left">
      <h1 data-testid="pageHeaderTitle">Your API Integrations</h1>
    </div>
    <div class="right">
      <button
        mat-flat-button
        color="primary"
        class="add-btn"
        data-testid="addButton"
        (click)="addApp()"
        *ngIf="apps && apps.length !== 0"
      >
        Add New App
      </button>
    </div>
  </div>

  <app-message-box></app-message-box>

  <mat-card appearance="outlined" *ngIf="displayWelcome()">
    <div class="card-header">
      <div>
        <mat-card-header><h1>Anonym's REST API</h1></mat-card-header>
      </div>
      <div>
        <button
          data-testid="closeBtn"
          class="tool-btn"
          matTooltip="Close"
          (click)="closeWelcome()"
        >
          <mat-icon color="#000000">close</mat-icon>
        </button>
      </div>
    </div>
    <p class="welcome-title-block">
      Integrate With Anonym Services Seamlessly!
    </p>

    <mat-card-content class="welcome-content">
      <p class="how-title">How it works</p>

      <div class="welcome-card-data">
        <div class="key-grid-item"><span class="blue-number">1</span></div>
        <div class="key-grid-item">
          <p class="welcome-title">Plan</p>
          <p class="key-row">
            <span class="welcome-data"
              >Check out our API documentation and sample code provided
              <a [routerLink]="[]" (click)="onClick()">here</a> for more
              insights.</span
            >
          </p>
        </div>
        <div class="key-grid-item"><span class="blue-number">2</span></div>
        <div class="key-grid-item">
          <p class="welcome-title">Create</p>
          <p class="key-row">
            <span class="welcome-data"
              >Generate your application using the Portal, copy the API key and
              App ID, and securely store them.</span
            >
          </p>
        </div>
        <div class="key-grid-item"><span class="blue-number">3</span></div>
        <div class="key-grid-item">
          <p class="welcome-title">Implement</p>
          <p class="key-row">
            <span class="welcome-data"
              >Build your app, set up request authentication headers, and start
              making API requests.</span
            >
          </p>
        </div>
      </div>
    </mat-card-content>
    <mat-card-footer> </mat-card-footer>
  </mat-card>

  <div *ngFor="let app of apps">
    <mat-card appearance="outlined">
      <div class="card-header">
        <div>
          <mat-card-header
            ><label class="app-title">{{ app.appName }}</label></mat-card-header
          >
        </div>
        <div>
          <button
            data-testid="rotateBtn"
            class="tool-btn"
            matTooltip="Rotate Key"
            (click)="rotateKeys(app.id, app.appName, app.etag)"
          >
            <mat-icon color="#000000">cached</mat-icon>
          </button>
          <button
            data-testid="rotateBtn"
            class="tool-btn"
            matTooltip="Delete App"
            (click)="delete(app.id, app.appName, app.etag)"
          >
            <mat-icon color="#000000">delete</mat-icon>
          </button>
        </div>
      </div>

      <mat-card-content>
        <div class="key-info-card-data">
          <div class="key-grid-item">
            <p class="field-title">App ID</p>
            <span data-testid="appId" class="field-data">{{ app.id }}</span>
            <div class="key-row-top">
              <button
                data-testid="copyIdBtn"
                class="tool-btn-row"
                matTooltip="Copy"
                [cdkCopyToClipboard]="app.id"
              >
                <mat-icon color="#000000">content_copy</mat-icon>
              </button>
            </div>
          </div>
          <div class="key-grid-item">
            <p class="field-title">App Modified Date</p>
            <span data-testid="creationTime" class="field-data">{{
              formatTimestamp(app.lastUpdate)
            }}</span>
          </div>
          <div class="key-grid-item">
            <p class="field-title">Key Version</p>
            <span class="badge" *ngIf="keys.get(app.id)"
              >{{ app.apiKeyData?.schemeVersion }}.{{
                keys.get(app.id).apiKeyRev
              }}</span
            >
            <span class="badge" *ngIf="!keys.get(app.id)"
              >{{ app.apiKeyData?.schemeVersion }}.{{
                app.apiKeyData?.rev
              }}</span
            >
          </div>
          <div class="key-grid-item">
            <p class="field-title">Key Rotation Date</p>
            <span data-testid="keyDate" class="field-data">{{
              formatTimestamp(app.apiKeyData?.creationTime)
            }}</span>
          </div>
          <div class="key-grid-item">
            <div *ngIf="keys.get(app.id)">
              <p class="field-title">App Key</p>
              <div class="key-row-top">
                <div class="key-row-top">
                  <span
                    data-testid="keySecret"
                    class="key-data"
                    matTooltip="Copy the key and store it in a safe location"
                  >
                    {{ keys.get(app.id).apiKey }}
                  </span>
                </div>
                <div class="key-row-top">
                  <button
                    data-testid="copyKeyBtn"
                    class="tool-btn-row"
                    matTooltip="Copy"
                    [cdkCopyToClipboard]="keys.get(app.id).apiKey"
                  >
                    <mat-icon color="#000000">content_copy</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
      <mat-card-footer> </mat-card-footer>
    </mat-card>
  </div>

  <div *ngIf="isLoadingApps" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>

  <div class="no-app-msg" *ngIf="!apps || apps.length === 0">
    <h2>You haven't created any apps yet.</h2>
    <div class="button-center">
      <button
        mat-flat-button
        color="primary"
        class="add-btn"
        data-testid="addButton"
        (click)="addApp()"
      >
        Add New App
      </button>
    </div>
  </div>
</app-container>
