/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
export enum BinaryType {
  BINARY_TYPE_UNSPECIFIED = 0,
  BINARY_TYPE_ATTRIBUTION = 1,
  BINARY_TYPE_DENTSU_PROTOTYPE = 6,
  BINARY_TYPE_ENCLAVE_ENCRYPTION = 5,
  BINARY_TYPE_LIFT = 2,
  BINARY_TYPE_LIFT_DASK = 3,
  BINARY_TYPE_REDDIT_ATTRIBUTION = 9,
  BINARY_TYPE_REDDIT_LIFT = 8,
  BINARY_TYPE_TIKTOK_TARGETING = 7,
  BINARY_TYPE_VALIDATION = 4,
  BINARY_TYPE_POSTPROCESSING = 10,
  BINARY_TYPE_TIKTOK_AUDIENCE_INFERENCE = 11,
}
/**
 * Message implementation for storage.BinaryTypeInfo
 */
export class BinaryTypeInfo implements GrpcMessage {
  static id = 'storage.BinaryTypeInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryTypeInfo();
    BinaryTypeInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryTypeInfo) {
    _instance.binaryType = _instance.binaryType || 0;

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.name = _instance.name || '';
    _instance.description = _instance.description || '';
    _instance.deprecated = _instance.deprecated || false;
    _instance.customerDataSchemas = _instance.customerDataSchemas || {};
    _instance.sgx = _instance.sgx || false;
    _instance.dask = _instance.dask || false;
    _instance.binaryTypeUrl = _instance.binaryTypeUrl || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryTypeInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.binaryType = _reader.readEnum();
          break;
        case 4:
          _instance.etag = _reader.readString();
          break;
        case 5:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          _instance.description = _reader.readString();
          break;
        case 6:
          _instance.deprecated = _reader.readBool();
          break;
        case 7:
          const msg_7 = {} as any;
          _reader.readMessage(
            msg_7,
            BinaryTypeInfo.CustomerDataSchemasEntry.deserializeBinaryFromReader
          );
          _instance.customerDataSchemas = _instance.customerDataSchemas || {};
          _instance.customerDataSchemas[msg_7.key] = msg_7.value;
          break;
        case 8:
          _instance.sgx = _reader.readBool();
          break;
        case 9:
          _instance.dask = _reader.readBool();
          break;
        case 10:
          _instance.binaryTypeUrl = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryTypeInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryTypeInfo,
    _writer: BinaryWriter
  ) {
    if (_instance.binaryType) {
      _writer.writeEnum(1, _instance.binaryType);
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(4, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        5,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.description) {
      _writer.writeString(3, _instance.description);
    }
    if (_instance.deprecated) {
      _writer.writeBool(6, _instance.deprecated);
    }
    if (!!_instance.customerDataSchemas) {
      const keys_7 = Object.keys(_instance.customerDataSchemas as any);

      if (keys_7.length) {
        const repeated_7 = keys_7
          .map((key) => ({
            key: key,
            value: (_instance.customerDataSchemas as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          7,
          repeated_7,
          BinaryTypeInfo.CustomerDataSchemasEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.sgx) {
      _writer.writeBool(8, _instance.sgx);
    }
    if (_instance.dask) {
      _writer.writeBool(9, _instance.dask);
    }
    if (_instance.binaryTypeUrl) {
      _writer.writeString(10, _instance.binaryTypeUrl);
    }
  }

  private _binaryType: BinaryType;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _name: string;
  private _description: string;
  private _deprecated: boolean;
  private _customerDataSchemas: { [prop: string]: CustomerDataSchema };
  private _sgx: boolean;
  private _dask: boolean;
  private _binaryTypeUrl: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryTypeInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryTypeInfo.AsObject>) {
    _value = _value || {};
    this.binaryType = _value.binaryType;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.name = _value.name;
    this.description = _value.description;
    this.deprecated = _value.deprecated;
    (this.customerDataSchemas = _value!.customerDataSchemas
      ? Object.keys(_value!.customerDataSchemas).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.customerDataSchemas![k]
              ? new CustomerDataSchema(_value!.customerDataSchemas![k])
              : undefined,
          }),
          {}
        )
      : {}),
      (this.sgx = _value.sgx);
    this.dask = _value.dask;
    this.binaryTypeUrl = _value.binaryTypeUrl;
    BinaryTypeInfo.refineValues(this);
  }
  get binaryType(): BinaryType {
    return this._binaryType;
  }
  set binaryType(value: BinaryType) {
    this._binaryType = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get description(): string {
    return this._description;
  }
  set description(value: string) {
    this._description = value;
  }
  get deprecated(): boolean {
    return this._deprecated;
  }
  set deprecated(value: boolean) {
    this._deprecated = value;
  }
  get customerDataSchemas(): { [prop: string]: CustomerDataSchema } {
    return this._customerDataSchemas;
  }
  set customerDataSchemas(value: { [prop: string]: CustomerDataSchema }) {
    this._customerDataSchemas = value;
  }
  get sgx(): boolean {
    return this._sgx;
  }
  set sgx(value: boolean) {
    this._sgx = value;
  }
  get dask(): boolean {
    return this._dask;
  }
  set dask(value: boolean) {
    this._dask = value;
  }
  get binaryTypeUrl(): string {
    return this._binaryTypeUrl;
  }
  set binaryTypeUrl(value: string) {
    this._binaryTypeUrl = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryTypeInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryTypeInfo.AsObject {
    return {
      binaryType: this.binaryType,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      name: this.name,
      description: this.description,
      deprecated: this.deprecated,
      customerDataSchemas: this.customerDataSchemas
        ? Object.keys(this.customerDataSchemas).reduce(
            (r, k) => ({
              ...r,
              [k]: this.customerDataSchemas![k]
                ? this.customerDataSchemas![k].toObject()
                : undefined,
            }),
            {}
          )
        : {},
      sgx: this.sgx,
      dask: this.dask,
      binaryTypeUrl: this.binaryTypeUrl,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryTypeInfo.AsProtobufJSON {
    return {
      binaryType:
        BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      name: this.name,
      description: this.description,
      deprecated: this.deprecated,
      customerDataSchemas: this.customerDataSchemas
        ? Object.keys(this.customerDataSchemas).reduce(
            (r, k) => ({
              ...r,
              [k]: this.customerDataSchemas![k]
                ? this.customerDataSchemas![k].toJSON()
                : null,
            }),
            {}
          )
        : {},
      sgx: this.sgx,
      dask: this.dask,
      binaryTypeUrl: this.binaryTypeUrl,
    };
  }
}
export module BinaryTypeInfo {
  /**
   * Standard JavaScript object representation for BinaryTypeInfo
   */
  export interface AsObject {
    binaryType: BinaryType;
    etag?: string;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    name: string;
    description: string;
    deprecated: boolean;
    customerDataSchemas: { [prop: string]: CustomerDataSchema };
    sgx: boolean;
    dask: boolean;
    binaryTypeUrl: string;
  }

  /**
   * Protobuf JSON representation for BinaryTypeInfo
   */
  export interface AsProtobufJSON {
    binaryType: string;
    etag: string | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    name: string;
    description: string;
    deprecated: boolean;
    customerDataSchemas: { [prop: string]: CustomerDataSchema };
    sgx: boolean;
    dask: boolean;
    binaryTypeUrl: string;
  }

  /**
   * Message implementation for storage.BinaryTypeInfo.CustomerDataSchemasEntry
   */
  export class CustomerDataSchemasEntry implements GrpcMessage {
    static id = 'storage.BinaryTypeInfo.CustomerDataSchemasEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new CustomerDataSchemasEntry();
      CustomerDataSchemasEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: CustomerDataSchemasEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: CustomerDataSchemasEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new CustomerDataSchema();
            _reader.readMessage(
              _instance.value,
              CustomerDataSchema.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      CustomerDataSchemasEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: CustomerDataSchemasEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          CustomerDataSchema.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: CustomerDataSchema;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of CustomerDataSchemasEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<CustomerDataSchemasEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value
        ? new CustomerDataSchema(_value.value)
        : undefined;
      CustomerDataSchemasEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): CustomerDataSchema | undefined {
      return this._value;
    }
    set value(value: CustomerDataSchema | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      CustomerDataSchemasEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): CustomerDataSchemasEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): CustomerDataSchemasEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module CustomerDataSchemasEntry {
    /**
     * Standard JavaScript object representation for CustomerDataSchemasEntry
     */
    export interface AsObject {
      key: string;
      value?: CustomerDataSchema.AsObject;
    }

    /**
     * Protobuf JSON representation for CustomerDataSchemasEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: CustomerDataSchema.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for storage.CustomerDataSchema
 */
export class CustomerDataSchema implements GrpcMessage {
  static id = 'storage.CustomerDataSchema';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSchema();
    CustomerDataSchema.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSchema) {
    _instance.name = _instance.name || '';
    _instance.datesAllowed = _instance.datesAllowed || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSchema,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.datesAllowed = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSchema.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSchema,
    _writer: BinaryWriter
  ) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.datesAllowed) {
      _writer.writeBool(2, _instance.datesAllowed);
    }
  }

  private _name: string;
  private _datesAllowed: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSchema to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerDataSchema.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.datesAllowed = _value.datesAllowed;
    CustomerDataSchema.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get datesAllowed(): boolean {
    return this._datesAllowed;
  }
  set datesAllowed(value: boolean) {
    this._datesAllowed = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSchema.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSchema.AsObject {
    return {
      name: this.name,
      datesAllowed: this.datesAllowed,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSchema.AsProtobufJSON {
    return {
      name: this.name,
      datesAllowed: this.datesAllowed,
    };
  }
}
export module CustomerDataSchema {
  /**
   * Standard JavaScript object representation for CustomerDataSchema
   */
  export interface AsObject {
    name: string;
    datesAllowed: boolean;
  }

  /**
   * Protobuf JSON representation for CustomerDataSchema
   */
  export interface AsProtobufJSON {
    name: string;
    datesAllowed: boolean;
  }
}
