<form [formGroup]="form">
  <mat-form-field class="input-full-width">
    <mat-label>Storage Type</mat-label>
    <mat-select
      formControlName="storage_type"
      (selectionChange)="onStorageTypeChange()"
    >
      <mat-option value="aws">AWS S3</mat-option>
      <mat-option value="gcs">Google Cloud Storage</mat-option>
    </mat-select>
  </mat-form-field>
  <app-customer-dropdown
    class="input-half-width"
    [showArchiveToggle]="false"
    [selectedCustomer]="inputCustomer"
    [showSearchBox]="false"
    [displayAsBanner]="false"
    [displayTitle]="false"
    [showCustomerId]="true"
    [displayLabel]="true"
    label="Customer ID"
    (selectCustomerEvent)="onCustomerSelect($event)"
  ></app-customer-dropdown>
  <mat-form-field class="input-half-width">
    <mat-label>Customer data set id</mat-label>
    <input matInput formControlName="customer_data_set_id" />
  </mat-form-field>
  <br />
  <mat-checkbox formControlName="isEncrypted"> Data encrypted </mat-checkbox>
  <mat-checkbox formControlName="scanOnly" class="scan-only-checkbox">
    Scan only
  </mat-checkbox>

  <div *ngIf="form.value.storage_type === 'aws'">
    <mat-form-field class="input-full-width">
      <mat-label>Storage Credentials</mat-label>
      <mat-select formControlName="storage_credential_id">
        <mat-option
          *ngFor="let storage_credential of storage_credentials"
          [value]="storage_credential.id"
        >
          {{ storage_credential.id }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>S3 Bucket Name</mat-label>
      <input matInput formControlName="s3_bucket_name" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>S3 Prefixes</mat-label>
      <div class="prefix-input-container">
        <div class="prefix-input-row">
          <input
            matInput
            #s3Input
            [placeholder]="'Enter prefix path'"
            (keyup.enter)="addPrefix('s3', s3Input.value); s3Input.value = ''"
          />
          <button
            mat-icon-button
            color="primary"
            (click)="addPrefix('s3', s3Input.value); s3Input.value = ''"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="prefix-chip-list">
          <mat-chip-set>
            <mat-chip
              *ngFor="
                let prefix of form.get('s3_prefixes')?.value || [];
                let i = index
              "
              [removable]="true"
              (removed)="removePrefix('s3', i)"
            >
              {{ prefix }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>key prefix</mat-label>
      <input matInput formControlName="key_prefix" />
    </mat-form-field>
  </div>
  <div *ngIf="form.value.storage_type === 'gcs'">
    <mat-form-field class="input-full-width">
      <mat-label>GCS Bucket Name</mat-label>
      <input matInput formControlName="gcs_bucket_name" />
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>GCS Prefixes</mat-label>
      <div class="prefix-input-container">
        <div class="prefix-input-row">
          <input
            matInput
            #gcsInput
            [placeholder]="'Enter prefix path'"
            (keyup.enter)="
              addPrefix('gcs', gcsInput.value); gcsInput.value = ''
            "
          />
          <button
            mat-icon-button
            color="primary"
            (click)="addPrefix('gcs', gcsInput.value); gcsInput.value = ''"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <div class="prefix-chip-list">
          <mat-chip-set>
            <mat-chip
              *ngFor="
                let prefix of form.get('gcs_prefixes')?.value || [];
                let i = index
              "
              [removable]="true"
              (removed)="removePrefix('gcs', i)"
            >
              {{ prefix }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </mat-form-field>
    <mat-form-field class="input-full-width">
      <mat-label>key prefix</mat-label>
      <input matInput formControlName="key_prefix" />
    </mat-form-field>
  </div>
</form>
