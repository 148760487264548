<form [formGroup]="form">
  <mat-form-field class="input-half-width">
    <mat-label>Job ID</mat-label>
    <input
      type="originalJobId"
      #originalJobId
      matInput
      formControlName="originalJobId"
    />
  </mat-form-field>
  <div *ngIf="showJobError" class="error">No job with this ID found</div>
  <br />
  <div *ngIf="originalJobId.value" class="cost-file-section">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner diameter="42"></mat-spinner>
    </div>
    <h2>Cost file upload</h2>

    <div class="drop-zone">
      <ngx-file-drop
        [multiple]="false"
        [directory]="false"
        dropZoneClassName="drop-zone"
        accept=".csv"
        (onFileDrop)="dropped($event)"
      >
        <ng-template
          ngx-file-drop-content-tmp
          let-openFileSelector="openFileSelector"
          showBrowseBtn="true"
        >
          <input
            type="file"
            class="file-input"
            data-testid="fileUploadControl"
            multiple
            fileControlName="fileUpload"
            required
            hidden
            accept=".csv"
            (change)="onFileSelectionChange($event)"
            #fileUpload
          />
          <div (click)="fileUpload.click()">
            <button class="upload-btn" data-testid="fileUploadButton">
              <mat-icon class="upload-icon">cloud_upload</mat-icon>
              <div class="upload-title">File Upload</div>
            </button>
          </div>
        </ng-template>
      </ngx-file-drop>
      <div class="file-upload-info" *ngIf="currentBlobUrl">
        <a
          href="{{ downloadCostFileContents() }}"
          class="button"
          download="cost-file.csv"
          target="_blank"
          ><mat-icon matTooltip="Download Cost File"
            >cloud_download</mat-icon
          ></a
        >
      </div>
    </div>
  </div>
</form>
