<app-container>
  <app-page-header
    title="Customer Manager"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <app-message-box></app-message-box>

  <section class="team-table-container" tabindex="0">
    <div class="team-table-title-container">
      <button
        class="add-user-button"
        (click)="createCustomer()"
        *ngIf="showCreateCustomer"
      >
        Add Customer
      </button>
    </div>

    <div class="mat-mdc-card">
      <div class="select">
        <mat-select
          matNativeControl
          required
          placeholder="Select customer type"
          (selectionChange)="filterByCustomerType($event.value)"
        >
          <mat-option value="0">Show All</mat-option>
          <mat-option value="1">{{ customerType[1] }}</mat-option>
          <mat-option value="2">{{ customerType[2] }}</mat-option>
        </mat-select>
      </div>
      <div class="select">
        <mat-checkbox
          name="showAllCustomers"
          [checked]="displayAllCustomers"
          (change)="setCustomerDisplay()"
          >Show all customers
        </mat-checkbox>
      </div>
    </div>

    <table
      mat-table
      [dataSource]="customers"
      matSort
      class="table-data"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="UserName">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="displayName"
        >
          User Name
        </th>
        <td mat-cell *matCellDef="let user">
          <div class="user-name-col">
            <img class="user-avatar" src="{{ 'assets/default-profile.png' }}" />
            <span>{{ user.displayName }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CompanyName">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="companyName"
        >
          Name
        </th>
        <td mat-cell *matCellDef="let user">
          <a (click)="editCustomer(user)" class="customer-name">{{
            user.companyName
          }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="Domains">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="domainNames"
        >
          Allowed Domains
        </th>
        <td mat-cell *matCellDef="let user">{{ user.domainNames }}</td>
      </ng-container>

      <ng-container matColumnDef="CustomerType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="companyType"
        >
          Customer Type
        </th>
        <td mat-cell *matCellDef="let user">
          <label
            class="badge-customer-type"
            *ngFor="
              let customerType of displayCustomerTypes(
                user.customerTypes
              ).split(',')
            "
            >{{ customerType }}</label
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Tenant">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="tenantId"
        >
          Tenant Id
        </th>
        <td mat-cell *matCellDef="let user">
          {{ user.tenantId }}
        </td>
      </ng-container>

      <ng-container matColumnDef="BinaryType">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="type"
        >
          Assigned Binaries
        </th>
        <td mat-cell *matCellDef="let user">
          <label
            *ngFor="let label of displayBinaryTypes(user.allowedBinaryTypes)"
            class="badge"
            >{{ label }}</label
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="disabled"
        >
          Enabled
        </th>
        <td mat-cell *matCellDef="let user" class="text-center">
          <input
            type="checkbox"
            name="{{ user.uid }}"
            [checked]="!user.disabled"
            [value]="user.uid"
            [disabled]="user.tenantId === anonymTenantId"
            (change)="disableCustomer(user)"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="row-header">Actions</th>
        <td mat-cell *matCellDef="let user" class="text-center">
          <button
            mat-icon-button
            [matMenuTriggerFor]="actionsMenu"
            data-testid="viewActionMenu"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #actionsMenu>
            <button mat-menu-item (click)="openStorageMetadataViewer(user.id)">
              <mat-icon>visibility</mat-icon>
              <span>View Metadata</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
    <mat-paginator
      *ngIf="(!isLoading || customers.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
    <div *ngIf="isLoading" class="loader">
      <mat-spinner diameter="42"></mat-spinner>
    </div>
  </section>
</app-container>
