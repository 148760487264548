<app-container>
  <app-page-header
    title="Storage Viewer"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <app-message-box></app-message-box>
  <span *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="24"></mat-spinner>
  </span>
  <section class="team-table-container" tabindex="0">
    <div class="title">
      <p>
        Dataset ID: <b>{{ customerDataSet?.id }}</b>
      </p>
      <p>
        Dataset Name: <b>{{ customerDataSet?.name }}</b>
      </p>
      <p>
        Location: <b>{{ getLocationName(customerDataSet?.location) }} region</b>
      </p>
      <p>
        State: <b>{{ customerDataSet?.state === 1 ? 'Draft' : 'Finalized' }}</b>
      </p>
    </div>
    <app-file-viewer
      [storageMetadata]="storageMetadata"
      [customerDataSet]="customerDataSet"
    ></app-file-viewer>
  </section>
</app-container>
