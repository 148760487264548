import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ApprovalDetailComponent } from 'app/views/audit-approval/approval-detail/approval-detail.component';
import { BinaryDetailsDialogComponent } from 'app/views/audit-approval/approval-detail/binary-details-dialog/binary-details-dialog.component';
import { AuditApprovalComponent } from 'app/views/audit-approval/audit-approval.component';
import { BinaryComponent } from 'app/views/audit-approval/binary/binary.component';
import { BinaryTypeDropdownComponent } from 'app/views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

@NgModule({
  declarations: [AuditApprovalComponent, BinaryDetailsDialogComponent],
  imports: [
    ApprovalDetailComponent,
    BinaryComponent,
    BinaryTypeDropdownComponent,
    CommonModule,
    ContainerComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSelectModule,
    MessageBoxComponent,
    PageHeaderComponent,
  ],
  providers: [MessageBoxProvider],
  exports: [AuditApprovalComponent],
})
export class AuditApprovalsModule {}
