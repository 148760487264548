import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  AwsAssumeRole,
  CustomerStorageCredential,
} from 'app/services/generated/src/main/proto/storage/customer-storage-credential.pb';

import { CustomerStorageCredentialService } from '../../services/customer-storage-credential.service';
import { CustomerDropdownComponent } from '../../views/shared/components/customer-dropdown/customer-dropdown.component';
import { MessageBoxComponent } from '../../views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-admin-storage-modal',
  templateUrl: './add-admin-storage-modal.component.html',
  styleUrls: [
    './add-admin-storage-modal.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    CustomerDropdownComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    ReactiveFormsModule,
  ],
})
export class AddAdminStorageModalComponent {
  adminStorageCredential: FormGroup;
  customerId: string | undefined;
  isLoading = false;
  update = false;
  title: string;

  constructor(
    private customerStorageCredentialService: CustomerStorageCredentialService,
    private dialogRef: MatDialogRef<AddAdminStorageModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private messageBox: MessageBoxProvider,
    @Inject(MAT_DIALOG_DATA)
    private storageCredential: CustomerStorageCredential
  ) {
    if (this.storageCredential) {
      this.update = true;
      this.title = 'Edit Customer Storage Credential';
    } else {
      this.title = 'Add Customer Storage Credential';
      this.storageCredential = new CustomerStorageCredential({
        customerId: '',
        awsAssumeRole: new AwsAssumeRole({
          roleArn: '',
          useExternalId: false,
        }),
      });
    }

    this.adminStorageCredential = this.formBuilder.group({
      customer_id: new FormControl(this.storageCredential?.customerId, {}),
      role_arn: new FormControl(
        this.storageCredential?.awsAssumeRole?.roleArn ?? '',
        {}
      ),
      use_external_id: new FormControl(
        this.storageCredential?.awsAssumeRole?.useExternalId ?? false,
        {}
      ),
    });

    this.formHelper.setForm(this.adminStorageCredential);
  }

  addCustomerStorageCredential(): void {
    this.isLoading = true;
    const customerStorageCredential = new CustomerStorageCredential();

    let newCustomerStorageCredential = undefined;
    try {
      newCustomerStorageCredential = this.buildCustomerStorageCredential(
        customerStorageCredential
      );
    } catch (error) {
      this.isLoading = false;
      this.messageBox.error((error as Error).message);
      return;
    }
    this.customerStorageCredentialService
      .createStorageCredential(newCustomerStorageCredential)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.messageBox.error(
          'Failed to create customer storage credential: ' + error.statusMessage
        );
      })
      .finally(() => (this.isLoading = false));
  }

  updateCustomerStorageCredential(): void {
    this.isLoading = true;

    let updatedCustomerStorageCredential = undefined;
    try {
      updatedCustomerStorageCredential = this.buildCustomerStorageCredential(
        this.storageCredential
      );
    } catch (error) {
      this.messageBox.error((error as Error).message);
      this.isLoading = false;
      return;
    }
    this.customerStorageCredentialService
      .updateStorageCredential(updatedCustomerStorageCredential)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.messageBox.error(
          'Failed to create customer storage credential: ' + error.statusMessage
        );
      })
      .finally(() => (this.isLoading = false));
  }

  buildCustomerStorageCredential(
    customerStorageCredential: CustomerStorageCredential
  ): CustomerStorageCredential {
    const { value } = this.adminStorageCredential;
    if (this.customerId) {
      customerStorageCredential.customerId = this.customerId;
    } else {
      throw new Error('Please select a valid customer.');
    }
    customerStorageCredential.awsAssumeRole = new AwsAssumeRole({
      roleArn: value.role_arn,
      useExternalId: value.use_external_id,
    });

    return customerStorageCredential;
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public close() {
    this.dialogRef.close();
  }

  process() {
    if (this.update) {
      this.updateCustomerStorageCredential();
    } else {
      this.addCustomerStorageCredential();
    }
  }

  onCustomerSelect(customer: Customer) {
    if (customer) {
      this.customerId = customer.id;
    }
  }
}
