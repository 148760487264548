import { DatePipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { FormatService } from 'app/services/format.service';
import {
  GetRequest,
  GetRequestFilter,
  GetResponse,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  JobLog,
  JobState,
} from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { Buffer } from 'buffer';
import { environment } from 'environments/environment';
import { gzip } from 'pako';

import {
  JOB_STATE_TO_STRING,
  TYPE_ID_TO_BINARY_TYPE,
} from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';

const RECORDS_PER_PAGE = 15;
const SESSION_STORAGE_JOB_CONFIG = 'adminJobPageConfiguration';
const REFRESH_TIME = 60 * 1000; // 1 minute

interface Page {
  continuationToken: string | null;
}

interface JobRow {
  id: string;
  description: string;
  binaryType: string;
  customers: string;
  state: string;
  creationTimestamp: string;
}

@Component({
  selector: 'app-admin-job-list',
  providers: [DatePipe],
  templateUrl: './admin-job-list.component.html',
  styleUrls: ['./admin-job-list.component.scss'],
  standalone: false,
})
export class AdminJobListComponent implements AfterViewInit, OnInit, OnDestroy {
  binaryTypeToName = new Map<BinaryType, string>();
  selectedBinaryType: BinaryType | undefined;
  customers: Customer[] | undefined;
  dataSource: MatTableDataSource<JobRow> = new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'id',
    'description',
    'state',
    'binaryType',
    'customers',
    'creationTimestamp',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  firstRun = true;
  jobLogs: JobLog[] = [];
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomer: Customer | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  readonly BinaryType = BinaryType;
  refreshInterval: number | undefined;
  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    private binaryTypeService: BinaryTypeService,
    private jobLogService: JobLogService,
    private formatService: FormatService,
    private logger: LoggerService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.restorePageConfig();
  }

  async ngOnInit() {
    await this.loadBinaryTypes();
    this.loadJobs();
    this.startAutoRefresh();
  }

  ngOnDestroy(): void {
    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
  }

  startAutoRefresh() {
    this.refreshInterval = window.setInterval(() => {
      if (this.document.hidden || this.isLoading) return;
      this.loadJobs(this.currentPageIndex, this.getContinuationToken());
    }, REFRESH_TIME);
  }

  restorePageConfig() {
    const config = sessionStorage.getItem(SESSION_STORAGE_JOB_CONFIG);
    if (config) {
      try {
        const { pageIndex, pages, totalRecords, customer, selectedBinaryType } =
          JSON.parse(config);
        this.currentPageIndex = pageIndex;
        this.pages = pages;
        this.totalRecords = totalRecords;
        this.selectedCustomer = customer;
        this.selectedBinaryType = selectedBinaryType;
      } catch (error) {
        this.logger.error('Error restoring page config:', error);
      }
    }
  }

  getContinuationToken() {
    return this.pages[this.currentPageIndex]?.continuationToken || null;
  }

  onCustomerSelect(customer: Customer) {
    this.selectedCustomer = customer;
    sessionStorage.removeItem(SESSION_STORAGE_JOB_CONFIG);
    if (this.firstRun) {
      this.loadJobs(this.currentPageIndex, this.getContinuationToken());
    } else {
      this.loadJobs();
    }
    this.firstRun = false;
  }

  onBinaryTypeSelect(selectedBinaryType: BinaryType) {
    if (selectedBinaryType === BinaryType.BINARY_TYPE_UNSPECIFIED) {
      this.selectedBinaryType = undefined;
    } else {
      this.selectedBinaryType = selectedBinaryType;
    }
    this.firstRun = false;
    this.loadJobs();
  }

  async onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const continuationToken = this.pages[pageIndex]?.continuationToken;
    this.loadJobs(pageIndex, continuationToken);
    this.currentPageIndex = pageIndex;
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  createGetRequest(token: string | null): GetRequest {
    const getRequest = new GetRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && getRequest.paginated) {
      getRequest.paginated.continuationToken = token;
    }

    if (this.selectedCustomer) {
      getRequest.filter = new GetRequestFilter();
      getRequest.filter.customerIds = [this.selectedCustomer.id];
    }

    return getRequest;
  }

  async loadJobs(pageIndex = 0, token: string | null = null) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.dataSource.data.splice(0);
      this.jobLogs.splice(0);

      try {
        const getRequest = this.createGetRequest(token);
        const response = await this.jobLogService.get(getRequest);

        response.jobLogs?.forEach((apiJobLog) => {
          const jobLog = apiJobLog!.jobLog!;
          const binaryType =
            TYPE_ID_TO_BINARY_TYPE[jobLog.binaryConfig!.getPackedMessageId()];

          // Skip if binary type filter is active and doesn't match
          if (
            this.selectedBinaryType &&
            binaryType !== this.selectedBinaryType
          ) {
            return;
          }

          this.jobLogs.push(jobLog);
          const roles: string[] = [];
          jobLog.customerRoles?.forEach((customerRole) => {
            if (roles.indexOf(customerRole.customerId) == -1) {
              roles.push(customerRole.customerId);
            }
          });

          this.dataSource.data.push({
            id: jobLog.id,
            description: jobLog.metadata!.description,
            binaryType: this.binaryTypeToName.get(binaryType) ?? '',
            customers: roles.join(', '),
            state: JOB_STATE_TO_STRING[jobLog.state!],
            creationTimestamp: jobLog.creationTimestamp
              ? jobLog.creationTimestamp.seconds
              : '',
          });
        });

        this.updatePaginationData(response, pageIndex);
        this.savePageConfig(pageIndex);
        this.setDataSource();
      } catch (error) {
        this.logger.error('Error loading jobs:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  setDataSource() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id':
          return item.id;
        case 'description':
          return item.description;
        case 'binaryType':
          return item.binaryType;
        case 'customers':
          return item.customers;
        case 'state':
          return item.state;
        case 'creationTimestamp':
          return item.creationTimestamp;
        default:
          return item.creationTimestamp;
      }
    };
    this.dataSource.sort = this.sort;
  }

  updatePaginationData(response: GetResponse, pageIndex: number) {
    if (pageIndex === 0) {
      this.totalRecords =
        response.paginatedResponse?.count || this.dataSource.data.length;
    }
    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
  }

  savePageConfig(pageIndex: number) {
    try {
      sessionStorage.setItem(
        SESSION_STORAGE_JOB_CONFIG,
        JSON.stringify({
          pageIndex: pageIndex,
          pages: this.pages,
          totalRecords: this.totalRecords,
          customer: this.selectedCustomer,
          selectedBinaryType: this.selectedBinaryType,
        })
      );
    } catch (error) {
      this.logger.error('Error saving page config:', error);
    }
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
  }

  async copyJob(id: string) {
    try {
      await this.jobLogService.clone(id);
      this.ngOnInit();
      this.loadJobs(this.currentPageIndex);
    } catch (error) {
      this.logger.error('Error copying job:', error);
    }
  }

  async cancelJob(id: string) {
    if (confirm('Are you sure you want to cancel this job?')) {
      try {
        await this.jobLogService.cancelJob(id);
        this.loadJobs(this.currentPageIndex);
      } catch (error) {
        this.logger.error('Error cancelling job:', error);
      }
    }
  }

  canCancelJob(state: string): boolean {
    return ['Running', 'Pending'].includes(state);
  }

  viewResults(id: string) {
    this.router.navigate([`/job-results/${id}/view`]);
  }

  displayResults(id: string) {
    const jobLog = this.jobLogs.find((log) => log.id === id);
    return jobLog && jobLog.state === JobState.JOB_STATE_COMPLETE;
  }

  editJob(id: string, state: string) {
    if (state === 'Draft') {
      this.router.navigate([`/jobs/${id}/edit`]);
    } else {
      this.router.navigate([`/job-results/${id}/view`]);
    }
  }

  createJob() {
    this.router.navigate(['/jobs/new']);
  }

  resetFilter() {
    sessionStorage.removeItem(SESSION_STORAGE_JOB_CONFIG);
    this.currentPageIndex = 0;
    this.pages = [];
    this.selectedCustomer = undefined;
    this.selectedBinaryType = BinaryType.BINARY_TYPE_UNSPECIFIED;
    this.loadJobs();
  }

  viewLogs(jobId: string) {
    const logsUrl = this.buildLogsUrl(jobId);
    window.open(logsUrl, '_blank');
  }

  private buildLogsUrl(jobId: string): string {
    // 1) The raw KQL query
    const query = `
      let jobid = "${jobId}";
      ContainerLogV2
      | where PodName has jobid
      | order by TimeGenerated asc
      | summarize strcat_array(make_list(LogMessage), "\\n") by PodName, ContainerId
      `;

    // 2) Convert query to UTF-8 bytes
    const encoder = new TextEncoder();
    const queryBytes = encoder.encode(query);

    // 3) GZip compress using pako
    const compressed = gzip(queryBytes);

    // 4) Base64-encode the compressed bytes
    const base64Encoded = Buffer.from(compressed).toString('base64');

    // 5) URL-encode special chars (/ + =)
    const encodedText = encodeURIComponent(base64Encoded)
      .replace(/\//g, '%2F')
      .replace(/\+/g, '%2B')
      .replace(/=/g, '%3D');

    // 6) Build final link using the compressed approach
    //    Example points to the LogsBlade or AnalyticsShareLinkToQuery.
    //    If you want “Logs.ReactView” style, it might look like:
    //      https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade
    //      /resourceId/<...>
    //      /source/LogsBlade.AnalyticsShareLinkToQuery
    //      /query/<encodedText>
    //      /isQueryBase64Compressed/true
    //
    //    Adjust the resourceId for your actual workspace.

    const resourceId = environment.azureLogs.resourceId;

    const url =
      `https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade` +
      `/resourceId/${encodeURIComponent(resourceId)}` +
      `/source/LogsBlade.AnalyticsShareLinkToQuery` +
      `/query/${encodedText}` +
      `/isQueryBase64Compressed/true` +
      `/timespan/P3D`;

    return url;
  }
}
