import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface SearchTerms {
  text: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class SearchComponent {
  @Input() defaultText: string | undefined;
  @Input() displayTitle: boolean = true;
  @Input() title: string = 'Search';
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Output() selectSearchEvent = new EventEmitter<SearchTerms>();
  @ViewChild('search') search!: ElementRef;
  form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.getForm();
  }

  getForm() {
    return this.formBuilder.group({
      text: this.defaultText,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  onSearch() {
    const { value } = this.form;
    let text = undefined;
    if (value.text && value.text.trim().length > 0) {
      text = value.text.trim();
    }

    let startDate = undefined;
    if (value.startDate) {
      startDate = new Date(value.startDate);
    }

    let endDate = undefined;
    if (value.endDate) {
      endDate = new Date(value.endDate);
    }

    this.selectSearchEvent.emit({
      text: text,
      startDate: startDate,
      endDate: endDate,
    });
  }

  onReset() {
    this.form = this.getForm();
    this.selectSearchEvent.emit({
      text: undefined,
      startDate: undefined,
      endDate: undefined,
    });
  }
}
