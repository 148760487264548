<form [formGroup]="schemaForm">
  <div class="schema-type-section" *ngIf="showTemplateLoader">
    <div class="form-group">
      <label for="schemaType">Load from template</label>
      <select id="schemaType" formControlName="schemaType">
        <option
          *ngFor="let template of Object.keys(templates)"
          [value]="template"
        >
          {{ template }}
        </option>
      </select>
    </div>
  </div>

  <div formArrayName="columns">
    <div
      *ngFor="let column of columns.controls; let i = index"
      [formGroupName]="i"
      class="column-form"
    >
      <div class="fields-row">
        <button type="button" class="remove-btn" (click)="removeColumn(i)">
          ×
        </button>
        <div class="form-group">
          <input
            type="text"
            formControlName="name"
            placeholder="Enter column name"
          />
        </div>

        <div class="form-group">
          <select formControlName="type">
            <option *ngFor="let type of columnTypes" [value]="type">
              {{ formatColumnType(type) }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <input
            type="text"
            formControlName="columnMapping"
            placeholder="Alias"
          />
        </div>
      </div>

      <!-- Time format section -->
      <div
        *ngIf="isTimeColumn(column.get('type')?.value)"
        class="time-format-section"
      >
        <div class="time-format-form">
          <div class="fields-row">
            <div class="form-group">
              <input
                type="text"
                formControlName="timeFormat"
                placeholder="Time format (e.g. %Y-%m-%d)"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Categories section for PII type and event type columns -->
      <div
        *ngIf="
          isPiiTypeColumn(column.get('type')?.value) ||
          isAdvertiserEventTypeColumn(column.get('type')?.value) ||
          isPublisherEventTypeColumn(column.get('type')?.value)
        "
        class="categories-section"
      >
        <div formArrayName="categories">
          <div
            *ngFor="let category of getCategories(i).controls; let j = index"
            [formGroupName]="j"
            class="category-form"
          >
            <div class="fields-row">
              <button
                type="button"
                class="remove-btn"
                (click)="removeCategory(i, j)"
              >
                ×
              </button>
              <div class="form-group">
                <ng-container *ngIf="isAdvertiserEventTypeCategoryName(i)">
                  <select [formControlName]="'name'">
                    <option
                      *ngFor="let type of advertiserEventTypes"
                      [value]="type"
                    >
                      {{ type }}
                    </option>
                  </select>
                </ng-container>
                <ng-container *ngIf="isPublisherEventTypeCategoryName(i)">
                  <select [formControlName]="'name'">
                    <option
                      *ngFor="let type of publisherEventTypes"
                      [value]="type"
                    >
                      {{ type }}
                    </option>
                  </select>
                </ng-container>
                <ng-container
                  *ngIf="
                    !isAdvertiserEventTypeCategoryName(i) &&
                    !isPublisherEventTypeCategoryName(i)
                  "
                >
                  <input
                    type="text"
                    formControlName="name"
                    placeholder="Category name"
                  />
                </ng-container>
              </div>
              <div class="form-group">
                <input
                  type="text"
                  formControlName="values"
                  placeholder="Aliases (comma-separated)"
                />
              </div>
            </div>
          </div>
        </div>
        <button type="button" (click)="addCategory(i)">Add Category</button>
      </div>
    </div>
  </div>

  <button type="button" (click)="addColumn()">Add Column</button>
</form>
