<app-container>
  <app-page-header
    title="Account Settings"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <div class="company-account-card-title">
    <h2 class="users-title">Customer Account</h2>
    <app-company-account-card [customerData]="customerData" title="">
    </app-company-account-card>
  </div>

  <div class="company-account-card-title">
    <h2 class="users-title">Permissions</h2>
    <mat-card appearance="outlined" class="managed-account-card">
      <mat-card-content class="managed-account-card-content">
        <ul>
          <li>
            <mat-slide-toggle
              color="primary"
              #managedAccountSlider
              (click)="updateManagedAccount(!isInReviewMode)"
              [checked]="isInReviewMode"
              [disabled]="!showCreateUser"
            >
              <p class="field-title">
                Allow <b>{{ customerData?.companyName }}</b> to manually
                <b>approve binaries</b> when a new version gets released.
              </p>
              <mat-hint class="field-title-hint" [hidden]="showCreateUser"
                >Only admins can toggle this option.</mat-hint
              >
            </mat-slide-toggle>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>

  <app-message-box></app-message-box>
  <section
    class="team-table-container"
    tabindex="0"
    *ngIf="users.data.length > 0"
  >
    <div class="team-table-title-container">
      <h2 class="users-title">Team Members</h2>
      <button
        class="add-user-button"
        (click)="createUser()"
        data-testid="addMember"
        *ngIf="showCreateUser"
      >
        Add Users
      </button>
    </div>
    <table
      mat-table
      [dataSource]="users"
      matSort
      class="table-data"
      (matSortChange)="announceSortChange($event)"
    >
      <ng-container matColumnDef="UserName">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header="displayName"
        >
          User Name
        </th>
        <td mat-cell *matCellDef="let teamMember">
          <div class="user-name-col">
            <img
              class="user-avatar"
              [src]="teamMember.photoUrl || 'assets/default-profile.png'"
            />
            <span *ngIf="showCreateUser">
              <a (click)="editUser(teamMember)" class="user-number">{{
                teamMember.displayName
              }}</a>
            </span>
            <span *ngIf="!showCreateUser">{{ teamMember.displayName }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Email">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="email"
        >
          Email
        </th>
        <td mat-cell *matCellDef="let teamMember">{{ teamMember.email }}</td>
      </ng-container>

      <ng-container matColumnDef="Role">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="roles"
        >
          Role
        </th>
        <td mat-cell *matCellDef="let teamMember">
          <label
            *ngFor="let role of filterRoles(teamMember.roles)"
            class="badge"
            >{{ role }}</label
          >
        </td>
      </ng-container>

      <ng-container matColumnDef="Enabled">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="row-header"
          mat-sort-header="disabled"
        >
          Enabled
        </th>
        <td mat-cell *matCellDef="let teamMember" class="text-center">
          <input
            type="checkbox"
            name="{{ teamMember.uid }}"
            [checked]="!teamMember.disabled"
            [value]="teamMember.uid"
            [disabled]="!this.showCreateUser"
            (change)="changeUserState(teamMember)"
            matTooltip="Disable user"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="Delete">
        <th mat-header-cell *matHeaderCellDef class="row-header"></th>
        <td mat-cell *matCellDef="let teamMember" class="text-center">
          <button
            (click)="deleteUser(teamMember)"
            class="delete"
            data-testid="delete"
            matTooltip="Delete user"
          >
            <mat-icon class="delete-icon">delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
    <div *ngIf="isLoadingUsers" class="loader">
      <mat-spinner diameter="42"></mat-spinner>
    </div>
  </section>
</app-container>
