<app-container>
  <app-page-header title="Job Results" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <div class="sub-header">
    <div class="meta-group">
      <div class="meta-item">
        <div class="label">Name</div>
        <div class="value">{{ jobLog.metadata!.description }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Type</div>
        <div class="value">{{ headerBinaryType }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Date</div>
        <div class="value">{{ headerJobTime }}</div>
      </div>
      <div class="meta-item">
        <div class="label">State</div>
        <div class="value">{{ headerState }}</div>
      </div>
      <div class="meta-item" *ngIf="headerPubCustomerId">
        <div class="label">Publisher</div>
        <div class="value">{{ headerPubCustomerId }}</div>
      </div>
      <div class="meta-item" *ngIf="headerAdvCustomerId">
        <div class="label">Advertiser</div>
        <div class="value">{{ headerAdvCustomerId }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Region</div>
        <div class="value">{{ headerRegion }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyId">
        <div class="label">Study ID</div>
        <div class="value">{{ headerStudyId }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyStartTime">
        <div class="label">Study Start Date</div>
        <div class="value">{{ headerStudyStartTime }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyEndTime">
        <div class="label">Study End Date</div>
        <div class="value">{{ headerStudyEndTime }}</div>
      </div>
      <div class="meta-item" *ngIf="headerAdvEndTime">
        <div class="label">Conversions End Date</div>
        <div class="value">{{ headerAdvEndTime }}</div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <section class="data-table-container" tabindex="0">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <mat-tab-group>
      <mat-tab label="Results">
        <mat-card *ngIf="resultsUiEnabled" appearance="outlined">
          <div class="stat-group">
            <h3>Lift Results</h3>
            <div *ngFor="let lec of liftEstimationConfigs; let i = index">
              <div class="sub-header">
                <div class="meta-group">
                  <div class="meta-item">
                    <div class="label">Algorithm</div>
                    <div class="value-lower">
                      {{ LiftAlgorithm[lec.liftAlgorithm!] }}
                    </div>
                  </div>
                  <div class="meta-item">
                    <div class="label">Rho</div>
                    <div class="value-lower">
                      {{ lec.privacyConfig ? lec.privacyConfig.rho! : '' }}
                    </div>
                  </div>
                  <div class="meta-item">
                    <div class="label">Sensitivity percentile</div>
                    <div class="value-lower">
                      {{
                        lec.sensitivityConfig
                          ? lec.sensitivityConfig.percentile!
                          : '0'
                      }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <app-results-table
                [jobId]="jobId"
                [fileName]="'postprocessing/results_lec_' + i + '.csv'"
              >
              </app-results-table>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="QA/QC">
        <mat-card *ngIf="resultsUiEnabled" appearance="outlined">
          <div class="stat-group">
            <h3>Opportunities Data Cleaning Stats</h3>
            <app-results-table
              [jobId]="jobId"
              fileName="postprocessing/publisher_cleaning.csv"
            >
            </app-results-table>
          </div>
          <div class="stat-group">
            <h3>Conversions Data Cleaning Stats</h3>
            <app-results-table
              [jobId]="jobId"
              fileName="postprocessing/advertiser_cleaning.csv"
            >
            </app-results-table>
          </div>
          <div class="stat-group">
            <h3>Waterfall Match</h3>
            <app-results-table
              [jobId]="jobId"
              fileName="postprocessing/waterfall_match_stats.csv"
            >
            </app-results-table>
          </div>
          <div class="stat-group">
            <h3>Conversions Funnel</h3>
            <app-results-table
              [jobId]="jobId"
              fileName="postprocessing/conversions.csv"
            >
            </app-results-table>
          </div>
          <div class="stat-group">
            <h3>Study setup</h3>
            <app-results-table
              [jobId]="jobId"
              fileName="postprocessing/test_setup.csv"
            >
            </app-results-table>
          </div>
          <div class="stat-group">
            <h3>Lift Power Analysis</h3>
            <div *ngFor="let lec of liftEstimationConfigs; let i = index">
              <div class="sub-header">
                <div class="meta-group">
                  <div class="meta-item">
                    <div class="label">Algorithm</div>
                    <div class="value-lower">
                      {{ LiftAlgorithm[lec.liftAlgorithm!] }}
                    </div>
                  </div>
                  <div class="meta-item">
                    <div class="label">Rho</div>
                    <div class="value-lower">
                      {{ lec.privacyConfig ? lec.privacyConfig.rho! : '' }}
                    </div>
                  </div>
                  <div class="meta-item">
                    <div class="label">Sensitivity percentile</div>
                    <div class="value-lower">
                      {{
                        lec.sensitivityConfig
                          ? lec.sensitivityConfig.percentile!
                          : '0'
                      }}%
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <app-results-table
                [jobId]="jobId"
                [fileName]="'postprocessing/power_analysis_lec_' + i + '.csv'"
              >
              </app-results-table>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Config">
        <mat-card appearance="outlined">
          <pre><code [highlight]="formatJson(jobLogJson)" language="json"></code></pre>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
        "
        label="Debug logs"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
                "
                matTooltip="Download Json log file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
          )
        "
        label="Raw results"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                  )
                "
                matTooltip="Download CSV file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                  )
                "
                matTooltip="Download csv file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON)
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                  )
                "
                matTooltip="Download json file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_STORAGE_METADATA
          )
        "
        label="Storage Metadata"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_STORAGE_METADATA
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_STORAGE_METADATA
                  )
                "
                matTooltip="Download CSV file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_STORAGE_METADATA
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_STORAGE_METADATA
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          >
          </app-csv-viewer>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </section>
</app-container>
