import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GrpcStatusEvent } from '@ngx-grpc/common';
import { GrpcStatus } from 'app/constants/grpc-status';
import { LocationList } from 'app/constants/lookups';
import { FormHelpersService } from 'app/services/form-helpers.service';
import {
  KeyVersion,
  KeyVersions,
} from 'app/services/generated/src/main/proto/api/key-service.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  CustomerDataSet,
  EncryptedDataConfig,
  ExternalBlobStorage,
  FormatterConfig,
  KeyFileStructure,
} from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';

import { CustomerService } from '../../services/customer.service';
import { CustomerDataService } from '../../services/customer-data.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-add-customer-data-modal',
  templateUrl: './add-customer-data-modal.component.html',
  styleUrls: [
    './add-customer-data-modal.component.scss',
    '../../shared/shared.scss',
  ],
})
export class AddCustomerDataModalComponent implements OnInit {
  allowedCustomerLocations: Location[] | undefined;
  customerDataSet: FormGroup;
  defaultKey = 'default';
  isLoading = false;
  keyControl: FormControl;
  // Make KeyFileStructure available in the template.
  KeyFileStructure = KeyFileStructure;
  keyFileStructureControl: FormControl;
  keys: KeyVersions[] = [];
  keyVersions: KeyVersion[] = [];
  locations = LocationList;
  selectedCustomer: Customer | undefined;
  selectedLocation: Location | undefined;
  title: string;
  update = false;

  constructor(
    private customerDataService: CustomerDataService,
    private customerService: CustomerService,
    private dialogRef: MatDialogRef<AddCustomerDataModalComponent>,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private keyService: KeyService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    @Inject(MAT_DIALOG_DATA)
    private data: CustomerDataSet
  ) {
    if (this.data) {
      this.update = true;
      this.title = 'Edit Customer Data Set';
    } else {
      this.title = 'Add customer data';
      // This is the default for a new dataset.
      this.data = new CustomerDataSet({
        name: '',
        customerId: '',
        encryptedData: new EncryptedDataConfig({
          dataPathPrefix: 'data/',
          encryptedSymmetricKeyPath: 'key_file',
          keyId: '',
          keyVersion: '',
        }),
      });
    }

    this.keyControl = new FormControl(this.data?.encryptedData?.keyId, {
      validators: [Validators.required],
    });
    this.keyFileStructureControl = new FormControl(
      this.data?.encryptedData?.keyFileStructure ||
        KeyFileStructure.KEY_FILE_STRUCTURE_SINGLE_KEY,
      {}
    );

    this.customerDataSet = this.formBuilder.group({
      name: new FormControl(this.data?.name, {
        validators: [Validators.required],
      }),
      key: this.keyControl,
      key_version: new FormControl(this.data?.encryptedData?.keyVersion, {
        validators: [Validators.required],
      }),
      key_file_structure: this.keyFileStructureControl,
      external_data_directory: new FormControl(
        this.data?.externalBlobStorage?.dataPathPrefix,
        {}
      ),
      external_key_path: new FormControl(
        this.data?.externalBlobStorage?.encryptedSymmetricKeyPath
      ),
      data_directory: new FormControl(
        this.data?.encryptedData?.dataPathPrefix,
        {
          validators: [Validators.required],
        }
      ),
      key_path: new FormControl(
        this.data?.encryptedData?.encryptedSymmetricKeyPath,
        {
          validators: [Validators.required],
        }
      ),
      dated: new FormControl(this.data.dated ?? false),
      parser_config: new FormControl(
        this.data?.formatterConfig
          ? JSON.stringify(this.data?.formatterConfig.toJSON(), undefined, 2)
          : '{}',
        {
          validators: [Validators.required],
        }
      ),
    });

    this.formHelper.setForm(this.customerDataSet);
  }

  ngOnInit(): void {
    if (this.data.customerId) {
      this.isLoading = true;
      this.loadKeys(this.data.customerId)
        .then(() => {
          this.loadCustomer(this.data.customerId);
          // Keys are already selected, update versions
          if (this.data?.encryptedData?.keyId) {
            this.onKeySelect(this.data?.encryptedData?.keyId);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  addCustomerData(): void {
    this.isLoading = true;
    const customerDataSet = new CustomerDataSet();

    let newCustomerDataSet = undefined;
    try {
      newCustomerDataSet = this.buildCustomerDataSet(customerDataSet);
    } catch (error) {
      this.isLoading = false;
      this.messageBox.error((error as Error).message);
      return;
    }
    this.customerDataService
      .create(newCustomerDataSet)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.messageBox.error(
          'Failed to create customer data: ' + error.statusMessage
        );
      })
      .finally(() => (this.isLoading = false));
  }

  updateCustomerData(): void {
    this.isLoading = true;

    let newCustomerDataSet = undefined;
    try {
      newCustomerDataSet = this.buildCustomerDataSet(this.data);
    } catch (error) {
      this.messageBox.error((error as Error).message);
      return;
    }
    this.customerDataService
      .update(newCustomerDataSet)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        this.messageBox.error(
          'Failed to update customer data: ' + error.statusMessage
        );
      })
      .finally(() => (this.isLoading = false));
  }

  buildCustomerDataSet(customerDataSet: CustomerDataSet): CustomerDataSet {
    const { value } = this.customerDataSet;

    customerDataSet.name = value.name;
    if (this.selectedCustomer) {
      customerDataSet.customerId = this.selectedCustomer.id;
    }
    if (this.selectedLocation) {
      customerDataSet.location = this.selectedLocation;
    }
    if (value.key != undefined && value.key != '') {
      customerDataSet.encryptedData = new EncryptedDataConfig({
        keyId: value.key,
        keyVersion: value.key_version,
        dataPathPrefix: value.data_directory,
        encryptedSymmetricKeyPath: value.key_path,
        keyFileStructure: value.key_file_structure,
      });
    } else {
      throw new Error('Customer key and version are required.');
    }
    if (
      value.external_data_directory != undefined &&
      value.external_data_directory != ''
    ) {
      customerDataSet.externalBlobStorage = new ExternalBlobStorage({
        dataPathPrefix: value.external_data_directory,
      });
      if (value.key != undefined && value.key != '') {
        customerDataSet.externalBlobStorage.encryptedSymmetricKeyPath =
          value.external_key_path;
      }
    }
    customerDataSet.dated = value.dated;
    try {
      customerDataSet.formatterConfig = new FormatterConfig(
        JSON.parse(value.parser_config)
      );
    } catch (e) {
      throw new Error('Failed to parse advanced config');
    }

    return customerDataSet;
  }

  public checkError(controlName: string, errorName: string) {
    return this.formHelper.checkError(controlName, errorName);
  }

  public close() {
    this.dialogRef.close();
  }

  process() {
    if (this.update) {
      this.updateCustomerData();
    } else {
      this.addCustomerData();
    }
  }

  loadCustomer(customerId: string) {
    this.customerService.readCustomer(customerId).then((response) => {
      this.selectedCustomer = response.customer;
      if (this.selectedCustomer) {
        this.onCustomerSelect(this.selectedCustomer);
      }
    });
  }

  async loadKeys(customerId: string) {
    try {
      const response = await this.keyService.listAllKeys([customerId]);
      this.keys = [];
      this.keys.push(...response.keyVersions!);
      this.keys.sort((a, b) => {
        if (a.config?.keyName && b.config?.keyName) {
          return a.config?.keyName!.localeCompare(b.config?.keyName);
        } else {
          return 0;
        }
      });

      if (this.keys.length == 1 && this.keys[0].config) {
        this.onKeySelect(this.keys[0].config!.id);
        this.customerDataSet.get('key')?.setValue(this.keys[0].config!.id);
      } else {
        const key = this.keys.find((key) => key.config?.keyName === 'default');
        if (key) {
          this.customerDataSet
            .get('key')
            ?.setValue(key.config?.id, { onlySelf: true });
        }
      }
    } catch (error) {
      const grpcStatusEvent = error as GrpcStatusEvent;
      if (grpcStatusEvent.statusCode === GrpcStatus.NOT_FOUND) {
        this.logger.info(grpcStatusEvent.statusMessage);
        this.messageBox.show('No keys found for customer.');
      } else {
        this.logger.error(grpcStatusEvent.statusMessage);
        this.messageBox.error('Error loading customer keys.');
      }
    }
  }

  getAllowedCustomerLocations() {
    if (this.selectedCustomer) {
      this.allowedCustomerLocations = this.selectedCustomer.locations;
    }
    this.selectedLocation = this.data?.location;
  }

  onCustomerSelect(customer: Customer) {
    this.selectedCustomer = customer;
    this.getAllowedCustomerLocations();
    this.loadKeys(customer.id);
  }

  onKeySelect(keyId: string) {
    this.keyVersions.splice(0);
    this.keys.forEach((key) => {
      if (key.config?.id == keyId) {
        this.keyVersions.push(...key.versions!);
      }
    });
    if (this.keyVersions.length == 1) {
      this.customerDataSet
        .get('key_version')
        ?.setValue(this.keyVersions[0].version);
    }
  }

  onLocationSelect(locations: Location) {
    this.selectedLocation = locations;
  }
}
