<app-container>
  <app-page-header title="Notifications"></app-page-header>
  <app-message-box></app-message-box>

  <form
    ngNativeValidate
    (ngSubmit)="send()"
    [formGroup]="notificationForm"
    id="customerForm"
  >
    <div class="field-row">
      <app-customer-dropdown
        [showArchiveToggle]="false"
        [showSearchBox]="true"
        [displayAsBanner]="false"
        [displayTitle]="true"
        (selectCustomerEvent)="onCustomerSelect($event)"
      ></app-customer-dropdown>
    </div>

    <div class="field-row">
      <mat-form-field appearance="fill">
        <mat-label>Topic</mat-label>
        <mat-select
          data-testid="topicSelect"
          formControlName="notificationTopic"
          tabIndex="2"
          required
        >
          <mat-option
            *ngFor="let topic of notificationTopics"
            [value]="topic.value"
            >{{ topic.name }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            notificationForm.controls['notificationTopic'].hasError('required')
          "
          >Topic is required.</mat-error
        >
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-label class="field-title">Notification Type</mat-label>
      <div *ngFor="let type of notificationTypes">
        <mat-label class="field-title">{{ type.name }}</mat-label>
        <input
          class="app-input"
          type="checkbox"
          formArrayName="notificationTypes"
          [value]="type.value"
          (change)="onCheckboxChange($event, 'notificationTypes')"
          tabIndex="3"
        />
      </div>
    </div>

    <div class="field-row">
      <mat-form-field appearance="fill">
        <mat-label class="field-title">Title</mat-label>
        <input
          matInput
          class="app-input"
          type="text"
          required
          formControlName="title"
          size="60"
          tabIndex="5"
        />
        <mat-error *ngIf="checkError('title', 'required')"
          >Message title is required.</mat-error
        >
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field appearance="fill">
        <mat-label class="field-title">Body</mat-label>
        <textarea
          matInput
          placeholder="Type in your message..."
          formControlName="body"
        ></textarea>
        <mat-error *ngIf="checkError('body', 'required')"
          >Message body is required.</mat-error
        >
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field appearance="fill">
        <mat-label class="field-title">Icon</mat-label>
        <input
          matInput
          class="app-input"
          type="text"
          placeholder="Type icon Name..."
          size="40"
          tabIndex="6"
          formControlName="icon"
        />
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field appearance="fill">
        <mat-label class="field-title">Link</mat-label>
        <input
          matInput
          class="app-input"
          type="text"
          placeholder="Type link to target page..."
          size="60"
          tabIndex="7"
          formControlName="link"
        />
      </mat-form-field>
    </div>

    <div class="save-btn" mat-dialog-actions>
      <button
        mat-flat-button
        color="primary"
        [disabled]="isLoading"
        type="submit"
        data-testid="saveBtn"
      >
        Send
        <span *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="24"></mat-spinner>
        </span>
      </button>
    </div>
  </form>
</app-container>
