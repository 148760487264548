import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import {
  GRPC_MESSAGE_POOL,
  TYPE_ID_TO_BINARY_TYPE,
} from 'app/constants/lookups';
import { GetRequest } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { PostprocessingConfig } from 'app/services/generated/src/main/proto/postprocessing/postprocessing-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { Project } from 'app/services/generated/src/main/proto/storage/project.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';

export interface PostprocessingInfo {
  postprocessingConfig: PostprocessingConfig;
  customerIds: string[];
}

interface FormModel {
  originalJobId: string;
}

interface CostFileState {
  contents?: string;
  blobUrl?: string;
  isUploading: boolean;
}

@Component({
  selector: 'app-postprocessing',
  templateUrl: './postprocessing.component.html',
  styleUrls: ['./postprocessing.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    NgxFileDropModule,
    ReactiveFormsModule,
  ],
})
export class PostprocessingComponent implements OnChanges, OnDestroy {
  @Output() postprocessingInfo = new EventEmitter<PostprocessingInfo>();
  @Input() project: Project | undefined;
  @Input() inputJobLog: JobLog | undefined;

  readonly form = this.fb.group<FormModel>({
    originalJobId: '',
  });

  private costFileState: CostFileState = {
    isUploading: false,
  };

  get isLoading(): boolean {
    return this.costFileState.isUploading;
  }

  get currentBlobUrl(): string | undefined {
    return this.costFileState.blobUrl;
  }

  binaryType?: BinaryType;
  showJobError = false;
  private costFileUpload?: File;

  constructor(
    private readonly fb: FormBuilder,
    private readonly jobLogService: JobLogService,
    private readonly logger: LoggerService,
    private readonly snackBar: MatSnackBar
  ) {
    this.form.valueChanges.subscribe(() => this.emitPostprocessingConfig());
  }

  private async handleJobLogResponse(jobLogId: string): Promise<string[]> {
    try {
      const resp = await this.jobLogService.get(new GetRequest({ jobLogId }));
      const jobLog = resp.jobLogs?.[0]?.jobLog;

      if (!jobLog) {
        throw new Error('No job log found');
      }

      this.showJobError = false;
      this.binaryType =
        TYPE_ID_TO_BINARY_TYPE[jobLog.binaryConfig!.getPackedMessageId()];

      return jobLog.customerRoles?.map((customer) => customer.customerId) ?? [];
    } catch (error) {
      this.logger.error('Unable to get job log.', error as string);
      this.showJobError = true;
      this.binaryType = undefined;
      return [];
    }
  }

  async emitPostprocessingConfig(): Promise<void> {
    const jobLogId = this.form.value.originalJobId;
    const customerIds = jobLogId
      ? await this.handleJobLogResponse(jobLogId)
      : [];

    this.postprocessingInfo.emit({
      postprocessingConfig: this.createPostprocessingConfig(),
      customerIds,
    });
  }

  private async updateCostFile(jobLogId: string): Promise<void> {
    try {
      this.costFileState.isUploading = true;
      const contents = await this.jobLogService.getCostFile(jobLogId);

      if (contents) {
        this.cleanupBlobUrl();
        this.costFileState.contents = contents;
        this.costFileState.blobUrl = URL.createObjectURL(
          new Blob([contents], { type: 'text/csv' })
        );
      }
    } catch (error) {
      this.logger.error('Unable to get cost file contents.', error as string);
    } finally {
      this.costFileState.isUploading = false;
    }
  }

  async uploadCostFile(): Promise<void> {
    const jobLogId = this.form.value.originalJobId;

    if (!jobLogId || !this.costFileUpload) {
      this.showError('Please enter a job Id and cost file to upload');
      return;
    }

    try {
      this.costFileState.isUploading = true;
      await this.jobLogService.uploadCostFile(jobLogId, this.costFileUpload);
      await this.updateCostFile(jobLogId);
      this.showSuccess('Cost file uploaded successfully');
    } catch (error) {
      this.logger.error('Unable to upload cost file.', error as string);
      this.showError('Failed to upload cost file');
    } finally {
      this.costFileState.isUploading = false;
    }
  }

  private showSuccess(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: ['success-snackbar'],
    });
  }

  private showError(message: string): void {
    this.snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: ['error-snackbar'],
    });
  }

  // File handling methods
  public dropped(files: NgxFileDropEntry[]): void {
    const droppedFile = files[0];
    if (droppedFile?.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        this.costFileUpload = file;
        this.uploadCostFile();
      });
    }
  }

  public onFileSelectionChange(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    if (files?.length) {
      this.costFileUpload = files[0];
      this.uploadCostFile();
    }
  }

  downloadCostFileContents(): string {
    return this.currentBlobUrl || '';
  }

  private cleanupBlobUrl(): void {
    if (this.costFileState.blobUrl) {
      URL.revokeObjectURL(this.costFileState.blobUrl);
      this.costFileState.blobUrl = undefined;
    }
  }

  ngOnDestroy(): void {
    this.cleanupBlobUrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputJobLog = changes['inputJobLog']?.currentValue as JobLog;
    if (!inputJobLog) return;

    if (inputJobLog.id) {
      this.form.patchValue({ originalJobId: inputJobLog.id });
    }

    if (inputJobLog.binaryConfig) {
      const config =
        inputJobLog.binaryConfig.unpack<PostprocessingConfig>(
          GRPC_MESSAGE_POOL
        );
      if (config.originalJobId) {
        this.form.patchValue({ originalJobId: config.originalJobId });
        this.updateCostFile(config.originalJobId);
      }
    }
  }

  createPostprocessingConfig(): PostprocessingConfig {
    const form = this.form.value;
    return new PostprocessingConfig({
      originalJobId: form.originalJobId!,
    });
  }
}
