<form [formGroup]="form">
  <app-dataset-picker
    name="Customer"
    [inputCustomerId]="customerId"
    [customerDataSetReference]="customerDataSetReference"
    [location]="location"
    (customerDataSetInfo)="receiveCustomerDataSet($event)"
  >
  </app-dataset-picker>
  <div class="note">
    If custom formatting needs to be applied, be sure to set column name to the
    key name in formatter_config.column_name_map (rather than the actual name of
    the column in the dataset).
  </div>
  <div formArrayName="expectedColumns">
    <div *ngFor="let expectedColumn of expectedColumns.controls; let i = index">
      <div [formGroupName]="i">
        <mat-form-field class="input-half-width">
          <mat-label>Column name</mat-label>
          <input formControlName="name" matInput />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Column type</mat-label>
          <mat-select formControlName="columnType">
            <mat-option *ngFor="let ordinal of columnTypes" [value]="ordinal">
              {{ columnTypeName(ordinal) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button (click)="deleteExpectedColumn(i)" mat-button color="primary">
          Delete
        </button>
      </div>
    </div>
    <button (click)="addExpectedColumn()" mat-button color="primary">
      Add expected column
    </button>
  </div>
  <div>
    <mat-checkbox formControlName="emitSummaryStatistics">
      Emit summary statistics
    </mat-checkbox>
  </div>
  <mat-form-field class="input-full-width">
    <mat-label>Num shards to process (default is all shards)</mat-label>
    <input matInput formControlName="numShards" />
  </mat-form-field>
</form>
