// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'anonym-firebase',
    appId: '1:17620239307:web:1c9f1d7c36e61e2243ac4e',
    storageBucket: 'anonym-firebase.appspot.com',
    apiKey: 'AIzaSyA7HXtSf-CIWIiCFCGPlGyHnMSIR8logyU',
    authDomain: 'dev.anonymportal.com',
    messagingSenderId: '17620239307',
    measurementId: 'G-JE5J795059',
    vapidKey:
      'BAZDU_6OHf_JnazEjanel2nGkh3izpj-vgdMZrfEl9F3XiObgYMwoiAGLSlOXKmUgA_RF69OtLFi1MMIgA8DUNM',
    outputNotificationToken: true,
    dynamicLinkDomain: 'devanonymportal.page.link',
  },
  grpc: {
    host: 'https://dev.anonymapis.com',
  },
  hsm: {
    host: 'https://anonymdev.managedhsm.azure.net',
  },
  blobStorageBasePath: 'blob.core.windows.net',
  internalBlobStoragePrefix: 'anonymcustomerdev',
  externalBlobStoragePrefix: 'anonymexternaldev',
  production: false,
  useEmulators: false,
  azureLogs: {
    resourceId:
      '/subscriptions/4d9a0bb5-163f-406e-b1c1-a1ca4b9397da/resourceGroups/rg-logs/providers/Microsoft.OperationalInsights/workspaces/logs',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
