<app-container>
  <app-page-header title="TikTok App Management" lastUpdated="{{ lastUpdate }}">
  </app-page-header>

  <div class="customer-dropdown">
    <app-customer-dropdown
      [showArchiveToggle]="false"
      [showSearchBox]="false"
      [selectedCustomer]="selectedCustomer"
      (selectCustomerEvent)="onCustomerSelect($event)"
    ></app-customer-dropdown>
  </div>
  <app-message-box></app-message-box>

  <mat-card
    appearance="outlined"
    class="success-card animate-in"
    *ngIf="tikTokAppLink"
  >
    <mat-card-content>
      <p>
        Below is the link to the TikTok app. Please click the link to redirect
        to the TikTok app.
      </p>
      <hr />
      <p>
        <a [href]="tikTokAppLink" target="_blank">
          Click here to redirect to the TikTok app
          <mat-icon>open_in_new</mat-icon>
        </a>
        <button
          mat-icon-button
          (click)="copyToClipboard()"
          matTooltip="Copy link to clipboard"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </p>
    </mat-card-content>
  </mat-card>
</app-container>
