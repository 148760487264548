/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/commons.pb';
import * as storage002 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api003 from '../../../../src/main/proto/api/headers.pb';
import * as api004 from '../../../../src/main/proto/api/pagination.pb';
import * as storage005 from '../../../../src/main/proto/storage/customer-data-set.pb';
import * as storage006 from '../../../../src/main/proto/storage/storage-metadata.pb';
/**
 * Message implementation for api.customerdataset.CreateRequest
 */
export class CreateRequest implements GrpcMessage {
  static id = 'api.customerdataset.CreateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateRequest();
    CreateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSet = _instance.customerDataSet || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSet = new storage005.CustomerDataSet();
          _reader.readMessage(
            _instance.customerDataSet,
            storage005.CustomerDataSet.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSet) {
      _writer.writeMessage(
        2,
        _instance.customerDataSet as any,
        storage005.CustomerDataSet.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSet?: storage005.CustomerDataSet;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSet = _value.customerDataSet
      ? new storage005.CustomerDataSet(_value.customerDataSet)
      : undefined;
    CreateRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSet(): storage005.CustomerDataSet | undefined {
    return this._customerDataSet;
  }
  set customerDataSet(value: storage005.CustomerDataSet | undefined) {
    this._customerDataSet = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toProtobufJSON(options)
        : null,
    };
  }
}
export module CreateRequest {
  /**
   * Standard JavaScript object representation for CreateRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSet?: storage005.CustomerDataSet.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSet: storage005.CustomerDataSet.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.CreateResponse
 */
export class CreateResponse implements GrpcMessage {
  static id = 'api.customerdataset.CreateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateResponse();
    CreateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _customerDataSetId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    this.etag = _value.etag;
    CreateResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }
}
export module CreateResponse {
  /**
   * Standard JavaScript object representation for CreateResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    customerDataSetId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for CreateResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    customerDataSetId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.CreateSasTokensRequest
 */
export class CreateSasTokensRequest implements GrpcMessage {
  static id = 'api.customerdataset.CreateSasTokensRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateSasTokensRequest();
    CreateSasTokensRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateSasTokensRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.dataFileExtension = _instance.dataFileExtension || '';
    _instance.dataSasTokenCount = _instance.dataSasTokenCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateSasTokensRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 3:
          _instance.dataFileExtension = _reader.readString();
          break;
        case 4:
          _instance.dataSasTokenCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateSasTokensRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateSasTokensRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
    if (_instance.dataFileExtension) {
      _writer.writeString(3, _instance.dataFileExtension);
    }
    if (_instance.dataSasTokenCount) {
      _writer.writeInt32(4, _instance.dataSasTokenCount);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;
  private _dataFileExtension: string;
  private _dataSasTokenCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateSasTokensRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateSasTokensRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    this.dataFileExtension = _value.dataFileExtension;
    this.dataSasTokenCount = _value.dataSasTokenCount;
    CreateSasTokensRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get dataFileExtension(): string {
    return this._dataFileExtension;
  }
  set dataFileExtension(value: string) {
    this._dataFileExtension = value;
  }
  get dataSasTokenCount(): number {
    return this._dataSasTokenCount;
  }
  set dataSasTokenCount(value: number) {
    this._dataSasTokenCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateSasTokensRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateSasTokensRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
      dataFileExtension: this.dataFileExtension,
      dataSasTokenCount: this.dataSasTokenCount,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateSasTokensRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
      dataFileExtension: this.dataFileExtension,
      dataSasTokenCount: this.dataSasTokenCount,
    };
  }
}
export module CreateSasTokensRequest {
  /**
   * Standard JavaScript object representation for CreateSasTokensRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
    dataFileExtension: string;
    dataSasTokenCount: number;
  }

  /**
   * Protobuf JSON representation for CreateSasTokensRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
    dataFileExtension: string;
    dataSasTokenCount: number;
  }
}

/**
 * Message implementation for api.customerdataset.CreateSasTokensResponse
 */
export class CreateSasTokensResponse implements GrpcMessage {
  static id = 'api.customerdataset.CreateSasTokensResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateSasTokensResponse();
    CreateSasTokensResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateSasTokensResponse) {
    _instance.header = _instance.header || undefined;
    _instance.keySasToken = _instance.keySasToken || undefined;
    _instance.dataSasTokens = _instance.dataSasTokens || [];
    _instance.metadataSasToken = _instance.metadataSasToken || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateSasTokensResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.keySasToken = new SasToken();
          _reader.readMessage(
            _instance.keySasToken,
            SasToken.deserializeBinaryFromReader
          );
          break;
        case 3:
          const messageInitializer3 = new SasToken();
          _reader.readMessage(
            messageInitializer3,
            SasToken.deserializeBinaryFromReader
          );
          (_instance.dataSasTokens = _instance.dataSasTokens || []).push(
            messageInitializer3
          );
          break;
        case 4:
          _instance.metadataSasToken = new SasToken();
          _reader.readMessage(
            _instance.metadataSasToken,
            SasToken.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateSasTokensResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateSasTokensResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.keySasToken) {
      _writer.writeMessage(
        2,
        _instance.keySasToken as any,
        SasToken.serializeBinaryToWriter
      );
    }
    if (_instance.dataSasTokens && _instance.dataSasTokens.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.dataSasTokens as any,
        SasToken.serializeBinaryToWriter
      );
    }
    if (_instance.metadataSasToken) {
      _writer.writeMessage(
        4,
        _instance.metadataSasToken as any,
        SasToken.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;
  private _keySasToken?: SasToken;
  private _dataSasTokens?: SasToken[];
  private _metadataSasToken?: SasToken;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateSasTokensResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateSasTokensResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.keySasToken = _value.keySasToken
      ? new SasToken(_value.keySasToken)
      : undefined;
    this.dataSasTokens = (_value.dataSasTokens || []).map(
      (m) => new SasToken(m)
    );
    this.metadataSasToken = _value.metadataSasToken
      ? new SasToken(_value.metadataSasToken)
      : undefined;
    CreateSasTokensResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get keySasToken(): SasToken | undefined {
    return this._keySasToken;
  }
  set keySasToken(value: SasToken | undefined) {
    this._keySasToken = value;
  }
  get dataSasTokens(): SasToken[] | undefined {
    return this._dataSasTokens;
  }
  set dataSasTokens(value: SasToken[] | undefined) {
    this._dataSasTokens = value;
  }
  get metadataSasToken(): SasToken | undefined {
    return this._metadataSasToken;
  }
  set metadataSasToken(value: SasToken | undefined) {
    this._metadataSasToken = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateSasTokensResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateSasTokensResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      keySasToken: this.keySasToken ? this.keySasToken.toObject() : undefined,
      dataSasTokens: (this.dataSasTokens || []).map((m) => m.toObject()),
      metadataSasToken: this.metadataSasToken
        ? this.metadataSasToken.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateSasTokensResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      keySasToken: this.keySasToken
        ? this.keySasToken.toProtobufJSON(options)
        : null,
      dataSasTokens: (this.dataSasTokens || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      metadataSasToken: this.metadataSasToken
        ? this.metadataSasToken.toProtobufJSON(options)
        : null,
    };
  }
}
export module CreateSasTokensResponse {
  /**
   * Standard JavaScript object representation for CreateSasTokensResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    keySasToken?: SasToken.AsObject;
    dataSasTokens?: SasToken.AsObject[];
    metadataSasToken?: SasToken.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateSasTokensResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    keySasToken: SasToken.AsProtobufJSON | null;
    dataSasTokens: SasToken.AsProtobufJSON[] | null;
    metadataSasToken: SasToken.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.SasToken
 */
export class SasToken implements GrpcMessage {
  static id = 'api.customerdataset.SasToken';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SasToken();
    SasToken.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SasToken) {
    _instance.sasToken = _instance.sasToken || '';
    _instance.sasUrl = _instance.sasUrl || '';
    _instance.createdTime = _instance.createdTime || undefined;
    _instance.expiresTime = _instance.expiresTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SasToken,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.sasToken = _reader.readString();
          break;
        case 2:
          _instance.sasUrl = _reader.readString();
          break;
        case 3:
          _instance.createdTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.createdTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.expiresTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.expiresTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    SasToken.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: SasToken, _writer: BinaryWriter) {
    if (_instance.sasToken) {
      _writer.writeString(1, _instance.sasToken);
    }
    if (_instance.sasUrl) {
      _writer.writeString(2, _instance.sasUrl);
    }
    if (_instance.createdTime) {
      _writer.writeMessage(
        3,
        _instance.createdTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.expiresTime) {
      _writer.writeMessage(
        4,
        _instance.expiresTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _sasToken: string;
  private _sasUrl: string;
  private _createdTime?: googleProtobuf000.Timestamp;
  private _expiresTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SasToken to deeply clone from
   */
  constructor(_value?: RecursivePartial<SasToken.AsObject>) {
    _value = _value || {};
    this.sasToken = _value.sasToken;
    this.sasUrl = _value.sasUrl;
    this.createdTime = _value.createdTime
      ? new googleProtobuf000.Timestamp(_value.createdTime)
      : undefined;
    this.expiresTime = _value.expiresTime
      ? new googleProtobuf000.Timestamp(_value.expiresTime)
      : undefined;
    SasToken.refineValues(this);
  }
  get sasToken(): string {
    return this._sasToken;
  }
  set sasToken(value: string) {
    this._sasToken = value;
  }
  get sasUrl(): string {
    return this._sasUrl;
  }
  set sasUrl(value: string) {
    this._sasUrl = value;
  }
  get createdTime(): googleProtobuf000.Timestamp | undefined {
    return this._createdTime;
  }
  set createdTime(value: googleProtobuf000.Timestamp | undefined) {
    this._createdTime = value;
  }
  get expiresTime(): googleProtobuf000.Timestamp | undefined {
    return this._expiresTime;
  }
  set expiresTime(value: googleProtobuf000.Timestamp | undefined) {
    this._expiresTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SasToken.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SasToken.AsObject {
    return {
      sasToken: this.sasToken,
      sasUrl: this.sasUrl,
      createdTime: this.createdTime ? this.createdTime.toObject() : undefined,
      expiresTime: this.expiresTime ? this.expiresTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SasToken.AsProtobufJSON {
    return {
      sasToken: this.sasToken,
      sasUrl: this.sasUrl,
      createdTime: this.createdTime
        ? this.createdTime.toProtobufJSON(options)
        : null,
      expiresTime: this.expiresTime
        ? this.expiresTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module SasToken {
  /**
   * Standard JavaScript object representation for SasToken
   */
  export interface AsObject {
    sasToken: string;
    sasUrl: string;
    createdTime?: googleProtobuf000.Timestamp.AsObject;
    expiresTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for SasToken
   */
  export interface AsProtobufJSON {
    sasToken: string;
    sasUrl: string;
    createdTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    expiresTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.FinalizeRequest
 */
export class FinalizeRequest implements GrpcMessage {
  static id = 'api.customerdataset.FinalizeRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FinalizeRequest();
    FinalizeRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FinalizeRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.etag = _instance.etag || '';
    _instance.scheduleValidation = _instance.scheduleValidation || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FinalizeRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        case 4:
          _instance.scheduleValidation = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    FinalizeRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FinalizeRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
    if (_instance.scheduleValidation) {
      _writer.writeBool(4, _instance.scheduleValidation);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;
  private _etag: string;
  private _scheduleValidation: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FinalizeRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<FinalizeRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    this.etag = _value.etag;
    this.scheduleValidation = _value.scheduleValidation;
    FinalizeRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }
  get scheduleValidation(): boolean {
    return this._scheduleValidation;
  }
  set scheduleValidation(value: boolean) {
    this._scheduleValidation = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FinalizeRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FinalizeRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
      scheduleValidation: this.scheduleValidation,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FinalizeRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
      scheduleValidation: this.scheduleValidation,
    };
  }
}
export module FinalizeRequest {
  /**
   * Standard JavaScript object representation for FinalizeRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
    etag: string;
    scheduleValidation: boolean;
  }

  /**
   * Protobuf JSON representation for FinalizeRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
    etag: string;
    scheduleValidation: boolean;
  }
}

/**
 * Message implementation for api.customerdataset.ScheduleValidationRequest
 */
export class ScheduleValidationRequest implements GrpcMessage {
  static id = 'api.customerdataset.ScheduleValidationRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ScheduleValidationRequest();
    ScheduleValidationRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ScheduleValidationRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ScheduleValidationRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ScheduleValidationRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ScheduleValidationRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ScheduleValidationRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ScheduleValidationRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    this.etag = _value.etag;
    ScheduleValidationRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ScheduleValidationRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ScheduleValidationRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ScheduleValidationRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }
}
export module ScheduleValidationRequest {
  /**
   * Standard JavaScript object representation for ScheduleValidationRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ScheduleValidationRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.ScheduleValidationResponse
 */
export class ScheduleValidationResponse implements GrpcMessage {
  static id = 'api.customerdataset.ScheduleValidationResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ScheduleValidationResponse();
    ScheduleValidationResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ScheduleValidationResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ScheduleValidationResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ScheduleValidationResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ScheduleValidationResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ScheduleValidationResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ScheduleValidationResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ScheduleValidationResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ScheduleValidationResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ScheduleValidationResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ScheduleValidationResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ScheduleValidationResponse {
  /**
   * Standard JavaScript object representation for ScheduleValidationResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ScheduleValidationResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.FinalizeResponse
 */
export class FinalizeResponse implements GrpcMessage {
  static id = 'api.customerdataset.FinalizeResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new FinalizeResponse();
    FinalizeResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: FinalizeResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: FinalizeResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    FinalizeResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: FinalizeResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of FinalizeResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<FinalizeResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    FinalizeResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    FinalizeResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): FinalizeResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): FinalizeResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module FinalizeResponse {
  /**
   * Standard JavaScript object representation for FinalizeResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for FinalizeResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.ListRequest
 */
export class ListRequest implements GrpcMessage {
  static id = 'api.customerdataset.ListRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListRequest();
    ListRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListRequest) {
    _instance.header = _instance.header || undefined;
    _instance.filter = _instance.filter || undefined;
    _instance.paginated = _instance.paginated || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.filter = new ListFilter();
          _reader.readMessage(
            _instance.filter,
            ListFilter.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.paginated = new api004.GetPaginatedRequest();
          _reader.readMessage(
            _instance.paginated,
            api004.GetPaginatedRequest.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.filter) {
      _writer.writeMessage(
        2,
        _instance.filter as any,
        ListFilter.serializeBinaryToWriter
      );
    }
    if (_instance.paginated) {
      _writer.writeMessage(
        3,
        _instance.paginated as any,
        api004.GetPaginatedRequest.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.RequestHeader;
  private _filter?: ListFilter;
  private _paginated?: api004.GetPaginatedRequest;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.filter = _value.filter ? new ListFilter(_value.filter) : undefined;
    this.paginated = _value.paginated
      ? new api004.GetPaginatedRequest(_value.paginated)
      : undefined;
    ListRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get filter(): ListFilter | undefined {
    return this._filter;
  }
  set filter(value: ListFilter | undefined) {
    this._filter = value;
  }
  get paginated(): api004.GetPaginatedRequest | undefined {
    return this._paginated;
  }
  set paginated(value: api004.GetPaginatedRequest | undefined) {
    this._paginated = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      filter: this.filter ? this.filter.toObject() : undefined,
      paginated: this.paginated ? this.paginated.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      filter: this.filter ? this.filter.toProtobufJSON(options) : null,
      paginated: this.paginated ? this.paginated.toProtobufJSON(options) : null,
    };
  }
}
export module ListRequest {
  /**
   * Standard JavaScript object representation for ListRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    filter?: ListFilter.AsObject;
    paginated?: api004.GetPaginatedRequest.AsObject;
  }

  /**
   * Protobuf JSON representation for ListRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    filter: ListFilter.AsProtobufJSON | null;
    paginated: api004.GetPaginatedRequest.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.ListFilter
 */
export class ListFilter implements GrpcMessage {
  static id = 'api.customerdataset.ListFilter';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListFilter();
    ListFilter.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListFilter) {
    _instance.customerId = _instance.customerId || '';
    _instance.location = _instance.location || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListFilter,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.customerId = _reader.readString();
          break;
        case 2:
          _instance.location = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ListFilter.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ListFilter, _writer: BinaryWriter) {
    if (_instance.customerId) {
      _writer.writeString(1, _instance.customerId);
    }
    if (_instance.location) {
      _writer.writeEnum(2, _instance.location);
    }
  }

  private _customerId: string;
  private _location: storage001.Location;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListFilter to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListFilter.AsObject>) {
    _value = _value || {};
    this.customerId = _value.customerId;
    this.location = _value.location;
    ListFilter.refineValues(this);
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get location(): storage001.Location {
    return this._location;
  }
  set location(value: storage001.Location) {
    this._location = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListFilter.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListFilter.AsObject {
    return {
      customerId: this.customerId,
      location: this.location,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListFilter.AsProtobufJSON {
    return {
      customerId: this.customerId,
      location:
        storage001.Location[
          this.location === null || this.location === undefined
            ? 0
            : this.location
        ],
    };
  }
}
export module ListFilter {
  /**
   * Standard JavaScript object representation for ListFilter
   */
  export interface AsObject {
    customerId: string;
    location: storage001.Location;
  }

  /**
   * Protobuf JSON representation for ListFilter
   */
  export interface AsProtobufJSON {
    customerId: string;
    location: string;
  }
}

/**
 * Message implementation for api.customerdataset.ListResponse
 */
export class ListResponse implements GrpcMessage {
  static id = 'api.customerdataset.ListResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListResponse();
    ListResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSets = _instance.customerDataSets || [];
    _instance.paginatedResponse = _instance.paginatedResponse || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage005.CustomerDataSet();
          _reader.readMessage(
            messageInitializer2,
            storage005.CustomerDataSet.deserializeBinaryFromReader
          );
          (_instance.customerDataSets = _instance.customerDataSets || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.paginatedResponse = new api004.PaginatedResponse();
          _reader.readMessage(
            _instance.paginatedResponse,
            api004.PaginatedResponse.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ListResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSets && _instance.customerDataSets.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.customerDataSets as any,
        storage005.CustomerDataSet.serializeBinaryToWriter
      );
    }
    if (_instance.paginatedResponse) {
      _writer.writeMessage(
        3,
        _instance.paginatedResponse as any,
        api004.PaginatedResponse.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;
  private _customerDataSets?: storage005.CustomerDataSet[];
  private _paginatedResponse?: api004.PaginatedResponse;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ListResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.customerDataSets = (_value.customerDataSets || []).map(
      (m) => new storage005.CustomerDataSet(m)
    );
    this.paginatedResponse = _value.paginatedResponse
      ? new api004.PaginatedResponse(_value.paginatedResponse)
      : undefined;
    ListResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerDataSets(): storage005.CustomerDataSet[] | undefined {
    return this._customerDataSets;
  }
  set customerDataSets(value: storage005.CustomerDataSet[] | undefined) {
    this._customerDataSets = value;
  }
  get paginatedResponse(): api004.PaginatedResponse | undefined {
    return this._paginatedResponse;
  }
  set paginatedResponse(value: api004.PaginatedResponse | undefined) {
    this._paginatedResponse = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSets: (this.customerDataSets || []).map((m) => m.toObject()),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSets: (this.customerDataSets || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      paginatedResponse: this.paginatedResponse
        ? this.paginatedResponse.toProtobufJSON(options)
        : null,
    };
  }
}
export module ListResponse {
  /**
   * Standard JavaScript object representation for ListResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    customerDataSets?: storage005.CustomerDataSet.AsObject[];
    paginatedResponse?: api004.PaginatedResponse.AsObject;
  }

  /**
   * Protobuf JSON representation for ListResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    customerDataSets: storage005.CustomerDataSet.AsProtobufJSON[] | null;
    paginatedResponse: api004.PaginatedResponse.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.GetRequest
 */
export class GetRequest implements GrpcMessage {
  static id = 'api.customerdataset.GetRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetRequest();
    GetRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: GetRequest, _writer: BinaryWriter) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    GetRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
    };
  }
}
export module GetRequest {
  /**
   * Standard JavaScript object representation for GetRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
  }

  /**
   * Protobuf JSON representation for GetRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
  }
}

/**
 * Message implementation for api.customerdataset.GetResponse
 */
export class GetResponse implements GrpcMessage {
  static id = 'api.customerdataset.GetResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetResponse();
    GetResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetResponse) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSet = _instance.customerDataSet || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSet = new storage005.CustomerDataSet();
          _reader.readMessage(
            _instance.customerDataSet,
            storage005.CustomerDataSet.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSet) {
      _writer.writeMessage(
        2,
        _instance.customerDataSet as any,
        storage005.CustomerDataSet.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _customerDataSet?: storage005.CustomerDataSet;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.customerDataSet = _value.customerDataSet
      ? new storage005.CustomerDataSet(_value.customerDataSet)
      : undefined;
    this.etag = _value.etag;
    GetResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get customerDataSet(): storage005.CustomerDataSet | undefined {
    return this._customerDataSet;
  }
  set customerDataSet(value: storage005.CustomerDataSet | undefined) {
    this._customerDataSet = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module GetResponse {
  /**
   * Standard JavaScript object representation for GetResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    customerDataSet?: storage005.CustomerDataSet.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for GetResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    customerDataSet: storage005.CustomerDataSet.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.GetStorageMetadataRequest
 */
export class GetStorageMetadataRequest implements GrpcMessage {
  static id = 'api.customerdataset.GetStorageMetadataRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStorageMetadataRequest();
    GetStorageMetadataRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStorageMetadataRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStorageMetadataRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetStorageMetadataRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStorageMetadataRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStorageMetadataRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStorageMetadataRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    GetStorageMetadataRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStorageMetadataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStorageMetadataRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStorageMetadataRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
    };
  }
}
export module GetStorageMetadataRequest {
  /**
   * Standard JavaScript object representation for GetStorageMetadataRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
  }

  /**
   * Protobuf JSON representation for GetStorageMetadataRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
  }
}

/**
 * Message implementation for api.customerdataset.GetExternalStorageMetadataRequest
 */
export class GetExternalStorageMetadataRequest implements GrpcMessage {
  static id = 'api.customerdataset.GetExternalStorageMetadataRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetExternalStorageMetadataRequest();
    GetExternalStorageMetadataRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetExternalStorageMetadataRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerId = _instance.customerId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetExternalStorageMetadataRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetExternalStorageMetadataRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetExternalStorageMetadataRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetExternalStorageMetadataRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<GetExternalStorageMetadataRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerId = _value.customerId;
    GetExternalStorageMetadataRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetExternalStorageMetadataRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetExternalStorageMetadataRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerId: this.customerId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetExternalStorageMetadataRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerId: this.customerId,
    };
  }
}
export module GetExternalStorageMetadataRequest {
  /**
   * Standard JavaScript object representation for GetExternalStorageMetadataRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerId: string;
  }

  /**
   * Protobuf JSON representation for GetExternalStorageMetadataRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerId: string;
  }
}

/**
 * Message implementation for api.customerdataset.GetStorageMetadataResponse
 */
export class GetStorageMetadataResponse implements GrpcMessage {
  static id = 'api.customerdataset.GetStorageMetadataResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetStorageMetadataResponse();
    GetStorageMetadataResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetStorageMetadataResponse) {
    _instance.header = _instance.header || undefined;
    _instance.storageMetadata = _instance.storageMetadata || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetStorageMetadataResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          const messageInitializer2 = new storage006.StorageMetadata();
          _reader.readMessage(
            messageInitializer2,
            storage006.StorageMetadata.deserializeBinaryFromReader
          );
          (_instance.storageMetadata = _instance.storageMetadata || []).push(
            messageInitializer2
          );
          break;
        case 3:
          _instance.fileMappingJson = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetStorageMetadataResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetStorageMetadataResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.storageMetadata && _instance.storageMetadata.length) {
      _writer.writeRepeatedMessage(
        2,
        _instance.storageMetadata as any,
        storage006.StorageMetadata.serializeBinaryToWriter
      );
    }
    if (
      _instance.fileMappingJson !== undefined &&
      _instance.fileMappingJson !== null
    ) {
      _writer.writeString(3, _instance.fileMappingJson);
    }
  }

  private _header?: api003.ResponseHeader;
  private _storageMetadata?: storage006.StorageMetadata[];
  private _fileMappingJson?: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetStorageMetadataResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetStorageMetadataResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.storageMetadata = (_value.storageMetadata || []).map(
      (m) => new storage006.StorageMetadata(m)
    );
    this.fileMappingJson = _value.fileMappingJson;
    GetStorageMetadataResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get storageMetadata(): storage006.StorageMetadata[] | undefined {
    return this._storageMetadata;
  }
  set storageMetadata(value: storage006.StorageMetadata[] | undefined) {
    this._storageMetadata = value;
  }
  get fileMappingJson(): string | undefined {
    return this._fileMappingJson;
  }
  set fileMappingJson(value?: string) {
    this._fileMappingJson = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetStorageMetadataResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetStorageMetadataResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      storageMetadata: (this.storageMetadata || []).map((m) => m.toObject()),
      fileMappingJson: this.fileMappingJson,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetStorageMetadataResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      storageMetadata: (this.storageMetadata || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      fileMappingJson:
        this.fileMappingJson === null || this.fileMappingJson === undefined
          ? null
          : this.fileMappingJson,
    };
  }
}
export module GetStorageMetadataResponse {
  /**
   * Standard JavaScript object representation for GetStorageMetadataResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    storageMetadata?: storage006.StorageMetadata.AsObject[];
    fileMappingJson?: string;
  }

  /**
   * Protobuf JSON representation for GetStorageMetadataResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    storageMetadata: storage006.StorageMetadata.AsProtobufJSON[] | null;
    fileMappingJson: string | null;
  }
}

/**
 * Message implementation for api.customerdataset.StartExternalTransferRequest
 */
export class StartExternalTransferRequest implements GrpcMessage {
  static id = 'api.customerdataset.StartExternalTransferRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartExternalTransferRequest();
    StartExternalTransferRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartExternalTransferRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartExternalTransferRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    StartExternalTransferRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartExternalTransferRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartExternalTransferRequest to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<StartExternalTransferRequest.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    StartExternalTransferRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartExternalTransferRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartExternalTransferRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartExternalTransferRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
    };
  }
}
export module StartExternalTransferRequest {
  /**
   * Standard JavaScript object representation for StartExternalTransferRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
  }

  /**
   * Protobuf JSON representation for StartExternalTransferRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
  }
}

/**
 * Message implementation for api.customerdataset.StartExternalTransferResponse
 */
export class StartExternalTransferResponse implements GrpcMessage {
  static id = 'api.customerdataset.StartExternalTransferResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new StartExternalTransferResponse();
    StartExternalTransferResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: StartExternalTransferResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: StartExternalTransferResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    StartExternalTransferResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: StartExternalTransferResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of StartExternalTransferResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<StartExternalTransferResponse.AsObject>
  ) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    StartExternalTransferResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    StartExternalTransferResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): StartExternalTransferResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): StartExternalTransferResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module StartExternalTransferResponse {
  /**
   * Standard JavaScript object representation for StartExternalTransferResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for StartExternalTransferResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.ArchiveRequest
 */
export class ArchiveRequest implements GrpcMessage {
  static id = 'api.customerdataset.ArchiveRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveRequest();
    ArchiveRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    this.etag = _value.etag;
    ArchiveRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
      etag: this.etag,
    };
  }
}
export module ArchiveRequest {
  /**
   * Standard JavaScript object representation for ArchiveRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.ArchiveResponse
 */
export class ArchiveResponse implements GrpcMessage {
  static id = 'api.customerdataset.ArchiveResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ArchiveResponse();
    ArchiveResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ArchiveResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ArchiveResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ArchiveResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ArchiveResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ArchiveResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ArchiveResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    ArchiveResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ArchiveResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ArchiveResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ArchiveResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module ArchiveResponse {
  /**
   * Standard JavaScript object representation for ArchiveResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for ArchiveResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.CreateTriggerRequest
 */
export class CreateTriggerRequest implements GrpcMessage {
  static id = 'api.customerdataset.CreateTriggerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateTriggerRequest();
    CreateTriggerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateTriggerRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateTriggerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    CreateTriggerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateTriggerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateTriggerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateTriggerRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    CreateTriggerRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateTriggerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateTriggerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateTriggerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
    };
  }
}
export module CreateTriggerRequest {
  /**
   * Standard JavaScript object representation for CreateTriggerRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
  }

  /**
   * Protobuf JSON representation for CreateTriggerRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
  }
}

/**
 * Message implementation for api.customerdataset.CreateTriggerResponse
 */
export class CreateTriggerResponse implements GrpcMessage {
  static id = 'api.customerdataset.CreateTriggerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CreateTriggerResponse();
    CreateTriggerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CreateTriggerResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CreateTriggerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CreateTriggerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CreateTriggerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CreateTriggerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<CreateTriggerResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    CreateTriggerResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CreateTriggerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CreateTriggerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CreateTriggerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module CreateTriggerResponse {
  /**
   * Standard JavaScript object representation for CreateTriggerResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for CreateTriggerResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.DeleteTriggerRequest
 */
export class DeleteTriggerRequest implements GrpcMessage {
  static id = 'api.customerdataset.DeleteTriggerRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteTriggerRequest();
    DeleteTriggerRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteTriggerRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSetId = _instance.customerDataSetId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteTriggerRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSetId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteTriggerRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteTriggerRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSetId) {
      _writer.writeString(2, _instance.customerDataSetId);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSetId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteTriggerRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteTriggerRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSetId = _value.customerDataSetId;
    DeleteTriggerRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSetId(): string {
    return this._customerDataSetId;
  }
  set customerDataSetId(value: string) {
    this._customerDataSetId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteTriggerRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteTriggerRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSetId: this.customerDataSetId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteTriggerRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSetId: this.customerDataSetId,
    };
  }
}
export module DeleteTriggerRequest {
  /**
   * Standard JavaScript object representation for DeleteTriggerRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSetId: string;
  }

  /**
   * Protobuf JSON representation for DeleteTriggerRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSetId: string;
  }
}

/**
 * Message implementation for api.customerdataset.DeleteTriggerResponse
 */
export class DeleteTriggerResponse implements GrpcMessage {
  static id = 'api.customerdataset.DeleteTriggerResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DeleteTriggerResponse();
    DeleteTriggerResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DeleteTriggerResponse) {
    _instance.header = _instance.header || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DeleteTriggerResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    DeleteTriggerResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DeleteTriggerResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
  }

  private _header?: api003.ResponseHeader;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DeleteTriggerResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<DeleteTriggerResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    DeleteTriggerResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DeleteTriggerResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DeleteTriggerResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DeleteTriggerResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
    };
  }
}
export module DeleteTriggerResponse {
  /**
   * Standard JavaScript object representation for DeleteTriggerResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
  }

  /**
   * Protobuf JSON representation for DeleteTriggerResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for api.customerdataset.UpdateRequest
 */
export class UpdateRequest implements GrpcMessage {
  static id = 'api.customerdataset.UpdateRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateRequest();
    UpdateRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateRequest) {
    _instance.header = _instance.header || undefined;
    _instance.customerDataSet = _instance.customerDataSet || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.RequestHeader();
          _reader.readMessage(
            _instance.header,
            api003.RequestHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.customerDataSet = new storage005.CustomerDataSet();
          _reader.readMessage(
            _instance.customerDataSet,
            storage005.CustomerDataSet.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.RequestHeader.serializeBinaryToWriter
      );
    }
    if (_instance.customerDataSet) {
      _writer.writeMessage(
        2,
        _instance.customerDataSet as any,
        storage005.CustomerDataSet.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(3, _instance.etag);
    }
  }

  private _header?: api003.RequestHeader;
  private _customerDataSet?: storage005.CustomerDataSet;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateRequest.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.RequestHeader(_value.header)
      : undefined;
    this.customerDataSet = _value.customerDataSet
      ? new storage005.CustomerDataSet(_value.customerDataSet)
      : undefined;
    this.etag = _value.etag;
    UpdateRequest.refineValues(this);
  }
  get header(): api003.RequestHeader | undefined {
    return this._header;
  }
  set header(value: api003.RequestHeader | undefined) {
    this._header = value;
  }
  get customerDataSet(): storage005.CustomerDataSet | undefined {
    return this._customerDataSet;
  }
  set customerDataSet(value: storage005.CustomerDataSet | undefined) {
    this._customerDataSet = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateRequest.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toObject()
        : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateRequest.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toProtobufJSON(options)
        : null,
      etag: this.etag,
    };
  }
}
export module UpdateRequest {
  /**
   * Standard JavaScript object representation for UpdateRequest
   */
  export interface AsObject {
    header?: api003.RequestHeader.AsObject;
    customerDataSet?: storage005.CustomerDataSet.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateRequest
   */
  export interface AsProtobufJSON {
    header: api003.RequestHeader.AsProtobufJSON | null;
    customerDataSet: storage005.CustomerDataSet.AsProtobufJSON | null;
    etag: string;
  }
}

/**
 * Message implementation for api.customerdataset.UpdateResponse
 */
export class UpdateResponse implements GrpcMessage {
  static id = 'api.customerdataset.UpdateResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new UpdateResponse();
    UpdateResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: UpdateResponse) {
    _instance.header = _instance.header || undefined;
    _instance.etag = _instance.etag || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: UpdateResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.header = new api003.ResponseHeader();
          _reader.readMessage(
            _instance.header,
            api003.ResponseHeader.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.etag = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    UpdateResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: UpdateResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.header) {
      _writer.writeMessage(
        1,
        _instance.header as any,
        api003.ResponseHeader.serializeBinaryToWriter
      );
    }
    if (_instance.etag) {
      _writer.writeString(2, _instance.etag);
    }
  }

  private _header?: api003.ResponseHeader;
  private _etag: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of UpdateResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<UpdateResponse.AsObject>) {
    _value = _value || {};
    this.header = _value.header
      ? new api003.ResponseHeader(_value.header)
      : undefined;
    this.etag = _value.etag;
    UpdateResponse.refineValues(this);
  }
  get header(): api003.ResponseHeader | undefined {
    return this._header;
  }
  set header(value: api003.ResponseHeader | undefined) {
    this._header = value;
  }
  get etag(): string {
    return this._etag;
  }
  set etag(value: string) {
    this._etag = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    UpdateResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): UpdateResponse.AsObject {
    return {
      header: this.header ? this.header.toObject() : undefined,
      etag: this.etag,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): UpdateResponse.AsProtobufJSON {
    return {
      header: this.header ? this.header.toProtobufJSON(options) : null,
      etag: this.etag,
    };
  }
}
export module UpdateResponse {
  /**
   * Standard JavaScript object representation for UpdateResponse
   */
  export interface AsObject {
    header?: api003.ResponseHeader.AsObject;
    etag: string;
  }

  /**
   * Protobuf JSON representation for UpdateResponse
   */
  export interface AsProtobufJSON {
    header: api003.ResponseHeader.AsProtobufJSON | null;
    etag: string;
  }
}
