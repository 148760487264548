import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { CustomerService } from 'app/services/customer.service';
import { FormatService } from 'app/services/format.service';
import { LoggerService } from 'app/services/logger.service';

import { ContainerComponent } from '../shared/components/container/container.component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

@Component({
  selector: 'app-tiktok-confirmation',
  templateUrl: './tiktok-app-confirmation.component.html',
  styleUrls: ['./tiktok-app-confirmation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
    ContainerComponent,
    MatCardModule,
    MatIconModule,
    RouterModule,
  ],
})
export class TikTokAppConfirmationComponent implements OnInit {
  isLoadingLongLivedToken = false;
  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    public dialog: MatDialog,
    private formatService: FormatService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider
  ) {}

  ngOnInit(): void {
    this.logger.error(
      'Received query params:',
      this.activatedRoute.snapshot.queryParams
    );
    this.getLongLivedToken();
  }

  async getLongLivedToken() {
    const authCode = this.activatedRoute.snapshot.queryParams['auth_code'];
    const state = this.activatedRoute.snapshot.queryParams['state'];

    if (!authCode || !state) {
      this.logger.error('Missing auth_code or state');
      this.messageBox.error('Missing auth_code or state');
      return;
    }

    try {
      await this.customerService.createTikTokAppIntegration(state, authCode);
      this.messageBox.success('TikTok app integration created');
    } catch (error) {
      this.logger.error('Error creating TikTok app integration', error);
      this.messageBox.error('Error creating TikTok app integration');
    }
  }
}
