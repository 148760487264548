import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { StorageMetadata } from 'app/services/generated/src/main/proto/storage/storage-metadata.pb';
import { LoggerService } from 'app/services/logger.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-storage-metadata-viewer',
  templateUrl: './storage-metadata-viewer.component.html',
  styleUrls: [
    './storage-metadata-viewer.component.scss',
    '../../shared/shared.scss',
  ],
})
export class StorageMetadataViewerComponent implements OnInit {
  customerDataSet: CustomerDataSet | undefined;
  isLoading = false;
  storageMetadata: StorageMetadata[] = [];
  time: string = new Date().toLocaleString();

  constructor(
    private customerDataService: CustomerDataService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: ActivatedRoute
  ) {}
  ngOnInit() {
    this.router.params.subscribe(async (params) => {
      if (params['customerDataSetId']) {
        await this.getCustomerDataSet(params['customerDataSetId']);
        this.getFileMetadata();
      } else {
        this.messageBox.error('Customer id or location is missing.');
        this.logger.error('Invalid customer id or location');
      }
    });
  }

  async getCustomerDataSet(customerDataSetId: string) {
    try {
      const response = await this.customerDataService.get(customerDataSetId);
      this.customerDataSet = response.customerDataSet;
    } catch (e) {
      this.messageBox.error('Error loading customer data set.');
      this.logger.error('Error loading customer dataset.', e);
    }
  }

  async getFileMetadata() {
    if (this.customerDataSet) {
      this.isLoading = true;
      try {
        const storageMetadata = (
          await this.customerDataService.getFileMetadata(
            this.customerDataSet.id
          )
        ).storageMetadata;
        if (storageMetadata) {
          this.storageMetadata = storageMetadata;
        }
      } catch (error) {
        this.logger.error('Error getting file metadata', error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.logger.info('Customer dataset not loaded yet.');
    }
  }

  getLocationName(location: Location | undefined) {
    if (!location) {
      return 'Unknown';
    }

    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }
}
