import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { TiktokAudienceInferenceConfig } from 'app/services/generated/src/main/proto/tiktok-audience-inference/tiktok-audience-inference-config.pb';
import { JobLogService } from 'app/services/job-log.service';

@Component({
  selector: 'app-tiktok-audience-inference',
  templateUrl: './tiktok-audience-inference.component.html',
  styleUrls: ['./tiktok-audience-inference.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
})
export class TiktokAudienceInferenceComponent implements OnChanges {
  @Input() inputJobLog: JobLog | undefined;
  @Output() tiktokAudienceInferenceConfig =
    new EventEmitter<TiktokAudienceInferenceConfig>();

  tiktokAudienceInferenceForm = this.formBuilder.group({
    jobId: ['', [Validators.required]],
    advertiserId: ['', [Validators.required]],
    reach: [0, [Validators.required, Validators.min(1)]],
    enableDebugLogging: [false],
  });

  constructor(
    private formBuilder: FormBuilder,
    private jobLogService: JobLogService,
    private router: Router
  ) {
    this.tiktokAudienceInferenceForm.valueChanges.subscribe(() =>
      this.emitConfig()
    );
  }

  emitConfig() {
    if (this.tiktokAudienceInferenceForm.valid) {
      const config = new TiktokAudienceInferenceConfig({
        jobId: this.tiktokAudienceInferenceForm.value.jobId || '',
        advertiserId: this.tiktokAudienceInferenceForm.value.advertiserId || '',
        reach: this.tiktokAudienceInferenceForm.value.reach || 0,
        enableDebugLogging:
          this.tiktokAudienceInferenceForm.value.enableDebugLogging || false,
      });
      this.tiktokAudienceInferenceConfig.emit(config);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const config =
        inputJobLog.binaryConfig.unpack<TiktokAudienceInferenceConfig>(
          GRPC_MESSAGE_POOL
        );
      if (!config) {
        return;
      }

      const controls = this.tiktokAudienceInferenceForm.controls;
      controls['jobId'].setValue(config.jobId);
      controls['advertiserId'].setValue(config.advertiserId);
      controls['reach'].setValue(config.reach);
      controls['enableDebugLogging'].setValue(config.enableDebugLogging);
    }
  }
}
