import { Injectable } from '@angular/core';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  ArchiveRequest,
  ArchiveResponse,
  CreateRequest,
  CreateResponse,
  CreateSasTokensRequest,
  CreateSasTokensResponse,
  CreateTriggerRequest,
  CreateTriggerResponse,
  DeleteTriggerRequest,
  DeleteTriggerResponse,
  FinalizeRequest,
  FinalizeResponse,
  GetRequest,
  GetResponse,
  GetStorageMetadataRequest,
  GetStorageMetadataResponse,
  ListRequest,
  ListResponse,
  StartExternalTransferRequest,
  StartExternalTransferResponse,
  UpdateRequest,
  UpdateResponse,
} from './generated/src/main/proto/api/customer-data-set-service.pb';
import { CustomerDataSetServiceClient } from './generated/src/main/proto/api/customer-data-set-service.pbsc';

/**
 * Service to manage customer data sets, including creating, updating, listing, and archiving.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerDataService {
  constructor(
    private customerDataSetService: CustomerDataSetServiceClient,
    private apiAuthService: ApiAuthService
  ) {}

  // TODO(kenny): If we start hitting this limit, introduce filters.
  MAX_RECORDS = 50;

  /**
   * Creates a new customer data set.
   *
   * @param customerDataSet - The data set to create.
   * @returns A promise that resolves to a CreateResponse containing the result of the operation.
   */
  public async create(
    customerDataSet: CustomerDataSet
  ): Promise<CreateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const createRequest = new CreateRequest({
      customerDataSet: customerDataSet,
    });
    return firstValueFrom(
      this.customerDataSetService.create(createRequest, grpcMetaData)
    );
  }

  /**
   * Creates SAS tokens for a specified customer data set.
   *
   * @param id - The ID of the customer data set.
   * @param dataFileExtension - The file extension for the data files.
   * @param dataSasTokenCount - The number of SAS tokens to create.
   * @returns A promise that resolves to a CreateSasTokensResponse containing the result of the operation.
   */
  public async createSasTokens(
    id: string,
    dataFileExtension: string,
    dataSasTokenCount: number
  ): Promise<CreateSasTokensResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const createRequest = new CreateSasTokensRequest({
      customerDataSetId: id,
      dataFileExtension: dataFileExtension,
      dataSasTokenCount: dataSasTokenCount,
    });
    return firstValueFrom(
      this.customerDataSetService.createSasTokens(createRequest, grpcMetaData)
    );
  }

  /**
   * Lists customer data sets based on the provided request.
   *
   * @param listRequest - The request parameters for listing customer data sets.
   * @returns A promise that resolves to a ListResponse containing the list of customer data sets.
   */
  public async list(listRequest: ListRequest): Promise<ListResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.list(listRequest, grpcMetaData)
    );
  }

  /**
   * Retrieves a specific customer data set by its ID.
   *
   * @param id - The ID of the customer data set to retrieve.
   * @returns A promise that resolves to a GetResponse containing the customer data set.
   */
  public async get(id: string): Promise<GetResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.get(
        new GetRequest({
          customerDataSetId: id,
        }),
        grpcMetaData
      )
    );
  }

  /**
   * Get file metadata for customer data set.
   *
   * @param customerDataSetId - The customerDataSetId.
   * @returns A promise that resolves to a GetStorageMetadataResponse containing the result of the operation.
   */
  public async getFileMetadata(
    customerDataSetId: string
  ): Promise<GetStorageMetadataResponse> {
    const getStorageMetadataRequest = new GetStorageMetadataRequest();

    getStorageMetadataRequest.customerDataSetId = customerDataSetId;

    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.getStorageMetadata(
        getStorageMetadataRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Initiates an external transfer for a customer data set.
   *
   * @param startExternalTransferRequest - The request parameters for starting the external transfer.
   * @returns A promise that resolves to a StartExternalTransferResponse containing the result of the operation.
   */
  public async startExternalTransfer(
    startExternalTransferRequest: StartExternalTransferRequest
  ): Promise<StartExternalTransferResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.startExternalTransfer(
        startExternalTransferRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Archives a specified customer data set.
   *
   * @param archiveRequest - The request parameters for archiving the data set.
   * @returns A promise that resolves to an ArchiveResponse containing the result of the operation.
   */
  public async archive(
    archiveRequest: ArchiveRequest
  ): Promise<ArchiveResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.archive(archiveRequest, grpcMetaData)
    );
  }

  /**
   * Finalizes a customer data set.
   *
   * @param finalizeRequest - The request parameters for finalizing the data set.
   * @returns A promise that resolves to a FinalizeResponse containing the result of the operation.
   */
  public async finalize(
    finalizeRequest: FinalizeRequest
  ): Promise<FinalizeResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.finalize(finalizeRequest, grpcMetaData)
    );
  }

  /**
   * Updates a specified customer data set.
   *
   * @param customerDataSet - The data set to update.
   * @returns A promise that resolves to an UpdateResponse containing the result of the operation.
   */
  public async update(
    customerDataSet: CustomerDataSet
  ): Promise<UpdateResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    const updateRequest = new UpdateRequest({
      customerDataSet: customerDataSet,
      etag: customerDataSet.etag,
    });
    return firstValueFrom(
      this.customerDataSetService.update(updateRequest, grpcMetaData)
    );
  }

  /**
   * Creates a trigger for a specified customer data set.
   *
   * @param createTriggerRequest - The request parameters for creating the trigger.
   * @returns A promise that resolves to a CreateTriggerResponse containing the result of the operation.
   */
  public async createTrigger(
    createTriggerRequest: CreateTriggerRequest
  ): Promise<CreateTriggerResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.createTrigger(
        createTriggerRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Deletes a trigger for a specified customer data set.
   *
   * @param deleteTriggerRequest - The request parameters for deleting the trigger.
   * @returns A promise that resolves to a DeleteTriggerResponse containing the result of the operation.
   */
  public async deleteTrigger(
    deleteTriggerRequest: DeleteTriggerRequest
  ): Promise<DeleteTriggerResponse> {
    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerDataSetService.deleteTrigger(
        deleteTriggerRequest,
        grpcMetaData
      )
    );
  }
}
