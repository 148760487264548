import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { StorageMetadata } from 'app/services/generated/src/main/proto/storage/storage-metadata.pb';
import { LoggerService } from 'app/services/logger.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { HighlightModule } from 'ngx-highlightjs';

import { ContainerComponent } from '../shared/components/container/container.component';
import { FileViewerComponent } from '../shared/components/file-viewer/file-viewer-component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

interface LocationInfo {
  value: Location;
  name: string;
}

@Component({
  selector: 'app-storage-metadata-viewer',
  templateUrl: './storage-metadata-viewer.component.html',
  styleUrls: [
    './storage-metadata-viewer.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    FileViewerComponent,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
    MatCardModule,
    HighlightModule,
  ],
})
export class StorageMetadataViewerComponent implements OnInit {
  customerDataSet: CustomerDataSet | undefined;
  isLoading = false;
  storageMetadata: StorageMetadata[] = [];
  fileMappingJson: string | undefined;
  time = new Date().toLocaleString();

  private readonly DRAFT_STATE = 1;

  constructor(
    private readonly customerDataService: CustomerDataService,
    private readonly logger: LoggerService,
    private readonly messageBox: MessageBoxProvider,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializeData();
  }

  private async initializeData(): Promise<void> {
    const customerDataSetId = this.route.snapshot.params['customerDataSetId'];

    if (!customerDataSetId) {
      this.handleError('Customer dataset ID is missing');
      return;
    }

    await this.loadCustomerDataSet(customerDataSetId);
    if (this.customerDataSet) {
      await this.loadFileMetadata();
    }
  }

  private async loadCustomerDataSet(id: string): Promise<void> {
    try {
      const response = await this.customerDataService.get(id);
      this.customerDataSet = response.customerDataSet;
    } catch (error) {
      this.handleError('Error loading customer dataset', error);
    }
  }

  private async loadFileMetadata(): Promise<void> {
    if (!this.customerDataSet) return;

    this.isLoading = true;
    try {
      const response = await this.customerDataService.getFileMetadata(
        this.customerDataSet.id
      );
      this.storageMetadata = response.storageMetadata ?? [];
      this.fileMappingJson = response.fileMappingJson;
    } catch (error) {
      this.handleError('Error loading file metadata', error);
    } finally {
      this.isLoading = false;
    }
  }

  formatJson(content: string | undefined): string {
    if (!content) return '{}';

    try {
      return JSON.stringify(JSON.parse(content), null, 2);
    } catch (error) {
      this.logger.error('Invalid JSON format', error);
      return '{}';
    }
  }

  getLocationName(location: Location | undefined): string {
    if (!location) return 'Unknown';

    const locationInfo = LocationList.find(
      (l: LocationInfo) => l.value === location
    );
    return locationInfo?.name ?? 'Unknown';
  }

  private handleError(message: string, error?: unknown): void {
    this.messageBox.error(message);
    this.logger.error(message, error);
  }
}
