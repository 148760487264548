/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './job-log-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/commons.pb';
import * as storage003 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
import * as storage004 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api005 from '../../../../src/main/proto/api/headers.pb';
import * as api006 from '../../../../src/main/proto/api/pagination.pb';
import * as storage007 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage008 from '../../../../src/main/proto/storage/job-log.pb';
import { GRPC_JOB_LOG_SERVICE_CLIENT_SETTINGS } from './job-log-service.pbconf';
/**
 * Service client implementation for api.job_log.JobLogService
 */
@Injectable({ providedIn: 'any' })
export class JobLogServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.job_log.JobLogService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/Clone
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CloneResponse>>
     */
    clone: (
      requestData: thisProto.CloneRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CloneResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Clone',
        requestData,
        requestMetadata,
        requestClass: thisProto.CloneRequest,
        responseClass: thisProto.CloneResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetResponse>>
     */
    get: (
      requestData: thisProto.GetRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetRequest,
        responseClass: thisProto.GetResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/GetJobsForCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetJobsForCustomerResponse>>
     */
    getJobsForCustomer: (
      requestData: thisProto.GetJobsForCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetJobsForCustomerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/GetJobsForCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetJobsForCustomerRequest,
        responseClass: thisProto.GetJobsForCustomerResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/GetJobResults
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetJobResultsResponse>>
     */
    getJobResults: (
      requestData: thisProto.GetJobResultsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetJobResultsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/GetJobResults',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetJobResultsRequest,
        responseClass: thisProto.GetJobResultsResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/UpdateState
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateStateResponse>>
     */
    updateState: (
      requestData: thisProto.UpdateStateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateStateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/UpdateState',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateStateRequest,
        responseClass: thisProto.UpdateStateResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/Archive
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ArchiveResponse>>
     */
    archive: (
      requestData: thisProto.ArchiveRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ArchiveResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Archive',
        requestData,
        requestMetadata,
        requestClass: thisProto.ArchiveRequest,
        responseClass: thisProto.ArchiveResponse,
      });
    },
    /**
     * Unary call: /api.job_log.JobLogService/Cancel
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CancelResponse>>
     */
    cancel: (
      requestData: thisProto.CancelRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CancelResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.job_log.JobLogService/Cancel',
        requestData,
        requestMetadata,
        requestClass: thisProto.CancelRequest,
        responseClass: thisProto.CancelResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_JOB_LOG_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.job_log.JobLogService',
      settings
    );
  }

  /**
   * Unary call @/api.job_log.JobLogService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/Clone
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CloneResponse>
   */
  clone(
    requestData: thisProto.CloneRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CloneResponse> {
    return this.$raw
      .clone(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetResponse>
   */
  get(
    requestData: thisProto.GetRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/GetJobsForCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetJobsForCustomerResponse>
   */
  getJobsForCustomer(
    requestData: thisProto.GetJobsForCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetJobsForCustomerResponse> {
    return this.$raw
      .getJobsForCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/GetJobResults
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetJobResultsResponse>
   */
  getJobResults(
    requestData: thisProto.GetJobResultsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetJobResultsResponse> {
    return this.$raw
      .getJobResults(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/UpdateState
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateStateResponse>
   */
  updateState(
    requestData: thisProto.UpdateStateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateStateResponse> {
    return this.$raw
      .updateState(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/Archive
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ArchiveResponse>
   */
  archive(
    requestData: thisProto.ArchiveRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ArchiveResponse> {
    return this.$raw
      .archive(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.job_log.JobLogService/Cancel
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CancelResponse>
   */
  cancel(
    requestData: thisProto.CancelRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CancelResponse> {
    return this.$raw
      .cancel(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
