<aside>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree">
    <!-- Replace the flat node template with a nested node template -->
    <mat-nested-tree-node *matTreeNodeDef="let node">
      <div class="mat-tree-node" routerLinkActive="active">
        <a
          class="single node"
          [routerLink]="node.route"
          [queryParams]="node.queryParam"
          (click)="resetIcons(node)"
        >
          <mat-icon
            name="menu-icon"
            class="icon"
            [svgIcon]="node.icon"
            *ngIf="node.icon && node.custom_icon === true"
          ></mat-icon>
          <mat-icon
            name="menu-icon"
            class="icon"
            [fontIcon]="node.icon"
            *ngIf="node.icon && node.custom_icon === false"
          ></mat-icon>
          <span class="label">{{ node.name }}</span>
        </a>
      </div>
    </mat-nested-tree-node>

    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <div class="mat-tree-node" routerLinkActive="active">
        <button
          mat-icon-button
          matTreeNodeToggle
          [disableRipple]="true"
          [attr.aria-label]="'Toggle ' + node.name"
        >
          <mat-icon class="icon">
            <p class="icon-text">
              {{ treeControl.isExpanded(node) ? '-' : '+' }}
            </p>
          </mat-icon>
        </button>
        <a (click)="onParenNodeClick(node)" class="parent node">
          <mat-icon
            name="menu-icon"
            class="icon"
            [svgIcon]="
              node.routerLinkActive ? node.icon + '_selected' : node.icon
            "
            *ngIf="node.icon && node.custom_icon === true"
          ></mat-icon>
          <mat-icon
            name="menu-icon"
            class="icon"
            [fontIcon]="node.icon"
            *ngIf="node.icon && node.custom_icon === false"
          ></mat-icon>
          <span class="label">{{ node.name }}</span>
        </a>
      </div>
      <div [class.tree-invisible]="!treeControl.isExpanded(node)" role="group">
        <ng-container matTreeNodeOutlet></ng-container>
      </div>
    </mat-nested-tree-node>
  </mat-tree>
</aside>
