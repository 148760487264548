import { ClipboardModule } from '@angular/cdk/clipboard';
import {
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  FirestoreModule,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GrpcMessage } from '@ngx-grpc/common';
import { GrpcCoreModule, GrpcLoggerModule } from '@ngx-grpc/core';
import { GrpcWebClientModule } from '@ngx-grpc/grpc-web-client';
import { SideBySideDiffComponent, UnifiedDiffComponent } from 'ngx-diff';
import { NgxFileDropModule } from 'ngx-file-drop';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { MarkdownModule, MarkdownService } from 'ngx-markdown';

import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { GRPC_MESSAGE_POOL } from './constants/lookups';
import { AddAdminStorageModalComponent } from './modals/add-admin-storage-modal/add-admin-storage-modal.component';
import { AddAppModalComponent } from './modals/add-app/add-app-modal.component';
import { AddCustomerDataModalComponent } from './modals/add-customer-data-modal/add-customer-data-modal.component';
import { AddJobScheduleModalComponent } from './modals/add-job-schedule-modal/add-job-schedule-modal.component';
import { ConfirmationModalComponent } from './modals/confirmation-modal/confirmation.component';
import { DownloadSourceCodeComponent } from './modals/download-source-code-modal/download-source-code-modal.component';
import { MultifactorLoginModalComponent } from './modals/multifactor-login-modal.component/multifactor-login-modal.component';
import { PendingOnboardingModalComponent } from './modals/pending-onboarding-modal/pending-onboarding-modal.component';
import { TenantSelectModalComponent } from './modals/tenant-select-modal/tenant-select.component';
import { AdminModule } from './modules/admin/admin.module';
import { AuditApprovalsModule } from './modules/audit-approvals/audit-approvals.module';
import { ContentPageModule } from './modules/content-page/content-page.module';
import { DocumentationModule } from './modules/documentation/documentation.module';
import { JobLogModule } from './modules/job-log/job-log';
import { KeysModule } from './modules/keys/keys.module';
import { SettingsModule } from './modules/settings/settings.module';
import { StorageCredentialCreatorModule } from './modules/storage-credential/storage-credential.module';
import { TodoListModule } from './modules/todo-list/todo-list.module';
import { UserProfileModule } from './modules/user-profile/user-profile.module';
import { AppRoutingModule } from './routing/app-routing.module';
import { ApiAuthService } from './services/api-auth.service';
import { AppService } from './services/app.service';
import { ApprovalService } from './services/approval.service';
import { BinaryTypeService } from './services/binary-type.service';
import { ContentService } from './services/content.service';
import { CustomerService } from './services/customer.service';
import { CustomerDataService } from './services/customer-data.service';
import { DocumentService } from './services/document.service';
import { EventBusService } from './services/event-bus.service';
import { FileUploadService } from './services/file-upload.service';
import { FormatService } from './services/format.service';
import { KeyService } from './services/key.service';
import { LoggerService } from './services/logger.service';
import { NotificationService } from './services/notification.service';
import { PermissionService } from './services/permission.service';
import { SourceCodeService } from './services/source-code.service';
import { UserService } from './services/user.service';
import { AppManagerComponent } from './views/app-manager/app-manager.component';
import { DataManagementComponent } from './views/data-management/data-management.component';
import { EmailSignInComponent } from './views/email-sign-in/email-sign-in.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { LandingPageComponent } from './views/landing-page/landing-page.component';
import { NewUserPasswordComponent } from './views/new-user-password/new-user-password.component';
import { NotificationComponent } from './views/notifications-page/notification.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { SetPasswordComponent } from './views/set-password/set-password.component';
import { AnonymLogoComponent } from './views/shared/components/anonym-logo/anonym-logo.component';
import { BinaryTypeDropdownComponent } from './views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from './views/shared/components/container/container.component';
import { MessageBoxComponent } from './views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from './views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from './views/shared/components/page-header/page-header.component';
import { SidebarComponent } from './views/shared/components/sidebar/sidebar.component';
import { SupportDialogComponent } from './views/shared/components/top-navigation/support-dialog/support-dialog.component';
import { TopNavigationComponent } from './views/shared/components/top-navigation/top-navigation.component';
import { SignInComponent } from './views/sign-in/sign-in.component';
import { SignInOptionsComponent } from './views/sign-in-options/sign-in-options.component';
import { SourceCodeDiffComponent } from './views/source-code-diff/source-code-diff-page.component';

@NgModule({
  declarations: [
    AddAdminStorageModalComponent,
    AddAppModalComponent,
    AppComponent,
    AddCustomerDataModalComponent,
    AddJobScheduleModalComponent,
    AnonymLogoComponent,
    AppManagerComponent,
    ConfirmationModalComponent,
    DataManagementComponent,
    DownloadSourceCodeComponent,
    EmailSignInComponent,
    ForgotPasswordComponent,
    LandingPageComponent,
    MultifactorLoginModalComponent,
    NewUserPasswordComponent,
    NotificationComponent,
    PendingOnboardingModalComponent,
    ResetPasswordComponent,
    SidebarComponent,
    SignInComponent,
    SignInOptionsComponent,
    SetPasswordComponent,
    SourceCodeDiffComponent,
    SupportDialogComponent,
    TenantSelectModalComponent,
    TopNavigationComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    AngularFireAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AdminModule,
    AppRoutingModule,
    AuditApprovalsModule,
    BinaryTypeDropdownComponent,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    ClipboardModule,
    ContainerComponent,
    ContentPageModule,
    DocumentationModule,
    FirestoreModule,
    GrpcCoreModule.forRoot(),
    GrpcLoggerModule.forRoot({
      settings: {
        requestMapper: (msg: GrpcMessage) =>
          msg.toProtobufJSON({
            messagePool: GRPC_MESSAGE_POOL,
          }),
        responseMapper: (msg: GrpcMessage) =>
          msg.toProtobufJSON({
            messagePool: GRPC_MESSAGE_POOL,
          }),
      },
    }),
    GrpcWebClientModule.forRoot({
      settings: { host: environment.grpc.host },
    }),
    HighlightModule,
    JobLogModule,
    KeysModule,
    StorageCredentialCreatorModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      sanitize: SecurityContext.NONE,
    }),
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MessageBoxComponent,
    NgxFileDropModule,
    PageHeaderComponent,
    ReactiveFormsModule,
    SettingsModule,
    TodoListModule,
    SideBySideDiffComponent,
    UnifiedDiffComponent,
    UserProfileModule,
  ],
  providers: [
    ApiAuthService,
    AppService,
    ApprovalService,
    BinaryTypeService,
    ContentService,
    CustomerService,
    CustomerDataService,
    DocumentService,
    EventBusService,
    FormatService,
    KeyService,
    LoggerService,
    MarkdownService,
    MessageBoxProvider,
    NotificationService,
    PermissionService,
    SourceCodeService,
    FileUploadService,
    UserService,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideAnalytics(() => getAnalytics()),
    ScreenTrackingService,
    UserTrackingService,
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
  ],
})
export class AppModule {}
