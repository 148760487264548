import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { AdminModule } from 'app/modules/admin/admin.module';
import { FormHelpersService } from 'app/services/form-helpers.service';
import {
  GetJobResultsRequest,
  GetRequest,
  JobResultFile,
  JobResultFileType,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { ReportService } from 'app/services/report.service';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

import {
  DefinitionsPageDefaultParameters,
  SpecsPageDefaultParameters,
} from '../../constants/reports';
import { ReportOutputModalComponent } from '../../modals/report-complete-modal/report-output-modal.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss', '../../shared/shared.scss'],
  imports: [
    AdminModule,
    CommonModule,
    ContainerComponent,
    FormsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
    ReactiveFormsModule,
  ],
})
export class ReportsComponent implements OnInit {
  currencies = ['USD', 'EUR', 'GBP', 'JPY', 'CAD', 'AUD'];
  definitionDataSource = new MatTableDataSource<any>();
  definitionRows = this.formBuilder.array([
    this.formBuilder.group({
      key: '',
      value: '',
      dp: '',
    }),
  ]);
  enabled = true;
  isLoading = false;
  jobLog: JobLog | undefined;
  jobResults: JobResultFile[] | undefined;
  reportForm: FormGroup;
  selectedCurrency: string = 'USD';
  title = 'Report';
  time: string = new Date().toLocaleString();
  specDataSource = new MatTableDataSource<any>();
  specRows = this.formBuilder.array([
    this.formBuilder.group({
      key: '',
      value: '',
    }),
  ]);
  user: User | undefined;

  get specs() {
    return this.reportForm.get('specs') as FormArray;
  }

  get definitions() {
    return this.reportForm.get('definitions') as FormArray;
  }

  constructor(
    private dialog: MatDialog,
    private activatedRouter: ActivatedRoute,
    private jobLogService: JobLogService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private reportService: ReportService,
    private auth: Auth
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
      }
    });

    this.reportForm = this.formBuilder.group({
      specs: new FormArray([]),
      definitions: new FormArray([]),
      recipients: new FormControl('', {}),
      notifications: new FormControl(false, {}),
      currency: new FormControl(this.selectedCurrency, {}),
    });
    this.setupSpecsTable();
    this.setupDefinitionsTable();

    this.formHelper.setForm(this.reportForm);
  }

  ngOnInit() {
    this.activatedRouter.params.subscribe(async (params: Params) => {
      if (params['id']) {
        try {
          this.isLoading = true;

          const jobResponse = await this.jobLogService.get(
            new GetRequest({
              jobLogId: params['id'],
            })
          );
          this.jobLog = jobResponse.jobLogs![0].jobLog!;

          if (!this.jobLog) {
            return;
          }

          if (!this.jobLog.postprocessing) {
            this.messageBox.error(
              'Job is not postprocessed, unable to create report',
              undefined,
              2000
            );
            this.enabled = false;
            return;
          }

          this.getResults();

          this.reportForm = this.formBuilder.group({
            specs: this.specRows,
            definitions: this.definitionRows,
            recipients: new FormControl(this.user?.email || '', {}),
            notifications: new FormControl(false, {}),
            currency: new FormControl(this.selectedCurrency, {}),
          });

          this.setupSpecsTable();
          this.setupDefinitionsTable();
        } catch (error) {
          this.logger.error('Unable to load job.', error, 15);
          this.messageBox.error('Unable to load job.');
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  async process() {
    const { value } = this.reportForm;

    if (this.jobLog) {
      this.isLoading = true;
      try {
        // Convert specs and definitions to lists of string lists
        const convertedSpecs = value.specs.map((spec: any) => [
          spec.key,
          spec.value,
        ]);
        const convertedDefinitions = value.definitions.map((def: any) => [
          def.key,
          def.value,
          def.dp,
        ]);

        const data = this.jobResults?.find(
          (result) =>
            result.jobResultFileType ===
            JobResultFileType.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
        );

        if (!data) {
          this.messageBox.error('No data found for measurements CSV');
          return;
        }

        const result = (await this.reportService.createReport(
          this.jobLog.id,
          data.fileContents,
          value.recipients,
          convertedSpecs,
          convertedDefinitions,
          value.notifications,
          this.selectedCurrency
        )) as {
          data:
            | {
                link: string;
                sheetTitle: string;
                success: boolean;
                error: string | undefined;
              }
            | undefined;
        };

        this.openReportCompleteDialog(result.data, value.recipients);
        this.logger.info('Report run successfully.');
        this.messageBox.success('Report run successfully.');
      } catch (error) {
        this.logger.error('Error generating report:', error);
        this.messageBox.error('Error generating report.');
      } finally {
        this.isLoading = false;
      }
    }
  }

  getLocationName(location: Location | undefined): string {
    if (!location) {
      return 'Unknown';
    }

    return LocationList.find((l) => l.value === location)?.name || 'Unknown';
  }

  async getResults() {
    this.isLoading = true;
    if (!this.jobLog) {
      return;
    }

    try {
      const getJobsResultsRequest = new GetJobResultsRequest();
      getJobsResultsRequest.jobLogId = this.jobLog!.id;
      const response = await this.jobLogService.getJobResults(
        getJobsResultsRequest
      );
      this.jobResults = response.jobResultFiles;
    } catch (error) {
      this.logger.error('Failed to load job results with error: {}', error);
    } finally {
      this.isLoading = false;
    }
  }

  onCurrencySelect(currency: string) {
    this.selectedCurrency = currency;
  }

  setupSpecsTable() {
    this.specs.clear();

    SpecsPageDefaultParameters.forEach((value) => {
      const row = this.formBuilder.group({
        key: value[0],
        value: value[1],
      });
      this.specRows.push(row);
    });
    this.specDataSource.data = this.specs.value;
  }

  setupDefinitionsTable() {
    this.definitions.clear();

    DefinitionsPageDefaultParameters.forEach((value) => {
      const row = this.formBuilder.group({
        key: value[0],
        value: value[1],
        dp: value[2],
      });
      this.definitionRows.push(row);
    });
    this.definitionDataSource.data = this.definitions.value;
  }

  openReportCompleteDialog(
    data:
      | {
          link: string;
          sheetTitle: string;
          success: boolean;
          error: string | undefined;
        }
      | undefined,
    recipients: string
  ) {
    this.dialog.open(ReportOutputModalComponent, {
      data: {
        link: data?.link,
        sheetTitle: data?.sheetTitle,
        success: data?.success,
        error: data?.error,
        recipients: recipients,
      },
    });
  }
}
