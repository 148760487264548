import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import {
  ListFilter,
  ListRequest,
} from 'app/services/generated/src/main/proto/api/customer-data-set-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import {
  CustomerDataSetReference,
  CustomerDataSetReferenceConfig,
  CustomerDataSetReferenceGroup,
} from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';

import { CustomerDropdownComponent } from '../../../shared/components/customer-dropdown/customer-dropdown.component';

const MAX_RECORDS = 50;

@Component({
  selector: 'app-dataset-picker',
  templateUrl: './dataset-picker.component.html',
  styleUrls: ['./dataset-picker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CustomerDropdownComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    ReactiveFormsModule,
  ],
})
export class DatasetPickerComponent implements OnInit, OnChanges {
  @Input() binaryType: BinaryType | undefined;
  @Input() inputCustomerId: string | undefined;
  @Input() inputCustomerDataSetReferenceConfig:
    | CustomerDataSetReferenceConfig
    | undefined;
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Input() name = '';
  // If set, only allow finalized datasets to be picked.
  @Input() finalizedOnly = true;
  @Input() showPartitions = true;
  @Input() showDatasetVersions = true;
  @Output() customerDataSetReferenceConfig =
    new EventEmitter<CustomerDataSetReferenceConfig>();
  datasets: Array<Map<string, CustomerDataSet>> = [];
  expiredDatasets: string[] = [];
  customers: Customer[] = [];

  form = this.fb.group({
    selectedDatasetGroups: this.fb.array([
      this.fb.group({
        customerId: '',
        startDate: '',
        endDate: '',
        selectedDatasets: this.fb.array([
          this.fb.group({
            datasetId: '',
            versions: [],
            partitions: 0,
          }),
        ]),
      }),
    ]),
  });

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService,
    private formatService: FormatService
  ) {
    this.form.valueChanges.subscribe(() => this.emitCustomerDataSetInfo());
  }

  async ngOnInit() {
    this.loadCustomerDataSetGroups();
  }

  nameForKey(key: string): string {
    if (key == 'advertiser') {
      return 'Advertiser';
    }
    if (key == 'publisher') {
      return 'Publisher';
    }
    if (key == 'publisherUser') {
      return 'Publisher (Aux)';
    }
    return '';
  }

  getDatasetKeys(binaryType: BinaryType | undefined): string[] {
    if (!binaryType) {
      return [];
    }
    switch (binaryType) {
      case BinaryType.BINARY_TYPE_ATTRIBUTION:
        return ['publisher', 'advertiser'];
      case BinaryType.BINARY_TYPE_DENTSU_PROTOTYPE:
        return [];
      case BinaryType.BINARY_TYPE_ENCLAVE_ENCRYPTION:
        return [];
      case BinaryType.BINARY_TYPE_LIFT:
        return ['publisher', 'advertiser'];
      case BinaryType.BINARY_TYPE_LIFT_DASK:
        return [];
      case BinaryType.BINARY_TYPE_REDDIT_ATTRIBUTION:
        return ['publisher', 'advertiser'];
      case BinaryType.BINARY_TYPE_REDDIT_LIFT:
        return ['publisher', 'publisher_user', 'advertiser'];
      case BinaryType.BINARY_TYPE_TIKTOK_TARGETING:
        return ['publisher', 'advertiser'];
      case BinaryType.BINARY_TYPE_VALIDATION:
        return ['data'];
      case BinaryType.BINARY_TYPE_POSTPROCESSING:
        return [];
      case BinaryType.BINARY_TYPE_TIKTOK_AUDIENCE_INFERENCE:
        return ['publisher', 'advertiser'];
      default:
        return [];
    }
  }

  loadCustomerDataSetGroups() {
    this.customers.splice(0);
    this.selectedDatasetGroups.clear();

    for (const [index] of this.getDatasetKeys(this.binaryType).entries()) {
      this.customers.push(new Customer());
      const group = this.fb.group({
        customerId: '',
        startDate: '',
        endDate: '',
        selectedDatasets: this.fb.array([
          this.fb.group({
            datasetId: '',
            versions: [],
            partitions: 0,
          }),
        ]),
      });

      group.get('customerId')?.valueChanges.subscribe(() => {
        this.onCustomerIdValueChange(index);
      });
      this.selectedDatasetGroups.push(group);
      this.datasets.push(new Map<string, CustomerDataSet>());
    }
  }

  get selectedDatasetGroups() {
    return this.form.controls['selectedDatasetGroups'] as FormArray;
  }

  getSelectedDatasets(index: number) {
    return this.selectedDatasetGroups
      .at(index)
      .get('selectedDatasets') as FormArray;
  }

  getSelectedCustomer(index: number) {
    return this.selectedDatasetGroups
      .at(index)
      .get('customerId') as FormControl;
  }

  getSelectedStartDate(index: number) {
    return this.selectedDatasetGroups.at(index).get('startDate') as FormControl;
  }

  getSelectedEndDate(index: number) {
    return this.selectedDatasetGroups.at(index).get('endDate') as FormControl;
  }

  addDataset(i: number) {
    this.getSelectedDatasets(i).push(
      this.fb.group({
        datasetId: '',
        version: '',
        partitions: 0,
      })
    );
  }

  isCustomerDataSetExpired(i: number, j: number): boolean {
    const datasetControl = this.getSelectedDatasets(i).at(j).get('datasetId');
    if (!datasetControl || !datasetControl.value) {
      return false;
    }
    return this.expiredDatasets.includes(datasetControl.value);
  }

  deleteDataset(i: number, j: number) {
    this.getSelectedDatasets(i).removeAt(j);
  }

  toTimestamp(
    timestampField: string | undefined | null,
    isEnd: boolean
  ): Timestamp | undefined {
    if (!timestampField) {
      return undefined;
    }
    let timeSeconds = Math.trunc(Date.parse(timestampField) / 1000);
    if (isEnd) {
      timeSeconds += 86400 - 1; // Make end timestamps end one second before the next day.
    }
    return new Timestamp({
      seconds: timeSeconds.toString(),
    });
  }

  emitCustomerDataSetInfo() {
    const values = this.form.value;
    const customerDataSetReferences: CustomerDataSetReference[] = [];
    customerDataSetReferences.push(new CustomerDataSetReference({}));
    const customerDataSetReferenceConfig = new CustomerDataSetReferenceConfig({
      groups: {},
    });
    for (const [index, group] of values.selectedDatasetGroups!.entries()) {
      customerDataSetReferenceConfig.groups[
        this.getDatasetKeys(this.binaryType)[index]
      ] = new CustomerDataSetReferenceGroup({
        customerId: group.customerId!,
        startTime: this.toTimestamp(group.startDate, false),
        endTime: this.toTimestamp(group.endDate, true),
        parts: group.selectedDatasets!.map((dataset) => {
          return new CustomerDataSetReference({
            id: dataset.datasetId!,
            versions: dataset.versions!,
            desiredPartitions: dataset.partitions ?? 0,
          });
        }),
      });
    }

    this.customerDataSetReferenceConfig.emit(customerDataSetReferenceConfig);
  }

  async onCustomerIdValueChange(i: number) {
    this.datasets[i].clear();
    this.expiredDatasets = [];

    const customerIdControl = this.selectedDatasetGroups
      .at(i)
      .get('customerId')!;
    if (!customerIdControl.value) {
      return;
    }
    const listResponse = await this.customerDataService.list(
      new ListRequest({
        paginated: new GetPaginatedRequest({
          numRecords: MAX_RECORDS,
        }),
        filter: new ListFilter({
          customerId: customerIdControl.value!,
          location: this.location,
        }),
      })
    );
    if (listResponse.customerDataSets) {
      listResponse.customerDataSets.forEach((dataset) => {
        if (this.customerDataService.isCustomerDataSetExpired(dataset)) {
          this.expiredDatasets.push(dataset.id);
        }
        if (
          this.finalizedOnly &&
          dataset.state != CustomerDataSet.DatasetState.DATASET_STATE_FINALIZED
        ) {
          return;
        }
        this.datasets[i].set(dataset.id, dataset);
      });
    }
  }

  onCustomerSelect(i: number, customer: Customer) {
    if (customer) {
      this.selectedDatasetGroups.at(i).get('customerId')?.setValue(customer.id);
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['binaryType']) {
      this.loadCustomerDataSetGroups();
    }
    if (changes['inputCustomerDataSetReferenceConfig']) {
      const inputCustomerDataSetReferenceConfig: CustomerDataSetReferenceConfig =
        changes['inputCustomerDataSetReferenceConfig'].currentValue;
      for (const [index, key] of this.getDatasetKeys(
        this.binaryType
      ).entries()) {
        if (key in inputCustomerDataSetReferenceConfig.groups) {
          const group = inputCustomerDataSetReferenceConfig.groups[key];
          this.getSelectedCustomer(index).setValue(group.customerId);
          this.customers[index].id = group.customerId;
          this.getSelectedStartDate(index).setValue(
            this.formatService.formatProtoDateForInput(group.startTime)
          );
          this.getSelectedEndDate(index).setValue(
            this.formatService.formatProtoDateForInput(group.endTime)
          );
          this.getSelectedDatasets(index).clear();
          for (const part of group.parts!) {
            this.getSelectedDatasets(index).push(
              this.fb.group({
                datasetId: part.id,
                versions: part.versions,
                partitions: part.desiredPartitions,
                customerId: group.customerId,
              })
            );
          }
        }
      }
    }
  }
}
