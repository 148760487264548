/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './project-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as attribution001 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as storage002 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage003 from '../../../../src/main/proto/storage/commons.pb';
import * as storage004 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
import * as storage005 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api006 from '../../../../src/main/proto/api/headers.pb';
import * as api007 from '../../../../src/main/proto/api/pagination.pb';
import * as storage008 from '../../../../src/main/proto/storage/project.pb';
import { GRPC_PROJECT_SERVICE_CLIENT_SETTINGS } from './project-service.pbconf';
/**
 * Service client implementation for api.project.ProjectService
 */
@Injectable({ providedIn: 'any' })
export class ProjectServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.project.ProjectService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.project.ProjectService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.project.ProjectService/Get
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetProjectResponse>>
     */
    get: (
      requestData: thisProto.GetProjectRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetProjectResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.project.ProjectService/Get',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetProjectRequest,
        responseClass: thisProto.GetProjectResponse,
      });
    },
    /**
     * Unary call: /api.project.ProjectService/List
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ListProjectsResponse>>
     */
    list: (
      requestData: thisProto.ListProjectsRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ListProjectsResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.project.ProjectService/List',
        requestData,
        requestMetadata,
        requestClass: thisProto.ListProjectsRequest,
        responseClass: thisProto.ListProjectsResponse,
      });
    },
    /**
     * Unary call: /api.project.ProjectService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateProjectResponse>>
     */
    update: (
      requestData: thisProto.UpdateProjectRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateProjectResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.project.ProjectService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateProjectRequest,
        responseClass: thisProto.UpdateProjectResponse,
      });
    },
    /**
     * Unary call: /api.project.ProjectService/CreateJob
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateJobResponse>>
     */
    createJob: (
      requestData: thisProto.CreateJobRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateJobResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.project.ProjectService/CreateJob',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateJobRequest,
        responseClass: thisProto.CreateJobResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_PROJECT_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.project.ProjectService',
      settings
    );
  }

  /**
   * Unary call @/api.project.ProjectService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.project.ProjectService/Get
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetProjectResponse>
   */
  get(
    requestData: thisProto.GetProjectRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetProjectResponse> {
    return this.$raw
      .get(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.project.ProjectService/List
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ListProjectsResponse>
   */
  list(
    requestData: thisProto.ListProjectsRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ListProjectsResponse> {
    return this.$raw
      .list(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.project.ProjectService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateProjectResponse>
   */
  update(
    requestData: thisProto.UpdateProjectRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateProjectResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.project.ProjectService/CreateJob
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateJobResponse>
   */
  createJob(
    requestData: thisProto.CreateJobRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateJobResponse> {
    return this.$raw
      .createJob(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
