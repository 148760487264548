<app-container>
  <app-page-header
    title="Storage Viewer"
    lastUpdated="{{ time }}"
  ></app-page-header>
  <app-message-box></app-message-box>

  <div *ngIf="isLoading" class="spinner-container">
    <mat-spinner diameter="24"></mat-spinner>
  </div>

  <section class="metadata-viewer" tabindex="0" *ngIf="customerDataSet">
    <div class="metadata-section">
      <h2 class="section-title">Dataset Information</h2>
      <div class="info-grid">
        <div class="info-item">
          <span class="label">Dataset ID:</span>
          <span class="value">{{ customerDataSet.id }}</span>
        </div>
        <div class="info-item">
          <span class="label">Dataset Name:</span>
          <span class="value">{{ customerDataSet.name }}</span>
        </div>
        <div class="info-item">
          <span class="label">Location:</span>
          <span class="value"
            >{{ getLocationName(customerDataSet.location) }} region</span
          >
        </div>
        <div class="info-item">
          <span class="label">State:</span>
          <span class="value" [class.draft]="customerDataSet.state === 1">
            {{ customerDataSet.state === 1 ? 'Draft' : 'Finalized' }}
          </span>
        </div>
      </div>
    </div>

    <div class="mapping-section" *ngIf="fileMappingJson">
      <h2 class="section-title">File Mapping</h2>
      <mat-card appearance="outlined">
        <pre><code [highlight]="formatJson(fileMappingJson)" language="json"></code></pre>
      </mat-card>
    </div>

    <app-file-viewer
      [storageMetadata]="storageMetadata"
      [customerDataSet]="customerDataSet"
    >
    </app-file-viewer>
  </section>

  <div *ngIf="!customerDataSet && !isLoading" class="no-data">
    <p>No dataset information available</p>
  </div>
</app-container>
