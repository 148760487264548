/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
export enum ColumnType {
  COLUMN_TYPE_UNSPECIFIED = 0,
  COLUMN_TYPE_STRING = 1,
  COLUMN_TYPE_NUMERIC = 2,
  COLUMN_TYPE_BOOLEAN = 3,
  COLUMN_TYPE_MATCH_KEY = 4,
  COLUMN_TYPE_TIME = 5,
  COLUMN_TYPE_ADVERTISER_EVENT_TYPE = 6,
  COLUMN_TYPE_PUBLISHER_EVENT_TYPE = 7,
  COLUMN_TYPE_PII_TYPE = 8,
  COLUMN_TYPE_PII_TYPE_ARRAY = 9,
  COLUMN_TYPE_PII_VALUE_ARRAY = 10,
}
/**
 * Message implementation for validation.ValidationConfig
 */
export class ValidationConfig implements GrpcMessage {
  static id = 'validation.ValidationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationConfig();
    ValidationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationConfig) {
    _instance.customerDataSet = _instance.customerDataSet || undefined;
    _instance.numShards = _instance.numShards || 0;
    _instance.expectedColumns = _instance.expectedColumns || {};
    _instance.emitSummaryStatistics = _instance.emitSummaryStatistics || false;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 6:
          _instance.customerDataSet = new storage001.CustomerDataSetReference();
          _reader.readMessage(
            _instance.customerDataSet,
            storage001.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.numShards = _reader.readInt32();
          break;
        case 7:
          const msg_7 = {} as any;
          _reader.readMessage(
            msg_7,
            ValidationConfig.ExpectedColumnsEntry.deserializeBinaryFromReader
          );
          _instance.expectedColumns = _instance.expectedColumns || {};
          _instance.expectedColumns[msg_7.key] = msg_7.value;
          break;
        case 8:
          _instance.emitSummaryStatistics = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.customerDataSet) {
      _writer.writeMessage(
        6,
        _instance.customerDataSet as any,
        storage001.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.numShards) {
      _writer.writeInt32(5, _instance.numShards);
    }
    if (!!_instance.expectedColumns) {
      const keys_7 = Object.keys(_instance.expectedColumns as any);

      if (keys_7.length) {
        const repeated_7 = keys_7
          .map((key) => ({
            key: key,
            value: (_instance.expectedColumns as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          7,
          repeated_7,
          ValidationConfig.ExpectedColumnsEntry.serializeBinaryToWriter
        );
      }
    }
    if (_instance.emitSummaryStatistics) {
      _writer.writeBool(8, _instance.emitSummaryStatistics);
    }
  }

  private _customerDataSet?: storage001.CustomerDataSetReference;
  private _numShards: number;
  private _expectedColumns: { [prop: string]: ColumnType };
  private _emitSummaryStatistics: boolean;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationConfig.AsObject>) {
    _value = _value || {};
    this.customerDataSet = _value.customerDataSet
      ? new storage001.CustomerDataSetReference(_value.customerDataSet)
      : undefined;
    this.numShards = _value.numShards;
    (this.expectedColumns = _value!.expectedColumns
      ? Object.keys(_value!.expectedColumns).reduce(
          (r, k) => ({ ...r, [k]: _value!.expectedColumns![k] }),
          {}
        )
      : {}),
      (this.emitSummaryStatistics = _value.emitSummaryStatistics);
    ValidationConfig.refineValues(this);
  }
  get customerDataSet(): storage001.CustomerDataSetReference | undefined {
    return this._customerDataSet;
  }
  set customerDataSet(value: storage001.CustomerDataSetReference | undefined) {
    this._customerDataSet = value;
  }
  get numShards(): number {
    return this._numShards;
  }
  set numShards(value: number) {
    this._numShards = value;
  }
  get expectedColumns(): { [prop: string]: ColumnType } {
    return this._expectedColumns;
  }
  set expectedColumns(value: { [prop: string]: ColumnType }) {
    this._expectedColumns = value;
  }
  get emitSummaryStatistics(): boolean {
    return this._emitSummaryStatistics;
  }
  set emitSummaryStatistics(value: boolean) {
    this._emitSummaryStatistics = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationConfig.AsObject {
    return {
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toObject()
        : undefined,
      numShards: this.numShards,
      expectedColumns: this.expectedColumns
        ? Object.keys(this.expectedColumns).reduce(
            (r, k) => ({ ...r, [k]: this.expectedColumns![k] }),
            {}
          )
        : {},
      emitSummaryStatistics: this.emitSummaryStatistics,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationConfig.AsProtobufJSON {
    return {
      customerDataSet: this.customerDataSet
        ? this.customerDataSet.toProtobufJSON(options)
        : null,
      numShards: this.numShards,
      expectedColumns: this.expectedColumns
        ? Object.keys(this.expectedColumns).reduce(
            (r, k) => ({ ...r, [k]: this.expectedColumns![k] }),
            {}
          )
        : {},
      emitSummaryStatistics: this.emitSummaryStatistics,
    };
  }
}
export module ValidationConfig {
  /**
   * Standard JavaScript object representation for ValidationConfig
   */
  export interface AsObject {
    customerDataSet?: storage001.CustomerDataSetReference.AsObject;
    numShards: number;
    expectedColumns: { [prop: string]: ColumnType };
    emitSummaryStatistics: boolean;
  }

  /**
   * Protobuf JSON representation for ValidationConfig
   */
  export interface AsProtobufJSON {
    customerDataSet: storage001.CustomerDataSetReference.AsProtobufJSON | null;
    numShards: number;
    expectedColumns: { [prop: string]: ColumnType };
    emitSummaryStatistics: boolean;
  }

  /**
   * Message implementation for validation.ValidationConfig.ExpectedColumnsEntry
   */
  export class ExpectedColumnsEntry implements GrpcMessage {
    static id = 'validation.ValidationConfig.ExpectedColumnsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ExpectedColumnsEntry();
      ExpectedColumnsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ExpectedColumnsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || 0;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ExpectedColumnsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readEnum();
            break;
          default:
            _reader.skipField();
        }
      }

      ExpectedColumnsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ExpectedColumnsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeEnum(2, _instance.value);
      }
    }

    private _key: string;
    private _value: ColumnType;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ExpectedColumnsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ExpectedColumnsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      ExpectedColumnsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): ColumnType {
      return this._value;
    }
    set value(value: ColumnType) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ExpectedColumnsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ExpectedColumnsEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ExpectedColumnsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value:
          ColumnType[
            this.value === null || this.value === undefined ? 0 : this.value
          ],
      };
    }
  }
  export module ExpectedColumnsEntry {
    /**
     * Standard JavaScript object representation for ExpectedColumnsEntry
     */
    export interface AsObject {
      key: string;
      value: ColumnType;
    }

    /**
     * Protobuf JSON representation for ExpectedColumnsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for validation.DateStat
 */
export class DateStat implements GrpcMessage {
  static id = 'validation.DateStat';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DateStat();
    DateStat.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DateStat) {
    _instance.date = _instance.date || '';
    _instance.count = _instance.count || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DateStat,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.date = _reader.readString();
          break;
        case 2:
          _instance.count = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    DateStat.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: DateStat, _writer: BinaryWriter) {
    if (_instance.date) {
      _writer.writeString(1, _instance.date);
    }
    if (_instance.count) {
      _writer.writeInt64String(2, _instance.count);
    }
  }

  private _date: string;
  private _count: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DateStat to deeply clone from
   */
  constructor(_value?: RecursivePartial<DateStat.AsObject>) {
    _value = _value || {};
    this.date = _value.date;
    this.count = _value.count;
    DateStat.refineValues(this);
  }
  get date(): string {
    return this._date;
  }
  set date(value: string) {
    this._date = value;
  }
  get count(): string {
    return this._count;
  }
  set count(value: string) {
    this._count = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DateStat.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DateStat.AsObject {
    return {
      date: this.date,
      count: this.count,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DateStat.AsProtobufJSON {
    return {
      date: this.date,
      count: this.count,
    };
  }
}
export module DateStat {
  /**
   * Standard JavaScript object representation for DateStat
   */
  export interface AsObject {
    date: string;
    count: string;
  }

  /**
   * Protobuf JSON representation for DateStat
   */
  export interface AsProtobufJSON {
    date: string;
    count: string;
  }
}

/**
 * Message implementation for validation.ValidationSummaryStatistics
 */
export class ValidationSummaryStatistics implements GrpcMessage {
  static id = 'validation.ValidationSummaryStatistics';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationSummaryStatistics();
    ValidationSummaryStatistics.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationSummaryStatistics) {
    _instance.rowCount = _instance.rowCount || '0';
    _instance.eventTypeCounts = _instance.eventTypeCounts || {};
    _instance.eventTimeStats = _instance.eventTimeStats || [];
    _instance.matchKeyCounts = _instance.matchKeyCounts || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationSummaryStatistics,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rowCount = _reader.readInt64String();
          break;
        case 3:
          const msg_3 = {} as any;
          _reader.readMessage(
            msg_3,
            ValidationSummaryStatistics.EventTypeCountsEntry
              .deserializeBinaryFromReader
          );
          _instance.eventTypeCounts = _instance.eventTypeCounts || {};
          _instance.eventTypeCounts[msg_3.key] = msg_3.value;
          break;
        case 6:
          const messageInitializer6 = new DateStat();
          _reader.readMessage(
            messageInitializer6,
            DateStat.deserializeBinaryFromReader
          );
          (_instance.eventTimeStats = _instance.eventTimeStats || []).push(
            messageInitializer6
          );
          break;
        case 5:
          const msg_5 = {} as any;
          _reader.readMessage(
            msg_5,
            ValidationSummaryStatistics.MatchKeyCountsEntry
              .deserializeBinaryFromReader
          );
          _instance.matchKeyCounts = _instance.matchKeyCounts || {};
          _instance.matchKeyCounts[msg_5.key] = msg_5.value;
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationSummaryStatistics.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationSummaryStatistics,
    _writer: BinaryWriter
  ) {
    if (_instance.rowCount) {
      _writer.writeInt64String(1, _instance.rowCount);
    }
    if (!!_instance.eventTypeCounts) {
      const keys_3 = Object.keys(_instance.eventTypeCounts as any);

      if (keys_3.length) {
        const repeated_3 = keys_3
          .map((key) => ({
            key: key,
            value: (_instance.eventTypeCounts as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          3,
          repeated_3,
          ValidationSummaryStatistics.EventTypeCountsEntry
            .serializeBinaryToWriter
        );
      }
    }
    if (_instance.eventTimeStats && _instance.eventTimeStats.length) {
      _writer.writeRepeatedMessage(
        6,
        _instance.eventTimeStats as any,
        DateStat.serializeBinaryToWriter
      );
    }
    if (!!_instance.matchKeyCounts) {
      const keys_5 = Object.keys(_instance.matchKeyCounts as any);

      if (keys_5.length) {
        const repeated_5 = keys_5
          .map((key) => ({
            key: key,
            value: (_instance.matchKeyCounts as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          5,
          repeated_5,
          ValidationSummaryStatistics.MatchKeyCountsEntry
            .serializeBinaryToWriter
        );
      }
    }
  }

  private _rowCount: string;
  private _eventTypeCounts: { [prop: string]: string };
  private _eventTimeStats?: DateStat[];
  private _matchKeyCounts: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationSummaryStatistics to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationSummaryStatistics.AsObject>) {
    _value = _value || {};
    this.rowCount = _value.rowCount;
    (this.eventTypeCounts = _value!.eventTypeCounts
      ? Object.keys(_value!.eventTypeCounts).reduce(
          (r, k) => ({ ...r, [k]: _value!.eventTypeCounts![k] }),
          {}
        )
      : {}),
      (this.eventTimeStats = (_value.eventTimeStats || []).map(
        (m) => new DateStat(m)
      ));
    (this.matchKeyCounts = _value!.matchKeyCounts
      ? Object.keys(_value!.matchKeyCounts).reduce(
          (r, k) => ({ ...r, [k]: _value!.matchKeyCounts![k] }),
          {}
        )
      : {}),
      ValidationSummaryStatistics.refineValues(this);
  }
  get rowCount(): string {
    return this._rowCount;
  }
  set rowCount(value: string) {
    this._rowCount = value;
  }
  get eventTypeCounts(): { [prop: string]: string } {
    return this._eventTypeCounts;
  }
  set eventTypeCounts(value: { [prop: string]: string }) {
    this._eventTypeCounts = value;
  }
  get eventTimeStats(): DateStat[] | undefined {
    return this._eventTimeStats;
  }
  set eventTimeStats(value: DateStat[] | undefined) {
    this._eventTimeStats = value;
  }
  get matchKeyCounts(): { [prop: string]: string } {
    return this._matchKeyCounts;
  }
  set matchKeyCounts(value: { [prop: string]: string }) {
    this._matchKeyCounts = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationSummaryStatistics.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationSummaryStatistics.AsObject {
    return {
      rowCount: this.rowCount,
      eventTypeCounts: this.eventTypeCounts
        ? Object.keys(this.eventTypeCounts).reduce(
            (r, k) => ({ ...r, [k]: this.eventTypeCounts![k] }),
            {}
          )
        : {},
      eventTimeStats: (this.eventTimeStats || []).map((m) => m.toObject()),
      matchKeyCounts: this.matchKeyCounts
        ? Object.keys(this.matchKeyCounts).reduce(
            (r, k) => ({ ...r, [k]: this.matchKeyCounts![k] }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationSummaryStatistics.AsProtobufJSON {
    return {
      rowCount: this.rowCount,
      eventTypeCounts: this.eventTypeCounts
        ? Object.keys(this.eventTypeCounts).reduce(
            (r, k) => ({ ...r, [k]: this.eventTypeCounts![k] }),
            {}
          )
        : {},
      eventTimeStats: (this.eventTimeStats || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      matchKeyCounts: this.matchKeyCounts
        ? Object.keys(this.matchKeyCounts).reduce(
            (r, k) => ({ ...r, [k]: this.matchKeyCounts![k] }),
            {}
          )
        : {},
    };
  }
}
export module ValidationSummaryStatistics {
  /**
   * Standard JavaScript object representation for ValidationSummaryStatistics
   */
  export interface AsObject {
    rowCount: string;
    eventTypeCounts: { [prop: string]: string };
    eventTimeStats?: DateStat.AsObject[];
    matchKeyCounts: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for ValidationSummaryStatistics
   */
  export interface AsProtobufJSON {
    rowCount: string;
    eventTypeCounts: { [prop: string]: string };
    eventTimeStats: DateStat.AsProtobufJSON[] | null;
    matchKeyCounts: { [prop: string]: string };
  }

  /**
   * Message implementation for validation.ValidationSummaryStatistics.EventTypeCountsEntry
   */
  export class EventTypeCountsEntry implements GrpcMessage {
    static id = 'validation.ValidationSummaryStatistics.EventTypeCountsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new EventTypeCountsEntry();
      EventTypeCountsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: EventTypeCountsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '0';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: EventTypeCountsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readInt64String();
            break;
          default:
            _reader.skipField();
        }
      }

      EventTypeCountsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: EventTypeCountsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeInt64String(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of EventTypeCountsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<EventTypeCountsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      EventTypeCountsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      EventTypeCountsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): EventTypeCountsEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): EventTypeCountsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module EventTypeCountsEntry {
    /**
     * Standard JavaScript object representation for EventTypeCountsEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for EventTypeCountsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }

  /**
   * Message implementation for validation.ValidationSummaryStatistics.MatchKeyCountsEntry
   */
  export class MatchKeyCountsEntry implements GrpcMessage {
    static id = 'validation.ValidationSummaryStatistics.MatchKeyCountsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new MatchKeyCountsEntry();
      MatchKeyCountsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: MatchKeyCountsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '0';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: MatchKeyCountsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readInt64String();
            break;
          default:
            _reader.skipField();
        }
      }

      MatchKeyCountsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: MatchKeyCountsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeInt64String(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of MatchKeyCountsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<MatchKeyCountsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      MatchKeyCountsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      MatchKeyCountsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): MatchKeyCountsEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): MatchKeyCountsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module MatchKeyCountsEntry {
    /**
     * Standard JavaScript object representation for MatchKeyCountsEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for MatchKeyCountsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for validation.ValidationSchema
 */
export class ValidationSchema implements GrpcMessage {
  static id = 'validation.ValidationSchema';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationSchema();
    ValidationSchema.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationSchema) {
    _instance.columnTypes = _instance.columnTypes || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationSchema,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            ValidationSchema.ColumnTypesEntry.deserializeBinaryFromReader
          );
          _instance.columnTypes = _instance.columnTypes || {};
          _instance.columnTypes[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationSchema.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationSchema,
    _writer: BinaryWriter
  ) {
    if (!!_instance.columnTypes) {
      const keys_1 = Object.keys(_instance.columnTypes as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({
            key: key,
            value: (_instance.columnTypes as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          ValidationSchema.ColumnTypesEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _columnTypes: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationSchema to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationSchema.AsObject>) {
    _value = _value || {};
    (this.columnTypes = _value!.columnTypes
      ? Object.keys(_value!.columnTypes).reduce(
          (r, k) => ({ ...r, [k]: _value!.columnTypes![k] }),
          {}
        )
      : {}),
      ValidationSchema.refineValues(this);
  }
  get columnTypes(): { [prop: string]: string } {
    return this._columnTypes;
  }
  set columnTypes(value: { [prop: string]: string }) {
    this._columnTypes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationSchema.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationSchema.AsObject {
    return {
      columnTypes: this.columnTypes
        ? Object.keys(this.columnTypes).reduce(
            (r, k) => ({ ...r, [k]: this.columnTypes![k] }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationSchema.AsProtobufJSON {
    return {
      columnTypes: this.columnTypes
        ? Object.keys(this.columnTypes).reduce(
            (r, k) => ({ ...r, [k]: this.columnTypes![k] }),
            {}
          )
        : {},
    };
  }
}
export module ValidationSchema {
  /**
   * Standard JavaScript object representation for ValidationSchema
   */
  export interface AsObject {
    columnTypes: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for ValidationSchema
   */
  export interface AsProtobufJSON {
    columnTypes: { [prop: string]: string };
  }

  /**
   * Message implementation for validation.ValidationSchema.ColumnTypesEntry
   */
  export class ColumnTypesEntry implements GrpcMessage {
    static id = 'validation.ValidationSchema.ColumnTypesEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ColumnTypesEntry();
      ColumnTypesEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ColumnTypesEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ColumnTypesEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      ColumnTypesEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ColumnTypesEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ColumnTypesEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ColumnTypesEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      ColumnTypesEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ColumnTypesEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ColumnTypesEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ColumnTypesEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module ColumnTypesEntry {
    /**
     * Standard JavaScript object representation for ColumnTypesEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for ColumnTypesEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for validation.ValidationStatus
 */
export class ValidationStatus implements GrpcMessage {
  static id = 'validation.ValidationStatus';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationStatus();
    ValidationStatus.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationStatus) {
    _instance.columnStatus = _instance.columnStatus || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationStatus,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const msg_1 = {} as any;
          _reader.readMessage(
            msg_1,
            ValidationStatus.ColumnStatusEntry.deserializeBinaryFromReader
          );
          _instance.columnStatus = _instance.columnStatus || {};
          _instance.columnStatus[msg_1.key] = msg_1.value;
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationStatus.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationStatus,
    _writer: BinaryWriter
  ) {
    if (!!_instance.columnStatus) {
      const keys_1 = Object.keys(_instance.columnStatus as any);

      if (keys_1.length) {
        const repeated_1 = keys_1
          .map((key) => ({
            key: key,
            value: (_instance.columnStatus as any)[key],
          }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          1,
          repeated_1,
          ValidationStatus.ColumnStatusEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _columnStatus: { [prop: string]: string };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationStatus to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationStatus.AsObject>) {
    _value = _value || {};
    (this.columnStatus = _value!.columnStatus
      ? Object.keys(_value!.columnStatus).reduce(
          (r, k) => ({ ...r, [k]: _value!.columnStatus![k] }),
          {}
        )
      : {}),
      ValidationStatus.refineValues(this);
  }
  get columnStatus(): { [prop: string]: string } {
    return this._columnStatus;
  }
  set columnStatus(value: { [prop: string]: string }) {
    this._columnStatus = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationStatus.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationStatus.AsObject {
    return {
      columnStatus: this.columnStatus
        ? Object.keys(this.columnStatus).reduce(
            (r, k) => ({ ...r, [k]: this.columnStatus![k] }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationStatus.AsProtobufJSON {
    return {
      columnStatus: this.columnStatus
        ? Object.keys(this.columnStatus).reduce(
            (r, k) => ({ ...r, [k]: this.columnStatus![k] }),
            {}
          )
        : {},
    };
  }
}
export module ValidationStatus {
  /**
   * Standard JavaScript object representation for ValidationStatus
   */
  export interface AsObject {
    columnStatus: { [prop: string]: string };
  }

  /**
   * Protobuf JSON representation for ValidationStatus
   */
  export interface AsProtobufJSON {
    columnStatus: { [prop: string]: string };
  }

  /**
   * Message implementation for validation.ValidationStatus.ColumnStatusEntry
   */
  export class ColumnStatusEntry implements GrpcMessage {
    static id = 'validation.ValidationStatus.ColumnStatusEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new ColumnStatusEntry();
      ColumnStatusEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: ColumnStatusEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || '';
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: ColumnStatusEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = _reader.readString();
            break;
          default:
            _reader.skipField();
        }
      }

      ColumnStatusEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: ColumnStatusEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeString(2, _instance.value);
      }
    }

    private _key: string;
    private _value: string;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of ColumnStatusEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<ColumnStatusEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value;
      ColumnStatusEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): string {
      return this._value;
    }
    set value(value: string) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      ColumnStatusEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): ColumnStatusEntry.AsObject {
      return {
        key: this.key,
        value: this.value,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): ColumnStatusEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value,
      };
    }
  }
  export module ColumnStatusEntry {
    /**
     * Standard JavaScript object representation for ColumnStatusEntry
     */
    export interface AsObject {
      key: string;
      value: string;
    }

    /**
     * Protobuf JSON representation for ColumnStatusEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: string;
    }
  }
}

/**
 * Message implementation for validation.ValidationOutput
 */
export class ValidationOutput implements GrpcMessage {
  static id = 'validation.ValidationOutput';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationOutput();
    ValidationOutput.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationOutput) {
    _instance.schema = _instance.schema || undefined;
    _instance.status = _instance.status || undefined;
    _instance.summaryStatistics = _instance.summaryStatistics || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationOutput,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.schema = new ValidationSchema();
          _reader.readMessage(
            _instance.schema,
            ValidationSchema.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.status = new ValidationStatus();
          _reader.readMessage(
            _instance.status,
            ValidationStatus.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.summaryStatistics = new ValidationSummaryStatistics();
          _reader.readMessage(
            _instance.summaryStatistics,
            ValidationSummaryStatistics.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ValidationOutput.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationOutput,
    _writer: BinaryWriter
  ) {
    if (_instance.schema) {
      _writer.writeMessage(
        1,
        _instance.schema as any,
        ValidationSchema.serializeBinaryToWriter
      );
    }
    if (_instance.status) {
      _writer.writeMessage(
        2,
        _instance.status as any,
        ValidationStatus.serializeBinaryToWriter
      );
    }
    if (_instance.summaryStatistics) {
      _writer.writeMessage(
        3,
        _instance.summaryStatistics as any,
        ValidationSummaryStatistics.serializeBinaryToWriter
      );
    }
  }

  private _schema?: ValidationSchema;
  private _status?: ValidationStatus;
  private _summaryStatistics?: ValidationSummaryStatistics;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationOutput to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationOutput.AsObject>) {
    _value = _value || {};
    this.schema = _value.schema
      ? new ValidationSchema(_value.schema)
      : undefined;
    this.status = _value.status
      ? new ValidationStatus(_value.status)
      : undefined;
    this.summaryStatistics = _value.summaryStatistics
      ? new ValidationSummaryStatistics(_value.summaryStatistics)
      : undefined;
    ValidationOutput.refineValues(this);
  }
  get schema(): ValidationSchema | undefined {
    return this._schema;
  }
  set schema(value: ValidationSchema | undefined) {
    this._schema = value;
  }
  get status(): ValidationStatus | undefined {
    return this._status;
  }
  set status(value: ValidationStatus | undefined) {
    this._status = value;
  }
  get summaryStatistics(): ValidationSummaryStatistics | undefined {
    return this._summaryStatistics;
  }
  set summaryStatistics(value: ValidationSummaryStatistics | undefined) {
    this._summaryStatistics = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationOutput.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationOutput.AsObject {
    return {
      schema: this.schema ? this.schema.toObject() : undefined,
      status: this.status ? this.status.toObject() : undefined,
      summaryStatistics: this.summaryStatistics
        ? this.summaryStatistics.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationOutput.AsProtobufJSON {
    return {
      schema: this.schema ? this.schema.toProtobufJSON(options) : null,
      status: this.status ? this.status.toProtobufJSON(options) : null,
      summaryStatistics: this.summaryStatistics
        ? this.summaryStatistics.toProtobufJSON(options)
        : null,
    };
  }
}
export module ValidationOutput {
  /**
   * Standard JavaScript object representation for ValidationOutput
   */
  export interface AsObject {
    schema?: ValidationSchema.AsObject;
    status?: ValidationStatus.AsObject;
    summaryStatistics?: ValidationSummaryStatistics.AsObject;
  }

  /**
   * Protobuf JSON representation for ValidationOutput
   */
  export interface AsProtobufJSON {
    schema: ValidationSchema.AsProtobufJSON | null;
    status: ValidationStatus.AsProtobufJSON | null;
    summaryStatistics: ValidationSummaryStatistics.AsProtobufJSON | null;
  }
}
