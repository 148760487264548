import { CommonModule, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import { ApprovalService } from 'app/services/approval.service';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import {
  GetRequest,
  GetRequestFilter,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import {
  ListProjectsFilter,
  ListProjectsRequest,
  ListProjectsResponse,
} from 'app/services/generated/src/main/proto/api/project-service.pb';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { CustomerDataSetReferenceGroup } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { JobState } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import {
  AdvertiserEvents,
  Project,
} from 'app/services/generated/src/main/proto/storage/project.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';

import { JOB_STATE_TO_STRING, LocationList } from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';
import { ContainerComponent } from '../shared/components/container/container.component';
import { CustomerDropdownComponent } from '../shared/components/customer-dropdown/customer-dropdown.component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

const RECORDS_PER_PAGE = 15;

function formatDuration(
  startDate: Date | undefined,
  endDate: Date | undefined
): string | undefined {
  if (!startDate || !endDate) {
    return undefined;
  }
  const diffInMs = endDate.getTime() - startDate.getTime();
  const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));
  return `${diffInDays} days`;
}

interface Page {
  continuationToken: string | null;
}

interface ProjectRow {
  id: string;
  lastUpdate: string | undefined;
  lastUpdater: string | undefined;
  binaryType: BinaryType;
  name: string;
  publisherId: string | undefined;
  advertiserId: string | undefined;
  location: string;
  publisherDataSetId: string | undefined;
  advertiserDataSetId: string | undefined;
  lookbackWindow: string | undefined;
  showLookbackWindow: boolean;
  matchKeys: string[];
  notes: string;
  studyId: string;
  publisherStartDate: string | null;
  publisherEndDate: string | null;
  advertiserStartDate: string | null;
  advertiserEndDate: string | null;
  publisherDuration: string | undefined;
  postPeriodDuration: string | undefined;
  advertiserEventTypes: string[];
  advertiserEventMetrics: string[];
  publisherDatasetId: string | undefined;
  advertiserDatasetId: string | undefined;
  publisherDatasetName: string | undefined;
  advertiserDatasetName: string | undefined;
  publisherDatasetStatus: string | undefined;
  advertiserDatasetStatus: string | undefined;
  statusMessage: string;
  statusColor: string;
  jobState: string | undefined;
}

@Component({
  selector: 'app-admin-project-manager',
  providers: [DatePipe],
  templateUrl: './admin-project-manager.component.html',
  styleUrls: ['./admin-project-manager.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    CustomerDropdownComponent,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
    RouterModule,
  ],
})
export class AdminProjectManagerComponent implements OnInit {
  archived: boolean = false;
  binaryTypeToName = new Map<BinaryType, string>();
  customerNameMap = new Map<string, string>();
  projectRows: ProjectRow[] = [];
  isLoading = false;
  currentPageIndex = 0;
  selectedAdvertiser: Customer | undefined;
  selectedPublisher: Customer | undefined;
  projects: { [key: string]: Project } = {};
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  lastUpdate = this.formatService.getLastUpdate();
  selectedCustomer: any;

  constructor(
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private customerDataService: CustomerDataService,
    private projectService: ProjectService,
    private jobLogService: JobLogService,
    private formatService: FormatService,
    private logger: LoggerService,
    private router: Router,
    private snackBar: MatSnackBar,
    private approvalService: ApprovalService
  ) {}

  async ngOnInit() {
    this.getCustomerData();
    this.loadBinaryTypes();
    this.loadProjects();
  }

  getContinuationToken() {
    return this.pages[this.currentPageIndex]?.continuationToken || null;
  }

  onAdvertiserSelect(customer: Customer) {
    this.selectedAdvertiser = customer;
    this.loadProjects();
  }

  onPublisherSelect(customer: Customer) {
    this.selectedPublisher = customer;
    this.loadProjects();
  }

  async onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const continuationToken = this.pages[pageIndex]?.continuationToken;
    this.loadProjects(pageIndex, continuationToken);
    this.currentPageIndex = pageIndex;
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  async getDatasetInfo(group: CustomerDataSetReferenceGroup | undefined) {
    const returnVal: { id?: string; name?: string; status?: string } = {
      id: undefined,
      name: undefined,
      status: undefined,
    };
    if (group) {
      if (group?.parts && group.parts[0] && group.parts[0].id) {
        const dataset = (await this.customerDataService.get(group.parts[0].id))
          .customerDataSet;
        if (dataset) {
          returnVal.id = dataset.id;
          if (
            dataset.state == CustomerDataSet.DatasetState.DATASET_STATE_DRAFT
          ) {
            returnVal.status = 'Draft';
          } else if (
            dataset.state ==
            CustomerDataSet.DatasetState.DATASET_STATE_FINALIZED
          ) {
            returnVal.status = 'Finalized';
          } else {
            returnVal.status = 'Unspecified';
          }
          if (dataset.name) {
            returnVal.name = dataset.name;
            if (group.parts.length > 1) {
              returnVal.name += ` + ${group.parts.length - 1} more`;
            }
          }
        }
      }
    }
    return returnVal;
  }

  async loadProjects(pageIndex = 0, token: string | null = null) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.projectRows.splice(0);
      this.projects = {};

      try {
        const getRequest = this.createGetRequest(token);
        const response = await this.projectService.listProjects(getRequest);
        if (response.projects) {
          for (const project of response.projects) {
            this.projects[project.id] = project;

            let jobState;
            const jobResp = await this.jobLogService.get(
              new GetRequest({
                paginated: new GetPaginatedRequest({
                  numRecords: 10,
                }),
                filter: new GetRequestFilter({
                  projectId: project.id,
                }),
              })
            );
            for (const jobLogWrapper of jobResp.jobLogs!) {
              if (!jobLogWrapper.jobLog) {
                continue;
              }
              const jobLog = jobLogWrapper.jobLog;
              if (jobLog.state == JobState.JOB_STATE_DRAFT) {
                continue;
              }
              jobState = JOB_STATE_TO_STRING[jobLog.state];
              break;
            }

            const publisherData =
              project.customerDataSetReferenceConfig?.groups['publisher'];
            const advertiserData =
              project.customerDataSetReferenceConfig?.groups['advertiser'];

            const publisherDatasetInfo =
              await this.getDatasetInfo(publisherData);
            const advertiserDatasetInfo =
              await this.getDatasetInfo(advertiserData);
            const status = this.getStatus(project, jobState);
            this.projectRows.push({
              id: project.id,
              lastUpdate: this.formatService.formatProtoDateTime(
                project.lastUpdate
              ),
              lastUpdater: project.lastUpdatedEmail,
              binaryType: project.binaryType,
              name: project.name,
              advertiserId: advertiserData?.customerId,
              publisherId: publisherData?.customerId,
              location: this.getLocationName(project.location),
              lookbackWindow: this.formatLookbackWindow(project),
              showLookbackWindow:
                project.binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION,
              matchKeys: project.matchKeys,
              notes: project.notes,
              studyId: project.studyId,
              advertiserDataSetId: this.getDataSetId(publisherData),
              publisherDataSetId: this.getDataSetId(advertiserData),
              publisherStartDate: this.formatService.formatProtoDateForInput(
                publisherData?.startTime
              ),
              publisherEndDate: this.formatService.formatProtoDateForInput(
                publisherData?.endTime
              ),
              advertiserStartDate: this.formatService.formatProtoDateForInput(
                advertiserData?.startTime
              ),
              advertiserEndDate: this.formatService.formatProtoDateForInput(
                advertiserData?.endTime
              ),
              publisherDuration: formatDuration(
                publisherData?.startTime?.toDate(),
                publisherData?.endTime?.toDate()
              ),
              postPeriodDuration: formatDuration(
                publisherData?.endTime?.toDate(),
                advertiserData?.endTime?.toDate()
              ),
              advertiserEventTypes: this.formatAdvertiserEvents(
                project.advertiserEvents ?? []
              ),
              advertiserEventMetrics: this.formatAdvertiserMetrics(
                project.advertiserEvents ?? [],
                project.binaryType
              ),
              publisherDatasetId: publisherDatasetInfo.id,
              advertiserDatasetId: advertiserDatasetInfo.id,
              publisherDatasetName: publisherDatasetInfo.name,
              advertiserDatasetName: advertiserDatasetInfo.name,
              publisherDatasetStatus: publisherDatasetInfo.status,
              advertiserDatasetStatus: advertiserDatasetInfo.status,

              statusMessage: status.message,
              statusColor: status.color,
              jobState: jobState,
            });
          }
          this.updatePaginationData(response, pageIndex);
        }
      } catch (error) {
        this.logger.error('Error loading projects:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  formatLookbackWindow(project: Project): string | undefined {
    if (project.binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION) {
      if (
        !project.lookbackWindow?.clickDays ||
        !project.lookbackWindow?.viewDays
      ) {
        return undefined;
      } else {
        return `${project.lookbackWindow.clickDays}d click / ${project.lookbackWindow.viewDays}d view`;
      }
    }
    return undefined;
  }

  getStatus(
    project: Project,
    jobState?: string
  ): { message: string; color: string } {
    const now = new Date();
    if (
      project.matchKeys!.length == 0 ||
      project.advertiserEvents!.length == 0
    ) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    const publisherData =
      project.customerDataSetReferenceConfig?.groups['publisher'];
    if (!publisherData || !publisherData.startTime || !publisherData.endTime) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    const advertiserData =
      project.customerDataSetReferenceConfig?.groups['advertiser'];
    if (
      !advertiserData ||
      !advertiserData.startTime ||
      !advertiserData.endTime
    ) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    if (now < publisherData.startTime.toDate()) {
      return {
        message: 'Campaign not started',
        color: 'gray',
      };
    }
    if (now < publisherData.endTime.toDate()) {
      return {
        message: 'Campaign running',
        color: 'gray',
      };
    }
    if (publisherData.parts!.filter((part) => part.id != '').length == 0) {
      return {
        message: 'Missing data',
        color: 'yellow',
      };
    }
    if (now < advertiserData.endTime.toDate()) {
      return {
        message: 'Campaign in post-period',
        color: 'gray',
      };
    }
    if (advertiserData.parts!.filter((part) => part.id != '').length == 0) {
      return {
        message: 'Missing data',
        color: 'yellow',
      };
    }
    if (!jobState) {
      return {
        message: 'Ready to measure',
        color: 'yellow',
      };
    }
    if (jobState == 'Pending' || jobState == 'Running') {
      return {
        message: 'Job in progress',
        color: 'yellow',
      };
    }
    if (jobState == 'Failed') {
      return {
        message: 'Most recent job failed',
        color: 'red',
      };
    }
    if (jobState == 'Cancelled') {
      return {
        message: 'Most recent job cancelled',
        color: 'red',
      };
    }
    return {
      message: 'Job complete',
      color: 'green',
    };
  }

  getAdvertiserEventName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  formatAdvertiserEvents(advertiserEvents: AdvertiserEvents[]): string[] {
    return advertiserEvents.map((event) => {
      const value = AdvertiserEventType[event.advertiserEventType];
      return value
        .substring('ADVERTISER_EVENT_TYPE_'.length)
        .toLocaleLowerCase();
    });
  }

  formatAdvertiserMetrics(
    advertiserEvents: AdvertiserEvents[],
    binaryType: BinaryType
  ): string[] {
    const metrics: string[] = [];
    if (advertiserEvents.length > 0) {
      if (binaryType == BinaryType.BINARY_TYPE_LIFT) {
        metrics.push('users');
      } else if (binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION) {
        metrics.push('counts');
      }
    }
    for (const event of advertiserEvents) {
      if (event.includeAmounts) {
        if (!metrics.includes('amount')) {
          metrics.push('amount');
        }
      }
      if (event.includeNumUnits) {
        if (!metrics.includes('num_units')) {
          metrics.push('num_units');
        }
      }
    }
    return metrics;
  }

  getDataSetId(
    group: CustomerDataSetReferenceGroup | undefined
  ): string | undefined {
    if (!group) {
      return undefined;
    }
    if (!group.parts || group.parts.length == 0) {
      return undefined;
    }
    return group.parts[0].id!;
  }

  createGetRequest(token: string | null): ListProjectsRequest {
    const getProjectRequest = new ListProjectsRequest();

    if (token && getProjectRequest.paginated) {
      getProjectRequest.paginated.continuationToken = token;
    }

    getProjectRequest.filter = new ListProjectsFilter();

    if (this.selectedPublisher) {
      getProjectRequest.filter.publisherId = this.selectedPublisher.id;
    }

    if (this.archived) {
      getProjectRequest.filter.archived = this.archived;
    }

    if (this.selectedAdvertiser) {
      getProjectRequest.filter.advertiserId = this.selectedAdvertiser.id;
    }

    return getProjectRequest;
  }

  updatePaginationData(response: ListProjectsResponse, pageIndex: number) {
    if (pageIndex === 0) {
      this.totalRecords =
        response.paginatedResponse?.count || this.projectRows.length;
    }
    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
  }

  newProject() {
    this.router.navigate(['/projects/new']);
  }

  getLocationName(location: Location | undefined) {
    if (!location) {
      return 'Unknown';
    }

    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }

  getCustomerName(customerId: string) {
    return this.customerNameMap.get(customerId);
  }

  edit(projectId: string) {
    this.router.navigate([`projects/${projectId}/edit`]);
  }

  async getCustomerData() {
    const customers = await this.customerService.readAllCustomers();
    this.customerNameMap.clear();
    customers.forEach((customer) =>
      this.customerNameMap.set(customer.id, customer.companyName)
    );
  }

  viewDataSet(dataSetId: string) {
    this.router.navigate([`dataset/${dataSetId}/edit`]);
  }

  viewAllJobs(projectId: string) {
    this.router.navigate(['jobs'], { queryParams: { projectId } });
  }

  async archive(projectId: string) {
    const project = this.projects[projectId];
    if (!project) {
      return;
    }
    if (confirm(`Are you sure you want to delete project ${project.id}`)) {
      project.archived = true;
      await this.projectService.updateProject(project);
      await this.loadProjects();
    }
  }

  async new(projectId: string) {
    const project = this.projects[projectId];
    if (!project) {
      return;
    }
    const publisherData =
      project.customerDataSetReferenceConfig?.groups['publisher'];
    const advertiserData =
      project.customerDataSetReferenceConfig?.groups['advertiser'];
    if (!publisherData || !publisherData.customerId) {
      this.snackBar.open("Can't create a job without publisher.", 'Dismiss', {
        verticalPosition: 'top',
      });
      return;
    }
    if (!advertiserData || !advertiserData.customerId) {
      this.snackBar.open("Can't create a job without advertiser.", 'Dismiss', {
        verticalPosition: 'top',
      });
      return;
    }
    const pubApprovals = await this.approvalService.getApprovedBinaries(
      publisherData.customerId
    );
    const advApprovals = await this.approvalService.getApprovedBinaries(
      advertiserData.customerId
    );
    const advApprovalIds = new Set(
      advApprovals.approvedBinaryChange!.map((x) => x.approvalId)
    );
    let approvalId: string | undefined;
    for (const approval of pubApprovals.approvedBinaryChange!) {
      if (!advApprovalIds.has(approval.approvalId)) {
        continue;
      }
      if (
        !approval.change?.binaryInfo?.binaryType ||
        approval.change.binaryInfo.binaryType != project.binaryType
      ) {
        continue;
      }
      approvalId = approval.approvalId;
      break;
    }
    if (!approvalId) {
      this.snackBar.open('No approved binaries for customers.', 'Dismiss', {
        verticalPosition: 'top',
      });
      return;
    }

    try {
      const createJobResponse = await this.projectService.createJob(
        projectId,
        approvalId
      );
      this.router.navigate([`jobs/${createJobResponse.jobId}/edit`]);
    } catch (error: any) {
      this.snackBar.open(error.statusMessage, 'Dismiss', {
        verticalPosition: 'top',
      });
    }
  }
}
