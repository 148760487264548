<app-container>
  <app-page-header
    title="TikTok App Confirmation"
    lastUpdated="{{ lastUpdate }}"
  ></app-page-header>

  <div *ngIf="isLoadingLongLivedToken" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>

  <mat-card appearance="outlined" class="success-card animate-in">
    <div class="card-header">
      <mat-card-header>
        <mat-icon>check_circle</mat-icon>
        <span>Application Setup Complete</span>
      </mat-card-header>
    </div>

    <mat-card-content>
      <p>
        You have successfully added the TikTok app to your account. Your
        integration is now ready to use.
      </p>
      <p>
        You can revoke access to the TikTok app at any time through the TikTok
        business developer account settings.
      </p>
      <p>Anonym can now access TikTok's audience data through this platform.</p>

      <div class="action-buttons">
        <button mat-flat-button color="primary" routerLink="/">
          <mat-icon>dashboard</mat-icon>
          Login
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <app-message-box></app-message-box>
</app-container>
