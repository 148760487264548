/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as storage002 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage003 from '../../../../src/main/proto/storage/schema-annotations.pb';
export enum AuditAreaApprovalState {
  AUDIT_AREA_APPROVAL_STATE_UNSPECIFIED = 0,
  AUDIT_AREA_APPROVAL_STATE_PENDING = 1,
  AUDIT_AREA_APPROVAL_STATE_APPROVED = 2,
  AUDIT_AREA_APPROVAL_STATE_DISAPPROVED = 3,
}
export enum AuditArea {
  AUDIT_AREA_UNSPECIFIED = 0,
  AUDIT_AREA_EXECUTION_ENVIRONMENT = 1,
  AUDIT_AREA_MEASUREMENT_ALGORITHMS = 2,
  AUDIT_AREA_PRIVACY_ALGORITHMS = 3,
}
export enum BinaryState {
  BINARY_STATE_UNSPECIFIED = 0,
  BINARY_STATE_ACTIVE = 1,
  BINARY_STATE_INACTIVE = 2,
}
export enum AttestationPolicyState {
  ATTESTATION_POLICY_STATE_UNSPECIFIED = 0,
  ATTESTATION_POLICY_STATE_SET = 1,
  ATTESTATION_POLICY_STATE_UNSET = 2,
}
/**
 * Message implementation for storage.ApprovalRequest
 */
export class ApprovalRequest implements GrpcMessage {
  static id = 'storage.ApprovalRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalRequest();
    ApprovalRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalRequest) {
    _instance.id = _instance.id || '';
    _instance.approvalRequestDetail =
      _instance.approvalRequestDetail || undefined;

    _instance.lastUpdate = _instance.lastUpdate || undefined;
    _instance.archived = _instance.archived || false;
    _instance.approvedViewers = _instance.approvedViewers || undefined;
    _instance.binaryStateDecisionHistory =
      _instance.binaryStateDecisionHistory || [];
    _instance.approvalDecisionHistory = _instance.approvalDecisionHistory || [];
    _instance.attestationPolicyHistory =
      _instance.attestationPolicyHistory || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.approvalRequestDetail = new ApprovalRequestDetail();
          _reader.readMessage(
            _instance.approvalRequestDetail,
            ApprovalRequestDetail.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.etag = _reader.readString();
          break;
        case 7:
          _instance.lastUpdate = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.lastUpdate,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 8:
          _instance.archived = _reader.readBool();
          break;
        case 9:
          _instance.approvedViewers = new ApprovedViewers();
          _reader.readMessage(
            _instance.approvedViewers,
            ApprovedViewers.deserializeBinaryFromReader
          );
          break;
        case 10:
          const messageInitializer10 = new BinaryStateDecision();
          _reader.readMessage(
            messageInitializer10,
            BinaryStateDecision.deserializeBinaryFromReader
          );
          (_instance.binaryStateDecisionHistory =
            _instance.binaryStateDecisionHistory || []).push(
            messageInitializer10
          );
          break;
        case 4:
          const messageInitializer4 = new ApprovalDecision();
          _reader.readMessage(
            messageInitializer4,
            ApprovalDecision.deserializeBinaryFromReader
          );
          (_instance.approvalDecisionHistory =
            _instance.approvalDecisionHistory || []).push(messageInitializer4);
          break;
        case 5:
          const messageInitializer5 = new AttestationPolicyUpdate();
          _reader.readMessage(
            messageInitializer5,
            AttestationPolicyUpdate.deserializeBinaryFromReader
          );
          (_instance.attestationPolicyHistory =
            _instance.attestationPolicyHistory || []).push(messageInitializer5);
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.approvalRequestDetail) {
      _writer.writeMessage(
        2,
        _instance.approvalRequestDetail as any,
        ApprovalRequestDetail.serializeBinaryToWriter
      );
    }
    if (_instance.etag !== undefined && _instance.etag !== null) {
      _writer.writeString(6, _instance.etag);
    }
    if (_instance.lastUpdate) {
      _writer.writeMessage(
        7,
        _instance.lastUpdate as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.archived) {
      _writer.writeBool(8, _instance.archived);
    }
    if (_instance.approvedViewers) {
      _writer.writeMessage(
        9,
        _instance.approvedViewers as any,
        ApprovedViewers.serializeBinaryToWriter
      );
    }
    if (
      _instance.binaryStateDecisionHistory &&
      _instance.binaryStateDecisionHistory.length
    ) {
      _writer.writeRepeatedMessage(
        10,
        _instance.binaryStateDecisionHistory as any,
        BinaryStateDecision.serializeBinaryToWriter
      );
    }
    if (
      _instance.approvalDecisionHistory &&
      _instance.approvalDecisionHistory.length
    ) {
      _writer.writeRepeatedMessage(
        4,
        _instance.approvalDecisionHistory as any,
        ApprovalDecision.serializeBinaryToWriter
      );
    }
    if (
      _instance.attestationPolicyHistory &&
      _instance.attestationPolicyHistory.length
    ) {
      _writer.writeRepeatedMessage(
        5,
        _instance.attestationPolicyHistory as any,
        AttestationPolicyUpdate.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _approvalRequestDetail?: ApprovalRequestDetail;
  private _etag?: string;
  private _lastUpdate?: googleProtobuf000.Timestamp;
  private _archived: boolean;
  private _approvedViewers?: ApprovedViewers;
  private _binaryStateDecisionHistory?: BinaryStateDecision[];
  private _approvalDecisionHistory?: ApprovalDecision[];
  private _attestationPolicyHistory?: AttestationPolicyUpdate[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalRequest.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.approvalRequestDetail = _value.approvalRequestDetail
      ? new ApprovalRequestDetail(_value.approvalRequestDetail)
      : undefined;
    this.etag = _value.etag;
    this.lastUpdate = _value.lastUpdate
      ? new googleProtobuf000.Timestamp(_value.lastUpdate)
      : undefined;
    this.archived = _value.archived;
    this.approvedViewers = _value.approvedViewers
      ? new ApprovedViewers(_value.approvedViewers)
      : undefined;
    this.binaryStateDecisionHistory = (
      _value.binaryStateDecisionHistory || []
    ).map((m) => new BinaryStateDecision(m));
    this.approvalDecisionHistory = (_value.approvalDecisionHistory || []).map(
      (m) => new ApprovalDecision(m)
    );
    this.attestationPolicyHistory = (_value.attestationPolicyHistory || []).map(
      (m) => new AttestationPolicyUpdate(m)
    );
    ApprovalRequest.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get approvalRequestDetail(): ApprovalRequestDetail | undefined {
    return this._approvalRequestDetail;
  }
  set approvalRequestDetail(value: ApprovalRequestDetail | undefined) {
    this._approvalRequestDetail = value;
  }
  get etag(): string | undefined {
    return this._etag;
  }
  set etag(value?: string) {
    this._etag = value;
  }
  get lastUpdate(): googleProtobuf000.Timestamp | undefined {
    return this._lastUpdate;
  }
  set lastUpdate(value: googleProtobuf000.Timestamp | undefined) {
    this._lastUpdate = value;
  }
  get archived(): boolean {
    return this._archived;
  }
  set archived(value: boolean) {
    this._archived = value;
  }
  get approvedViewers(): ApprovedViewers | undefined {
    return this._approvedViewers;
  }
  set approvedViewers(value: ApprovedViewers | undefined) {
    this._approvedViewers = value;
  }
  get binaryStateDecisionHistory(): BinaryStateDecision[] | undefined {
    return this._binaryStateDecisionHistory;
  }
  set binaryStateDecisionHistory(value: BinaryStateDecision[] | undefined) {
    this._binaryStateDecisionHistory = value;
  }
  get approvalDecisionHistory(): ApprovalDecision[] | undefined {
    return this._approvalDecisionHistory;
  }
  set approvalDecisionHistory(value: ApprovalDecision[] | undefined) {
    this._approvalDecisionHistory = value;
  }
  get attestationPolicyHistory(): AttestationPolicyUpdate[] | undefined {
    return this._attestationPolicyHistory;
  }
  set attestationPolicyHistory(value: AttestationPolicyUpdate[] | undefined) {
    this._attestationPolicyHistory = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalRequest.AsObject {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toObject()
        : undefined,
      etag: this.etag,
      lastUpdate: this.lastUpdate ? this.lastUpdate.toObject() : undefined,
      archived: this.archived,
      approvedViewers: this.approvedViewers
        ? this.approvedViewers.toObject()
        : undefined,
      binaryStateDecisionHistory: (this.binaryStateDecisionHistory || []).map(
        (m) => m.toObject()
      ),
      approvalDecisionHistory: (this.approvalDecisionHistory || []).map((m) =>
        m.toObject()
      ),
      attestationPolicyHistory: (this.attestationPolicyHistory || []).map((m) =>
        m.toObject()
      ),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalRequest.AsProtobufJSON {
    return {
      id: this.id,
      approvalRequestDetail: this.approvalRequestDetail
        ? this.approvalRequestDetail.toProtobufJSON(options)
        : null,
      etag: this.etag === null || this.etag === undefined ? null : this.etag,
      lastUpdate: this.lastUpdate
        ? this.lastUpdate.toProtobufJSON(options)
        : null,
      archived: this.archived,
      approvedViewers: this.approvedViewers
        ? this.approvedViewers.toProtobufJSON(options)
        : null,
      binaryStateDecisionHistory: (this.binaryStateDecisionHistory || []).map(
        (m) => m.toProtobufJSON(options)
      ),
      approvalDecisionHistory: (this.approvalDecisionHistory || []).map((m) =>
        m.toProtobufJSON(options)
      ),
      attestationPolicyHistory: (this.attestationPolicyHistory || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module ApprovalRequest {
  /**
   * Standard JavaScript object representation for ApprovalRequest
   */
  export interface AsObject {
    id: string;
    approvalRequestDetail?: ApprovalRequestDetail.AsObject;
    etag?: string;
    lastUpdate?: googleProtobuf000.Timestamp.AsObject;
    archived: boolean;
    approvedViewers?: ApprovedViewers.AsObject;
    binaryStateDecisionHistory?: BinaryStateDecision.AsObject[];
    approvalDecisionHistory?: ApprovalDecision.AsObject[];
    attestationPolicyHistory?: AttestationPolicyUpdate.AsObject[];
  }

  /**
   * Protobuf JSON representation for ApprovalRequest
   */
  export interface AsProtobufJSON {
    id: string;
    approvalRequestDetail: ApprovalRequestDetail.AsProtobufJSON | null;
    etag: string | null;
    lastUpdate: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    archived: boolean;
    approvedViewers: ApprovedViewers.AsProtobufJSON | null;
    binaryStateDecisionHistory: BinaryStateDecision.AsProtobufJSON[] | null;
    approvalDecisionHistory: ApprovalDecision.AsProtobufJSON[] | null;
    attestationPolicyHistory: AttestationPolicyUpdate.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for storage.ApprovedViewers
 */
export class ApprovedViewers implements GrpcMessage {
  static id = 'storage.ApprovedViewers';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovedViewers();
    ApprovedViewers.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovedViewers) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovedViewers,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.all = _reader.readBool();
          break;
        case 2:
          _instance.customerIdList = new CustomerIdList();
          _reader.readMessage(
            _instance.customerIdList,
            CustomerIdList.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovedViewers.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovedViewers,
    _writer: BinaryWriter
  ) {
    if (_instance.all || _instance.all === false) {
      _writer.writeBool(1, _instance.all);
    }
    if (_instance.customerIdList) {
      _writer.writeMessage(
        2,
        _instance.customerIdList as any,
        CustomerIdList.serializeBinaryToWriter
      );
    }
  }

  private _all: boolean;
  private _customerIdList?: CustomerIdList;

  private _groups: ApprovedViewers.GroupsCase = ApprovedViewers.GroupsCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovedViewers to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovedViewers.AsObject>) {
    _value = _value || {};
    this.all = _value.all;
    this.customerIdList = _value.customerIdList
      ? new CustomerIdList(_value.customerIdList)
      : undefined;
    ApprovedViewers.refineValues(this);
  }
  get all(): boolean {
    return this._all;
  }
  set all(value: boolean) {
    if (value !== undefined && value !== null) {
      this._customerIdList = undefined;
      this._groups = ApprovedViewers.GroupsCase.all;
    }
    this._all = value;
  }
  get customerIdList(): CustomerIdList | undefined {
    return this._customerIdList;
  }
  set customerIdList(value: CustomerIdList | undefined) {
    if (value !== undefined && value !== null) {
      this._all = undefined;
      this._groups = ApprovedViewers.GroupsCase.customerIdList;
    }
    this._customerIdList = value;
  }
  get groups() {
    return this._groups;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovedViewers.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovedViewers.AsObject {
    return {
      all: this.all,
      customerIdList: this.customerIdList
        ? this.customerIdList.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovedViewers.AsProtobufJSON {
    return {
      all: this.all,
      customerIdList: this.customerIdList
        ? this.customerIdList.toProtobufJSON(options)
        : null,
    };
  }
}
export module ApprovedViewers {
  /**
   * Standard JavaScript object representation for ApprovedViewers
   */
  export interface AsObject {
    all: boolean;
    customerIdList?: CustomerIdList.AsObject;
  }

  /**
   * Protobuf JSON representation for ApprovedViewers
   */
  export interface AsProtobufJSON {
    all: boolean;
    customerIdList: CustomerIdList.AsProtobufJSON | null;
  }
  export enum GroupsCase {
    none = 0,
    all = 1,
    customerIdList = 2,
  }
}

/**
 * Message implementation for storage.CustomerIdList
 */
export class CustomerIdList implements GrpcMessage {
  static id = 'storage.CustomerIdList';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerIdList();
    CustomerIdList.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerIdList) {
    _instance.customerIds = _instance.customerIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerIdList,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.customerIds = _instance.customerIds || []).push(
            _reader.readString()
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerIdList.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerIdList,
    _writer: BinaryWriter
  ) {
    if (_instance.customerIds && _instance.customerIds.length) {
      _writer.writeRepeatedString(1, _instance.customerIds);
    }
  }

  private _customerIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerIdList to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerIdList.AsObject>) {
    _value = _value || {};
    this.customerIds = (_value.customerIds || []).slice();
    CustomerIdList.refineValues(this);
  }
  get customerIds(): string[] {
    return this._customerIds;
  }
  set customerIds(value: string[]) {
    this._customerIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerIdList.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerIdList.AsObject {
    return {
      customerIds: (this.customerIds || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerIdList.AsProtobufJSON {
    return {
      customerIds: (this.customerIds || []).slice(),
    };
  }
}
export module CustomerIdList {
  /**
   * Standard JavaScript object representation for CustomerIdList
   */
  export interface AsObject {
    customerIds: string[];
  }

  /**
   * Protobuf JSON representation for CustomerIdList
   */
  export interface AsProtobufJSON {
    customerIds: string[];
  }
}

/**
 * Message implementation for storage.ApprovalRequestDetail
 */
export class ApprovalRequestDetail implements GrpcMessage {
  static id = 'storage.ApprovalRequestDetail';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalRequestDetail();
    ApprovalRequestDetail.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalRequestDetail) {
    _instance.creationTime = _instance.creationTime || undefined;
    _instance.change = _instance.change || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalRequestDetail,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.creationTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.creationTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.change = new RequestedBinaryChange();
          _reader.readMessage(
            _instance.change,
            RequestedBinaryChange.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalRequestDetail.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalRequestDetail,
    _writer: BinaryWriter
  ) {
    if (_instance.creationTime) {
      _writer.writeMessage(
        2,
        _instance.creationTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.change) {
      _writer.writeMessage(
        3,
        _instance.change as any,
        RequestedBinaryChange.serializeBinaryToWriter
      );
    }
  }

  private _creationTime?: googleProtobuf000.Timestamp;
  private _change?: RequestedBinaryChange;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalRequestDetail to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalRequestDetail.AsObject>) {
    _value = _value || {};
    this.creationTime = _value.creationTime
      ? new googleProtobuf000.Timestamp(_value.creationTime)
      : undefined;
    this.change = _value.change
      ? new RequestedBinaryChange(_value.change)
      : undefined;
    ApprovalRequestDetail.refineValues(this);
  }
  get creationTime(): googleProtobuf000.Timestamp | undefined {
    return this._creationTime;
  }
  set creationTime(value: googleProtobuf000.Timestamp | undefined) {
    this._creationTime = value;
  }
  get change(): RequestedBinaryChange | undefined {
    return this._change;
  }
  set change(value: RequestedBinaryChange | undefined) {
    this._change = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalRequestDetail.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalRequestDetail.AsObject {
    return {
      creationTime: this.creationTime
        ? this.creationTime.toObject()
        : undefined,
      change: this.change ? this.change.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalRequestDetail.AsProtobufJSON {
    return {
      creationTime: this.creationTime
        ? this.creationTime.toProtobufJSON(options)
        : null,
      change: this.change ? this.change.toProtobufJSON(options) : null,
    };
  }
}
export module ApprovalRequestDetail {
  /**
   * Standard JavaScript object representation for ApprovalRequestDetail
   */
  export interface AsObject {
    creationTime?: googleProtobuf000.Timestamp.AsObject;
    change?: RequestedBinaryChange.AsObject;
  }

  /**
   * Protobuf JSON representation for ApprovalRequestDetail
   */
  export interface AsProtobufJSON {
    creationTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    change: RequestedBinaryChange.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.RequestedBinaryChange
 */
export class RequestedBinaryChange implements GrpcMessage {
  static id = 'storage.RequestedBinaryChange';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RequestedBinaryChange();
    RequestedBinaryChange.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RequestedBinaryChange) {
    _instance.binaryInfo = _instance.binaryInfo || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RequestedBinaryChange,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 5:
          _instance.binaryInfo = new BinaryInfo();
          _reader.readMessage(
            _instance.binaryInfo,
            BinaryInfo.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    RequestedBinaryChange.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RequestedBinaryChange,
    _writer: BinaryWriter
  ) {
    if (_instance.binaryInfo) {
      _writer.writeMessage(
        5,
        _instance.binaryInfo as any,
        BinaryInfo.serializeBinaryToWriter
      );
    }
  }

  private _binaryInfo?: BinaryInfo;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RequestedBinaryChange to deeply clone from
   */
  constructor(_value?: RecursivePartial<RequestedBinaryChange.AsObject>) {
    _value = _value || {};
    this.binaryInfo = _value.binaryInfo
      ? new BinaryInfo(_value.binaryInfo)
      : undefined;
    RequestedBinaryChange.refineValues(this);
  }
  get binaryInfo(): BinaryInfo | undefined {
    return this._binaryInfo;
  }
  set binaryInfo(value: BinaryInfo | undefined) {
    this._binaryInfo = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RequestedBinaryChange.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RequestedBinaryChange.AsObject {
    return {
      binaryInfo: this.binaryInfo ? this.binaryInfo.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RequestedBinaryChange.AsProtobufJSON {
    return {
      binaryInfo: this.binaryInfo
        ? this.binaryInfo.toProtobufJSON(options)
        : null,
    };
  }
}
export module RequestedBinaryChange {
  /**
   * Standard JavaScript object representation for RequestedBinaryChange
   */
  export interface AsObject {
    binaryInfo?: BinaryInfo.AsObject;
  }

  /**
   * Protobuf JSON representation for RequestedBinaryChange
   */
  export interface AsProtobufJSON {
    binaryInfo: BinaryInfo.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.AttributionJoinBinaryInfo
 */
export class AttributionJoinBinaryInfo implements GrpcMessage {
  static id = 'storage.AttributionJoinBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AttributionJoinBinaryInfo();
    AttributionJoinBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AttributionJoinBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AttributionJoinBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    AttributionJoinBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AttributionJoinBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AttributionJoinBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<AttributionJoinBinaryInfo.AsObject>) {
    _value = _value || {};
    AttributionJoinBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AttributionJoinBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AttributionJoinBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AttributionJoinBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module AttributionJoinBinaryInfo {
  /**
   * Standard JavaScript object representation for AttributionJoinBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for AttributionJoinBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.DentsuPrototypeBinaryInfo
 */
export class DentsuPrototypeBinaryInfo implements GrpcMessage {
  static id = 'storage.DentsuPrototypeBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new DentsuPrototypeBinaryInfo();
    DentsuPrototypeBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: DentsuPrototypeBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: DentsuPrototypeBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    DentsuPrototypeBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: DentsuPrototypeBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of DentsuPrototypeBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<DentsuPrototypeBinaryInfo.AsObject>) {
    _value = _value || {};
    DentsuPrototypeBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    DentsuPrototypeBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): DentsuPrototypeBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): DentsuPrototypeBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module DentsuPrototypeBinaryInfo {
  /**
   * Standard JavaScript object representation for DentsuPrototypeBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for DentsuPrototypeBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.EnclaveEncryptionBinaryInfo
 */
export class EnclaveEncryptionBinaryInfo implements GrpcMessage {
  static id = 'storage.EnclaveEncryptionBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new EnclaveEncryptionBinaryInfo();
    EnclaveEncryptionBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: EnclaveEncryptionBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: EnclaveEncryptionBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    EnclaveEncryptionBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: EnclaveEncryptionBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of EnclaveEncryptionBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<EnclaveEncryptionBinaryInfo.AsObject>) {
    _value = _value || {};
    EnclaveEncryptionBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    EnclaveEncryptionBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): EnclaveEncryptionBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): EnclaveEncryptionBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module EnclaveEncryptionBinaryInfo {
  /**
   * Standard JavaScript object representation for EnclaveEncryptionBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for EnclaveEncryptionBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.TrainingBinaryInfo
 */
export class TrainingBinaryInfo implements GrpcMessage {
  static id = 'storage.TrainingBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TrainingBinaryInfo();
    TrainingBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TrainingBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TrainingBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    TrainingBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TrainingBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TrainingBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<TrainingBinaryInfo.AsObject>) {
    _value = _value || {};
    TrainingBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TrainingBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TrainingBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TrainingBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module TrainingBinaryInfo {
  /**
   * Standard JavaScript object representation for TrainingBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for TrainingBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.LiftBinaryInfo
 */
export class LiftBinaryInfo implements GrpcMessage {
  static id = 'storage.LiftBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LiftBinaryInfo();
    LiftBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LiftBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LiftBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LiftBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LiftBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LiftBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<LiftBinaryInfo.AsObject>) {
    _value = _value || {};
    LiftBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LiftBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LiftBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LiftBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module LiftBinaryInfo {
  /**
   * Standard JavaScript object representation for LiftBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LiftBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.LiftDaskBinaryInfo
 */
export class LiftDaskBinaryInfo implements GrpcMessage {
  static id = 'storage.LiftDaskBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LiftDaskBinaryInfo();
    LiftDaskBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LiftDaskBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LiftDaskBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    LiftDaskBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LiftDaskBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LiftDaskBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<LiftDaskBinaryInfo.AsObject>) {
    _value = _value || {};
    LiftDaskBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LiftDaskBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LiftDaskBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LiftDaskBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module LiftDaskBinaryInfo {
  /**
   * Standard JavaScript object representation for LiftDaskBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for LiftDaskBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.RedditAttributionBinaryInfo
 */
export class RedditAttributionBinaryInfo implements GrpcMessage {
  static id = 'storage.RedditAttributionBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RedditAttributionBinaryInfo();
    RedditAttributionBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RedditAttributionBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RedditAttributionBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    RedditAttributionBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RedditAttributionBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RedditAttributionBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<RedditAttributionBinaryInfo.AsObject>) {
    _value = _value || {};
    RedditAttributionBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RedditAttributionBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RedditAttributionBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RedditAttributionBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module RedditAttributionBinaryInfo {
  /**
   * Standard JavaScript object representation for RedditAttributionBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for RedditAttributionBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.RedditLiftBinaryInfo
 */
export class RedditLiftBinaryInfo implements GrpcMessage {
  static id = 'storage.RedditLiftBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RedditLiftBinaryInfo();
    RedditLiftBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RedditLiftBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: RedditLiftBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    RedditLiftBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: RedditLiftBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RedditLiftBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<RedditLiftBinaryInfo.AsObject>) {
    _value = _value || {};
    RedditLiftBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RedditLiftBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RedditLiftBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): RedditLiftBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module RedditLiftBinaryInfo {
  /**
   * Standard JavaScript object representation for RedditLiftBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for RedditLiftBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.TiktokTargetingBinaryInfo
 */
export class TiktokTargetingBinaryInfo implements GrpcMessage {
  static id = 'storage.TiktokTargetingBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TiktokTargetingBinaryInfo();
    TiktokTargetingBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TiktokTargetingBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TiktokTargetingBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    TiktokTargetingBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TiktokTargetingBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TiktokTargetingBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<TiktokTargetingBinaryInfo.AsObject>) {
    _value = _value || {};
    TiktokTargetingBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TiktokTargetingBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TiktokTargetingBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TiktokTargetingBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module TiktokTargetingBinaryInfo {
  /**
   * Standard JavaScript object representation for TiktokTargetingBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for TiktokTargetingBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.ValidationBinaryInfo
 */
export class ValidationBinaryInfo implements GrpcMessage {
  static id = 'storage.ValidationBinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ValidationBinaryInfo();
    ValidationBinaryInfo.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ValidationBinaryInfo) {}

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ValidationBinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        default:
          _reader.skipField();
      }
    }

    ValidationBinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ValidationBinaryInfo,
    _writer: BinaryWriter
  ) {}

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ValidationBinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<ValidationBinaryInfo.AsObject>) {
    _value = _value || {};
    ValidationBinaryInfo.refineValues(this);
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ValidationBinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ValidationBinaryInfo.AsObject {
    return {};
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ValidationBinaryInfo.AsProtobufJSON {
    return {};
  }
}
export module ValidationBinaryInfo {
  /**
   * Standard JavaScript object representation for ValidationBinaryInfo
   */
  export interface AsObject {}

  /**
   * Protobuf JSON representation for ValidationBinaryInfo
   */
  export interface AsProtobufJSON {}
}

/**
 * Message implementation for storage.BinaryInfo
 */
export class BinaryInfo implements GrpcMessage {
  static id = 'storage.BinaryInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryInfo();
    BinaryInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryInfo) {
    _instance.name = _instance.name || '';
    _instance.target = _instance.target || '';
    _instance.mrEnclave = _instance.mrEnclave || '';
    _instance.mrSigner = _instance.mrSigner || '';
    _instance.commitId = _instance.commitId || '';
    _instance.version = _instance.version || '';
    _instance.imageRepositoryPath = _instance.imageRepositoryPath || '';
    _instance.binaryType = _instance.binaryType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryInfo,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.name = _reader.readString();
          break;
        case 2:
          _instance.target = _reader.readString();
          break;
        case 3:
          _instance.mrEnclave = _reader.readString();
          break;
        case 4:
          _instance.mrSigner = _reader.readString();
          break;
        case 5:
          _instance.commitId = _reader.readString();
          break;
        case 6:
          _instance.version = _reader.readString();
          break;
        case 7:
          _instance.imageRepositoryPath = _reader.readString();
          break;
        case 8:
          _instance.binaryType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: BinaryInfo, _writer: BinaryWriter) {
    if (_instance.name) {
      _writer.writeString(1, _instance.name);
    }
    if (_instance.target) {
      _writer.writeString(2, _instance.target);
    }
    if (_instance.mrEnclave) {
      _writer.writeString(3, _instance.mrEnclave);
    }
    if (_instance.mrSigner) {
      _writer.writeString(4, _instance.mrSigner);
    }
    if (_instance.commitId) {
      _writer.writeString(5, _instance.commitId);
    }
    if (_instance.version) {
      _writer.writeString(6, _instance.version);
    }
    if (_instance.imageRepositoryPath) {
      _writer.writeString(7, _instance.imageRepositoryPath);
    }
    if (_instance.binaryType) {
      _writer.writeEnum(8, _instance.binaryType);
    }
  }

  private _name: string;
  private _target: string;
  private _mrEnclave: string;
  private _mrSigner: string;
  private _commitId: string;
  private _version: string;
  private _imageRepositoryPath: string;
  private _binaryType: storage002.BinaryType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryInfo.AsObject>) {
    _value = _value || {};
    this.name = _value.name;
    this.target = _value.target;
    this.mrEnclave = _value.mrEnclave;
    this.mrSigner = _value.mrSigner;
    this.commitId = _value.commitId;
    this.version = _value.version;
    this.imageRepositoryPath = _value.imageRepositoryPath;
    this.binaryType = _value.binaryType;
    BinaryInfo.refineValues(this);
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get target(): string {
    return this._target;
  }
  set target(value: string) {
    this._target = value;
  }
  get mrEnclave(): string {
    return this._mrEnclave;
  }
  set mrEnclave(value: string) {
    this._mrEnclave = value;
  }
  get mrSigner(): string {
    return this._mrSigner;
  }
  set mrSigner(value: string) {
    this._mrSigner = value;
  }
  get commitId(): string {
    return this._commitId;
  }
  set commitId(value: string) {
    this._commitId = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get imageRepositoryPath(): string {
    return this._imageRepositoryPath;
  }
  set imageRepositoryPath(value: string) {
    this._imageRepositoryPath = value;
  }
  get binaryType(): storage002.BinaryType {
    return this._binaryType;
  }
  set binaryType(value: storage002.BinaryType) {
    this._binaryType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryInfo.AsObject {
    return {
      name: this.name,
      target: this.target,
      mrEnclave: this.mrEnclave,
      mrSigner: this.mrSigner,
      commitId: this.commitId,
      version: this.version,
      imageRepositoryPath: this.imageRepositoryPath,
      binaryType: this.binaryType,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryInfo.AsProtobufJSON {
    return {
      name: this.name,
      target: this.target,
      mrEnclave: this.mrEnclave,
      mrSigner: this.mrSigner,
      commitId: this.commitId,
      version: this.version,
      imageRepositoryPath: this.imageRepositoryPath,
      binaryType:
        storage002.BinaryType[
          this.binaryType === null || this.binaryType === undefined
            ? 0
            : this.binaryType
        ],
    };
  }
}
export module BinaryInfo {
  /**
   * Standard JavaScript object representation for BinaryInfo
   */
  export interface AsObject {
    name: string;
    target: string;
    mrEnclave: string;
    mrSigner: string;
    commitId: string;
    version: string;
    imageRepositoryPath: string;
    binaryType: storage002.BinaryType;
  }

  /**
   * Protobuf JSON representation for BinaryInfo
   */
  export interface AsProtobufJSON {
    name: string;
    target: string;
    mrEnclave: string;
    mrSigner: string;
    commitId: string;
    version: string;
    imageRepositoryPath: string;
    binaryType: string;
  }
}

/**
 * Message implementation for storage.ApprovalDecision
 */
export class ApprovalDecision implements GrpcMessage {
  static id = 'storage.ApprovalDecision';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalDecision();
    ApprovalDecision.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalDecision) {
    _instance.auditAreaApprovalState = _instance.auditAreaApprovalState || 0;
    _instance.approverEmail = _instance.approverEmail || '';
    _instance.customerId = _instance.customerId || '';
    _instance.reason = _instance.reason || undefined;
    _instance.time = _instance.time || undefined;
    _instance.auditArea = _instance.auditArea || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalDecision,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.auditAreaApprovalState = _reader.readEnum();
          break;
        case 2:
          _instance.approverEmail = _reader.readString();
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 4:
          _instance.reason = new ApprovalDecisionReason();
          _reader.readMessage(
            _instance.reason,
            ApprovalDecisionReason.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.auditArea = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalDecision.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalDecision,
    _writer: BinaryWriter
  ) {
    if (_instance.auditAreaApprovalState) {
      _writer.writeEnum(1, _instance.auditAreaApprovalState);
    }
    if (_instance.approverEmail) {
      _writer.writeString(2, _instance.approverEmail);
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.reason) {
      _writer.writeMessage(
        4,
        _instance.reason as any,
        ApprovalDecisionReason.serializeBinaryToWriter
      );
    }
    if (_instance.time) {
      _writer.writeMessage(
        5,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.auditArea) {
      _writer.writeEnum(6, _instance.auditArea);
    }
  }

  private _auditAreaApprovalState: AuditAreaApprovalState;
  private _approverEmail: string;
  private _customerId: string;
  private _reason?: ApprovalDecisionReason;
  private _time?: googleProtobuf000.Timestamp;
  private _auditArea: AuditArea;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalDecision to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalDecision.AsObject>) {
    _value = _value || {};
    this.auditAreaApprovalState = _value.auditAreaApprovalState;
    this.approverEmail = _value.approverEmail;
    this.customerId = _value.customerId;
    this.reason = _value.reason
      ? new ApprovalDecisionReason(_value.reason)
      : undefined;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    this.auditArea = _value.auditArea;
    ApprovalDecision.refineValues(this);
  }
  get auditAreaApprovalState(): AuditAreaApprovalState {
    return this._auditAreaApprovalState;
  }
  set auditAreaApprovalState(value: AuditAreaApprovalState) {
    this._auditAreaApprovalState = value;
  }
  get approverEmail(): string {
    return this._approverEmail;
  }
  set approverEmail(value: string) {
    this._approverEmail = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get reason(): ApprovalDecisionReason | undefined {
    return this._reason;
  }
  set reason(value: ApprovalDecisionReason | undefined) {
    this._reason = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }
  get auditArea(): AuditArea {
    return this._auditArea;
  }
  set auditArea(value: AuditArea) {
    this._auditArea = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalDecision.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalDecision.AsObject {
    return {
      auditAreaApprovalState: this.auditAreaApprovalState,
      approverEmail: this.approverEmail,
      customerId: this.customerId,
      reason: this.reason ? this.reason.toObject() : undefined,
      time: this.time ? this.time.toObject() : undefined,
      auditArea: this.auditArea,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalDecision.AsProtobufJSON {
    return {
      auditAreaApprovalState:
        AuditAreaApprovalState[
          this.auditAreaApprovalState === null ||
          this.auditAreaApprovalState === undefined
            ? 0
            : this.auditAreaApprovalState
        ],
      approverEmail: this.approverEmail,
      customerId: this.customerId,
      reason: this.reason ? this.reason.toProtobufJSON(options) : null,
      time: this.time ? this.time.toProtobufJSON(options) : null,
      auditArea:
        AuditArea[
          this.auditArea === null || this.auditArea === undefined
            ? 0
            : this.auditArea
        ],
    };
  }
}
export module ApprovalDecision {
  /**
   * Standard JavaScript object representation for ApprovalDecision
   */
  export interface AsObject {
    auditAreaApprovalState: AuditAreaApprovalState;
    approverEmail: string;
    customerId: string;
    reason?: ApprovalDecisionReason.AsObject;
    time?: googleProtobuf000.Timestamp.AsObject;
    auditArea: AuditArea;
  }

  /**
   * Protobuf JSON representation for ApprovalDecision
   */
  export interface AsProtobufJSON {
    auditAreaApprovalState: string;
    approverEmail: string;
    customerId: string;
    reason: ApprovalDecisionReason.AsProtobufJSON | null;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    auditArea: string;
  }
}

/**
 * Message implementation for storage.ApprovalDecisionReason
 */
export class ApprovalDecisionReason implements GrpcMessage {
  static id = 'storage.ApprovalDecisionReason';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ApprovalDecisionReason();
    ApprovalDecisionReason.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ApprovalDecisionReason) {
    _instance.note = _instance.note || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ApprovalDecisionReason,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.note = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ApprovalDecisionReason.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ApprovalDecisionReason,
    _writer: BinaryWriter
  ) {
    if (_instance.note) {
      _writer.writeString(1, _instance.note);
    }
  }

  private _note: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ApprovalDecisionReason to deeply clone from
   */
  constructor(_value?: RecursivePartial<ApprovalDecisionReason.AsObject>) {
    _value = _value || {};
    this.note = _value.note;
    ApprovalDecisionReason.refineValues(this);
  }
  get note(): string {
    return this._note;
  }
  set note(value: string) {
    this._note = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ApprovalDecisionReason.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ApprovalDecisionReason.AsObject {
    return {
      note: this.note,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ApprovalDecisionReason.AsProtobufJSON {
    return {
      note: this.note,
    };
  }
}
export module ApprovalDecisionReason {
  /**
   * Standard JavaScript object representation for ApprovalDecisionReason
   */
  export interface AsObject {
    note: string;
  }

  /**
   * Protobuf JSON representation for ApprovalDecisionReason
   */
  export interface AsProtobufJSON {
    note: string;
  }
}

/**
 * Message implementation for storage.BinaryStateDecision
 */
export class BinaryStateDecision implements GrpcMessage {
  static id = 'storage.BinaryStateDecision';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryStateDecision();
    BinaryStateDecision.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryStateDecision) {
    _instance.binaryState = _instance.binaryState || 0;
    _instance.approverEmail = _instance.approverEmail || '';
    _instance.customerId = _instance.customerId || '';
    _instance.reason = _instance.reason || undefined;
    _instance.time = _instance.time || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryStateDecision,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.binaryState = _reader.readEnum();
          break;
        case 2:
          _instance.approverEmail = _reader.readString();
          break;
        case 3:
          _instance.customerId = _reader.readString();
          break;
        case 4:
          _instance.reason = new BinaryStateDecisionReason();
          _reader.readMessage(
            _instance.reason,
            BinaryStateDecisionReason.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryStateDecision.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryStateDecision,
    _writer: BinaryWriter
  ) {
    if (_instance.binaryState) {
      _writer.writeEnum(1, _instance.binaryState);
    }
    if (_instance.approverEmail) {
      _writer.writeString(2, _instance.approverEmail);
    }
    if (_instance.customerId) {
      _writer.writeString(3, _instance.customerId);
    }
    if (_instance.reason) {
      _writer.writeMessage(
        4,
        _instance.reason as any,
        BinaryStateDecisionReason.serializeBinaryToWriter
      );
    }
    if (_instance.time) {
      _writer.writeMessage(
        5,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _binaryState: BinaryState;
  private _approverEmail: string;
  private _customerId: string;
  private _reason?: BinaryStateDecisionReason;
  private _time?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryStateDecision to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryStateDecision.AsObject>) {
    _value = _value || {};
    this.binaryState = _value.binaryState;
    this.approverEmail = _value.approverEmail;
    this.customerId = _value.customerId;
    this.reason = _value.reason
      ? new BinaryStateDecisionReason(_value.reason)
      : undefined;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    BinaryStateDecision.refineValues(this);
  }
  get binaryState(): BinaryState {
    return this._binaryState;
  }
  set binaryState(value: BinaryState) {
    this._binaryState = value;
  }
  get approverEmail(): string {
    return this._approverEmail;
  }
  set approverEmail(value: string) {
    this._approverEmail = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get reason(): BinaryStateDecisionReason | undefined {
    return this._reason;
  }
  set reason(value: BinaryStateDecisionReason | undefined) {
    this._reason = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryStateDecision.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryStateDecision.AsObject {
    return {
      binaryState: this.binaryState,
      approverEmail: this.approverEmail,
      customerId: this.customerId,
      reason: this.reason ? this.reason.toObject() : undefined,
      time: this.time ? this.time.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryStateDecision.AsProtobufJSON {
    return {
      binaryState:
        BinaryState[
          this.binaryState === null || this.binaryState === undefined
            ? 0
            : this.binaryState
        ],
      approverEmail: this.approverEmail,
      customerId: this.customerId,
      reason: this.reason ? this.reason.toProtobufJSON(options) : null,
      time: this.time ? this.time.toProtobufJSON(options) : null,
    };
  }
}
export module BinaryStateDecision {
  /**
   * Standard JavaScript object representation for BinaryStateDecision
   */
  export interface AsObject {
    binaryState: BinaryState;
    approverEmail: string;
    customerId: string;
    reason?: BinaryStateDecisionReason.AsObject;
    time?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for BinaryStateDecision
   */
  export interface AsProtobufJSON {
    binaryState: string;
    approverEmail: string;
    customerId: string;
    reason: BinaryStateDecisionReason.AsProtobufJSON | null;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.BinaryStateDecisionReason
 */
export class BinaryStateDecisionReason implements GrpcMessage {
  static id = 'storage.BinaryStateDecisionReason';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new BinaryStateDecisionReason();
    BinaryStateDecisionReason.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: BinaryStateDecisionReason) {
    _instance.note = _instance.note || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: BinaryStateDecisionReason,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.note = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    BinaryStateDecisionReason.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: BinaryStateDecisionReason,
    _writer: BinaryWriter
  ) {
    if (_instance.note) {
      _writer.writeString(1, _instance.note);
    }
  }

  private _note: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of BinaryStateDecisionReason to deeply clone from
   */
  constructor(_value?: RecursivePartial<BinaryStateDecisionReason.AsObject>) {
    _value = _value || {};
    this.note = _value.note;
    BinaryStateDecisionReason.refineValues(this);
  }
  get note(): string {
    return this._note;
  }
  set note(value: string) {
    this._note = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    BinaryStateDecisionReason.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): BinaryStateDecisionReason.AsObject {
    return {
      note: this.note,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): BinaryStateDecisionReason.AsProtobufJSON {
    return {
      note: this.note,
    };
  }
}
export module BinaryStateDecisionReason {
  /**
   * Standard JavaScript object representation for BinaryStateDecisionReason
   */
  export interface AsObject {
    note: string;
  }

  /**
   * Protobuf JSON representation for BinaryStateDecisionReason
   */
  export interface AsProtobufJSON {
    note: string;
  }
}

/**
 * Message implementation for storage.AttestationPolicyUpdate
 */
export class AttestationPolicyUpdate implements GrpcMessage {
  static id = 'storage.AttestationPolicyUpdate';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AttestationPolicyUpdate();
    AttestationPolicyUpdate.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AttestationPolicyUpdate) {
    _instance.state = _instance.state || 0;
    _instance.customerId = _instance.customerId || '';
    _instance.time = _instance.time || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AttestationPolicyUpdate,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.state = _reader.readEnum();
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.time = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.time,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AttestationPolicyUpdate.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AttestationPolicyUpdate,
    _writer: BinaryWriter
  ) {
    if (_instance.state) {
      _writer.writeEnum(1, _instance.state);
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.time) {
      _writer.writeMessage(
        3,
        _instance.time as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _state: AttestationPolicyState;
  private _customerId: string;
  private _time?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AttestationPolicyUpdate to deeply clone from
   */
  constructor(_value?: RecursivePartial<AttestationPolicyUpdate.AsObject>) {
    _value = _value || {};
    this.state = _value.state;
    this.customerId = _value.customerId;
    this.time = _value.time
      ? new googleProtobuf000.Timestamp(_value.time)
      : undefined;
    AttestationPolicyUpdate.refineValues(this);
  }
  get state(): AttestationPolicyState {
    return this._state;
  }
  set state(value: AttestationPolicyState) {
    this._state = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get time(): googleProtobuf000.Timestamp | undefined {
    return this._time;
  }
  set time(value: googleProtobuf000.Timestamp | undefined) {
    this._time = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AttestationPolicyUpdate.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AttestationPolicyUpdate.AsObject {
    return {
      state: this.state,
      customerId: this.customerId,
      time: this.time ? this.time.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AttestationPolicyUpdate.AsProtobufJSON {
    return {
      state:
        AttestationPolicyState[
          this.state === null || this.state === undefined ? 0 : this.state
        ],
      customerId: this.customerId,
      time: this.time ? this.time.toProtobufJSON(options) : null,
    };
  }
}
export module AttestationPolicyUpdate {
  /**
   * Standard JavaScript object representation for AttestationPolicyUpdate
   */
  export interface AsObject {
    state: AttestationPolicyState;
    customerId: string;
    time?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for AttestationPolicyUpdate
   */
  export interface AsProtobufJSON {
    state: string;
    customerId: string;
    time: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
