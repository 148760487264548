import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { LoggerService } from '../../services/logger.service';

@Component({
  selector: 'app-report-output-modal',
  templateUrl: './report-output-modal.component.html',
  styleUrls: [
    './report-output-modal.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class ReportOutputModalComponent {
  isLoading = false;
  title = 'Report created';

  constructor(
    private clipboard: Clipboard,
    private dialogRef: MatDialogRef<ReportOutputModalComponent>,
    private logger: LoggerService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      link: string | undefined;
      sheetTitle: string | undefined;
      recipients: string | undefined;
      success: boolean | undefined;
      error: string | undefined;
    }
  ) {
    if (this.data.success) {
      this.title = `Report created`;
    } else {
      this.title = `Report creation failed`;
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public copyToClipboard(value: string | undefined) {
    if (value) {
      this.clipboard.copy(value);
    }
  }
}
