<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="customerForm"
      id="customerForm"
    >
      <div class="field-row">
        <div class="field-types-container">
          <label class="field-title">Customer Name</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Customer's Name"
              formControlName="customerName"
              required
              size="40"
              [ngClass]="{
                'app-input-error': checkError('customerName', 'required'),
              }"
            />
          </div>
          <mat-error *ngIf="checkError('customerName', 'required')"
            >Customer name is required.</mat-error
          >
        </div>
      </div>

      <div class="field-row">
        <div class="field-domain-container">
          <label class="field-title">Customer Domains</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="Customer's Domain 1, Customer's Domain 2"
              formControlName="domainNames"
              required
              size="40"
              [ngClass]="{
                'app-input-error': checkError('domainNames', 'required'),
              }"
            />
          </div>
          <mat-error *ngIf="checkError('domainNames', 'required')"
            >Customer domain is required.</mat-error
          >
        </div>
      </div>

      <div class="field-row">
        <div class="field-types-container">
          <label class="field-title">Customer Type</label>
          <div class="field-types-checkboxes">
            <div *ngFor="let type of customerTypes">
              <label class="customer-type">
                <input
                  type="checkbox"
                  formArrayName="customerTypes"
                  [value]="type.value"
                  [checked]="type.selected"
                  (change)="onCheckboxChange($event, 'customerTypes')"
                />
                {{ type.name }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-types-container">
          <label class="field-title">Binary Type</label>
          <div class="field-types-checkboxes">
            <div *ngFor="let type of binaryTypeInfos">
              <label class="customer-type">
                <input
                  type="checkbox"
                  formArrayName="binaryTypes"
                  [value]="type.value"
                  [checked]="type.selected"
                  (change)="onCheckboxChange($event, 'binaryTypes')"
                />
                {{ type.name }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-types-container">
          <p class="field-title">Locations</p>
          <div class="field-types-checkboxes">
            <div *ngFor="let location of locations">
              <label class="customer-type">
                <input
                  type="checkbox"
                  formArrayName="locations"
                  [value]="location.value"
                  [checked]="location.selected"
                  (change)="onCheckboxChange($event, 'locations')"
                />
                {{ location.name }}
              </label>
            </div>
          </div>
          <mat-error *ngIf="checkLocations()"
            >At least one location is required.</mat-error
          >
        </div>
      </div>

      <div class="field-row">
        <div class="field-types-container">
          <label class="field-title">Customer Disabled</label>
          <div>
            <input
              type="checkbox"
              name="binaryTypes"
              formControlName="disabled"
            />
          </div>
        </div>
        <div class="field-types-container">
          <label class="field-title">Enable web upload data formatting</label>
          <div>
            <input
              type="checkbox"
              name="webUploadCsvFormattingEnabled"
              formControlName="webUploadCsvFormattingEnabled"
            />
          </div>
        </div>
        <div
          class="field-contact-container"
          *ngIf="!(update && !data?.isManagedCustomer)"
        >
          <label class="field-title">Review Mode</label>
          <div>
            <input
              type="checkbox"
              name="reviewMode"
              [disabled]="update"
              formControlName="isInReviewMode"
            />
          </div>
        </div>
      </div>
      <div class="field-row">
        <div class="field-contact-container">
          <label class="field-title">Allow Username/Password sign-in</label>
          <div>
            <input
              type="checkbox"
              name="signInAllowed"
              formControlName="signInAllowed"
            />
          </div>
        </div>
        <div class="field-contact-container">
          <label class="field-title">Enable SAML authentication</label>
          <div>
            <input
              type="checkbox"
              name="enableSamlConfiguration"
              formControlName="enableSamlConfiguration"
              (click)="enableSamlConfiguration()"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="saml-component">
    <app-saml
      *ngIf="displaySamlConfiguration"
      [samlProviderConfig]="samlProviderConfig"
      [tenantId]="tenantId"
      (samlProviderConfigEvent)="updateSamlProviderConfig($event)"
    ></app-saml>
  </div>

  <app-message-box></app-message-box>
  <div class="save-btn" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading || !customerForm.valid"
      form="customerForm"
      type="submit"
      data-testid="saveBtn"
    >
      {{ isLoading ? null : 'Save' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
</div>
