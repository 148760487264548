<app-container>
  <app-page-header title="Job Manager" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <div *ngIf="isLoading" class="loader">
    <mat-spinner [diameter]="dataSource.data.length ? 24 : 42"></mat-spinner>
  </div>

  <div class="customer-dropdown">
    <app-customer-dropdown
      [showArchiveToggle]="true"
      [showSearchBox]="true"
      [selectedCustomer]="selectedCustomer"
      (selectCustomerEvent)="onCustomerSelect($event)"
    ></app-customer-dropdown>
  </div>
  <div class="filter-block">
    <div class="binary-type-filter">
      <app-binary-type-dropdown
        [selectedBinaryType]="selectedBinaryType"
        [displayBinaryDescription]="false"
        [placeholderText]="'All Binary Types'"
        (selectBinaryTypeEvent)="onBinaryTypeSelect($event)"
      ></app-binary-type-dropdown>
    </div>
    <div class="binary-type-filter">
      <div class="field-title">Project Id</div>
      <div class="input-position">
        <mat-form-field appearance="outline" class="project-id-filter">
          <input
            type="text"
            matInput
            (input)="onProjectIdSearch($event)"
            placeholder="Search by project Id"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="location-filter">
    <app-location-dropdown
      *ngIf="allowedCustomerLocations"
      [allowedLocations]="allowedCustomerLocations"
      [selectedLocation]="selectedLocation"
      (selectLocationEvent)="onLocationSelect($event)"
    ></app-location-dropdown>
  </div>

  <section class="data-table-container" tabindex="0">
    <div class="data-table-title-container">
      <h2 class="data-users-title">Jobs</h2>
      <button
        data-testid="create"
        class="add-user-button"
        (click)="createJob()"
      >
        Create New Job
      </button>
    </div>

    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <table
      mat-table
      data-testid="datasetTable"
      [dataSource]="dataSource"
      class="table-data"
      matSort
    >
      <ng-container matColumnDef="id">
        <th class="row-header" mat-header-cell *matHeaderCellDef>id</th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <a class="dataset-id" (click)="editJob(jobRow.id, jobRow.state)">{{
              jobRow.id
            }}</a>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by description"
        >
          Description
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.description }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by state"
        >
          State
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="col-state">
            <span>
              <span
                *ngIf="jobRow.state === 'Failed'"
                class="circle failed"
              ></span>
              <span
                *ngIf="jobRow.state === 'Completed'"
                class="circle completed"
              ></span>
              <span
                *ngIf="jobRow.state === 'Draft'"
                class="circle draft"
              ></span>
              <span
                *ngIf="jobRow.state === 'Running'"
                class="circle running"
              ></span>
              <span
                *ngIf="jobRow.state === 'Pending'"
                class="circle pending"
              ></span>
              <span
                *ngIf="jobRow.state === 'Cancelled'"
                class="circle canceling"
              ></span>
              {{ jobRow.state }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="binaryType">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by binaryType"
        >
          Binary Type
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.binaryType }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="customers">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by customers"
        >
          Customers
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.customers }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="creationTimestamp">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by job creation time"
        >
          Creation time
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.creationTimestamp * 1000 | date: 'medium' }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="startTime">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by job start time"
        >
          Kickoff time
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.startTime * 1000 | date: 'medium' }}</span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="completeTime">
        <th
          class="row-header"
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          sortActionDescription="Sort by job completion time"
        >
          Completion time
        </th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <span>{{ jobRow.completeTime * 1000 | date: 'medium' }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th class="row-header" mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let jobRow">
          <div class="user-name-col">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu>
              <button mat-menu-item (click)="copyJob(jobRow.id)">
                <mat-icon>content_copy</mat-icon>
                <span>Copy job</span>
              </button>
              <button
                mat-menu-item
                (click)="cancelJob(jobRow.id)"
                *ngIf="canCancelJob(jobRow.state)"
              >
                <mat-icon>cancel</mat-icon>
                <span>Cancel job</span>
              </button>
              <button
                mat-menu-item
                (click)="viewResults(jobRow.id)"
                *ngIf="displayResults(jobRow.id)"
              >
                <mat-icon>speaker_notes</mat-icon>
                <span>View results</span>
              </button>

              <button
                mat-menu-item
                (click)="createReport(jobRow.id)"
                *ngIf="displayReports(jobRow.id)"
              >
                <mat-icon>book</mat-icon>
                <span>View reports</span>
              </button>

              <button mat-menu-item (click)="viewLogs(jobRow.id)">
                <mat-icon>search</mat-icon>
                <span>View logs</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: columnsToDisplay"
        class="table-row"
      ></tr>
    </table>
  </section>

  <div class="footer">
    <mat-paginator
      *ngIf="(!isLoading || dataSource.data.length) && totalRecords > pageSize"
      [length]="totalRecords"
      [pageSize]="pageSize"
      [pageIndex]="currentPageIndex"
      (page)="onPageChange($event)"
      [disabled]="isLoading"
    ></mat-paginator>
  </div>
</app-container>
