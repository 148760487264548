import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Any } from '@ngx-grpc/well-known-types';
import {
  GRPC_MESSAGE_POOL,
  LocationList,
  TYPE_ID_TO_BINARY_TYPE,
} from 'app/constants/lookups';
import { ApprovalService } from 'app/services/approval.service';
import {
  GetRequest,
  UpdateStateRequest,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { AttributionConfig } from 'app/services/generated/src/main/proto/attribution/attribution-config.pb';
import { LiftConfig } from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { RedditAttributionConfig } from 'app/services/generated/src/main/proto/reddit-attribution/reddit-attribution-config.pb';
import { RedditLiftConfig } from 'app/services/generated/src/main/proto/reddit-lift/reddit-lift-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReferenceConfig } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import {
  DaskConfig,
  JobBinary,
  JobLog,
  JobLogCustomerRole,
  JobMetadata,
  JobState,
  Postprocessing,
} from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { TiktokTargetingConfig } from 'app/services/generated/src/main/proto/tiktok-targeting/tiktok-targeting-config.pb';
import { ValidationConfig } from 'app/services/generated/src/main/proto/validation/validation-config.pb';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';
import { AttributionComponent } from 'app/views/binary-type/attribution/attribution.component';
import { LiftComponent } from 'app/views/binary-type/lift/lift.component';
import { RedditAttributionComponent } from 'app/views/binary-type/reddit-attribution/reddit-attribution.component';

import { Project } from '../../services/generated/src/main/proto/storage/project.pb';
import { JobLogService } from '../../services/job-log.service';
import {
  EnclaveEncryptionComponent,
  EnclaveEncryptionInfo,
} from '../binary-type/enclave-encryption/enclave-encryption.component';
import {
  PostprocessingComponent,
  PostprocessingInfo,
} from '../binary-type/postprocessing/postprocessing.component';
import { RedditLiftComponent } from '../binary-type/reddit-lift/reddit-lift.component';
import { TiktokTargetingComponent } from '../binary-type/tiktok-targeting/tiktok-targeting.component';
import { ValidationComponent } from '../binary-type/validation/validation.component';

interface BinaryVersion {
  name: string;
  approvalRequestId: string;
}

// TODO: add to binary_types.json message.
const daskBinaryTypes = [
  BinaryType.BINARY_TYPE_ATTRIBUTION,
  BinaryType.BINARY_TYPE_LIFT,
  BinaryType.BINARY_TYPE_REDDIT_ATTRIBUTION,
  BinaryType.BINARY_TYPE_REDDIT_LIFT,
  BinaryType.BINARY_TYPE_TIKTOK_TARGETING,
];

// TODO: add to binary_types.json message.
const sgxBinaryTypes = [
  BinaryType.BINARY_TYPE_ATTRIBUTION,
  BinaryType.BINARY_TYPE_ENCLAVE_ENCRYPTION,
  BinaryType.BINARY_TYPE_LIFT,
  BinaryType.BINARY_TYPE_REDDIT_ATTRIBUTION,
  BinaryType.BINARY_TYPE_REDDIT_LIFT,
  BinaryType.BINARY_TYPE_TIKTOK_TARGETING,
  BinaryType.BINARY_TYPE_VALIDATION,
];

@Component({
  selector: 'app-job-log-creator',
  templateUrl: './job-log-creator.component.html',
  styleUrls: ['./job-log-creator.component.scss'],
  standalone: false,
})
export class JobLogCreatorComponent implements AfterViewInit {
  @ViewChild('jsonPreview') jsonPreview!: ElementRef;
  @ViewChild(LiftComponent) liftComponent!: LiftComponent;
  @ViewChild(RedditAttributionComponent)
  redditAttributionComponent!: RedditAttributionComponent;
  @ViewChild(RedditLiftComponent) redditLiftComponent!: RedditLiftComponent;
  @ViewChild(TiktokTargetingComponent)
  tiktokTargetingComponent!: TiktokTargetingComponent;
  @ViewChild(AttributionComponent) attributionComponent!: AttributionComponent;
  @ViewChild(EnclaveEncryptionComponent)
  enclaveEncryptionComponent!: EnclaveEncryptionComponent;
  @ViewChild(PostprocessingComponent)
  postprocessingComponent!: PostprocessingComponent;
  @ViewChild(ValidationComponent) validationComponent!: ValidationComponent;
  jobLog: JobLog = new JobLog({
    state: JobState.JOB_STATE_DRAFT,
    postprocessing: new Postprocessing(),
  });
  inputJobLog: JobLog = new JobLog();
  binaryVersions: BinaryVersion[] = [];
  locations = LocationList;
  customerIds: Set<string> = new Set();
  selectLatestBinary: boolean = false;
  showDaskConfig: boolean = false;
  showBinaryApprovalConfig: boolean = false;
  datasetKeys: string[] = [];
  enclaveEncryptionCustomerId: string | undefined;

  // Make BinaryType available in the template.
  readonly BinaryType = BinaryType;

  jobLogForm = this.formBuilder.group({
    binaryApprovalRequestId: '',
    location: Location.LOCATION_UNSPECIFIED,
    description: '',
    numWorkers: 0,
    enablePostprocessing: false,
  });
  selectedBinaryType: BinaryType | undefined;
  project: Project | undefined;

  constructor(
    private approvalService: ApprovalService,
    private formBuilder: FormBuilder,
    private jobLogService: JobLogService,
    private logger: LoggerService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}

  updateCustomers(customerIds: string[]) {
    this.customerIds = new Set(customerIds);
    this.customerIds.delete('');
    this.updateApprovedBinariesSelect();
    this.updateCustomerRoles();
  }

  receiveLiftInfo(liftConfig: LiftConfig) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(liftConfig);
    this.updateJsonPreview();
  }

  receiveTiktokTargetingInfo(tiktokTargetingConfig: TiktokTargetingConfig) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(tiktokTargetingConfig);
    this.updateJsonPreview();
  }

  receiveRedditAttributionInfo(
    redditAttributionConfig: RedditAttributionConfig
  ) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(redditAttributionConfig);
    this.updateJsonPreview();
  }

  receiveRedditLiftInfo(redditLiftConfig: RedditLiftConfig) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(redditLiftConfig);
    this.updateJsonPreview();
  }

  receiveAttributionInfo(attributionConfig: AttributionConfig) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(attributionConfig);
    this.updateJsonPreview();
  }

  receiveValidationInfo(validationConfig: ValidationConfig) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(validationConfig);
    this.updateJsonPreview();
  }

  receiveEnclaveEncryptionInfo(enclaveEncryptionInfo: EnclaveEncryptionInfo) {
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(
      enclaveEncryptionInfo.enclaveEncryptionConfig
    );
    if (enclaveEncryptionInfo.customerId) {
      this.updateCustomers([enclaveEncryptionInfo.customerId]);
    }
    this.updateJsonPreview();
  }

  receivePostprocessingInfo(postprocessingInfo: PostprocessingInfo) {
    this.updateCustomers(postprocessingInfo.customerIds);
    this.jobLog.binaryConfig = new Any();
    this.jobLog.binaryConfig.pack(postprocessingInfo.postprocessingConfig);
    this.updateJsonPreview();
  }

  updateCustomerRoles() {
    const customerRoles: JobLogCustomerRole[] = [];
    for (const customerId of this.customerIds) {
      customerRoles.push(
        new JobLogCustomerRole({
          role: JobLogCustomerRole.JobLogRole.JOB_LOG_ROLE_READER,
          customerId: customerId,
        })
      );
      customerRoles.push(
        new JobLogCustomerRole({
          role: JobLogCustomerRole.JobLogRole.JOB_LOG_ROLE_APPROVER,
          customerId: customerId,
        })
      );
    }
    this.jobLog.customerRoles = customerRoles;
  }

  updateJobLog() {
    const form = this.jobLogForm.value;
    this.jobLog.daskConfig = new DaskConfig({
      numWorkers: form.numWorkers!,
    });
    this.jobLog.location = form.location ?? Location.LOCATION_UNSPECIFIED;
    this.jobLog.metadata = new JobMetadata({
      description: form.description ?? '',
    });
    this.jobLog.binary = new JobBinary({
      approvalRequestId: form.binaryApprovalRequestId ?? '',
    });
    this.jobLog.postprocessing!.enabled = form.enablePostprocessing ?? false;
    if (this.inputJobLog.id) {
      this.jobLog.id = this.inputJobLog.id;
      this.jobLog.state = this.inputJobLog.state;
      this.jobLog.archived = this.inputJobLog.archived;
      if (this.inputJobLog.etag) {
        this.jobLog.etag = this.inputJobLog.etag;
      }
      if (this.inputJobLog.postprocessing?.postprocessingConfig) {
        this.jobLog.postprocessing!.postprocessingConfig =
          this.inputJobLog.postprocessing.postprocessingConfig;
      }
    }
  }

  async handleSubmit(startJob: boolean, validations: boolean) {
    if (!validations) {
      return;
    }
    let jobId = this.inputJobLog.id;
    try {
      if (this.inputJobLog.id) {
        this.jobLog.id = this.inputJobLog.id;
        await this.jobLogService.update(this.jobLog, this.inputJobLog.etag!);
      } else {
        const response = await this.jobLogService.create(this.jobLog);
        jobId = response.id;
      }
      if (startJob) {
        this.jobLogService.updateState(
          new UpdateStateRequest({
            jobLogId: jobId,
            state: JobState.JOB_STATE_PENDING,
          })
        );
      }
      this.router.navigate(['/jobs']);
    } catch (error: any) {
      this.snackBar.open(error.statusMessage, 'Dismiss', {
        verticalPosition: 'top',
      });
    }
  }

  isBinaryType(binaryType: BinaryType) {
    if (!this.selectedBinaryType) {
      return false;
    }
    return binaryType == this.selectedBinaryType;
  }

  async updateApprovedBinariesSelect() {
    if (!this.selectedBinaryType) {
      return;
    }

    if (this.customerIds.size == 0) {
      return;
    }

    // Compute intersection of approval requests.
    // TODO(kenny): Consider moving this to server side logic.
    const customerIter = this.customerIds.values();

    const firstCustomerId = customerIter.next().value;
    if (!firstCustomerId) {
      return;
    }

    const approvalResponse =
      await this.approvalService.getApprovedBinaries(firstCustomerId);

    let approvals = approvalResponse.approvedBinaryChange!;
    const remainingCustomerIds = new Set(this.customerIds);
    remainingCustomerIds.delete(firstCustomerId);

    for (const customerId of customerIter) {
      const approvalResponse =
        await this.approvalService.getApprovedBinaries(customerId);
      const customerApprovalIds = new Set(
        approvalResponse.approvedBinaryChange!.map((x) => x.approvalId)
      );
      approvals = approvals.filter((x) =>
        customerApprovalIds.has(x.approvalId)
      );
    }

    this.binaryVersions.splice(0);
    approvals.forEach((approval) => {
      const binaryInfo = approval.change
        ? approval.change.binaryInfo
        : undefined;
      if (!binaryInfo || binaryInfo.binaryType != this.selectedBinaryType) {
        return;
      }
      this.binaryVersions.push({
        name: `${binaryInfo.name} ${binaryInfo.version}`,
        approvalRequestId: approval.approvalId,
      });
    });

    // When generating from a project, preselect the latest binary.
    if (this.selectLatestBinary) {
      this.jobLogForm.controls['binaryApprovalRequestId'].setValue(
        this.binaryVersions[0].approvalRequestId
      );
    }
  }

  updateJsonPreview() {
    if (!this.jsonPreview) {
      console.log('json preview undefined');
      return;
    }
    this.jsonPreview.nativeElement.textContent = JSON.stringify(
      this.jobLog.toProtobufJSON({
        messagePool: GRPC_MESSAGE_POOL,
      }),
      undefined,
      2
    );
  }

  async loadJobFromParam() {
    const jobId = this.route.snapshot.paramMap.get('id');
    if (jobId) {
      const response = await this.jobLogService.get(
        new GetRequest({
          jobLogId: jobId,
        })
      );
      const jobLog = response!.jobLogs![0].jobLog!;
      this.inputJobLog = jobLog;
      this.selectedBinaryType = this.getBinaryType(jobLog);
      this.showBinaryApprovalConfig = sgxBinaryTypes.includes(
        this.selectedBinaryType
      );
      this.showDaskConfig = daskBinaryTypes.includes(this.selectedBinaryType);
      const controls = this.jobLogForm.controls;
      if (jobLog.binary?.approvalRequestId) {
        controls.binaryApprovalRequestId.setValue(
          jobLog.binary.approvalRequestId
        );
      }
      if (jobLog.binary?.approvalRequestId) {
        controls.binaryApprovalRequestId.setValue(
          jobLog.binary.approvalRequestId
        );
      }
      if (jobLog.metadata?.description) {
        controls.description.setValue(jobLog.metadata.description);
      }
      if (jobLog.location) {
        controls.location.setValue(jobLog.location);
      }
      if (jobLog.postprocessing?.enabled) {
        controls.enablePostprocessing.setValue(jobLog.postprocessing.enabled);
      }
      if (jobLog.daskConfig?.numWorkers) {
        controls.numWorkers.setValue(jobLog.daskConfig.numWorkers);
      }
    }
  }

  getBinaryType(jobLog: JobLog): BinaryType {
    if (!jobLog.binaryConfig) {
      return BinaryType.BINARY_TYPE_UNSPECIFIED;
    }
    return TYPE_ID_TO_BINARY_TYPE[jobLog.binaryConfig.getPackedMessageId()];
  }

  onBinarySelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.showBinaryApprovalConfig = sgxBinaryTypes.includes(binaryType);
    this.showDaskConfig = daskBinaryTypes.includes(binaryType);
    this.updateJobLog();
  }

  receiveCustomerDataSetReferenceConfig(
    customerDataSetReferenceConfig: CustomerDataSetReferenceConfig
  ) {
    this.jobLog.customerDataSetReferenceConfig = customerDataSetReferenceConfig;
    this.updateCustomers(
      Object.values(customerDataSetReferenceConfig.groups).map(
        (group) => group.customerId!
      )
    );
    this.updateJsonPreview();
  }

  async ngAfterViewInit() {
    this.loadJobFromParam();
    this.jobLogForm.valueChanges.subscribe(() => {
      this.updateJobLog();
      this.updateJsonPreview();
    });
    this.updateJobLog();
  }
}
