import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import {
  BinaryType,
  BinaryTypeInfo,
} from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from 'app/services/logger.service';
import {
  Entries,
  Entry,
  SourceCodeService,
} from 'app/services/source-code.service';

import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

const LINE_CONTEXT_SIZE = 4;
const FILE_EXEMPTION_LIST = ['csv', 'pqt'];

interface Diff {
  left: Entry | undefined;
  right: Entry | undefined;
}

interface LineNumbers {
  [key: string]: number;
}

@Component({
  selector: 'app-source-code-diff-page',
  templateUrl: './source-code-diff-page.component.html',
  styleUrls: ['./source-code-diff-page.component.scss'],
})
export class SourceCodeDiffComponent implements OnInit {
  binaryType: BinaryType = BinaryType.BINARY_TYPE_UNSPECIFIED;
  binaryTypeInfo: BinaryTypeInfo | undefined;
  isLoading = false;
  isSideBySide = true;
  lineContextSize: LineNumbers = {};
  before: string | undefined;
  after: string | undefined;
  files1: Entries | undefined;
  files2: Entries | undefined;
  filesToDisplay: Diff[] = [];
  filesToSkip: Diff[] = [];
  filesToDisclaim: Diff[] = [];

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private binaryTypeService: BinaryTypeService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private route: ActivatedRoute,
    private sourceCodeService: SourceCodeService
  ) {
    this.initializeParams();
    this.getBinaryTypes();
  }

  ngOnInit(): void {
    if (this.isValidParams()) {
      this.loadFiles();
    }
  }

  private initializeParams() {
    const binaryType = this.route.snapshot.paramMap.get('binary_type');
    this.before = this.route.snapshot.paramMap.get('before') || undefined;
    this.after = this.route.snapshot.paramMap.get('after') || undefined;

    if (binaryType && this.before && this.after) {
      this.binaryType = parseInt(binaryType, 10);
    } else {
      this.messageBox.error(
        'Parameters are missing from URL, please add missing parameters.'
      );
    }
  }

  private isValidParams(): boolean {
    return this.binaryType && this.before && this.after ? true : false;
  }

  private async loadFiles() {
    this.isLoading = true;
    if (!(this.before && this.after)) {
      this.messageBox.error('Before and After versions are required.');
      return;
    }

    try {
      const [files1, files2] = await Promise.all([
        this.loadSourceCode(this.before),
        this.loadSourceCode(this.after),
      ]);

      this.files1 = files1;
      this.files2 = files2;

      this.compareFiles();
    } catch (error) {
      this.logger.error('Error loading source code files', error);
      this.messageBox.error('Failed to load files.');
    } finally {
      this.isLoading = false;
    }
  }

  private async loadSourceCode(version: string) {
    const zipData = await this.sourceCodeService.downloadSourceCode(
      this.binaryType,
      version
    );
    return this.sourceCodeService.unzipFile(zipData);
  }

  private compareFiles() {
    if (!(this.files1 && this.files2)) {
      this.logger.error('File lists are undefined.');
      return;
    }

    const filenameSet = new Set(
      Object.keys(this.files1).concat(Object.keys(this.files2))
    );

    filenameSet.forEach((filename) => {
      const beforeFiles = this.files1![filename];
      const afterFiles = this.files2![filename];

      if (this.isFileExempt(filename)) {
        this.processFileExempt(beforeFiles, afterFiles);
      } else {
        this.processFileDiff(beforeFiles, afterFiles, filename);
      }
    });
  }

  private processFileDiff(
    beforeFiles: Entry | undefined,
    afterFiles: Entry | undefined,
    filename: string
  ) {
    if (this.displayDiff(beforeFiles, afterFiles)) {
      this.filesToDisplay.push({ left: beforeFiles, right: afterFiles });
      this.lineContextSize[filename] = LINE_CONTEXT_SIZE;
    } else {
      this.filesToSkip.push({ left: beforeFiles, right: afterFiles });
    }
  }

  private processFileExempt(
    beforeFiles: Entry | undefined,
    afterFiles: Entry | undefined
  ) {
    if (this.displayDiff(beforeFiles, afterFiles)) {
      this.filesToDisclaim.push({ left: beforeFiles, right: afterFiles });
    }
  }

  displayDiff(
    beforeFiles: Entry | undefined,
    afterFiles: Entry | undefined
  ): boolean {
    if ((beforeFiles && !afterFiles) || (!beforeFiles && afterFiles))
      return true;
    if (beforeFiles!['isBlob'] || afterFiles!['isBlob']) return false;
    return beforeFiles!['sha'] !== afterFiles!['sha'];
  }

  getVersionText(): string {
    return `Ver: ${this.before} diff Ver: ${this.after}`;
  }

  getBeforeVersionText(): string {
    return `Ver: ${this.before}`;
  }

  getAfterVersionText(): string {
    return `Ver: ${this.after}`;
  }

  expandCode(filename: string | undefined) {
    if (filename) {
      this.lineContextSize[filename] = this.lineContextSize[filename]
        ? 0
        : LINE_CONTEXT_SIZE;
    }
  }

  switchRenderMode() {
    this.isSideBySide = !this.isSideBySide;
  }

  isFileExempt(filename: string): boolean {
    const extension = filename.split('.').pop();
    return FILE_EXEMPTION_LIST.includes(extension || '');
  }

  getFilename(diff: Diff): string {
    if (diff.left && diff.left['filename']) {
      return diff.left['filename'];
    } else if (diff.right && diff.right['filename']) {
      return diff.right['filename'];
    } else {
      return 'unknown';
    }
  }

  async getBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      if (response.binaryTypeInfos) {
        this.binaryTypeInfo = response.binaryTypeInfos.find(
          (info) => info.binaryType === this.binaryType
        );
      }
    } catch (error) {
      this.logger.error('Error fetching binary types', error);
      this.messageBox.error('Failed to fetch binary types.');
    }
  }
}
