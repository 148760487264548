/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { Inject, Injectable, Optional } from '@angular/core';
import {
  GrpcCallType,
  GrpcClient,
  GrpcClientFactory,
  GrpcEvent,
  GrpcMetadata,
} from '@ngx-grpc/common';
import {
  GRPC_CLIENT_FACTORY,
  GrpcHandler,
  takeMessages,
  throwStatusErrors,
} from '@ngx-grpc/core';
import { Observable } from 'rxjs';
import * as thisProto from './customer-service.pb';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as storage001 from '../../../../src/main/proto/storage/binary-type.pb';
import * as storage002 from '../../../../src/main/proto/storage/commons.pb';
import * as storage003 from '../../../../src/main/proto/storage/schema-annotations.pb';
import * as api004 from '../../../../src/main/proto/api/headers.pb';
import * as api005 from '../../../../src/main/proto/api/pagination.pb';
import * as storage006 from '../../../../src/main/proto/storage/customer.pb';
import * as storage007 from '../../../../src/main/proto/storage/storage-metadata.pb';
import { GRPC_CUSTOMER_SERVICE_CLIENT_SETTINGS } from './customer-service.pbconf';
/**
 * Service client implementation for api.customer.CustomerService
 */
@Injectable({ providedIn: 'any' })
export class CustomerServiceClient {
  private client: GrpcClient<any>;

  /**
   * Raw RPC implementation for each service client method.
   * The raw methods provide more control on the incoming data and events. E.g. they can be useful to read status `OK` metadata.
   * Attention: these methods do not throw errors when non-zero status codes are received.
   */
  $raw = {
    /**
     * Unary call: /api.customer.CustomerService/Create
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.CreateResponse>>
     */
    create: (
      requestData: thisProto.CreateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.CreateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/Create',
        requestData,
        requestMetadata,
        requestClass: thisProto.CreateRequest,
        responseClass: thisProto.CreateResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/Update
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateResponse>>
     */
    update: (
      requestData: thisProto.UpdateRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/Update',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateRequest,
        responseClass: thisProto.UpdateResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/ReadCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReadCustomerResponse>>
     */
    readCustomer: (
      requestData: thisProto.ReadCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReadCustomerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/ReadCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.ReadCustomerRequest,
        responseClass: thisProto.ReadCustomerResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/ReadCustomers
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReadCustomersResponse>>
     */
    readCustomers: (
      requestData: thisProto.ReadCustomersRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReadCustomersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/ReadCustomers',
        requestData,
        requestMetadata,
        requestClass: thisProto.ReadCustomersRequest,
        responseClass: thisProto.ReadCustomersResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/GetExternalStorageMetadata
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetExternalStorageMetadataResponse>>
     */
    getExternalStorageMetadata: (
      requestData: thisProto.GetExternalStorageMetadataRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetExternalStorageMetadataResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/GetExternalStorageMetadata',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetExternalStorageMetadataRequest,
        responseClass: thisProto.GetExternalStorageMetadataResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/GetTenantId
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetTenantIdResponse>>
     */
    getTenantId: (
      requestData: thisProto.GetTenantIdRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetTenantIdResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/GetTenantId',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetTenantIdRequest,
        responseClass: thisProto.GetTenantIdResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/GetSelfCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.GetSelfCustomerResponse>>
     */
    getSelfCustomer: (
      requestData: thisProto.GetSelfCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.GetSelfCustomerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/GetSelfCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetSelfCustomerRequest,
        responseClass: thisProto.GetSelfCustomerResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/UpdateManagedCustomer
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.UpdateManagedCustomerResponse>>
     */
    updateManagedCustomer: (
      requestData: thisProto.UpdateManagedCustomerRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.UpdateManagedCustomerResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/UpdateManagedCustomer',
        requestData,
        requestMetadata,
        requestClass: thisProto.UpdateManagedCustomerRequest,
        responseClass: thisProto.UpdateManagedCustomerResponse,
      });
    },
    /**
     * Unary call: /api.customer.CustomerService/GetManagedCustomersForBinaryType
     *
     * @param requestMessage Request message
     * @param requestMetadata Request metadata
     * @returns Observable<GrpcEvent<thisProto.ReadCustomersResponse>>
     */
    getManagedCustomersForBinaryType: (
      requestData: thisProto.GetManagedCustomersForBinaryTypeRequest,
      requestMetadata = new GrpcMetadata()
    ): Observable<GrpcEvent<thisProto.ReadCustomersResponse>> => {
      return this.handler.handle({
        type: GrpcCallType.unary,
        client: this.client,
        path: '/api.customer.CustomerService/GetManagedCustomersForBinaryType',
        requestData,
        requestMetadata,
        requestClass: thisProto.GetManagedCustomersForBinaryTypeRequest,
        responseClass: thisProto.ReadCustomersResponse,
      });
    },
  };

  constructor(
    @Optional() @Inject(GRPC_CUSTOMER_SERVICE_CLIENT_SETTINGS) settings: any,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<any>,
    private handler: GrpcHandler
  ) {
    this.client = clientFactory.createClient(
      'api.customer.CustomerService',
      settings
    );
  }

  /**
   * Unary call @/api.customer.CustomerService/Create
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.CreateResponse>
   */
  create(
    requestData: thisProto.CreateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.CreateResponse> {
    return this.$raw
      .create(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/Update
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateResponse>
   */
  update(
    requestData: thisProto.UpdateRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateResponse> {
    return this.$raw
      .update(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/ReadCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReadCustomerResponse>
   */
  readCustomer(
    requestData: thisProto.ReadCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReadCustomerResponse> {
    return this.$raw
      .readCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/ReadCustomers
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReadCustomersResponse>
   */
  readCustomers(
    requestData: thisProto.ReadCustomersRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReadCustomersResponse> {
    return this.$raw
      .readCustomers(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/GetExternalStorageMetadata
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetExternalStorageMetadataResponse>
   */
  getExternalStorageMetadata(
    requestData: thisProto.GetExternalStorageMetadataRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetExternalStorageMetadataResponse> {
    return this.$raw
      .getExternalStorageMetadata(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/GetTenantId
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetTenantIdResponse>
   */
  getTenantId(
    requestData: thisProto.GetTenantIdRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetTenantIdResponse> {
    return this.$raw
      .getTenantId(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/GetSelfCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.GetSelfCustomerResponse>
   */
  getSelfCustomer(
    requestData: thisProto.GetSelfCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.GetSelfCustomerResponse> {
    return this.$raw
      .getSelfCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/UpdateManagedCustomer
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.UpdateManagedCustomerResponse>
   */
  updateManagedCustomer(
    requestData: thisProto.UpdateManagedCustomerRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.UpdateManagedCustomerResponse> {
    return this.$raw
      .updateManagedCustomer(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }

  /**
   * Unary call @/api.customer.CustomerService/GetManagedCustomersForBinaryType
   *
   * @param requestMessage Request message
   * @param requestMetadata Request metadata
   * @returns Observable<thisProto.ReadCustomersResponse>
   */
  getManagedCustomersForBinaryType(
    requestData: thisProto.GetManagedCustomersForBinaryTypeRequest,
    requestMetadata = new GrpcMetadata()
  ): Observable<thisProto.ReadCustomersResponse> {
    return this.$raw
      .getManagedCustomersForBinaryType(requestData, requestMetadata)
      .pipe(throwStatusErrors(), takeMessages());
  }
}
