import { CommonModule, DatePipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { FormatService } from 'app/services/format.service';
import {
  GetRequest,
  GetRequestFilter,
  GetResponse,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import {
  JobLog,
  JobState,
} from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { Buffer } from 'buffer';
import { environment } from 'environments/environment';
import { gzip } from 'pako';
import { firstValueFrom } from 'rxjs';

import {
  JOB_STATE_TO_STRING,
  LocationList,
  TYPE_ID_TO_BINARY_TYPE,
} from '../../constants/lookups';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';
import { BinaryTypeDropdownComponent } from '../../views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from '../../views/shared/components/container/container.component';
import { CustomerDropdownComponent } from '../../views/shared/components/customer-dropdown/customer-dropdown.component';
import { LocationDropdownComponent } from '../../views/shared/components/location-dropdown/location-dropdown.component';
import { MessageBoxComponent } from '../../views/shared/components/message-box/message-box.component';
import { PageHeaderComponent } from '../../views/shared/components/page-header/page-header.component';

const RECORDS_PER_PAGE = 15;
const REFRESH_TIME = 60 * 1000; // 1 minute

interface Page {
  continuationToken: string | null;
}

interface JobRow {
  id: string;
  description: string;
  binaryType: string;
  customers: string;
  state: string;
  creationTimestamp: string;
  startTime: string;
  completeTime: string;
}

@Component({
  selector: 'app-admin-job-list',
  providers: [DatePipe],
  templateUrl: './admin-job-list.component.html',
  styleUrls: ['./admin-job-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    CustomerDropdownComponent,
    BinaryTypeDropdownComponent,
    ContainerComponent,
    PageHeaderComponent,
    MessageBoxComponent,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    LocationDropdownComponent,
  ],
})
export class AdminJobListComponent implements AfterViewInit, OnInit, OnDestroy {
  allowedCustomerLocations: Location[] | undefined;
  binaryTypeToName = new Map<BinaryType, string>();
  selectedBinaryType: BinaryType | undefined;
  customers: Customer[] | undefined;
  dataSource: MatTableDataSource<JobRow> = new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'id',
    'description',
    'state',
    'binaryType',
    'customers',
    'creationTimestamp',
    'startTime',
    'completeTime',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  firstRun = true;
  jobLogs: JobLog[] = [];
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomer: Customer | undefined;
  selectedLocation: Location | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();
  readonly BinaryType = BinaryType;
  refreshInterval: number | undefined;
  lastUpdate = this.formatService.getLastUpdate();
  projectId: string | undefined | null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private binaryTypeService: BinaryTypeService,
    private jobLogService: JobLogService,
    private formatService: FormatService,
    private logger: LoggerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document
  ) {
    const pageId = this.activatedRoute.snapshot.queryParams['pageId'];
    const continuationToken =
      this.activatedRoute.snapshot.queryParams['continuationToken'];
    if (pageId && continuationToken) {
      this.currentPageIndex = parseInt(pageId);
      this.pages[this.currentPageIndex] = {
        continuationToken: continuationToken,
      };

      // Get initial filter values from query params
      const projectId = this.route.snapshot.queryParamMap.get('projectId');
      if (projectId && projectId !== 'None') {
        this.projectId = projectId;
      }

      const customerId = this.route.snapshot.queryParamMap.get('customerId');
      if (customerId && customerId !== 'None') {
        this.selectedCustomer = this.customers?.find(
          (customer) => customer.id === customerId
        );
      }

      const binaryType = this.route.snapshot.queryParamMap.get('binaryType');
      if (binaryType && binaryType !== 'None') {
        this.selectedBinaryType = parseInt(binaryType) as BinaryType;
      }

      const location = this.route.snapshot.queryParamMap.get('location');
      if (location && location !== 'None') {
        this.selectedLocation = parseInt(location) as Location;
      }
    }
  }

  async ngOnInit() {
    this.projectId = this.route.snapshot.queryParamMap.get('projectId');
    await this.loadBinaryTypes();
    this.getAllowedCustomerLocations();
    this.loadJobs();
    this.startAutoRefresh();
  }

  ngOnDestroy(): void {
    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
  }

  startAutoRefresh() {
    this.refreshInterval = window.setInterval(() => {
      if (this.document.hidden || this.isLoading) return;
      this.loadJobs(this.currentPageIndex, this.getContinuationToken());
    }, REFRESH_TIME);
  }

  createReport(id: string) {
    this.router.navigate([`reports/${id}/view`]);
  }

  getContinuationToken() {
    return this.pages[this.currentPageIndex]?.continuationToken || null;
  }

  getAllowedCustomerLocations(): void {
    this.allowedCustomerLocations =
      this.selectedCustomer?.locations ||
      LocationList.map((location) => location.value);
  }

  onCustomerSelect(customer: Customer) {
    this.currentPageIndex = 0;
    this.pages = [];
    this.selectedCustomer = customer;
    this.getAllowedCustomerLocations();
    if (this.firstRun) {
      this.loadJobs(this.currentPageIndex, this.getContinuationToken());
    } else {
      this.loadJobs();
    }
    this.firstRun = false;
  }

  onLocationSelect(location: Location) {
    this.selectedLocation = location;
    this.currentPageIndex = 0;
    this.pages = [];
    if (this.firstRun) {
      this.loadJobs(this.currentPageIndex, this.getContinuationToken());
    } else {
      this.loadJobs();
    }
    this.firstRun = false;
  }

  onBinaryTypeSelect(selectedBinaryType: BinaryType) {
    this.currentPageIndex = 0;
    this.pages = [];
    if (selectedBinaryType === BinaryType.BINARY_TYPE_UNSPECIFIED) {
      this.selectedBinaryType = undefined;
    } else {
      this.selectedBinaryType = selectedBinaryType;
    }
    this.firstRun = false;
    this.loadJobs();
  }

  async onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const continuationToken = this.pages[pageIndex]?.continuationToken;
    this.loadJobs(pageIndex, continuationToken);
    this.currentPageIndex = pageIndex;

    // Build query params object, only including non-empty values
    const queryParams: { [key: string]: string | number } = {
      pageId: pageIndex,
    };

    if (continuationToken) {
      queryParams['continuationToken'] = continuationToken;
    }

    if (this.projectId) {
      queryParams['projectId'] = this.projectId;
    }

    if (this.selectedBinaryType) {
      queryParams['binaryType'] = this.selectedBinaryType;
    }

    if (this.selectedCustomer?.id) {
      queryParams['customerId'] = this.selectedCustomer.id;
    }

    if (this.selectedLocation) {
      queryParams['location'] = this.selectedLocation;
    }

    // Navigate using only the non-empty query params
    this.router.navigate(['/jobs'], {
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  onProjectIdSearch(event: Event) {
    const input = event.target as HTMLInputElement;
    this.projectId = input.value?.trim() || undefined;
    this.currentPageIndex = 0;
    this.pages = [];
    this.loadJobs();
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  createGetRequest(token: string | null): GetRequest {
    const getRequest = new GetRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && getRequest.paginated) {
      getRequest.paginated.continuationToken = token;
    }

    getRequest.filter = new GetRequestFilter();

    if (this.selectedCustomer) {
      getRequest.filter.customerIds = [this.selectedCustomer.id];
    }

    if (this.projectId) {
      getRequest.filter.projectId = this.projectId;
    }

    if (this.selectedBinaryType) {
      getRequest.filter.binaryType = this.selectedBinaryType;
    }

    if (this.selectedLocation) {
      getRequest.filter.location = this.selectedLocation;
    }
    return getRequest;
  }

  async loadJobs(pageIndex = 0, token: string | null = null) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.dataSource.data.splice(0);
      this.jobLogs.splice(0);

      try {
        const getRequest = this.createGetRequest(token);
        const response = await this.jobLogService.get(getRequest);

        response.jobLogs?.forEach((apiJobLog) => {
          const jobLog = apiJobLog!.jobLog!;
          const binaryType =
            TYPE_ID_TO_BINARY_TYPE[jobLog.binaryConfig!.getPackedMessageId()];

          this.jobLogs.push(jobLog);
          const roles: string[] = [];
          jobLog.customerRoles?.forEach((customerRole) => {
            if (roles.indexOf(customerRole.customerId) == -1) {
              roles.push(customerRole.customerId);
            }
          });

          this.dataSource.data.push({
            id: jobLog.id,
            description: jobLog.metadata!.description,
            binaryType: this.binaryTypeToName.get(binaryType) ?? '',
            customers: roles.join(', '),
            state: JOB_STATE_TO_STRING[jobLog.state!],
            startTime: jobLog.execution?.startTime
              ? String(Number(jobLog.execution.startTime.seconds) * 1000)
              : '',
            completeTime: jobLog.execution?.completeTime
              ? String(Number(jobLog.execution.completeTime.seconds) * 1000)
              : '',
            creationTimestamp: jobLog.creationTimestamp
              ? jobLog.creationTimestamp.seconds
              : '',
          });
        });

        this.updatePaginationData(response, pageIndex);
        this.setDataSource();
      } catch (error) {
        this.logger.error('Error loading jobs:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  setDataSource() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'id':
          return item.id;
        case 'description':
          return item.description;
        case 'binaryType':
          return item.binaryType;
        case 'customers':
          return item.customers;
        case 'state':
          return item.state;
        case 'creationTimestamp':
          return item.creationTimestamp;
        case 'startTime':
          return item.startTime;
        default:
          return item.creationTimestamp;
      }
    };
    this.dataSource.sort = this.sort;
  }

  updatePaginationData(response: GetResponse, pageIndex: number) {
    if (pageIndex === 0) {
      this.totalRecords =
        response.paginatedResponse?.count || this.dataSource.data.length;
    }
    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.sort = this.sort;
    });
  }

  async copyJob(id: string) {
    try {
      await this.jobLogService.clone(id);
      this.ngOnInit();
      this.loadJobs(this.currentPageIndex);
    } catch (error) {
      this.logger.error('Error copying job:', error);
    }
  }

  async cancelJob(id: string) {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        title: 'Cancel Job',
        message: 'Are you sure you want to cancel this job?',
        buttonText: 'Cancel Job',
        cancelButtonText: 'Return',
        showCancelButton: true,
      },
    });

    const result = await firstValueFrom(dialogRef.afterClosed());
    if (result?.accepted) {
      try {
        await this.jobLogService.cancelJob(id);
        this.loadJobs(this.currentPageIndex);
      } catch (error) {
        this.logger.error('Error cancelling job:', error);
      }
    }
  }

  canCancelJob(state: string): boolean {
    return ['Running', 'Pending'].includes(state);
  }

  viewResults(id: string) {
    this.router.navigate([`/job-results/${id}/view`]);
  }

  displayResults(id: string) {
    const jobLog = this.jobLogs.find((log) => log.id === id);
    return jobLog && jobLog.state === JobState.JOB_STATE_COMPLETE;
  }

  displayReports(id: string) {
    const jobLog = this.jobLogs.find((log) => log.id === id);
    return (
      jobLog &&
      jobLog.state === JobState.JOB_STATE_COMPLETE &&
      (jobLog.postprocessing?.enabled || environment.localReportDebug)
    );
  }

  editJob(id: string, state: string) {
    if (state === 'Draft') {
      this.router.navigate([`/jobs/${id}/edit`]);
    } else {
      this.router.navigate([`/job-results/${id}/view`]);
    }
  }

  createJob() {
    this.router.navigate(['/jobs/new']);
  }

  viewLogs(jobId: string) {
    const logsUrl = this.buildLogsUrl(jobId);
    window.open(logsUrl, '_blank');
  }

  private buildLogsUrl(jobId: string): string {
    // 1) The raw KQL query
    const query = `
      let jobid = "${jobId}";
      ContainerLogV2
      | where PodName has jobid
      | order by TimeGenerated asc
      | summarize strcat_array(make_list(LogMessage), "\\n") by PodName, ContainerId
      `;

    // 2) Convert query to UTF-8 bytes
    const encoder = new TextEncoder();
    const queryBytes = encoder.encode(query);

    // 3) GZip compress using pako
    const compressed = gzip(queryBytes);

    // 4) Base64-encode the compressed bytes
    const base64Encoded = Buffer.from(compressed).toString('base64');

    // 5) URL-encode special chars (/ + =)
    const encodedText = encodeURIComponent(base64Encoded)
      .replace(/\//g, '%2F')
      .replace(/\+/g, '%2B')
      .replace(/=/g, '%3D');

    // 6) Build final link using the compressed approach
    //    Example points to the LogsBlade or AnalyticsShareLinkToQuery.
    //    If you want "Logs.ReactView" style, it might look like:
    //      https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade
    //      /resourceId/<...>
    //      /source/LogsBlade.AnalyticsShareLinkToQuery
    //      /query/<encodedText>
    //      /isQueryBase64Compressed/true
    //
    //    Adjust the resourceId for your actual workspace.

    const resourceId = environment.azureLogs.resourceId;

    const url =
      `https://portal.azure.com/#blade/Microsoft_Azure_Monitoring_Logs/LogsBlade` +
      `/resourceId/${encodeURIComponent(resourceId)}` +
      `/source/LogsBlade.AnalyticsShareLinkToQuery` +
      `/query/${encodedText}` +
      `/isQueryBase64Compressed/true` +
      `/timespan/P3D`;

    return url;
  }
}
