<p>
  <mat-toolbar>
    <span>Job log creator</span>
  </mat-toolbar>
</p>

<div class="wrapper">
  <div>
    <form [formGroup]="jobLogForm">
      <mat-form-field class="input-full-width">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" />
      </mat-form-field>

      <mat-form-field class="input-half-width">
        <mat-label>Location</mat-label>
        <mat-select formControlName="location">
          <mat-option
            *ngFor="let location of locations"
            [value]="location.value"
          >
            {{ location.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="dropdown">
        <app-binary-type-dropdown
          [selectedBinaryType]="selectedBinaryType"
          [displayTitle]="false"
          placeholderText="Binary type"
          appearance="fill"
          (selectBinaryTypeEvent)="onBinarySelect($event)"
        ></app-binary-type-dropdown>
      </div>
      <app-dataset-picker
        [binaryType]="selectedBinaryType"
        [location]="jobLog.location"
        [inputCustomerDataSetReferenceConfig]="
          inputJobLog.customerDataSetReferenceConfig
        "
        (customerDataSetReferenceConfig)="
          receiveCustomerDataSetReferenceConfig($event)
        "
        [showPartitions]="true"
        [showDatasetVersions]="true"
      >
      </app-dataset-picker>
      <ng-container
        *ngIf="isBinaryType(BinaryType.BINARY_TYPE_ENCLAVE_ENCRYPTION)"
      >
        <app-enclave-encryption
          [inputJobLog]="inputJobLog"
          (enclaveEncryptionInfo)="receiveEnclaveEncryptionInfo($event)"
        ></app-enclave-encryption>
      </ng-container>
      <ng-container *ngIf="isBinaryType(BinaryType.BINARY_TYPE_VALIDATION)">
        <app-validation
          [inputJobLog]="inputJobLog"
          (validationConfig)="receiveValidationInfo($event)"
        ></app-validation>
      </ng-container>
      <ng-container *ngIf="isBinaryType(BinaryType.BINARY_TYPE_LIFT)">
        <app-lift
          [project]="project"
          [inputJobLog]="inputJobLog"
          (liftConfig)="receiveLiftInfo($event)"
        ></app-lift>
      </ng-container>
      <ng-container *ngIf="isBinaryType(BinaryType.BINARY_TYPE_ATTRIBUTION)">
        <app-attribution
          [project]="project"
          [inputJobLog]="inputJobLog"
          (attributionConfig)="receiveAttributionInfo($event)"
        ></app-attribution>
      </ng-container>
      <ng-container
        *ngIf="isBinaryType(BinaryType.BINARY_TYPE_REDDIT_ATTRIBUTION)"
      >
        <app-reddit-attribution
          [project]="project"
          [inputJobLog]="inputJobLog"
          (redditAttributionConfig)="receiveRedditAttributionInfo($event)"
        ></app-reddit-attribution>
      </ng-container>
      <ng-container *ngIf="isBinaryType(BinaryType.BINARY_TYPE_REDDIT_LIFT)">
        <app-reddit-lift
          [project]="project"
          [inputJobLog]="inputJobLog"
          (redditLiftConfig)="receiveRedditLiftInfo($event)"
        ></app-reddit-lift>
      </ng-container>
      <ng-container
        *ngIf="isBinaryType(BinaryType.BINARY_TYPE_TIKTOK_TARGETING)"
      >
        <app-tiktok-targeting
          [project]="project"
          [inputJobLog]="inputJobLog"
          (tiktokTargetingConfig)="receiveTiktokTargetingInfo($event)"
        ></app-tiktok-targeting>
      </ng-container>
      <ng-container
        *ngIf="isBinaryType(BinaryType.BINARY_TYPE_TIKTOK_AUDIENCE_INFERENCE)"
      >
        <app-tiktok-audience-inference
          [inputJobLog]="inputJobLog"
          (tiktokAudienceInferenceConfig)="
            receiveTiktokAudienceInferenceInfo($event)
          "
        ></app-tiktok-audience-inference>
      </ng-container>
      <ng-container *ngIf="isBinaryType(BinaryType.BINARY_TYPE_POSTPROCESSING)">
        <app-postprocessing
          [inputJobLog]="inputJobLog"
          (postprocessingInfo)="receivePostprocessingInfo($event)"
        ></app-postprocessing>
      </ng-container>
      <mat-form-field *ngIf="showBinaryApprovalConfig" class="input-full-width">
        <mat-label>Binary ID</mat-label>
        <mat-select formControlName="binaryApprovalRequestId">
          <mat-option
            *ngFor="let binary of binaryVersions"
            [value]="binary.approvalRequestId"
          >
            {{ binary.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="showDaskConfig" class="input-full-width">
        <mat-label>Number of Dask Workers</mat-label>
        <input matInput formControlName="numWorkers" />
      </mat-form-field>
      <br />
      <mat-checkbox
        #enablePostprocessing
        formControlName="enablePostprocessing"
        *ngIf="!isBinaryType(BinaryType.BINARY_TYPE_POSTPROCESSING)"
      >
        Enable Postprocessing
      </mat-checkbox>

      <br />
      <button
        (click)="handleSubmit(false, jobLogForm.valid)"
        mat-button
        color="primary"
      >
        Save Draft
      </button>
      <button
        (click)="handleSubmit(true, jobLogForm.valid)"
        mat-button
        color="primary"
      >
        Start Job
      </button>
    </form>
  </div>
  <div>
    <h2>Preview</h2>
    <mat-card appearance="outlined">
      <mat-card-content>
        <pre #jsonPreview></pre>
      </mat-card-content>
    </mat-card>
  </div>
</div>
