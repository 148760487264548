import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import {
  AdvertiserEventEligibility,
  GroupConfig,
  LiftAlgorithm,
  LiftConfig,
  LiftEstimationConfig,
  LiftOutputSet,
  Metric,
  MetricType,
  PrivacyConfig,
  SensitivityConfig,
  TimeWindow,
} from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { BehaviorSubject } from 'rxjs';

import {
  CustomerDataSetInfo,
  DatasetPickerComponent,
} from '../common/dataset-picker/dataset-picker.component';

export interface LiftInfo {
  liftConfig: LiftConfig;
  customerIds: string[];
}

@Component({
  selector: 'app-lift',
  templateUrl: './lift.component.html',
  styleUrls: ['./lift.component.scss'],
})
export class LiftComponent implements OnChanges {
  @ViewChild(DatasetPickerComponent)
  publisherDatasetPicker!: DatasetPickerComponent;
  @ViewChild(DatasetPickerComponent)
  advertiserDatasetPicker!: DatasetPickerComponent;
  @Input() liftConfig: LiftConfig | undefined = new LiftConfig();
  @Input() location: Location = Location.LOCATION_UNSPECIFIED;
  @Output() liftInfo = new EventEmitter<LiftInfo>();
  pubCustomerId = '';
  pubCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  advCustomerId = '';
  advCustomerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;
  // Source for the metrics table.
  liftMetricsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  liftMetricsColumns: string[] = [
    'type',
    'counts',
    'users',
    'amount',
    'numUnits',
  ];

  liftMetricsRows = this.fb.array([
    this.fb.group({
      counts: false,
      users: false,
      amount: false,
      numUnits: false,
    }),
  ]);
  form = this.fb.group({
    advertiserStartDate: '',
    advertiserEndDate: '',
    publisherStartDate: '',
    publisherEndDate: '',
    studyId: '',
    matchingColumns: '',
    maximumLookbackWindowDays: '',
    liftEstimationConfigs: this.fb.array([
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_NON_DP,
        rho: '',
        sensitivity: '',
      }),
    ]),
    liftMetrics: this.liftMetricsRows,
    enableDebugLogging: false,
    loadRecordId: false,
    dropDuplicateRecordIds: false,
  });

  liftAlgorithmTypes: LiftAlgorithm[] = [];
  publisherCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };
  advertiserCustomerDataSetInfo: CustomerDataSetInfo = {
    customerId: '',
    customerDataSetReference: new CustomerDataSetReference(),
  };

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService,
    private formatService: FormatService
  ) {
    this.liftMetrics.removeAt(0);
    this.liftMetricsDataSource.next(this.liftMetricsRows.controls);
    for (const value in LiftAlgorithm) {
      if ((parseInt(value) || 0) < 1) {
        // Skip LIFT_ALGORITHM_UNSPECIFIED
        continue;
      }
      this.liftAlgorithmTypes.push(parseInt(value));
    }
    // Populate the metrics table with one row for every possible event_type.
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      const row = this.fb.group({
        counts: false,
        users: false,
        amount: false,
        numUnits: false,
      });
      this.liftMetricsRows.push(row);
    }
    this.form.valueChanges.subscribe(() => this.emitLiftInfo());
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  receivePublisherCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.publisherCustomerDataSetInfo = data;
    this.emitLiftInfo();
  }

  receiveAdvertiserCustomerDataSetInfo(data: CustomerDataSetInfo) {
    this.advertiserCustomerDataSetInfo = data;
    this.emitLiftInfo();
  }

  get liftEstimationConfigs() {
    return this.form.controls['liftEstimationConfigs'] as FormArray;
  }

  get liftMetrics() {
    return this.form.controls['liftMetrics'] as FormArray;
  }

  addLiftEstimationConfig() {
    this.liftEstimationConfigs.push(
      this.fb.group({
        algorithm: LiftAlgorithm.LIFT_ALGORITHM_NON_DP,
        rho: '',
        sensitivity: '',
      })
    );
  }

  deleteLiftEstimationConfig(index: number) {
    this.liftEstimationConfigs.removeAt(index);
  }

  algorithmName(index: number) {
    return LiftAlgorithm[index];
  }

  liftOutputSetName(index: number) {
    return LiftOutputSet[index];
  }

  toTimestamp(
    timestampField: string | undefined | null
  ): Timestamp | undefined {
    if (!timestampField) {
      return undefined;
    }
    const time_millis = Date.parse(timestampField);
    return new Timestamp({
      seconds: Math.trunc(time_millis / 1000).toString(),
    });
  }

  emitLiftInfo() {
    this.liftInfo.emit({
      liftConfig: this.emitLiftConfig(),
      customerIds: [
        this.publisherCustomerDataSetInfo.customerId,
        this.advertiserCustomerDataSetInfo.customerId,
      ],
    });
  }

  emitLiftConfig(): LiftConfig {
    const form = this.form.value;
    const advertiserRef =
      this.advertiserCustomerDataSetInfo.customerDataSetReference;
    advertiserRef.startTime = this.toTimestamp(form.advertiserStartDate);
    advertiserRef.endTime = this.toTimestamp(form.advertiserEndDate);
    const publisherRef =
      this.publisherCustomerDataSetInfo.customerDataSetReference;
    publisherRef.startTime = this.toTimestamp(form.publisherStartDate);
    publisherRef.endTime = this.toTimestamp(form.publisherEndDate);
    const liftConfig = new LiftConfig({
      studyId: form.studyId!,
      advertiserCustomerDataSet: advertiserRef,
      publisherCustomerDataSet: publisherRef,
      groups: [new GroupConfig()],
      timeWindow: new TimeWindow({
        advertiserStartTime: this.toTimestamp(form.advertiserStartDate),
        advertiserEndTime: this.toTimestamp(form.advertiserEndDate),
        publisherStartTime: this.toTimestamp(form.publisherStartDate),
        publisherEndTime: this.toTimestamp(form.publisherEndDate),
      }),
      matchingConfig: new MatchingConfig({
        matchingColumns: form.matchingColumns!.split(',').map((x) => x.trim()),
      }),
      eligibility: new AdvertiserEventEligibility({
        maximumLookbackWindowSeconds:
          parseInt(form.maximumLookbackWindowDays!) * 86400,
      }),
      liftEstimationConfigs: form.liftEstimationConfigs
        ? form.liftEstimationConfigs?.map((value) => {
            return new LiftEstimationConfig({
              thresholdConverters: 5,
              liftAlgorithm: value.algorithm!,
              privacyConfig: new PrivacyConfig({
                rho: parseFloat(value.rho!),
              }),
              sensitivityConfig: new SensitivityConfig({
                percentile: parseFloat(value.sensitivity!),
              }),
              liftOutputSet: LiftOutputSet.LIFT_OUTPUT_SET_ALL,
            });
          })
        : [],
      enableDebugLogging: form.enableDebugLogging!,
      loadRecordId: form.loadRecordId!,
      dropDuplicateRecordIds: form.dropDuplicateRecordIds!,
    });

    if (form.liftMetrics) {
      for (let i = 0; i < form.liftMetrics.length; i++) {
        const row = form.liftMetrics[i];
        if (row.counts) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_COUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.users) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_USERS,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.amount) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_AMOUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.numUnits) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_NUM_UNITS,
              advertiserEventType: i + 1,
            })
          );
        }
      }
    }

    return liftConfig;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['liftConfig']) {
      const liftConfig: LiftConfig = changes['liftConfig'].currentValue;
      if (!liftConfig) {
        return;
      }
      const controls = this.form.controls;
      this.liftEstimationConfigs.clear();
      liftConfig.liftEstimationConfigs!.forEach((v) => {
        this.liftEstimationConfigs.push(
          this.fb.group({
            algorithm: v.liftAlgorithm!,
            rho: '' + v.privacyConfig!.rho!,
            sensitivity: '' + v.sensitivityConfig!.percentile!,
          })
        );
      });
      liftConfig.metrics!.forEach((v) => {
        const index = v.advertiserEventType - 1;
        if (v.metricType == MetricType.METRIC_TYPE_COUNT) {
          controls.liftMetrics.at(index).controls.counts.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_USERS) {
          controls.liftMetrics.at(index).controls.users.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_AMOUNT) {
          controls.liftMetrics.at(index).controls.amount.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_NUM_UNITS) {
          controls.liftMetrics.at(index).controls.numUnits.setValue(true);
        }
      });
      controls.advertiserStartDate.setValue(
        this.formatService.formatProtoDateForInput(
          liftConfig.timeWindow?.advertiserStartTime
        )
      );
      controls.advertiserEndDate.setValue(
        this.formatService.formatProtoDateForInput(
          liftConfig.timeWindow?.advertiserEndTime
        )
      );
      controls.publisherStartDate.setValue(
        this.formatService.formatProtoDateForInput(
          liftConfig.timeWindow?.publisherStartTime
        )
      );
      controls.publisherEndDate.setValue(
        this.formatService.formatProtoDateForInput(
          liftConfig.timeWindow?.publisherEndTime
        )
      );
      if (liftConfig.studyId) {
        controls.studyId.setValue(liftConfig.studyId);
      }
      controls.matchingColumns.setValue(
        liftConfig.matchingConfig!.matchingColumns!.join(',')
      );
      if (liftConfig.eligibility?.maximumLookbackWindowSeconds) {
        controls.maximumLookbackWindowDays.setValue(
          '' + liftConfig.eligibility.maximumLookbackWindowSeconds / 86400
        );
      }
      controls.enableDebugLogging.setValue(liftConfig.enableDebugLogging!);
      controls.loadRecordId.setValue(liftConfig.loadRecordId!);
      controls.dropDuplicateRecordIds.setValue(
        liftConfig.dropDuplicateRecordIds!
      );
      const pubResponse = await this.customerDataService.get(
        liftConfig.publisherCustomerDataSet!.id
      );
      this.pubCustomerId = pubResponse.customerDataSet!.customerId;
      this.pubCustomerDataSetReference = liftConfig.publisherCustomerDataSet!;
      const advResponse = await this.customerDataService.get(
        liftConfig.advertiserCustomerDataSet!.id
      );
      this.advCustomerId = advResponse.customerDataSet!.customerId;
      this.advCustomerDataSetReference = liftConfig.advertiserCustomerDataSet!;
    }
  }
}
