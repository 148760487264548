import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerService } from 'app/services/customer.service';
import { FormatService } from 'app/services/format.service';
import {
  ListProjectsFilter,
  ListProjectsRequest,
  ListProjectsResponse,
} from 'app/services/generated/src/main/proto/api/project-service.pb';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerDataSetReferenceGroup } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import {
  AdvertiserEvents,
  Project,
  State,
} from 'app/services/generated/src/main/proto/storage/project.pb';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';

import { LocationList, ProjectStateList } from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';

const RECORDS_PER_PAGE = 15;

function formatDuration(
  startDate: Date | undefined,
  endDate: Date | undefined
): string | undefined {
  if (!startDate || !endDate) {
    return undefined;
  }
  const diffInMs = endDate.getTime() - startDate.getTime();
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  return `${diffInDays} days`;
}

interface Page {
  continuationToken: string | null;
}

interface ProjectRow {
  id: string;
  binaryType: BinaryType;
  name: string;
  publisherId: string | undefined;
  advertiserId: string | undefined;
  location: string;
  publisherDataSetId: string | undefined;
  advertiserDataSetId: string | undefined;
  lookbackWindow: string | undefined;
  showLookbackWindow: boolean;
  matchKeys: string[];
  notes: string;
  studyId: string;
  publisherStartDate: string | null;
  publisherEndDate: string | null;
  advertiserStartDate: string | null;
  advertiserEndDate: string | null;
  publisherDuration: string | undefined;
  postPeriodDuration: string | undefined;
  advertiserEventTypes: string[];
  advertiserEventMetrics: string[];
  statusMessage: string;
  statusColor: string;
}

@Component({
  selector: 'app-admin-project-manager',
  providers: [DatePipe],
  templateUrl: './admin-project-manager.component.html',
  styleUrls: ['./admin-project-manager.component.scss'],
  standalone: false,
})
export class AdminProjectManagerComponent implements OnInit {
  allowedCustomerLocations: Location[] = [];
  allowedBinaryTypes: BinaryType[] = [];
  archived: boolean = false;
  selectedBinaryType: BinaryType | undefined;
  binaryTypeToName = new Map<BinaryType, string>();
  customerNameMap = new Map<string, string>();
  projectRows: ProjectRow[] = [];
  isLoading = false;
  currentPageIndex = 0;
  selectedAdvertiser: Customer | undefined;
  selectedLocation: Location | undefined;
  selectedPublisher: Customer | undefined;
  projects: { [key: string]: Project } = {};
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  lastUpdate = this.formatService.getLastUpdate();
  selectedCustomer: any;

  constructor(
    private binaryTypeService: BinaryTypeService,
    private customerService: CustomerService,
    private projectService: ProjectService,
    private formatService: FormatService,
    private logger: LoggerService,
    private router: Router
  ) {
    LocationList.forEach((location) =>
      this.allowedCustomerLocations.push(location.value)
    );
  }

  async ngOnInit() {
    this.getCustomerData();
    this.loadBinaryTypes();
    this.loadProjects();
  }

  getContinuationToken() {
    return this.pages[this.currentPageIndex]?.continuationToken || null;
  }

  onAdvertiserSelect(customer: Customer) {
    this.selectedAdvertiser = customer;
    this.loadProjects();
  }

  onPublisherSelect(customer: Customer) {
    this.selectedPublisher = customer;
    this.allowedCustomerLocations = customer.locations;
    this.allowedBinaryTypes = customer.allowedBinaryTypes;
    this.loadProjects();
  }

  async onPageChange(event: PageEvent) {
    const pageIndex = event.pageIndex;
    const continuationToken = this.pages[pageIndex]?.continuationToken;
    this.loadProjects(pageIndex, continuationToken);
    this.currentPageIndex = pageIndex;
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  async loadProjects(pageIndex = 0, token: string | null = null) {
    if (!this.isLoading) {
      this.isLoading = true;
      this.projectRows.splice(0);
      this.projects = {};

      try {
        const getRequest = this.createGetRequest(token);
        const response = await this.projectService.listProjects(getRequest);
        if (response.projects) {
          response.projects.forEach((project) => {
            this.projects[project.id] = project;

            const publisherData =
              project.customerDataSetReferenceConfig?.groups['publisher'];
            const advertiserData =
              project.customerDataSetReferenceConfig?.groups['advertiser'];
            const status = this.getStatus(project);
            this.projectRows.push({
              id: project.id,
              binaryType: project.binaryType,
              name: project.name,
              advertiserId: advertiserData?.customerId,
              publisherId: publisherData?.customerId,
              location: this.getLocationName(project.location),
              lookbackWindow: this.formatLookbackWindow(project),
              showLookbackWindow:
                project.binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION,
              matchKeys: project.matchKeys,
              notes: project.notes,
              studyId: project.studyId,
              advertiserDataSetId: this.getDataSetId(publisherData),
              publisherDataSetId: this.getDataSetId(advertiserData),
              publisherStartDate: this.formatService.formatProtoDateForInput(
                publisherData?.startTime
              ),
              publisherEndDate: this.formatService.formatProtoDateForInput(
                publisherData?.endTime
              ),
              advertiserStartDate: this.formatService.formatProtoDateForInput(
                advertiserData?.startTime
              ),
              advertiserEndDate: this.formatService.formatProtoDateForInput(
                advertiserData?.endTime
              ),
              publisherDuration: formatDuration(
                publisherData?.startTime?.toDate(),
                publisherData?.endTime?.toDate()
              ),
              postPeriodDuration: formatDuration(
                publisherData?.endTime?.toDate(),
                advertiserData?.endTime?.toDate()
              ),
              advertiserEventTypes: this.formatAdvertiserEvents(
                project.advertiserEvents ?? []
              ),
              advertiserEventMetrics: this.formatAdvertiserMetrics(
                project.advertiserEvents ?? [],
                project.binaryType
              ),
              statusMessage: status.message,
              statusColor: status.color,
            });
          });
          this.updatePaginationData(response, pageIndex);
        }
      } catch (error) {
        this.logger.error('Error loading projects:', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  formatLookbackWindow(project: Project): string | undefined {
    if (project.binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION) {
      if (
        !project.lookbackWindow?.clickDays ||
        !project.lookbackWindow?.viewDays
      ) {
        return undefined;
      } else {
        return `${project.lookbackWindow.clickDays}d click / ${project.lookbackWindow.viewDays}d view`;
      }
    }
    return undefined;
  }

  getStatus(project: Project): { message: string; color: string } {
    const now = new Date();
    if (
      project.matchKeys!.length == 0 ||
      project.advertiserEvents!.length == 0
    ) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    const publisherData =
      project.customerDataSetReferenceConfig?.groups['publisher'];
    if (!publisherData || !publisherData.startTime || !publisherData.endTime) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    const advertiserData =
      project.customerDataSetReferenceConfig?.groups['advertiser'];
    if (
      !advertiserData ||
      !advertiserData.startTime ||
      !advertiserData.endTime
    ) {
      return {
        message: 'Missing configuration',
        color: 'yellow',
      };
    }
    if (now < publisherData.startTime.toDate()) {
      return {
        message: 'Campaign not started',
        color: 'gray',
      };
    }
    if (now < publisherData.endTime.toDate()) {
      return {
        message: 'Campaign running',
        color: 'gray',
      };
    }
    if (publisherData.parts!.filter((part) => part.id != '').length == 0) {
      return {
        message: 'Missing data',
        color: 'yellow',
      };
    }
    if (now < advertiserData.endTime.toDate()) {
      return {
        message: 'Campaign in post-period',
        color: 'gray',
      };
    }
    if (advertiserData.parts!.filter((part) => part.id != '').length == 0) {
      return {
        message: 'Missing data',
        color: 'yellow',
      };
    }
    return {
      message: 'Ready to measure',
      color: 'green',
    };
  }

  getAdvertiserEventName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  formatAdvertiserEvents(advertiserEvents: AdvertiserEvents[]): string[] {
    return advertiserEvents.map((event) => {
      const value = AdvertiserEventType[event.advertiserEventType];
      return value
        .substring('ADVERTISER_EVENT_TYPE_'.length)
        .toLocaleLowerCase();
    });
  }

  formatAdvertiserMetrics(
    advertiserEvents: AdvertiserEvents[],
    binaryType: BinaryType
  ): string[] {
    const metrics: string[] = [];
    if (advertiserEvents.length > 0) {
      if (binaryType == BinaryType.BINARY_TYPE_LIFT) {
        metrics.push('users');
      } else if (binaryType == BinaryType.BINARY_TYPE_ATTRIBUTION) {
        metrics.push('counts');
      }
    }
    for (const event of advertiserEvents) {
      if (event.includeAmounts) {
        if (!metrics.includes('amount')) {
          metrics.push('amount');
        }
      }
      if (event.includeNumUnits) {
        if (!metrics.includes('num_units')) {
          metrics.push('num_units');
        }
      }
    }
    return metrics;
  }

  getDataSetId(
    group: CustomerDataSetReferenceGroup | undefined
  ): string | undefined {
    if (!group) {
      return undefined;
    }
    if (!group.parts || group.parts.length == 0) {
      return undefined;
    }
    return group.parts[0].id!;
  }

  createGetRequest(token: string | null): ListProjectsRequest {
    const getProjectRequest = new ListProjectsRequest();

    if (token && getProjectRequest.paginated) {
      getProjectRequest.paginated.continuationToken = token;
    }

    getProjectRequest.filter = new ListProjectsFilter();

    if (this.selectedPublisher) {
      getProjectRequest.filter.publisherId = this.selectedPublisher.id;
    }

    if (this.archived) {
      getProjectRequest.filter.archived = this.archived;
    }

    if (this.selectedAdvertiser) {
      getProjectRequest.filter.advertiserId = this.selectedAdvertiser.id;
    }

    if (this.selectedLocation) {
      getProjectRequest.filter.location = this.selectedLocation;
    }

    if (this.selectedBinaryType) {
      getProjectRequest.filter.binaryType = this.selectedBinaryType;
    }

    return getProjectRequest;
  }

  updatePaginationData(response: ListProjectsResponse, pageIndex: number) {
    if (pageIndex === 0) {
      this.totalRecords =
        response.paginatedResponse?.count || this.projectRows.length;
    }
    if (response.paginatedResponse?.continuationToken) {
      this.pages[pageIndex + 1] = {
        continuationToken: response.paginatedResponse.continuationToken,
      };
    }
  }

  newProject() {
    this.router.navigate(['/projects/new']);
  }

  onBinarySelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.loadProjects();
  }

  onLocationSelect(locations: Location) {
    this.selectedLocation = locations;
    this.loadProjects();
  }

  getLocationName(location: Location | undefined) {
    if (!location) {
      return 'Unknown';
    }

    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }

  getCustomerName(customerId: string) {
    return this.customerNameMap.get(customerId);
  }

  getStateName(projectState: State) {
    return ProjectStateList.find((state) => state.value === projectState)?.name;
  }

  edit(projectId: string) {
    this.router.navigate([`projects/${projectId}/edit`]);
  }

  async getCustomerData() {
    const customers = await this.customerService.readAllCustomers();
    this.customerNameMap.clear();
    customers.forEach((customer) =>
      this.customerNameMap.set(customer.id, customer.companyName)
    );
  }

  viewDataSet(dataSetId: string) {
    this.router.navigate([`dataset/${dataSetId}/edit`]);
  }

  viewJobs(projectId: string) {
    this.router.navigate([`jobs/${projectId}/project-view`]);
  }

  async archive(projectId: string) {
    const project = this.projects[projectId];
    if (!project) {
      return;
    }
    if (confirm(`Are you sure you want to delete project ${project.id}`)) {
      project.archived = true;
      await this.projectService.updateProject(project);
      await this.loadProjects();
    }
  }
}
