<div class="field-dropdown-label" *ngIf="!displayAsBanner && displayTitle">
  <span class="field-title">{{ title }}</span>
</div>
<div class="mat-mdc-card" *ngIf="displayAsBanner; else noBanner">
  <mat-form-field>
    <mat-label *ngIf="displayLabel">{{ label }}</mat-label>
    <mat-select
      class="select"
      data-testid="customerSelect"
      (selectionChange)="onCustomerSelect($event.value)"
      placeholder="--Select--"
      [disabled]="readOnly"
      [value]="getSelectedCustomerId()"
    >
      <mat-option *ngFor="let customer of customers" [value]="customer.id">
        {{ customer.companyName }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="search" *ngIf="showSearchBox">
    <mat-label>Search</mat-label>
    <input
      matInput
      #search
      type="text"
      size="40"
      placeholder="AnonymCo"
      (input)="onTextSearch()"
    />
  </mat-form-field>
  <div>
    <label class="checkbox" *ngIf="showArchiveToggle">
      <input
        type="checkbox"
        [checked]="filterArchived"
        (change)="onFilterArchivedChange()"
      />
      Filter archived
    </label>
  </div>
</div>
<ng-template #noBanner>
  <mat-form-field>
    <mat-label *ngIf="displayLabel">{{ label }}</mat-label>
    <mat-select
      class="select"
      data-testid="customerSelect"
      (selectionChange)="onCustomerSelect($event.value)"
      placeholder="--Select--"
      [disabled]="readOnly"
      [value]="getSelectedCustomerId()"
    >
      <mat-option *ngFor="let customer of customers" [value]="customer.id">
        {{ customer.companyName }}
        <div *ngIf="showCustomerId">({{ customer.id }})</div>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="search" *ngIf="showSearchBox">
    <mat-label>Search</mat-label>
    <input
      matInput
      #search
      type="text"
      size="40"
      placeholder="AnonymCo"
      (input)="onTextSearch()"
    />
  </mat-form-field>
  <label class="checkbox-form" *ngIf="showArchiveToggle">
    <input
      type="checkbox"
      [checked]="filterArchived"
      (change)="onFilterArchivedChange()"
    />
    Filter archived
  </label>
</ng-template>
<div *ngIf="isLoading" class="loader">
  <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
</div>
