/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as attribution000 from '../../../../src/main/proto/attribution/publisher.pb';
/**
 * Message implementation for attribution.LastTouchConfig
 */
export class LastTouchConfig implements GrpcMessage {
  static id = 'attribution.LastTouchConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LastTouchConfig();
    LastTouchConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LastTouchConfig) {
    _instance.lookbackWindow = _instance.lookbackWindow || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LastTouchConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new LookbackWindow();
          _reader.readMessage(
            messageInitializer1,
            LookbackWindow.deserializeBinaryFromReader
          );
          (_instance.lookbackWindow = _instance.lookbackWindow || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LastTouchConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LastTouchConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.lookbackWindow && _instance.lookbackWindow.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.lookbackWindow as any,
        LookbackWindow.serializeBinaryToWriter
      );
    }
  }

  private _lookbackWindow?: LookbackWindow[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LastTouchConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<LastTouchConfig.AsObject>) {
    _value = _value || {};
    this.lookbackWindow = (_value.lookbackWindow || []).map(
      (m) => new LookbackWindow(m)
    );
    LastTouchConfig.refineValues(this);
  }
  get lookbackWindow(): LookbackWindow[] | undefined {
    return this._lookbackWindow;
  }
  set lookbackWindow(value: LookbackWindow[] | undefined) {
    this._lookbackWindow = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LastTouchConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LastTouchConfig.AsObject {
    return {
      lookbackWindow: (this.lookbackWindow || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LastTouchConfig.AsProtobufJSON {
    return {
      lookbackWindow: (this.lookbackWindow || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module LastTouchConfig {
  /**
   * Standard JavaScript object representation for LastTouchConfig
   */
  export interface AsObject {
    lookbackWindow?: LookbackWindow.AsObject[];
  }

  /**
   * Protobuf JSON representation for LastTouchConfig
   */
  export interface AsProtobufJSON {
    lookbackWindow: LookbackWindow.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for attribution.LookbackWindow
 */
export class LookbackWindow implements GrpcMessage {
  static id = 'attribution.LookbackWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LookbackWindow();
    LookbackWindow.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LookbackWindow) {
    _instance.type = _instance.type || 0;
    _instance.durationSeconds = _instance.durationSeconds || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LookbackWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.durationSeconds = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    LookbackWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LookbackWindow,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.durationSeconds) {
      _writer.writeInt64String(2, _instance.durationSeconds);
    }
  }

  private _type: attribution000.PublisherEventType;
  private _durationSeconds: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LookbackWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<LookbackWindow.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.durationSeconds = _value.durationSeconds;
    LookbackWindow.refineValues(this);
  }
  get type(): attribution000.PublisherEventType {
    return this._type;
  }
  set type(value: attribution000.PublisherEventType) {
    this._type = value;
  }
  get durationSeconds(): string {
    return this._durationSeconds;
  }
  set durationSeconds(value: string) {
    this._durationSeconds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LookbackWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LookbackWindow.AsObject {
    return {
      type: this.type,
      durationSeconds: this.durationSeconds,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LookbackWindow.AsProtobufJSON {
    return {
      type: attribution000.PublisherEventType[
        this.type === null || this.type === undefined ? 0 : this.type
      ],
      durationSeconds: this.durationSeconds,
    };
  }
}
export module LookbackWindow {
  /**
   * Standard JavaScript object representation for LookbackWindow
   */
  export interface AsObject {
    type: attribution000.PublisherEventType;
    durationSeconds: string;
  }

  /**
   * Protobuf JSON representation for LookbackWindow
   */
  export interface AsProtobufJSON {
    type: string;
    durationSeconds: string;
  }
}
