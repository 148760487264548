import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CustomerService } from 'app/services/customer.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { PublisherEventType } from 'app/services/generated/src/main/proto/attribution/publisher.pb';
import {
  CategoryFormat,
  ColumnType,
  FieldInfo,
  FormatterConfig,
  TimeFormat,
} from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';

@Component({
  selector: 'app-schema-editor',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './schema-editor.component.html',
  styleUrls: ['./schema-editor.component.scss'],
})
export class SchemaEditorComponent implements OnInit, OnChanges {
  @Input() initialConfig?: FormatterConfig;
  @Input() showTemplateLoader: boolean = true;
  @Input() loadTemplatesFromCustomer: boolean = true;
  @Input() customerId: string | undefined;
  @Output() formatterConfig = new EventEmitter<FormatterConfig>();

  schemaForm: FormGroup;
  columnTypes: ColumnType[] = [];
  ColumnType = ColumnType; // Make enum available in template
  advertiserEventTypes: string[] = [];
  publisherEventTypes: string[] = [];
  // Available event type categories
  eventTypeCategories = ['custom1', 'custom2', 'purchase']; // Available event type categories
  initialized = false;
  Object = Object; // Make Object available in template

  templates: { [key: string]: FormatterConfig } = {
    ['Advertiser wide format']: new FormatterConfig({
      columnNameMap: {
        email: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_MATCH_KEY,
        }),
        phone: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_MATCH_KEY,
        }),
        idfa: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_MATCH_KEY,
        }),
        gaid: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_MATCH_KEY,
        }),
        event_time: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_TIME,
        }),
        event_type: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_ADVERTISER_EVENT_TYPE,
        }),
        amount: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_NUMERIC,
        }),
        num_units: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_NUMERIC,
        }),
      },
    }),
    ['Advertiser long format']: new FormatterConfig({
      columnNameMap: {
        pii_type: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_PII_TYPE,
        }),
        pii_value: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_MATCH_KEY,
        }),
        event_time: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_TIME,
        }),
        event_type: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_ADVERTISER_EVENT_TYPE,
        }),
        amount: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_NUMERIC,
        }),
        num_units: new FieldInfo({
          columnType: ColumnType.COLUMN_TYPE_NUMERIC,
        }),
      },
    }),
  };

  constructor(
    private fb: FormBuilder,
    private customerService: CustomerService
  ) {
    for (const value in ColumnType) {
      if ((parseInt(value) || 0) < 1) {
        continue;
      }
      this.columnTypes.push(parseInt(value));
    }
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        continue;
      }
      const eventName = AdvertiserEventType[parseInt(value)]
        .substring('ADVERTISER_EVENT_TYPE_'.length)
        .toLowerCase();
      this.advertiserEventTypes.push(eventName);
    }
    for (const value in PublisherEventType) {
      if ((parseInt(value) || 0) < 1) {
        continue;
      }
      const eventName = PublisherEventType[parseInt(value)]
        .substring('PUBLISHER_EVENT_TYPE_'.length)
        .toLowerCase();
      this.publisherEventTypes.push(eventName);
    }
    this.schemaForm = this.fb.group({
      schemaType: [],
      columns: this.fb.array([]),
    });
  }

  async loadTemplates(customerId: string) {
    this.templates = {};
    const customerResponse =
      await this.customerService.readCustomer(customerId);
    if (!customerResponse.customer?.schemas) {
      return;
    }
    customerResponse.customer.schemas.forEach((schema) => {
      if (schema.name && schema.formatterConfig) {
        this.templates[schema.name] = schema.formatterConfig;
      }
    });
  }

  ngOnInit() {
    // Subscribe to schema type changes
    this.schemaForm.get('schemaType')?.valueChanges.subscribe((newValue) => {
      this.handleSchemaTypeChange(newValue);
    });

    // Subscribe to form value changes
    this.schemaForm.valueChanges.subscribe(() => {
      this.emitFormatterConfig();
    });

    // If initial config is provided, populate the form;
    if (this.initialConfig) {
      this.populateFromConfig(this.initialConfig);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !this.initialized &&
      changes['initialConfig'] &&
      !changes['initialConfig'].firstChange
    ) {
      this.populateFromConfig(changes['initialConfig'].currentValue);
    }
    if (changes['customerId'] && !changes['customerId'].firstChange) {
      this.loadTemplates(changes['customerId'].currentValue);
    }
  }

  private handleSchemaTypeChange(schemaType: string) {
    // Clear existing columns
    while (this.columns.length) {
      this.columns.removeAt(0);
    }

    const formatterConfig = this.templates[schemaType];
    this.populateFromConfig(formatterConfig);

    // Emit initial configuration after setting up columns
    this.emitFormatterConfig();
  }

  get columns() {
    return this.schemaForm.get('columns') as FormArray;
  }

  getCategories(columnIndex: number) {
    return this.columns.at(columnIndex).get('categories') as FormArray;
  }

  formatColumnType(index: number): string {
    return ColumnType[index];
  }

  isTimeColumn(type: number): boolean {
    return type == ColumnType.COLUMN_TYPE_TIME;
  }

  isPiiTypeColumn(type: number): boolean {
    return type == ColumnType.COLUMN_TYPE_PII_TYPE;
  }

  isAdvertiserEventTypeColumn(type: number): boolean {
    return type == ColumnType.COLUMN_TYPE_ADVERTISER_EVENT_TYPE;
  }

  isAdvertiserEventTypeCategoryName(columnIndex: number): boolean {
    const column = this.columns.at(columnIndex);
    return this.isAdvertiserEventTypeColumn(column.get('type')?.value);
  }

  isPublisherEventTypeColumn(type: number): boolean {
    return type == ColumnType.COLUMN_TYPE_PUBLISHER_EVENT_TYPE;
  }

  isPublisherEventTypeCategoryName(columnIndex: number): boolean {
    const column = this.columns.at(columnIndex);
    return this.isPublisherEventTypeColumn(column.get('type')?.value);
  }

  isRequiredColumn(index: number): boolean {
    return this.columns.at(index).get('is_required')?.value || false;
  }

  addColumn() {
    const columnForm = this.fb.group({
      name: ['', Validators.required],
      type: [ColumnType.COLUMN_TYPE_STRING, Validators.required],
      timeFormat: [''],
      columnMapping: [''],
      categories: this.fb.array([]),
    });
    this.columns.push(columnForm);
  }

  removeColumn(index: number) {
    this.columns.removeAt(index);
  }

  addCategory(columnIndex: number) {
    const categoryForm = this.fb.group({
      name: ['', Validators.required],
      values: ['', Validators.required],
    });
    this.getCategories(columnIndex).push(categoryForm);
  }

  removeCategory(columnIndex: number, categoryIndex: number) {
    this.getCategories(columnIndex).removeAt(categoryIndex);
  }

  emitFormatterConfig() {
    const columnNameMap: { [key: string]: FieldInfo } = {};

    this.columns.controls.forEach((column) => {
      const columnName = column.get('name')?.value;
      const columnMapping = column.get('columnMapping')?.value;
      const columnType = column.get('type')?.value;
      const timeFormat = column.get('timeFormat')?.value;
      const categories = column.get('categories')?.value || [];

      const fieldInfo = new FieldInfo({
        name: columnMapping !== '' ? columnMapping : undefined,
        columnType: columnType,
      });
      if (timeFormat) {
        fieldInfo.timeFormat = new TimeFormat({
          timeFormat: timeFormat,
        });
      }

      if (categories && categories.length > 0) {
        const categoryFormat = new CategoryFormat();
        const categoryMap: { [key: string]: string } = {};
        categories.forEach((cat: any) => {
          if (!cat.values) {
            cat.values = cat.name;
          }
          const values = cat.values
            .split(',')
            .map((v: string) => v.trim())
            .filter((v: string) => v);
          values.forEach((value: string) => {
            categoryMap[value] = cat.name;
          });
        });
        categoryFormat.categoryMap = categoryMap;
        fieldInfo.categoryFormat = categoryFormat;
      }

      columnNameMap[columnName] = fieldInfo;
    });

    this.formatterConfig.emit(
      new FormatterConfig({
        columnNameMap,
      })
    );
  }

  private populateFromConfig(config: FormatterConfig) {
    this.initialized = true;

    // Clear existing columns
    while (this.columns.length) {
      this.columns.removeAt(0);
    }

    // Create a map of column names to their field info
    const columnMap = config.columnNameMap || {};

    // Add each column from the config
    Object.entries(columnMap).forEach(([columnName, fieldInfo]) => {
      const columnForm = this.fb.group({
        name: [columnName, Validators.required],
        type: [fieldInfo.columnType, Validators.required],
        timeFormat: [fieldInfo.timeFormat?.timeFormat || ''],
        columnMapping: [fieldInfo.name || ''],
        categories: this.fb.array([]),
      });

      // Add categories if they exist
      if (fieldInfo.categoryFormat?.categoryMap) {
        const categoriesArray = columnForm.get('categories') as FormArray;
        const categoryMap = fieldInfo.categoryFormat.categoryMap;

        // Group values by category name
        const categoryGroups: { [key: string]: string[] } = {};
        Object.entries(categoryMap).forEach(([value, categoryName]) => {
          if (!categoryGroups[categoryName]) {
            categoryGroups[categoryName] = [];
          }
          categoryGroups[categoryName].push(value);
        });

        // Create category forms
        Object.entries(categoryGroups).forEach(([categoryName, values]) => {
          categoriesArray.push(
            this.fb.group({
              name: [categoryName, Validators.required],
              values: [values.join(', '), Validators.required],
            })
          );
        });
      }

      this.columns.push(columnForm);
    });

    // Emit the initial configuration
    this.emitFormatterConfig();
  }
}
