import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTreeModule } from '@angular/material/tree';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

import { JobLogService } from '../../services/job-log.service';
import { JobLogComponent } from '../../views/job-log/job-log.component';
import { ContainerComponent } from '../../views/shared/components/container/container.component';

@NgModule({
  declarations: [JobLogComponent],
  providers: [JobLogService],
  imports: [
    CommonModule,
    MatTableModule,
    ContainerComponent,
    MatProgressSpinnerModule,
    PageHeaderComponent,
    MatSortModule,
    MatPaginatorModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatTreeModule,
    MatSelectModule,
  ],
})
export class JobLogModule {}
