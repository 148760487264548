import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSort, MatSortable, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { AddAdminStorageModalComponent } from 'app/modals/add-admin-storage-modal/add-admin-storage-modal.component';
import { FormatService } from 'app/services/format.service';
import { ListRequest } from 'app/services/generated/src/main/proto/api/customer-storage-credential-service.pb';
import { GetPaginatedRequest } from 'app/services/generated/src/main/proto/api/pagination.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { CustomerStorageCredential } from 'app/services/generated/src/main/proto/storage/customer-storage-credential.pb';
import { LoggerService } from 'app/services/logger.service';

import { CustomerStorageCredentialService } from '../../services/customer-storage-credential.service';
import { ContainerComponent } from '../shared/components/container/container.component';
import { CustomerDropdownComponent } from '../shared/components/customer-dropdown/customer-dropdown.component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

const RECORDS_PER_PAGE = 15;

interface Page {
  continuationToken: string | null;
}

@Component({
  selector: 'app-admin-storage',
  providers: [DatePipe],
  templateUrl: './admin-storage-credential.component.html',
  styleUrls: ['./admin-storage-credential.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterModule,
    ContainerComponent,
    PageHeaderComponent,
    MessageBoxComponent,
    CustomerDropdownComponent,
  ],
})
export class AdminStorageCredentialComponent implements AfterViewInit, OnInit {
  customers: Customer[] | undefined;
  dataSource: MatTableDataSource<CustomerStorageCredential> =
    new MatTableDataSource();
  public columnsToDisplay: string[] = [
    'ID',
    'customerId',
    'credentialInfo',
    'lastUpdate',
    'menu',
  ];
  isLoading = false;
  currentPageIndex = 0;
  totalRecords = 0;
  pages: Page[] = [];
  pageSize = RECORDS_PER_PAGE;
  selectedCustomerId: string | undefined;
  storageCredential: CustomerStorageCredential[] = [];
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  lastUpdate = this.formatService.getLastUpdate();

  constructor(
    public customerStorageCredentialService: CustomerStorageCredentialService,
    private dialog: MatDialog,
    private formatService: FormatService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadCustomerStorageCredential(this.currentPageIndex);
  }

  createStorageCredential(): void {
    const dialogRef = this.dialog.open(AddAdminStorageModalComponent, {
      height: '600px',
      width: '800px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadCustomerStorageCredential(this.currentPageIndex);
    });
  }

  archive(id: string): void {
    if (confirm(`Archive storage credential with ${id}`) == true) {
      this.isLoading = true;
      this.customerStorageCredentialService
        .archiveStorageCredential(id)
        .catch((error) => {
          this.snackBar.open(error.statusMessage, 'Archive', {
            verticalPosition: 'top',
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.ngOnInit();
        });
    }
  }

  onCustomerChange(customer: Customer) {
    if (customer) {
      this.selectedCustomerId = customer.id;
      this.loadCustomerStorageCredential(0);
    }
  }

  async onPageChange(event: PageEvent) {
    if (event.pageIndex == 0) {
      this.loadCustomerStorageCredential(0);
      this.currentPageIndex = 0;
    } else {
      const page = this.pages[event.pageIndex];
      if (page.continuationToken) {
        this.loadCustomerStorageCredential(
          event.pageIndex,
          page.continuationToken
        );
        this.currentPageIndex = event.pageIndex;
      }
    }
  }

  loadCustomerStorageCredential(pageIndex = 0, token: string | null = null) {
    this.isLoading = true;
    const listRequest = new ListRequest({
      paginated: new GetPaginatedRequest({
        numRecords: this.pageSize,
      }),
    });

    if (token && listRequest.paginated) {
      listRequest.paginated.continuationToken = token;
    }

    if (this.selectedCustomerId) {
      listRequest.customerId = this.selectedCustomerId;
    }

    this.customerStorageCredentialService
      .listAllStorageCredentials(listRequest)
      .then((data) => {
        if (data !== undefined && data.customerStorageCredentials) {
          this.storageCredential = data.customerStorageCredentials ?? [];

          this.dataSource.data = data.customerStorageCredentials;

          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'ID':
                return item.id;
              case 'customerId':
                return item.customerId;
              case 'credentialInfo':
                return (
                  item.awsCredential?.accessKeyId ||
                  item.awsAssumeRole?.roleArn ||
                  'N/A'
                );
              case 'lastUpdate':
                return item.lastUpdate!.seconds;
              default:
                // Sort by lastUpdate time by default.
                return item.lastUpdate!.seconds;
            }
          };
          this.dataSource.sort = this.sort;

          if (!token) {
            // When we fetch the first page, (no continuation token), we receive the
            // total records count.
            this.totalRecords =
              data.paginatedResponse?.count ?? this.dataSource.data.length;
          }

          if (data.paginatedResponse?.continuationToken) {
            this.pages[pageIndex + 1] = {
              continuationToken: data.paginatedResponse.continuationToken,
            };
          }
        }
      })
      .catch((error) => {
        this.logger.error('Error loading storage credential', error);
        this.messageBox.error('Error loading storage credential.');
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    this.sort.sort({ id: 'creationTimestamp', start: 'desc' } as MatSortable);
  }

  editStorageCredential(storageCredential: CustomerStorageCredential): void {
    const dialogRef = this.dialog.open(AddAdminStorageModalComponent, {
      height: '600px',
      width: '800px',
      data: storageCredential,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadCustomerStorageCredential(this.currentPageIndex);
    });
  }
}
