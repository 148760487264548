/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for storage.CustomerDataSetReferenceConfig
 */
export class CustomerDataSetReferenceConfig implements GrpcMessage {
  static id = 'storage.CustomerDataSetReferenceConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSetReferenceConfig();
    CustomerDataSetReferenceConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSetReferenceConfig) {
    _instance.groups = _instance.groups || {};
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSetReferenceConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          const msg_2 = {} as any;
          _reader.readMessage(
            msg_2,
            CustomerDataSetReferenceConfig.GroupsEntry
              .deserializeBinaryFromReader
          );
          _instance.groups = _instance.groups || {};
          _instance.groups[msg_2.key] = msg_2.value;
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSetReferenceConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSetReferenceConfig,
    _writer: BinaryWriter
  ) {
    if (!!_instance.groups) {
      const keys_2 = Object.keys(_instance.groups as any);

      if (keys_2.length) {
        const repeated_2 = keys_2
          .map((key) => ({ key: key, value: (_instance.groups as any)[key] }))
          .reduce((r, v) => [...r, v], [] as any[]);

        _writer.writeRepeatedMessage(
          2,
          repeated_2,
          CustomerDataSetReferenceConfig.GroupsEntry.serializeBinaryToWriter
        );
      }
    }
  }

  private _groups: { [prop: string]: CustomerDataSetReferenceGroup };

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSetReferenceConfig to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CustomerDataSetReferenceConfig.AsObject>
  ) {
    _value = _value || {};
    (this.groups = _value!.groups
      ? Object.keys(_value!.groups).reduce(
          (r, k) => ({
            ...r,
            [k]: _value!.groups![k]
              ? new CustomerDataSetReferenceGroup(_value!.groups![k])
              : undefined,
          }),
          {}
        )
      : {}),
      CustomerDataSetReferenceConfig.refineValues(this);
  }
  get groups(): { [prop: string]: CustomerDataSetReferenceGroup } {
    return this._groups;
  }
  set groups(value: { [prop: string]: CustomerDataSetReferenceGroup }) {
    this._groups = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSetReferenceConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSetReferenceConfig.AsObject {
    return {
      groups: this.groups
        ? Object.keys(this.groups).reduce(
            (r, k) => ({
              ...r,
              [k]: this.groups![k] ? this.groups![k].toObject() : undefined,
            }),
            {}
          )
        : {},
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSetReferenceConfig.AsProtobufJSON {
    return {
      groups: this.groups
        ? Object.keys(this.groups).reduce(
            (r, k) => ({
              ...r,
              [k]: this.groups![k] ? this.groups![k].toJSON() : null,
            }),
            {}
          )
        : {},
    };
  }
}
export module CustomerDataSetReferenceConfig {
  /**
   * Standard JavaScript object representation for CustomerDataSetReferenceConfig
   */
  export interface AsObject {
    groups: { [prop: string]: CustomerDataSetReferenceGroup };
  }

  /**
   * Protobuf JSON representation for CustomerDataSetReferenceConfig
   */
  export interface AsProtobufJSON {
    groups: { [prop: string]: CustomerDataSetReferenceGroup };
  }

  /**
   * Message implementation for storage.CustomerDataSetReferenceConfig.GroupsEntry
   */
  export class GroupsEntry implements GrpcMessage {
    static id = 'storage.CustomerDataSetReferenceConfig.GroupsEntry';

    /**
     * Deserialize binary data to message
     * @param instance message instance
     */
    static deserializeBinary(bytes: ByteSource) {
      const instance = new GroupsEntry();
      GroupsEntry.deserializeBinaryFromReader(
        instance,
        new BinaryReader(bytes)
      );
      return instance;
    }

    /**
     * Check all the properties and set default protobuf values if necessary
     * @param _instance message instance
     */
    static refineValues(_instance: GroupsEntry) {
      _instance.key = _instance.key || '';
      _instance.value = _instance.value || undefined;
    }

    /**
     * Deserializes / reads binary message into message instance using provided binary reader
     * @param _instance message instance
     * @param _reader binary reader instance
     */
    static deserializeBinaryFromReader(
      _instance: GroupsEntry,
      _reader: BinaryReader
    ) {
      while (_reader.nextField()) {
        if (_reader.isEndGroup()) break;

        switch (_reader.getFieldNumber()) {
          case 1:
            _instance.key = _reader.readString();
            break;
          case 2:
            _instance.value = new CustomerDataSetReferenceGroup();
            _reader.readMessage(
              _instance.value,
              CustomerDataSetReferenceGroup.deserializeBinaryFromReader
            );
            break;
          default:
            _reader.skipField();
        }
      }

      GroupsEntry.refineValues(_instance);
    }

    /**
     * Serializes a message to binary format using provided binary reader
     * @param _instance message instance
     * @param _writer binary writer instance
     */
    static serializeBinaryToWriter(
      _instance: GroupsEntry,
      _writer: BinaryWriter
    ) {
      if (_instance.key) {
        _writer.writeString(1, _instance.key);
      }
      if (_instance.value) {
        _writer.writeMessage(
          2,
          _instance.value as any,
          CustomerDataSetReferenceGroup.serializeBinaryToWriter
        );
      }
    }

    private _key: string;
    private _value?: CustomerDataSetReferenceGroup;

    /**
     * Message constructor. Initializes the properties and applies default Protobuf values if necessary
     * @param _value initial values object or instance of GroupsEntry to deeply clone from
     */
    constructor(_value?: RecursivePartial<GroupsEntry.AsObject>) {
      _value = _value || {};
      this.key = _value.key;
      this.value = _value.value
        ? new CustomerDataSetReferenceGroup(_value.value)
        : undefined;
      GroupsEntry.refineValues(this);
    }
    get key(): string {
      return this._key;
    }
    set key(value: string) {
      this._key = value;
    }
    get value(): CustomerDataSetReferenceGroup | undefined {
      return this._value;
    }
    set value(value: CustomerDataSetReferenceGroup | undefined) {
      this._value = value;
    }

    /**
     * Serialize message to binary data
     * @param instance message instance
     */
    serializeBinary() {
      const writer = new BinaryWriter();
      GroupsEntry.serializeBinaryToWriter(this, writer);
      return writer.getResultBuffer();
    }

    /**
     * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
     */
    toObject(): GroupsEntry.AsObject {
      return {
        key: this.key,
        value: this.value ? this.value.toObject() : undefined,
      };
    }

    /**
     * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
     */
    toJSON() {
      return this.toObject();
    }

    /**
     * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
     * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
     * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
     */
    toProtobufJSON(
      // @ts-ignore
      options?: ToProtobufJSONOptions
    ): GroupsEntry.AsProtobufJSON {
      return {
        key: this.key,
        value: this.value ? this.value.toProtobufJSON(options) : null,
      };
    }
  }
  export module GroupsEntry {
    /**
     * Standard JavaScript object representation for GroupsEntry
     */
    export interface AsObject {
      key: string;
      value?: CustomerDataSetReferenceGroup.AsObject;
    }

    /**
     * Protobuf JSON representation for GroupsEntry
     */
    export interface AsProtobufJSON {
      key: string;
      value: CustomerDataSetReferenceGroup.AsProtobufJSON | null;
    }
  }
}

/**
 * Message implementation for storage.CustomerDataSetReferenceGroup
 */
export class CustomerDataSetReferenceGroup implements GrpcMessage {
  static id = 'storage.CustomerDataSetReferenceGroup';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSetReferenceGroup();
    CustomerDataSetReferenceGroup.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSetReferenceGroup) {
    _instance.parts = _instance.parts || [];
    _instance.customerId = _instance.customerId || '';
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSetReferenceGroup,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new CustomerDataSetReference();
          _reader.readMessage(
            messageInitializer1,
            CustomerDataSetReference.deserializeBinaryFromReader
          );
          (_instance.parts = _instance.parts || []).push(messageInitializer1);
          break;
        case 2:
          _instance.customerId = _reader.readString();
          break;
        case 3:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSetReferenceGroup.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSetReferenceGroup,
    _writer: BinaryWriter
  ) {
    if (_instance.parts && _instance.parts.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.parts as any,
        CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.customerId) {
      _writer.writeString(2, _instance.customerId);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        3,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        4,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _parts?: CustomerDataSetReference[];
  private _customerId: string;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSetReferenceGroup to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<CustomerDataSetReferenceGroup.AsObject>
  ) {
    _value = _value || {};
    this.parts = (_value.parts || []).map(
      (m) => new CustomerDataSetReference(m)
    );
    this.customerId = _value.customerId;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    CustomerDataSetReferenceGroup.refineValues(this);
  }
  get parts(): CustomerDataSetReference[] | undefined {
    return this._parts;
  }
  set parts(value: CustomerDataSetReference[] | undefined) {
    this._parts = value;
  }
  get customerId(): string {
    return this._customerId;
  }
  set customerId(value: string) {
    this._customerId = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSetReferenceGroup.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSetReferenceGroup.AsObject {
    return {
      parts: (this.parts || []).map((m) => m.toObject()),
      customerId: this.customerId,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSetReferenceGroup.AsProtobufJSON {
    return {
      parts: (this.parts || []).map((m) => m.toProtobufJSON(options)),
      customerId: this.customerId,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
    };
  }
}
export module CustomerDataSetReferenceGroup {
  /**
   * Standard JavaScript object representation for CustomerDataSetReferenceGroup
   */
  export interface AsObject {
    parts?: CustomerDataSetReference.AsObject[];
    customerId: string;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CustomerDataSetReferenceGroup
   */
  export interface AsProtobufJSON {
    parts: CustomerDataSetReference.AsProtobufJSON[] | null;
    customerId: string;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for storage.CustomerDataSetReference
 */
export class CustomerDataSetReference implements GrpcMessage {
  static id = 'storage.CustomerDataSetReference';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new CustomerDataSetReference();
    CustomerDataSetReference.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: CustomerDataSetReference) {
    _instance.id = _instance.id || '';
    _instance.version = _instance.version || '';
    _instance.versions = _instance.versions || [];
    _instance.desiredPartitions = _instance.desiredPartitions || 0;
    _instance.startTime = _instance.startTime || undefined;
    _instance.endTime = _instance.endTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: CustomerDataSetReference,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.id = _reader.readString();
          break;
        case 2:
          _instance.version = _reader.readString();
          break;
        case 4:
          (_instance.versions = _instance.versions || []).push(
            _reader.readString()
          );
          break;
        case 3:
          _instance.desiredPartitions = _reader.readInt32();
          break;
        case 5:
          _instance.startTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.startTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.endTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.endTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    CustomerDataSetReference.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: CustomerDataSetReference,
    _writer: BinaryWriter
  ) {
    if (_instance.id) {
      _writer.writeString(1, _instance.id);
    }
    if (_instance.version) {
      _writer.writeString(2, _instance.version);
    }
    if (_instance.versions && _instance.versions.length) {
      _writer.writeRepeatedString(4, _instance.versions);
    }
    if (_instance.desiredPartitions) {
      _writer.writeInt32(3, _instance.desiredPartitions);
    }
    if (_instance.startTime) {
      _writer.writeMessage(
        5,
        _instance.startTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.endTime) {
      _writer.writeMessage(
        6,
        _instance.endTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _id: string;
  private _version: string;
  private _versions: string[];
  private _desiredPartitions: number;
  private _startTime?: googleProtobuf000.Timestamp;
  private _endTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of CustomerDataSetReference to deeply clone from
   */
  constructor(_value?: RecursivePartial<CustomerDataSetReference.AsObject>) {
    _value = _value || {};
    this.id = _value.id;
    this.version = _value.version;
    this.versions = (_value.versions || []).slice();
    this.desiredPartitions = _value.desiredPartitions;
    this.startTime = _value.startTime
      ? new googleProtobuf000.Timestamp(_value.startTime)
      : undefined;
    this.endTime = _value.endTime
      ? new googleProtobuf000.Timestamp(_value.endTime)
      : undefined;
    CustomerDataSetReference.refineValues(this);
  }
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value;
  }
  get version(): string {
    return this._version;
  }
  set version(value: string) {
    this._version = value;
  }
  get versions(): string[] {
    return this._versions;
  }
  set versions(value: string[]) {
    this._versions = value;
  }
  get desiredPartitions(): number {
    return this._desiredPartitions;
  }
  set desiredPartitions(value: number) {
    this._desiredPartitions = value;
  }
  get startTime(): googleProtobuf000.Timestamp | undefined {
    return this._startTime;
  }
  set startTime(value: googleProtobuf000.Timestamp | undefined) {
    this._startTime = value;
  }
  get endTime(): googleProtobuf000.Timestamp | undefined {
    return this._endTime;
  }
  set endTime(value: googleProtobuf000.Timestamp | undefined) {
    this._endTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    CustomerDataSetReference.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): CustomerDataSetReference.AsObject {
    return {
      id: this.id,
      version: this.version,
      versions: (this.versions || []).slice(),
      desiredPartitions: this.desiredPartitions,
      startTime: this.startTime ? this.startTime.toObject() : undefined,
      endTime: this.endTime ? this.endTime.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): CustomerDataSetReference.AsProtobufJSON {
    return {
      id: this.id,
      version: this.version,
      versions: (this.versions || []).slice(),
      desiredPartitions: this.desiredPartitions,
      startTime: this.startTime ? this.startTime.toProtobufJSON(options) : null,
      endTime: this.endTime ? this.endTime.toProtobufJSON(options) : null,
    };
  }
}
export module CustomerDataSetReference {
  /**
   * Standard JavaScript object representation for CustomerDataSetReference
   */
  export interface AsObject {
    id: string;
    version: string;
    versions: string[];
    desiredPartitions: number;
    startTime?: googleProtobuf000.Timestamp.AsObject;
    endTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for CustomerDataSetReference
   */
  export interface AsProtobufJSON {
    id: string;
    version: string;
    versions: string[];
    desiredPartitions: number;
    startTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    endTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}
