import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder } from '@angular/forms';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { FormatService } from 'app/services/format.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { MatchingConfig } from 'app/services/generated/src/main/proto/matching/matching-config.pb';
import {
  DataCleaningConfig,
  GroupConfig,
  LiftAlgorithm,
  LiftEstimationConfig,
  Metric,
  MetricType,
  RedditLiftConfig,
} from 'app/services/generated/src/main/proto/reddit-lift/reddit-lift-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { BehaviorSubject } from 'rxjs';

import { Project } from '../../../services/generated/src/main/proto/storage/project.pb';

@Component({
  selector: 'app-reddit-lift',
  templateUrl: './reddit-lift.component.html',
  styleUrls: ['./reddit-lift.component.scss'],
  standalone: false,
})
export class RedditLiftComponent implements OnChanges {
  @Input() project: Project | undefined;
  @Input() inputJobLog: JobLog | undefined;
  @Output() redditLiftConfig = new EventEmitter<RedditLiftConfig>();

  readonly BinaryType = BinaryType;
  // Source for the metrics table.
  liftMetricsDataSource = new BehaviorSubject<AbstractControl[]>([]);
  liftMetricsColumns: string[] = [
    'type',
    'counts',
    'users',
    'amounts',
    'num_units',
  ];

  liftMetricsRows = this.fb.array([
    this.fb.group({
      counts: false,
      users: false,
      amounts: false,
      num_units: false,
    }),
  ]);
  form = this.fb.group({
    matchingColumns: '',
    maxImpressionsPerKeyValue: 100,
    maxConversionsPerKeyValue: 100,
    lookbackWindowDays: '',
    liftEstimationConfigs: this.fb.array([
      this.fb.group({
        liftAlgorithm: '',
        rho: 0,
        percentile: 0,
        twoSided: false,
        alpha: 0,
        weightPercentile: 0,
        proportionRhoCap: 0,
        nLargest: 0,
        weightNLargest: 0,
      }),
    ]),
    groups: this.fb.array([
      this.fb.group({
        publisherAttributes: '',
        isEventLevel: false,
      }),
    ]),
    liftMetrics: this.liftMetricsRows,
    maximumImpressionsPerUser: '',
    advertiserEventsLimits: '[]',
    ignoreWeights: false,
    enableDebugLogging: false,
  });
  liftAlgorithms: LiftAlgorithm[] = [];

  constructor(
    private fb: FormBuilder,
    private formatService: FormatService
  ) {
    this.liftMetrics.removeAt(0);
    this.liftMetricsDataSource.next(this.liftMetricsRows.controls);
    for (const value in LiftAlgorithm) {
      if ((parseInt(value) || 0) < 1) {
        // Skip LIFT_ALGORITHM_UNSPECIFIED
        continue;
      }
      this.liftAlgorithms.push(parseInt(value));
    }
    // Populate the metrics table with one row for every possible event_type.
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      const row = this.fb.group({
        counts: false,
        users: false,
        amounts: false,
        num_units: false,
      });
      this.liftMetricsRows.push(row);
    }
    this.form.valueChanges.subscribe(() => this.emitRedditLiftInfo());
  }

  metricName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  liftAlgorithmName(index: number) {
    return LiftAlgorithm[index];
  }

  get groups() {
    return this.form.controls['groups'] as FormArray;
  }

  get liftEstimationConfigs() {
    return this.form.controls['liftEstimationConfigs'] as FormArray;
  }

  get liftMetrics() {
    return this.form.controls['liftMetrics'] as FormArray;
  }

  addGroup() {
    this.groups.push(
      this.fb.group({
        publisherAttributes: '',
        isEventLevel: false,
      })
    );
  }

  addLiftEstimationConfig() {
    this.liftEstimationConfigs.push(
      this.fb.group({
        liftAlgorithm: '',
        rho: 0,
        percentile: 0,
        twoSided: false,
        alpha: 0,
        weightPercentile: 0,
        proportionRhoCap: 0,
        nLargest: 0,
        weightNLargest: 0,
      })
    );
  }

  deleteLiftEstimationConfig(index: number) {
    this.liftEstimationConfigs.removeAt(index);
  }

  deleteGroup(index: number) {
    this.groups.removeAt(index);
  }

  emitRedditLiftInfo() {
    this.redditLiftConfig.emit(this.emitRedditLiftConfig());
  }

  emitRedditLiftConfig(): RedditLiftConfig {
    const form = this.form.value;
    const liftConfig = new RedditLiftConfig({
      matchingConfig: new MatchingConfig({
        matchingColumns: form.matchingColumns!.split(',').map((x) => x.trim()),
        maxImpressionsPerKeyValue: form.maxImpressionsPerKeyValue!,
        maxConversionsPerKeyValue: form.maxConversionsPerKeyValue!,
      }),
      lookbackWindowSeconds: parseInt(form.lookbackWindowDays!) * 86400,
      dataCleaningConfig: new DataCleaningConfig({
        maximumImpressionsPerUser: parseInt(form.maximumImpressionsPerUser!),
        advertiserEventLimits: JSON.parse(form.advertiserEventsLimits!).map(
          (obj: any) => new DataCleaningConfig.ConversionMaximumSpecifier(obj)
        ),
      }),
      liftEstimationConfigs: form.liftEstimationConfigs?.map((value) => {
        return new LiftEstimationConfig({
          liftAlgorithm: parseInt(value.liftAlgorithm!),
          rho: value.rho!,
          percentile: value.percentile!,
          twoSided: value.twoSided!,
          alpha: value.alpha!,
          weightPercentile: value.weightPercentile!,
          proportionRhoCap: value.proportionRhoCap!,
          nLargest: value.nLargest!,
          weightNLargest: value.weightNLargest!,
        });
      }),
      ignoreWeights: form.ignoreWeights!,
      enableDebugLogging: form.enableDebugLogging!,
    });

    form.groups?.forEach((value) => {
      const liftGroupConfig = new GroupConfig();
      if (value.publisherAttributes) {
        liftGroupConfig.publisherAttributes = value
          .publisherAttributes!.split(',')
          .map((s) => s.trim());
      }
      liftGroupConfig.isEventLevel = value.isEventLevel!;
      liftConfig.groups!.push(liftGroupConfig);
    });

    if (form.liftMetrics) {
      for (let i = 0; i < form.liftMetrics.length; i++) {
        const row = form.liftMetrics[i];
        if (row.counts) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_COUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.users) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_USERS,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.amounts) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_AMOUNT,
              advertiserEventType: i + 1,
            })
          );
        }
        if (row.num_units) {
          liftConfig.metrics?.push(
            new Metric({
              metricType: MetricType.METRIC_TYPE_NUM_UNITS,
              advertiserEventType: i + 1,
            })
          );
        }
      }
    }

    return liftConfig;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const redditLiftConfig =
        inputJobLog.binaryConfig.unpack<RedditLiftConfig>(GRPC_MESSAGE_POOL);
      const controls = this.form.controls;
      this.liftEstimationConfigs.clear();
      redditLiftConfig.liftEstimationConfigs!.forEach((v) => {
        this.liftEstimationConfigs.push(
          this.fb.group({
            liftAlgorithm: v.liftAlgorithm!,
            rho: v.rho!,
            percentile: v.percentile!,
            twoSided: v.twoSided!,
            alpha: v.alpha!,
            weightPercentile: v.weightPercentile!,
            proportionRhoCap: v.proportionRhoCap!,
            nLargest: v.nLargest!,
            weightNLargest: v.weightNLargest!,
          })
        );
      });
      this.groups.clear();
      redditLiftConfig.groups!.forEach((v) => {
        this.groups.push(
          this.fb.group({
            publisherAttributes: v.publisherAttributes.join(','),
            isEventLevel: v.isEventLevel,
          })
        );
      });
      redditLiftConfig.metrics!.forEach((v) => {
        const index = v.advertiserEventType - 1;
        if (v.metricType == MetricType.METRIC_TYPE_COUNT) {
          controls.liftMetrics.at(index).controls.counts.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_USERS) {
          controls.liftMetrics.at(index).controls.users.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_AMOUNT) {
          controls.liftMetrics.at(index).controls.amounts.setValue(true);
        }
        if (v.metricType == MetricType.METRIC_TYPE_NUM_UNITS) {
          controls.liftMetrics.at(index).controls.num_units.setValue(true);
        }
      });
      controls.matchingColumns.setValue(
        redditLiftConfig.matchingConfig!.matchingColumns!.join(',')
      );
      controls.maxImpressionsPerKeyValue.setValue(
        redditLiftConfig.matchingConfig!.maxImpressionsPerKeyValue!
      );
      controls.maxConversionsPerKeyValue.setValue(
        redditLiftConfig.matchingConfig!.maxConversionsPerKeyValue!
      );
      if (redditLiftConfig.lookbackWindowSeconds) {
        controls.lookbackWindowDays.setValue(
          '' + redditLiftConfig.lookbackWindowSeconds! / 86400
        );
      }
      controls.ignoreWeights.setValue(redditLiftConfig.ignoreWeights!);
      controls.enableDebugLogging.setValue(
        redditLiftConfig.enableDebugLogging!
      );
    }
  }
}
