<app-container>
  <app-page-header
    title="{{ title }}"
    lastUpdated="{{ time }}"
  ></app-page-header>

  <form
    ngNativeValidate
    [formGroup]="reportForm"
    id="reportForm"
    class="report-form"
  >
    <!-- Specs Table Section -->
    <section class="form-section">
      <h2 class="section-title">Specifications</h2>
      <div class="metrics-table">
        <table
          mat-table
          [dataSource]="specDataSource"
          formArrayName="specs"
          class="mat-elevation-z2"
        >
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef>Key</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="outline">
                <mat-label>Key</mat-label>
                <input matInput formControlName="key" placeholder="Enter key" />
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  rows="3"
                  cols="60"
                  formControlName="value"
                  placeholder="Enter value"
                ></textarea>
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['key', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['key', 'value']"></tr>
        </table>
      </div>
    </section>

    <!-- Definitions Table Section -->
    <section class="form-section">
      <h2 class="section-title">Definitions</h2>
      <div class="metrics-table">
        <table
          mat-table
          [dataSource]="definitionDataSource"
          formArrayName="definitions"
          class="mat-elevation-z2"
        >
          <ng-container matColumnDef="key">
            <th mat-header-cell *matHeaderCellDef>Key</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="outline">
                <mat-label>Key</mat-label>
                <input matInput formControlName="key" placeholder="Enter key" />
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="outline">
                <textarea
                  matInput
                  rows="3"
                  cols="60"
                  formControlName="value"
                  placeholder="Enter value"
                ></textarea>
              </mat-form-field>
            </td>
          </ng-container>
          <ng-container matColumnDef="dp">
            <th mat-header-cell *matHeaderCellDef>Differential Privacy</th>
            <td
              mat-cell
              *matCellDef="let index = index"
              [formGroupName]="index"
            >
              <mat-form-field appearance="outline">
                <mat-label>Differential Privacy</mat-label>
                <input matInput formControlName="dp" placeholder="No" />
              </mat-form-field>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="['key', 'value', 'dp']"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['key', 'value', 'dp']"
          ></tr>
        </table>
      </div>
    </section>

    <!-- Settings Section -->
    <section class="form-section settings-section">
      <h2 class="section-title">Settings</h2>
      <div class="settings-grid">
        <div class="setting-item">
          <mat-checkbox
            color="primary"
            formControlName="notifications"
            data-testid="notifications"
          >
            Send email notification
          </mat-checkbox>
        </div>

        <div class="setting-item">
          <mat-form-field appearance="outline">
            <mat-label>Currency</mat-label>
            <mat-select
              formControlName="currency"
              name="currency"
              data-testid="currency"
              (selectionChange)="onCurrencySelect($event.value)"
              [value]="selectedCurrency"
              required
            >
              <mat-option
                *ngFor="let currency of currencies"
                [value]="currency"
              >
                {{ currency }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="setting-item full-width">
          <mat-form-field appearance="outline">
            <mat-label>Recipients</mat-label>
            <textarea
              matInput
              rows="2"
              cols="60"
              formControlName="recipients"
              placeholder="Enter email addresses separated by commas"
            ></textarea>
            <mat-hint
              >Enter multiple email addresses separated by commas</mat-hint
            >
          </mat-form-field>
        </div>
      </div>
    </section>

    <app-message-box></app-message-box>

    <!-- Submit Button Section -->
    <section class="form-section submit-section">
      <button
        class="process-button"
        mat-flat-button
        (click)="process()"
        color="primary"
        form="reportForm"
        type="submit"
        data-testid="saveBtn"
        [disabled]="!enabled"
      >
        {{ isLoading ? null : 'Run Report' }}
        <span *ngIf="isLoading" class="spinner">
          <mat-spinner diameter="24"></mat-spinner>
        </span>
      </button>
    </section>
  </form>
</app-container>
