export const SpecsPageDefaultParameters = [
  ['Impression Dates', ''],
  ['Conversion Dates', ''],
  ['Attribution Definition', ''],
  ['Conversion Events', ''],
  ['Event Mapping', ''],
  ['Geographies', ''],
  ['Levels Measured', ''],
];

export const DefinitionsPageDefaultParameters = [
  ['', 'Definition', 'Differentially Private?'],
  [
    'CPM',
    'Cost per 1000 impressions. This was provided to Anonym at the ad level by TikTok. Anonym used the ad-level information to create the ad group and campaign CPM values.',
    'No',
  ],
  [
    'Impression Count',
    'The number of impressions served. For specific interaction types, this is the number of impressions that had the interaction. E.g., under clicks, impression count is the number of impressions that received a click',
    'No',
  ],
  [
    'Cost',
    'This is estimated spend for the media in the row. Anonym computed this by multiplying the number of impressions by the CPA provided by TikTok',
    'Yes',
  ],
  [
    'CPA',
    'Cost per action or in this case, cost per conversion. This is computed as cost for the row divided by the total conversions. The color of the text indicates if the CPA value is close to average (black), better than average (green), or worse than average (red). The logic for determining color includes privacy settings in addition to the amount of impressions/conversions observed.',
    'Yes',
  ],
  [
    'CPA Actionability',
    '[Hidden columns] This is a measure of the confidence Anonym has that the CPA is meaningfully different than the average CPA for that conversion event in that region. The score is based not just how different the value is from the average, but also the amount of signal.  For example, a CPA value based on 10 conversions will have a low score even if the CPA is 50% of the average, while one based 100 conversions would like have a high actionability score. This metric is what the conditional color coding is based for the CPA metric.',
    'Yes',
  ],
  [
    'Conversion Rate',
    'The total number of conversions for a given row divided by the total number of impressions for a given row.',
    'Yes',
  ],
  [
    'Conversion Count',
    'The total number of conversions attributed to an impression described by the given row and event interaction type',
    'Yes',
  ],
];
