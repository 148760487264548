import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { CustomerService } from 'app/services/customer.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { Customer } from 'app/services/generated/src/main/proto/storage/customer.pb';
import { StorageMetadata } from 'app/services/generated/src/main/proto/storage/storage-metadata.pb';
import { LoggerService } from 'app/services/logger.service';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';

import { ContainerComponent } from '../shared/components/container/container.component';
import { FileViewerComponent } from '../shared/components/file-viewer/file-viewer-component';
import { LocationDropdownComponent } from '../shared/components/location-dropdown/location-dropdown.component';
import { MessageBoxComponent } from '../shared/components/message-box/message-box.component';
import { PageHeaderComponent } from '../shared/components/page-header/page-header.component';

@Component({
  selector: 'app-external-storage-metadata-viewer',
  templateUrl: './external-storage-metadata-viewer.component.html',
  styleUrls: [
    './external-storage-metadata-viewer.component.scss',
    '../../shared/shared.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    ContainerComponent,
    FileViewerComponent,
    LocationDropdownComponent,
    MatProgressSpinnerModule,
    MessageBoxComponent,
    PageHeaderComponent,
  ],
})
export class ExternalStorageMetadataViewerComponent {
  allowedLocations: Location[] = [];
  isLoading = false;
  storageMetadata: StorageMetadata[] = [];
  filteredStorageMetada: StorageMetadata[] | undefined = undefined;
  customer: Customer | undefined;
  customerId: string | undefined;
  location: Location = Location.LOCATION_CUS;
  time: string = new Date().toLocaleString();

  constructor(
    private customerService: CustomerService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: ActivatedRoute
  ) {
    this.router.params.subscribe((params: any) => {
      if (params['customerId']) {
        this.customerId = params['customerId'];
        this.getCustomer(params['customerId']);
        this.getFileMetadata();
      } else {
        this.messageBox.error('Invalid customer Id');
        this.logger.error('Invalid customer id');
      }
    });
  }

  async getCustomer(customerId: string) {
    try {
      const response = await this.customerService.readCustomer(customerId);
      this.customer = response.customer;
      if (this.customer?.locations) {
        this.allowedLocations = this.customer.locations;
      }
    } catch (e) {
      this.messageBox.error('Error loading customer data set.');
      this.logger.error('Error loading customer dataset.', e);
    }
  }

  async getFileMetadata() {
    if (this.customerId && this.location) {
      this.isLoading = true;
      try {
        const storageMetadata = (
          await this.customerService.getExternalFileMetadata(
            this.customerId,
            this.location
          )
        ).storageMetadata;
        if (storageMetadata) {
          this.storageMetadata = storageMetadata;
        }
      } catch (error) {
        this.logger.error('Error getting file metadata', error);
      } finally {
        this.isLoading = false;
      }
    }
  }

  getLocationName(location: Location | undefined) {
    if (!location) {
      return 'Unknown';
    }

    const decodedLocation = LocationList.find((l) => l.value == location);
    if (decodedLocation) {
      return decodedLocation.name;
    } else {
      return 'Unknown';
    }
  }

  onLocationSelect(location: Location) {
    this.location = location;
    this.getFileMetadata();
  }
}
