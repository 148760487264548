import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Timestamp } from '@ngx-grpc/well-known-types';
import {
  LocationCodeList,
  LocationCodeStorageList,
} from 'app/constants/lookups';
import { FormatService } from 'app/services/format.service';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { StorageMetadata } from 'app/services/generated/src/main/proto/storage/storage-metadata.pb';
import { LoggerService } from 'app/services/logger.service';
import { environment } from 'environments/environment';

import {
  SearchComponent,
  SearchTerms,
} from '../search-component/search.component';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  metadata?: StorageMetadata;
}

const CHILD_NODE_PAGING_SIZE = 200;

@Component({
  standalone: true,
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    SearchComponent,
  ],
})
export class FileViewerComponent implements OnChanges {
  @Input() storageMetadata: StorageMetadata[] | undefined;
  @Input() customerDataSet: CustomerDataSet | undefined;
  @Input() customerId: string | undefined;
  @Input() location: Location | undefined;
  @Input() isInternal: boolean = true;

  activeNode: TreeNode | undefined;
  activeNodeChildCount = CHILD_NODE_PAGING_SIZE;
  configs: any;
  dataSource: TreeNode[] = [];
  filteredMetadata: StorageMetadata[] = [];
  isLoading = false;
  pageSize: number = 10;
  nodeCount: number = 0;
  pageIndex: number = 0;
  searchTerms: SearchTerms | undefined;
  totalFileSize: number = 0;

  constructor(
    private formatService: FormatService,
    private logger: LoggerService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasStorageMetadataChanged(changes)) {
      this.processData();
    }
  }

  private hasStorageMetadataChanged(changes: SimpleChanges): boolean {
    const metadataChange = changes['storageMetadata'];
    if (!metadataChange) return false;

    const currentValue = metadataChange.currentValue;
    const previousValue = metadataChange.previousValue;

    return (
      Array.isArray(currentValue) &&
      Array.isArray(previousValue) &&
      currentValue.length !== previousValue.length
    );
  }

  processData() {
    this.isLoading = true;
    this.totalFileSize = 0;
    const storageMetadata = this.filterStorageMetadata();
    const nodes: TreeNode[] = [];
    if (storageMetadata && storageMetadata.length > 0) {
      storageMetadata.sort((a: StorageMetadata, b: StorageMetadata) =>
        a.url.localeCompare(b.url)
      );
      storageMetadata.forEach((metadata) => {
        this.totalFileSize += parseInt(metadata.fileBytes);
        const path = metadata.url.includes('/')
          ? metadata.url
          : `/${metadata.url}`;
        const tokens = path.split('/');
        const root = tokens[0];
        const index = nodes.findIndex((node) => node.name == root);
        if (index > -1) {
          const node = nodes[index];
          nodes.splice(index, 1);
          const leaf: TreeNode = {
            name: tokens.slice(1).join('/'),
            metadata: metadata,
          };
          if (!node.children) {
            node.children = [];
          }
          node.children.push(leaf);
          nodes.push(node);
        } else {
          const leaf: TreeNode = {
            name: tokens.slice(1).join('/'),
            metadata: metadata,
          };
          const node: TreeNode = {
            name: tokens[0],
            children: [leaf],
          };
          this.nodeCount++;
          nodes.push(node);
        }
      });
    }
    this.dataSource = nodes;
    this.isLoading = false;
  }

  filterStorageMetadata(): StorageMetadata[] | undefined {
    if (!this.searchTerms || !this.storageMetadata) {
      return this.storageMetadata;
    }

    let filteredMetadata = this.storageMetadata;

    filteredMetadata = this.applyTextFilter(filteredMetadata);
    filteredMetadata = this.applyDateFilter(
      filteredMetadata,
      'startDate',
      (lastModified, date) => lastModified > date
    );
    filteredMetadata = this.applyDateFilter(
      filteredMetadata,
      'endDate',
      (lastModified, date) => lastModified < date
    );

    return filteredMetadata;
  }

  private applyTextFilter(metadata: StorageMetadata[]): StorageMetadata[] {
    if (!this.searchTerms?.text) {
      return metadata;
    }
    return metadata.filter((item) =>
      item.url.includes(this.searchTerms!.text!)
    );
  }

  private applyDateFilter(
    metadata: StorageMetadata[],
    dateField: 'startDate' | 'endDate',
    comparator: (lastModified: Date, date: Date) => boolean
  ): StorageMetadata[] {
    const date = this.searchTerms?.[dateField];
    if (!date) {
      return metadata;
    }

    return metadata.filter((item) => {
      const lastModified = item.lastModified?.toDate();
      return lastModified ? comparator(lastModified, date) : false;
    });
  }

  formatBytes(value: string | undefined) {
    if (value) {
      return this.formatService.formatBytes(parseInt(value));
    } else {
      return '-';
    }
  }

  formatDateTime(value: Timestamp | undefined) {
    return this.formatService.formatProtoDateTime(value);
  }

  getDirectoryFileSize(children: TreeNode[] | undefined) {
    return children
      ? children
          .reduce(
            (sum, node) => sum + parseInt(node.metadata?.fileBytes || '0', 10),
            0
          )
          .toString()
      : '0';
  }

  getChildNodePagingSize() {
    return CHILD_NODE_PAGING_SIZE;
  }

  handlePageEvent(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.getNextPage();
  }

  hideToggle(node: TreeNode) {
    return node.name === '';
  }

  getFileLink(path: string) {
    const prefix = this.isInternal
      ? environment.internalBlobStoragePrefix
      : environment.externalBlobStoragePrefix;

    if (this.isInternal && this.customerDataSet) {
      const locationCode = this.getLocationCode(this.customerDataSet.location);
      return `https://${prefix}${locationCode}.${environment.blobStorageBasePath}/${this.customerDataSet.id}/${path}`;
    }

    if (this.customerId && this.location) {
      const locationCode = this.getLocationStorageCode(this.location);
      return `https://${prefix}${locationCode}.${environment.blobStorageBasePath}/${this.customerId}-${locationCode}/${path}`;
    }

    this.logger.error('Customer data set or location is undefined.');
    return '#';
  }

  getFileStorageAccount() {
    return this.isInternal
      ? environment.internalBlobStoragePrefix
      : environment.externalBlobStoragePrefix;
  }

  getLocationCode(location: Location) {
    return location === Location.LOCATION_UNSPECIFIED
      ? ''
      : LocationCodeList.find((item) => item.value === location)?.name || '';
  }

  getLocationStorageCode(location: Location) {
    return location === Location.LOCATION_UNSPECIFIED
      ? ''
      : LocationCodeStorageList.find((item) => item.value === location)?.name ||
          '';
  }

  getNextPage() {
    const startIndex = this.pageIndex * this.pageSize;
    return this.dataSource.slice(startIndex, startIndex + this.pageSize);
  }

  onOpenPanel(node: TreeNode) {
    this.activeNode = node;
    this.activeNodeChildCount = CHILD_NODE_PAGING_SIZE;
  }

  isNodeActive(node: TreeNode) {
    return !!node.children?.length && this.activeNode == node;
  }

  getPagedChildren(nodes: TreeNode[] | undefined) {
    return nodes
      ? nodes
          .sort((a, b) => {
            const aTime = parseInt(
              a.metadata?.lastModified?.seconds || '0',
              10
            );
            const bTime = parseInt(
              b.metadata?.lastModified?.seconds || '0',
              10
            );
            return bTime - aTime;
          })
          .slice(0, this.activeNodeChildCount)
      : [];
  }

  onMoreFiles(nodes: TreeNode[]) {
    this.activeNodeChildCount += CHILD_NODE_PAGING_SIZE;
    this.getPagedChildren(nodes);
  }

  onSearchEvent(searchTerms: SearchTerms) {
    this.searchTerms = searchTerms;
    this.processData();
  }
}
