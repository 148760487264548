import { Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { getDownloadURL, ref } from '@angular/fire/storage';
import { getStorage } from 'firebase/storage';
import JSZip from 'jszip';

import { DocumentType } from '../../types/document';
import { BinaryTypeService } from './binary-type.service';
import { CsvFormatService } from './csv-format.service';
import {
  BinaryType,
  BinaryTypeInfo,
} from './generated/src/main/proto/storage/binary-type.pb';
import { LoggerService } from './logger.service';

export type Entry = {
  filename: string;
  sha: string | undefined;
  content: string | undefined;
  isBlob: boolean;
  fileSize: number;
};

export type Entries = {
  [key: string]: Entry;
};

const TextFileTypes = [
  '.txt',
  '.json',
  '.py',
  '.yaml',
  '.md',
  '.csv',
  '.proto',
  '.sh',
  'ipynb',
];

/**
 * Service to manage source code files, including retrieving signed URLs, caching, and verifying existence.
 */
@Injectable({
  providedIn: 'root',
})
export class SourceCodeService {
  private static textEncoder = new TextEncoder();
  binaryTypeInfos: BinaryTypeInfo[] | undefined;
  docsRef: AngularFirestoreCollection<DocumentType>;

  constructor(
    public afs: AngularFirestore,
    private binaryTypeService: BinaryTypeService,
    private csvFormatService: CsvFormatService,
    public firebaseApp: FirebaseApp,
    private logger: LoggerService
  ) {
    this.docsRef = this.afs.collection('source');
    this.loadBinaryTypes();
  }

  /**
   * Retrieves a signed URL for the given path.
   *
   * @param path - The path to retrieve the signed URL for.
   * @returns A promise that resolves to the signed URL.
   */
  public getSignedURL(path: string) {
    return getDownloadURL(ref(getStorage(), path));
  }

  /**
   * Generates a cache key for storing the signed URL of a source file.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns The generated cache key.
   */
  getCacheKey(binaryType: BinaryType, version: string) {
    return `source-file-cache_${binaryType.toString()}-${version}`;
  }

  /**
   * Retrieves the cached signed URL for a source file if available; otherwise, fetches a new URL and caches it.
   *
   * @param path - The path to retrieve the signed URL for.
   * @param version - The version of the source file.
   * @param binaryType - The binary type of the source file.
   * @returns A promise that resolves to the signed URL.
   */
  public getCachedSignedURL(
    path: string,
    version: string,
    binaryType: BinaryType
  ) {
    const cachedPath = localStorage.getItem(
      this.getCacheKey(binaryType, version)
    );
    let response = undefined;
    if (cachedPath) {
      response = Promise.resolve(cachedPath);
    } else {
      response = this.getSignedURL(path).then((path) => {
        localStorage.setItem(this.getCacheKey(binaryType, version), path);
      });
    }
    return response;
  }

  /**
   * Checks if a source file exists for the given binary type and version.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns A promise that resolves to the signed URL if the source file exists, otherwise rejects with an error message.
   */
  public sourceFileExists(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return this.getCachedSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/source.zip`,
        version,
        binaryType
      );
    } else {
      return new Promise((reject) => {
        return reject('No binary type or version specified');
      });
    }
  }

  /**
   * Loads the binary types from the BinaryTypeService.
   */
  loadBinaryTypes() {
    this.binaryTypeService.getBinaryTypes().then((response) => {
      this.binaryTypeInfos = response.binaryTypeInfos;
    });
  }

  /**
   * Retrieves the path name for a given binary type.
   *
   * @param binaryType - The binary type to retrieve the path name for.
   * @returns The path name of the binary type.
   */
  getBinaryTypePathName(binaryType: BinaryType) {
    return this.binaryTypeInfos
      ?.find(
        (binaryTypeInfo: BinaryTypeInfo) =>
          binaryTypeInfo.binaryType === binaryType
      )
      ?.name.toLowerCase()
      .replace(' ', '_');
  }

  /**
   * Retrieves the signed URL for downloading the source file of a given binary type and version.
   *
   * @param binaryType - The binary type of the source file.
   * @param version - The version of the source file.
   * @returns A promise that resolves to the signed URL for the source file.
   */
  async downloadSourceFileLink(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return this.getCachedSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/source.zip`,
        version,
        binaryType
      );
    } else {
      this.logger.error('Binary Type and Version should not be empty');
      return undefined;
    }
  }

  /**
   * Retrieves the signed URL for the release notes of a given binary type and version.
   *
   * @param binaryType - The binary type of the release notes.
   * @param version - The version of the release notes.
   * @returns A promise that resolves to the signed URL for the release notes.
   */
  async getReleaseNotesLink(
    binaryType: BinaryType | undefined,
    version: string | undefined
  ) {
    if (version && binaryType) {
      return await this.getSignedURL(
        `source/${this.getBinaryTypePathName(
          binaryType
        )}/${version}/CHANGELOG.md`
      );
    } else {
      return undefined;
    }
  }

  /**
   * Downloads the source code file as a blob given the binary type and version.
   *
   * @param binaryType - The binary type of the source code.
   * @param version - The version of the source code.
   * @returns A promise that resolves to a blob of the source code.
   */
  async downloadSourceCode(binaryType: BinaryType, version: string) {
    const signedUrl = await this.downloadSourceFileLink(binaryType, version);
    if (signedUrl) {
      const response = await fetch(signedUrl);
      if (!response.ok) {
        throw new Error(`Failed to download file: ${response.statusText}`);
      }
      return await response.blob();
    } else {
      throw new Error('Source code is not available for download.');
    }
  }

  /**
   * Unzips a file blob and processes its entries.
   *
   * @param file - The zip file blob to unzip.
   * @returns A promise that resolves to a map of entries.
   */
  async unzipFile(file: Blob): Promise<Entries> {
    const zip = new JSZip();
    const entries: Entries = {};

    try {
      const zipContent = await zip.loadAsync(file);

      for (const [, zipEntry] of Object.entries(zipContent.files)) {
        if (zipEntry.dir) {
          continue;
        }

        const content = this.isTextFile(zipEntry.name)
          ? await zipEntry.async('string') // For text-based files
          : await zipEntry.async('blob'); // For binary files

        let fileSize = 0;
        let isBlob = false;
        let sha: string | undefined;
        let textContent: string | undefined;

        if (typeof content === 'string') {
          fileSize = content.length;
          sha = await this.hashFile(content);
          textContent = content;
        } else if (content instanceof Blob) {
          isBlob = true;
          fileSize = content.size;
          sha = undefined;
        } else {
          this.logger.error(`Unknown file format for file: ${zipEntry.name}`);
          throw new Error('Unknown file format.');
        }
        const entry: Entry = {
          filename: zipEntry.name,
          fileSize: fileSize,
          isBlob: isBlob,
          content: textContent,
          sha: sha,
        };
        entries[zipEntry.name] = entry;
      }
    } catch (error) {
      this.logger.error('Error unzipping file:', error);
      throw new Error('Failed to unzip the file');
    }
    return entries;
  }

  /**
   * Checks if a given filename matches a list of text file extensions.
   *
   * @param filename - The name of the file to check.
   * @returns True if the file is a text file, otherwise false.
   */
  isTextFile(filename: string) {
    return TextFileTypes.find((ext) => filename.endsWith(ext));
  }

  /**
   * Computes the SHA-256 hash of a given string value, returning it as a hexadecimal string.
   *
   * @param value - The string to hash.
   * @returns A promise that resolves to the SHA-256 hash of the string, in hexadecimal format.
   *
   * The method trims and converts the string to lowercase before hashing for consistency,
   * then uses the TextEncoder to encode it as a Uint8Array. The resulting hash buffer
   * is converted to hexadecimal format using CsvFormatService.
   */
  async hashFile(value: string) {
    return CsvFormatService.convertArrayToHex(
      await crypto.subtle.digest(
        'SHA-256',
        SourceCodeService.textEncoder.encode(value.trim().toLowerCase())
      )
    );
  }
}
