import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { GRPC_MESSAGE_POOL } from 'app/constants/lookups';
import { CustomerDataService } from 'app/services/customer-data.service';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { CustomerDataSetReference } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import {
  ColumnType,
  ValidationConfig,
} from 'app/services/generated/src/main/proto/validation/validation-config.pb';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
})
export class ValidationComponent implements OnChanges {
  @Input() inputJobLog: JobLog | undefined;
  @Output() validationConfig = new EventEmitter<ValidationConfig>();
  customerId = '';
  customerDataSetReference: CustomerDataSetReference =
    new CustomerDataSetReference();
  readonly BinaryType = BinaryType;

  form = this.fb.group({
    expectedColumns: this.fb.array([
      this.fb.group({
        name: '',
        columnType: '',
      }),
    ]),
    emitSummaryStatistics: false,
    numShards: 0,
  });
  columnTypes: ColumnType[] = [];

  constructor(
    private fb: FormBuilder,
    private customerDataService: CustomerDataService
  ) {
    for (const value in ColumnType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip COLUMN_TYPE_UNSPECIFIED
        continue;
      }
      this.columnTypes.push(parseInt(value));
    }
    this.deleteExpectedColumn(0);
    this.form.valueChanges.subscribe(() => this.emitValidationInfo());
  }

  get expectedColumns() {
    return this.form.controls['expectedColumns'] as FormArray;
  }

  addExpectedColumn() {
    this.expectedColumns.push(
      this.fb.group({
        name: '',
        columnType: '',
      })
    );
  }

  columnTypeName(index: number) {
    return ColumnType[index];
  }

  deleteExpectedColumn(index: number) {
    this.expectedColumns.removeAt(index);
  }

  emitValidationInfo() {
    this.validationConfig.emit(this.createValidationConfig());
  }

  createValidationConfig(): ValidationConfig {
    const form = this.form.value;
    const expectedColumns: { [key: string]: ColumnType } = {};
    form.expectedColumns!.forEach((val) => {
      expectedColumns[val.name!] = parseInt(val.columnType!);
    });
    return new ValidationConfig({
      emitSummaryStatistics: form.emitSummaryStatistics ?? false,
      numShards: form.numShards!,
      expectedColumns: expectedColumns,
    });
  }

  formatTimestamp(timestampProto: Timestamp | undefined): string {
    if (!timestampProto) {
      return '';
    }
    const date = timestampProto.toDate();
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['inputJobLog']) {
      const inputJobLog: JobLog | undefined =
        changes['inputJobLog'].currentValue;
      if (!inputJobLog || !inputJobLog.binaryConfig) {
        return;
      }
      const validationConfig =
        inputJobLog.binaryConfig.unpack<ValidationConfig>(GRPC_MESSAGE_POOL);
      const controls = this.form.controls;
      this.expectedColumns.clear();
      for (const [key, value] of Object.entries(
        validationConfig.expectedColumns
      )) {
        this.expectedColumns.push(
          this.fb.group({
            name: key,
            columnType: value,
          })
        );
      }
      controls.emitSummaryStatistics.setValue(
        validationConfig.emitSummaryStatistics ?? false
      );
      if (validationConfig.numShards) {
        controls.numShards.setValue(validationConfig.numShards);
      }
    }
  }
}
