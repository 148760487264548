/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as attribution001 from '../../../../src/main/proto/attribution/advertiser.pb';
import * as attribution002 from '../../../../src/main/proto/attribution/publisher.pb';
import * as matching003 from '../../../../src/main/proto/matching/matching-config.pb';
import * as storage004 from '../../../../src/main/proto/storage/customer-data-set-reference.pb';
export enum MetricType {
  METRIC_TYPE_UNSPECIFIED = 0,
  METRIC_TYPE_COUNT = 1,
  METRIC_TYPE_AMOUNT = 3,
  METRIC_TYPE_NUM_UNITS = 4,
}
export enum AttributionAlgorithm {
  ATTRIBUTION_ALGORITHM_UNSPECIFIED = 0,
  ATTRIBUTION_ALGORITHM_NON_DP = 1,
  ATTRIBUTION_ALGORITHM_DP = 2,
}
/**
 * Message implementation for attribution_v2.TimeWindow
 */
export class TimeWindow implements GrpcMessage {
  static id = 'attribution_v2.TimeWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TimeWindow();
    TimeWindow.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TimeWindow) {
    _instance.publisherStartTime = _instance.publisherStartTime || undefined;
    _instance.publisherEndTime = _instance.publisherEndTime || undefined;
    _instance.advertiserStartTime = _instance.advertiserStartTime || undefined;
    _instance.advertiserEndTime = _instance.advertiserEndTime || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TimeWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.publisherStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 2:
          _instance.publisherEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.publisherEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.advertiserStartTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserStartTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.advertiserEndTime = new googleProtobuf000.Timestamp();
          _reader.readMessage(
            _instance.advertiserEndTime,
            googleProtobuf000.Timestamp.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    TimeWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: TimeWindow, _writer: BinaryWriter) {
    if (_instance.publisherStartTime) {
      _writer.writeMessage(
        1,
        _instance.publisherStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.publisherEndTime) {
      _writer.writeMessage(
        2,
        _instance.publisherEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserStartTime) {
      _writer.writeMessage(
        3,
        _instance.advertiserStartTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserEndTime) {
      _writer.writeMessage(
        4,
        _instance.advertiserEndTime as any,
        googleProtobuf000.Timestamp.serializeBinaryToWriter
      );
    }
  }

  private _publisherStartTime?: googleProtobuf000.Timestamp;
  private _publisherEndTime?: googleProtobuf000.Timestamp;
  private _advertiserStartTime?: googleProtobuf000.Timestamp;
  private _advertiserEndTime?: googleProtobuf000.Timestamp;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TimeWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<TimeWindow.AsObject>) {
    _value = _value || {};
    this.publisherStartTime = _value.publisherStartTime
      ? new googleProtobuf000.Timestamp(_value.publisherStartTime)
      : undefined;
    this.publisherEndTime = _value.publisherEndTime
      ? new googleProtobuf000.Timestamp(_value.publisherEndTime)
      : undefined;
    this.advertiserStartTime = _value.advertiserStartTime
      ? new googleProtobuf000.Timestamp(_value.advertiserStartTime)
      : undefined;
    this.advertiserEndTime = _value.advertiserEndTime
      ? new googleProtobuf000.Timestamp(_value.advertiserEndTime)
      : undefined;
    TimeWindow.refineValues(this);
  }
  get publisherStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherStartTime;
  }
  set publisherStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherStartTime = value;
  }
  get publisherEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._publisherEndTime;
  }
  set publisherEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._publisherEndTime = value;
  }
  get advertiserStartTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserStartTime;
  }
  set advertiserStartTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserStartTime = value;
  }
  get advertiserEndTime(): googleProtobuf000.Timestamp | undefined {
    return this._advertiserEndTime;
  }
  set advertiserEndTime(value: googleProtobuf000.Timestamp | undefined) {
    this._advertiserEndTime = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TimeWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TimeWindow.AsObject {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toObject()
        : undefined,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toObject()
        : undefined,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toObject()
        : undefined,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TimeWindow.AsProtobufJSON {
    return {
      publisherStartTime: this.publisherStartTime
        ? this.publisherStartTime.toProtobufJSON(options)
        : null,
      publisherEndTime: this.publisherEndTime
        ? this.publisherEndTime.toProtobufJSON(options)
        : null,
      advertiserStartTime: this.advertiserStartTime
        ? this.advertiserStartTime.toProtobufJSON(options)
        : null,
      advertiserEndTime: this.advertiserEndTime
        ? this.advertiserEndTime.toProtobufJSON(options)
        : null,
    };
  }
}
export module TimeWindow {
  /**
   * Standard JavaScript object representation for TimeWindow
   */
  export interface AsObject {
    publisherStartTime?: googleProtobuf000.Timestamp.AsObject;
    publisherEndTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserStartTime?: googleProtobuf000.Timestamp.AsObject;
    advertiserEndTime?: googleProtobuf000.Timestamp.AsObject;
  }

  /**
   * Protobuf JSON representation for TimeWindow
   */
  export interface AsProtobufJSON {
    publisherStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    publisherEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserStartTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
    advertiserEndTime: googleProtobuf000.Timestamp.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for attribution_v2.GroupConfig
 */
export class GroupConfig implements GrpcMessage {
  static id = 'attribution_v2.GroupConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GroupConfig();
    GroupConfig.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GroupConfig) {
    _instance.publisherAttributes = _instance.publisherAttributes || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GroupConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          (_instance.publisherAttributes =
            _instance.publisherAttributes || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    GroupConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GroupConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherAttributes && _instance.publisherAttributes.length) {
      _writer.writeRepeatedString(1, _instance.publisherAttributes);
    }
  }

  private _publisherAttributes: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GroupConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<GroupConfig.AsObject>) {
    _value = _value || {};
    this.publisherAttributes = (_value.publisherAttributes || []).slice();
    GroupConfig.refineValues(this);
  }
  get publisherAttributes(): string[] {
    return this._publisherAttributes;
  }
  set publisherAttributes(value: string[]) {
    this._publisherAttributes = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GroupConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GroupConfig.AsObject {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GroupConfig.AsProtobufJSON {
    return {
      publisherAttributes: (this.publisherAttributes || []).slice(),
    };
  }
}
export module GroupConfig {
  /**
   * Standard JavaScript object representation for GroupConfig
   */
  export interface AsObject {
    publisherAttributes: string[];
  }

  /**
   * Protobuf JSON representation for GroupConfig
   */
  export interface AsProtobufJSON {
    publisherAttributes: string[];
  }
}

/**
 * Message implementation for attribution_v2.LastTouchConfig
 */
export class LastTouchConfig implements GrpcMessage {
  static id = 'attribution_v2.LastTouchConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LastTouchConfig();
    LastTouchConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LastTouchConfig) {
    _instance.lookbackWindow = _instance.lookbackWindow || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LastTouchConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new LookbackWindow();
          _reader.readMessage(
            messageInitializer1,
            LookbackWindow.deserializeBinaryFromReader
          );
          (_instance.lookbackWindow = _instance.lookbackWindow || []).push(
            messageInitializer1
          );
          break;
        default:
          _reader.skipField();
      }
    }

    LastTouchConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LastTouchConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.lookbackWindow && _instance.lookbackWindow.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.lookbackWindow as any,
        LookbackWindow.serializeBinaryToWriter
      );
    }
  }

  private _lookbackWindow?: LookbackWindow[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LastTouchConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<LastTouchConfig.AsObject>) {
    _value = _value || {};
    this.lookbackWindow = (_value.lookbackWindow || []).map(
      (m) => new LookbackWindow(m)
    );
    LastTouchConfig.refineValues(this);
  }
  get lookbackWindow(): LookbackWindow[] | undefined {
    return this._lookbackWindow;
  }
  set lookbackWindow(value: LookbackWindow[] | undefined) {
    this._lookbackWindow = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LastTouchConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LastTouchConfig.AsObject {
    return {
      lookbackWindow: (this.lookbackWindow || []).map((m) => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LastTouchConfig.AsProtobufJSON {
    return {
      lookbackWindow: (this.lookbackWindow || []).map((m) =>
        m.toProtobufJSON(options)
      ),
    };
  }
}
export module LastTouchConfig {
  /**
   * Standard JavaScript object representation for LastTouchConfig
   */
  export interface AsObject {
    lookbackWindow?: LookbackWindow.AsObject[];
  }

  /**
   * Protobuf JSON representation for LastTouchConfig
   */
  export interface AsProtobufJSON {
    lookbackWindow: LookbackWindow.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for attribution_v2.LookbackWindow
 */
export class LookbackWindow implements GrpcMessage {
  static id = 'attribution_v2.LookbackWindow';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LookbackWindow();
    LookbackWindow.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LookbackWindow) {
    _instance.type = _instance.type || 0;
    _instance.durationSeconds = _instance.durationSeconds || '0';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: LookbackWindow,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.type = _reader.readEnum();
          break;
        case 2:
          _instance.durationSeconds = _reader.readInt64String();
          break;
        default:
          _reader.skipField();
      }
    }

    LookbackWindow.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: LookbackWindow,
    _writer: BinaryWriter
  ) {
    if (_instance.type) {
      _writer.writeEnum(1, _instance.type);
    }
    if (_instance.durationSeconds) {
      _writer.writeInt64String(2, _instance.durationSeconds);
    }
  }

  private _type: attribution002.PublisherEventType;
  private _durationSeconds: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LookbackWindow to deeply clone from
   */
  constructor(_value?: RecursivePartial<LookbackWindow.AsObject>) {
    _value = _value || {};
    this.type = _value.type;
    this.durationSeconds = _value.durationSeconds;
    LookbackWindow.refineValues(this);
  }
  get type(): attribution002.PublisherEventType {
    return this._type;
  }
  set type(value: attribution002.PublisherEventType) {
    this._type = value;
  }
  get durationSeconds(): string {
    return this._durationSeconds;
  }
  set durationSeconds(value: string) {
    this._durationSeconds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LookbackWindow.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LookbackWindow.AsObject {
    return {
      type: this.type,
      durationSeconds: this.durationSeconds,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): LookbackWindow.AsProtobufJSON {
    return {
      type: attribution002.PublisherEventType[
        this.type === null || this.type === undefined ? 0 : this.type
      ],
      durationSeconds: this.durationSeconds,
    };
  }
}
export module LookbackWindow {
  /**
   * Standard JavaScript object representation for LookbackWindow
   */
  export interface AsObject {
    type: attribution002.PublisherEventType;
    durationSeconds: string;
  }

  /**
   * Protobuf JSON representation for LookbackWindow
   */
  export interface AsProtobufJSON {
    type: string;
    durationSeconds: string;
  }
}

/**
 * Message implementation for attribution_v2.Metric
 */
export class Metric implements GrpcMessage {
  static id = 'attribution_v2.Metric';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Metric();
    Metric.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Metric) {
    _instance.advertiserEventType = _instance.advertiserEventType || 0;
    _instance.metricType = _instance.metricType || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Metric, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.advertiserEventType = _reader.readEnum();
          break;
        case 2:
          _instance.metricType = _reader.readEnum();
          break;
        default:
          _reader.skipField();
      }
    }

    Metric.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Metric, _writer: BinaryWriter) {
    if (_instance.advertiserEventType) {
      _writer.writeEnum(1, _instance.advertiserEventType);
    }
    if (_instance.metricType) {
      _writer.writeEnum(2, _instance.metricType);
    }
  }

  private _advertiserEventType: attribution001.AdvertiserEventType;
  private _metricType: MetricType;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Metric to deeply clone from
   */
  constructor(_value?: RecursivePartial<Metric.AsObject>) {
    _value = _value || {};
    this.advertiserEventType = _value.advertiserEventType;
    this.metricType = _value.metricType;
    Metric.refineValues(this);
  }
  get advertiserEventType(): attribution001.AdvertiserEventType {
    return this._advertiserEventType;
  }
  set advertiserEventType(value: attribution001.AdvertiserEventType) {
    this._advertiserEventType = value;
  }
  get metricType(): MetricType {
    return this._metricType;
  }
  set metricType(value: MetricType) {
    this._metricType = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Metric.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Metric.AsObject {
    return {
      advertiserEventType: this.advertiserEventType,
      metricType: this.metricType,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): Metric.AsProtobufJSON {
    return {
      advertiserEventType:
        attribution001.AdvertiserEventType[
          this.advertiserEventType === null ||
          this.advertiserEventType === undefined
            ? 0
            : this.advertiserEventType
        ],
      metricType:
        MetricType[
          this.metricType === null || this.metricType === undefined
            ? 0
            : this.metricType
        ],
    };
  }
}
export module Metric {
  /**
   * Standard JavaScript object representation for Metric
   */
  export interface AsObject {
    advertiserEventType: attribution001.AdvertiserEventType;
    metricType: MetricType;
  }

  /**
   * Protobuf JSON representation for Metric
   */
  export interface AsProtobufJSON {
    advertiserEventType: string;
    metricType: string;
  }
}

/**
 * Message implementation for attribution_v2.AttributionEstimationConfig
 */
export class AttributionEstimationConfig implements GrpcMessage {
  static id = 'attribution_v2.AttributionEstimationConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AttributionEstimationConfig();
    AttributionEstimationConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AttributionEstimationConfig) {
    _instance.attributionAlgorithm = _instance.attributionAlgorithm || 0;
    _instance.privacyConfig = _instance.privacyConfig || undefined;
    _instance.sensitivityConfig = _instance.sensitivityConfig || undefined;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AttributionEstimationConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 3:
          _instance.attributionAlgorithm = _reader.readEnum();
          break;
        case 4:
          _instance.privacyConfig = new PrivacyConfig();
          _reader.readMessage(
            _instance.privacyConfig,
            PrivacyConfig.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.sensitivityConfig = new SensitivityConfig();
          _reader.readMessage(
            _instance.sensitivityConfig,
            SensitivityConfig.deserializeBinaryFromReader
          );
          break;
        default:
          _reader.skipField();
      }
    }

    AttributionEstimationConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AttributionEstimationConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.attributionAlgorithm) {
      _writer.writeEnum(3, _instance.attributionAlgorithm);
    }
    if (_instance.privacyConfig) {
      _writer.writeMessage(
        4,
        _instance.privacyConfig as any,
        PrivacyConfig.serializeBinaryToWriter
      );
    }
    if (_instance.sensitivityConfig) {
      _writer.writeMessage(
        5,
        _instance.sensitivityConfig as any,
        SensitivityConfig.serializeBinaryToWriter
      );
    }
  }

  private _attributionAlgorithm: AttributionAlgorithm;
  private _privacyConfig?: PrivacyConfig;
  private _sensitivityConfig?: SensitivityConfig;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AttributionEstimationConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<AttributionEstimationConfig.AsObject>) {
    _value = _value || {};
    this.attributionAlgorithm = _value.attributionAlgorithm;
    this.privacyConfig = _value.privacyConfig
      ? new PrivacyConfig(_value.privacyConfig)
      : undefined;
    this.sensitivityConfig = _value.sensitivityConfig
      ? new SensitivityConfig(_value.sensitivityConfig)
      : undefined;
    AttributionEstimationConfig.refineValues(this);
  }
  get attributionAlgorithm(): AttributionAlgorithm {
    return this._attributionAlgorithm;
  }
  set attributionAlgorithm(value: AttributionAlgorithm) {
    this._attributionAlgorithm = value;
  }
  get privacyConfig(): PrivacyConfig | undefined {
    return this._privacyConfig;
  }
  set privacyConfig(value: PrivacyConfig | undefined) {
    this._privacyConfig = value;
  }
  get sensitivityConfig(): SensitivityConfig | undefined {
    return this._sensitivityConfig;
  }
  set sensitivityConfig(value: SensitivityConfig | undefined) {
    this._sensitivityConfig = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AttributionEstimationConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AttributionEstimationConfig.AsObject {
    return {
      attributionAlgorithm: this.attributionAlgorithm,
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toObject()
        : undefined,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toObject()
        : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AttributionEstimationConfig.AsProtobufJSON {
    return {
      attributionAlgorithm:
        AttributionAlgorithm[
          this.attributionAlgorithm === null ||
          this.attributionAlgorithm === undefined
            ? 0
            : this.attributionAlgorithm
        ],
      privacyConfig: this.privacyConfig
        ? this.privacyConfig.toProtobufJSON(options)
        : null,
      sensitivityConfig: this.sensitivityConfig
        ? this.sensitivityConfig.toProtobufJSON(options)
        : null,
    };
  }
}
export module AttributionEstimationConfig {
  /**
   * Standard JavaScript object representation for AttributionEstimationConfig
   */
  export interface AsObject {
    attributionAlgorithm: AttributionAlgorithm;
    privacyConfig?: PrivacyConfig.AsObject;
    sensitivityConfig?: SensitivityConfig.AsObject;
  }

  /**
   * Protobuf JSON representation for AttributionEstimationConfig
   */
  export interface AsProtobufJSON {
    attributionAlgorithm: string;
    privacyConfig: PrivacyConfig.AsProtobufJSON | null;
    sensitivityConfig: SensitivityConfig.AsProtobufJSON | null;
  }
}

/**
 * Message implementation for attribution_v2.AttributionConfig
 */
export class AttributionConfig implements GrpcMessage {
  static id = 'attribution_v2.AttributionConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AttributionConfig();
    AttributionConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AttributionConfig) {
    _instance.publisherCustomerDataSet =
      _instance.publisherCustomerDataSet || undefined;
    _instance.advertiserCustomerDataSet =
      _instance.advertiserCustomerDataSet || undefined;
    _instance.timeWindow = _instance.timeWindow || undefined;
    _instance.matchingConfig = _instance.matchingConfig || undefined;
    _instance.groups = _instance.groups || [];
    _instance.lastTouchConfig = _instance.lastTouchConfig || undefined;
    _instance.metrics = _instance.metrics || [];
    _instance.attributionEstimationConfigs =
      _instance.attributionEstimationConfigs || [];
    _instance.enableDebugLogging = _instance.enableDebugLogging || false;
    _instance.maxPiiPerImpression = _instance.maxPiiPerImpression || 0;
    _instance.campaignIds = _instance.campaignIds || [];
    _instance.adgroupIds = _instance.adgroupIds || [];
    _instance.adIds = _instance.adIds || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AttributionConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 11:
          _instance.publisherCustomerDataSet =
            new storage004.CustomerDataSetReference();
          _reader.readMessage(
            _instance.publisherCustomerDataSet,
            storage004.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 12:
          _instance.advertiserCustomerDataSet =
            new storage004.CustomerDataSetReference();
          _reader.readMessage(
            _instance.advertiserCustomerDataSet,
            storage004.CustomerDataSetReference.deserializeBinaryFromReader
          );
          break;
        case 3:
          _instance.timeWindow = new TimeWindow();
          _reader.readMessage(
            _instance.timeWindow,
            TimeWindow.deserializeBinaryFromReader
          );
          break;
        case 4:
          _instance.matchingConfig = new matching003.MatchingConfig();
          _reader.readMessage(
            _instance.matchingConfig,
            matching003.MatchingConfig.deserializeBinaryFromReader
          );
          break;
        case 5:
          const messageInitializer5 = new GroupConfig();
          _reader.readMessage(
            messageInitializer5,
            GroupConfig.deserializeBinaryFromReader
          );
          (_instance.groups = _instance.groups || []).push(messageInitializer5);
          break;
        case 6:
          _instance.lastTouchConfig = new LastTouchConfig();
          _reader.readMessage(
            _instance.lastTouchConfig,
            LastTouchConfig.deserializeBinaryFromReader
          );
          break;
        case 7:
          const messageInitializer7 = new Metric();
          _reader.readMessage(
            messageInitializer7,
            Metric.deserializeBinaryFromReader
          );
          (_instance.metrics = _instance.metrics || []).push(
            messageInitializer7
          );
          break;
        case 10:
          const messageInitializer10 = new AttributionEstimationConfig();
          _reader.readMessage(
            messageInitializer10,
            AttributionEstimationConfig.deserializeBinaryFromReader
          );
          (_instance.attributionEstimationConfigs =
            _instance.attributionEstimationConfigs || []).push(
            messageInitializer10
          );
          break;
        case 9:
          _instance.enableDebugLogging = _reader.readBool();
          break;
        case 13:
          _instance.maxPiiPerImpression = _reader.readInt32();
          break;
        case 14:
          (_instance.campaignIds = _instance.campaignIds || []).push(
            _reader.readString()
          );
          break;
        case 15:
          (_instance.adgroupIds = _instance.adgroupIds || []).push(
            _reader.readString()
          );
          break;
        case 16:
          (_instance.adIds = _instance.adIds || []).push(_reader.readString());
          break;
        default:
          _reader.skipField();
      }
    }

    AttributionConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AttributionConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.publisherCustomerDataSet) {
      _writer.writeMessage(
        11,
        _instance.publisherCustomerDataSet as any,
        storage004.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.advertiserCustomerDataSet) {
      _writer.writeMessage(
        12,
        _instance.advertiserCustomerDataSet as any,
        storage004.CustomerDataSetReference.serializeBinaryToWriter
      );
    }
    if (_instance.timeWindow) {
      _writer.writeMessage(
        3,
        _instance.timeWindow as any,
        TimeWindow.serializeBinaryToWriter
      );
    }
    if (_instance.matchingConfig) {
      _writer.writeMessage(
        4,
        _instance.matchingConfig as any,
        matching003.MatchingConfig.serializeBinaryToWriter
      );
    }
    if (_instance.groups && _instance.groups.length) {
      _writer.writeRepeatedMessage(
        5,
        _instance.groups as any,
        GroupConfig.serializeBinaryToWriter
      );
    }
    if (_instance.lastTouchConfig) {
      _writer.writeMessage(
        6,
        _instance.lastTouchConfig as any,
        LastTouchConfig.serializeBinaryToWriter
      );
    }
    if (_instance.metrics && _instance.metrics.length) {
      _writer.writeRepeatedMessage(
        7,
        _instance.metrics as any,
        Metric.serializeBinaryToWriter
      );
    }
    if (
      _instance.attributionEstimationConfigs &&
      _instance.attributionEstimationConfigs.length
    ) {
      _writer.writeRepeatedMessage(
        10,
        _instance.attributionEstimationConfigs as any,
        AttributionEstimationConfig.serializeBinaryToWriter
      );
    }
    if (_instance.enableDebugLogging) {
      _writer.writeBool(9, _instance.enableDebugLogging);
    }
    if (_instance.maxPiiPerImpression) {
      _writer.writeInt32(13, _instance.maxPiiPerImpression);
    }
    if (_instance.campaignIds && _instance.campaignIds.length) {
      _writer.writeRepeatedString(14, _instance.campaignIds);
    }
    if (_instance.adgroupIds && _instance.adgroupIds.length) {
      _writer.writeRepeatedString(15, _instance.adgroupIds);
    }
    if (_instance.adIds && _instance.adIds.length) {
      _writer.writeRepeatedString(16, _instance.adIds);
    }
  }

  private _publisherCustomerDataSet?: storage004.CustomerDataSetReference;
  private _advertiserCustomerDataSet?: storage004.CustomerDataSetReference;
  private _timeWindow?: TimeWindow;
  private _matchingConfig?: matching003.MatchingConfig;
  private _groups?: GroupConfig[];
  private _lastTouchConfig?: LastTouchConfig;
  private _metrics?: Metric[];
  private _attributionEstimationConfigs?: AttributionEstimationConfig[];
  private _enableDebugLogging: boolean;
  private _maxPiiPerImpression: number;
  private _campaignIds: string[];
  private _adgroupIds: string[];
  private _adIds: string[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AttributionConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<AttributionConfig.AsObject>) {
    _value = _value || {};
    this.publisherCustomerDataSet = _value.publisherCustomerDataSet
      ? new storage004.CustomerDataSetReference(_value.publisherCustomerDataSet)
      : undefined;
    this.advertiserCustomerDataSet = _value.advertiserCustomerDataSet
      ? new storage004.CustomerDataSetReference(
          _value.advertiserCustomerDataSet
        )
      : undefined;
    this.timeWindow = _value.timeWindow
      ? new TimeWindow(_value.timeWindow)
      : undefined;
    this.matchingConfig = _value.matchingConfig
      ? new matching003.MatchingConfig(_value.matchingConfig)
      : undefined;
    this.groups = (_value.groups || []).map((m) => new GroupConfig(m));
    this.lastTouchConfig = _value.lastTouchConfig
      ? new LastTouchConfig(_value.lastTouchConfig)
      : undefined;
    this.metrics = (_value.metrics || []).map((m) => new Metric(m));
    this.attributionEstimationConfigs = (
      _value.attributionEstimationConfigs || []
    ).map((m) => new AttributionEstimationConfig(m));
    this.enableDebugLogging = _value.enableDebugLogging;
    this.maxPiiPerImpression = _value.maxPiiPerImpression;
    this.campaignIds = (_value.campaignIds || []).slice();
    this.adgroupIds = (_value.adgroupIds || []).slice();
    this.adIds = (_value.adIds || []).slice();
    AttributionConfig.refineValues(this);
  }
  get publisherCustomerDataSet():
    | storage004.CustomerDataSetReference
    | undefined {
    return this._publisherCustomerDataSet;
  }
  set publisherCustomerDataSet(
    value: storage004.CustomerDataSetReference | undefined
  ) {
    this._publisherCustomerDataSet = value;
  }
  get advertiserCustomerDataSet():
    | storage004.CustomerDataSetReference
    | undefined {
    return this._advertiserCustomerDataSet;
  }
  set advertiserCustomerDataSet(
    value: storage004.CustomerDataSetReference | undefined
  ) {
    this._advertiserCustomerDataSet = value;
  }
  get timeWindow(): TimeWindow | undefined {
    return this._timeWindow;
  }
  set timeWindow(value: TimeWindow | undefined) {
    this._timeWindow = value;
  }
  get matchingConfig(): matching003.MatchingConfig | undefined {
    return this._matchingConfig;
  }
  set matchingConfig(value: matching003.MatchingConfig | undefined) {
    this._matchingConfig = value;
  }
  get groups(): GroupConfig[] | undefined {
    return this._groups;
  }
  set groups(value: GroupConfig[] | undefined) {
    this._groups = value;
  }
  get lastTouchConfig(): LastTouchConfig | undefined {
    return this._lastTouchConfig;
  }
  set lastTouchConfig(value: LastTouchConfig | undefined) {
    this._lastTouchConfig = value;
  }
  get metrics(): Metric[] | undefined {
    return this._metrics;
  }
  set metrics(value: Metric[] | undefined) {
    this._metrics = value;
  }
  get attributionEstimationConfigs():
    | AttributionEstimationConfig[]
    | undefined {
    return this._attributionEstimationConfigs;
  }
  set attributionEstimationConfigs(
    value: AttributionEstimationConfig[] | undefined
  ) {
    this._attributionEstimationConfigs = value;
  }
  get enableDebugLogging(): boolean {
    return this._enableDebugLogging;
  }
  set enableDebugLogging(value: boolean) {
    this._enableDebugLogging = value;
  }
  get maxPiiPerImpression(): number {
    return this._maxPiiPerImpression;
  }
  set maxPiiPerImpression(value: number) {
    this._maxPiiPerImpression = value;
  }
  get campaignIds(): string[] {
    return this._campaignIds;
  }
  set campaignIds(value: string[]) {
    this._campaignIds = value;
  }
  get adgroupIds(): string[] {
    return this._adgroupIds;
  }
  set adgroupIds(value: string[]) {
    this._adgroupIds = value;
  }
  get adIds(): string[] {
    return this._adIds;
  }
  set adIds(value: string[]) {
    this._adIds = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AttributionConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AttributionConfig.AsObject {
    return {
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toObject()
        : undefined,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toObject()
        : undefined,
      timeWindow: this.timeWindow ? this.timeWindow.toObject() : undefined,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toObject()
        : undefined,
      groups: (this.groups || []).map((m) => m.toObject()),
      lastTouchConfig: this.lastTouchConfig
        ? this.lastTouchConfig.toObject()
        : undefined,
      metrics: (this.metrics || []).map((m) => m.toObject()),
      attributionEstimationConfigs: (
        this.attributionEstimationConfigs || []
      ).map((m) => m.toObject()),
      enableDebugLogging: this.enableDebugLogging,
      maxPiiPerImpression: this.maxPiiPerImpression,
      campaignIds: (this.campaignIds || []).slice(),
      adgroupIds: (this.adgroupIds || []).slice(),
      adIds: (this.adIds || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AttributionConfig.AsProtobufJSON {
    return {
      publisherCustomerDataSet: this.publisherCustomerDataSet
        ? this.publisherCustomerDataSet.toProtobufJSON(options)
        : null,
      advertiserCustomerDataSet: this.advertiserCustomerDataSet
        ? this.advertiserCustomerDataSet.toProtobufJSON(options)
        : null,
      timeWindow: this.timeWindow
        ? this.timeWindow.toProtobufJSON(options)
        : null,
      matchingConfig: this.matchingConfig
        ? this.matchingConfig.toProtobufJSON(options)
        : null,
      groups: (this.groups || []).map((m) => m.toProtobufJSON(options)),
      lastTouchConfig: this.lastTouchConfig
        ? this.lastTouchConfig.toProtobufJSON(options)
        : null,
      metrics: (this.metrics || []).map((m) => m.toProtobufJSON(options)),
      attributionEstimationConfigs: (
        this.attributionEstimationConfigs || []
      ).map((m) => m.toProtobufJSON(options)),
      enableDebugLogging: this.enableDebugLogging,
      maxPiiPerImpression: this.maxPiiPerImpression,
      campaignIds: (this.campaignIds || []).slice(),
      adgroupIds: (this.adgroupIds || []).slice(),
      adIds: (this.adIds || []).slice(),
    };
  }
}
export module AttributionConfig {
  /**
   * Standard JavaScript object representation for AttributionConfig
   */
  export interface AsObject {
    publisherCustomerDataSet?: storage004.CustomerDataSetReference.AsObject;
    advertiserCustomerDataSet?: storage004.CustomerDataSetReference.AsObject;
    timeWindow?: TimeWindow.AsObject;
    matchingConfig?: matching003.MatchingConfig.AsObject;
    groups?: GroupConfig.AsObject[];
    lastTouchConfig?: LastTouchConfig.AsObject;
    metrics?: Metric.AsObject[];
    attributionEstimationConfigs?: AttributionEstimationConfig.AsObject[];
    enableDebugLogging: boolean;
    maxPiiPerImpression: number;
    campaignIds: string[];
    adgroupIds: string[];
    adIds: string[];
  }

  /**
   * Protobuf JSON representation for AttributionConfig
   */
  export interface AsProtobufJSON {
    publisherCustomerDataSet: storage004.CustomerDataSetReference.AsProtobufJSON | null;
    advertiserCustomerDataSet: storage004.CustomerDataSetReference.AsProtobufJSON | null;
    timeWindow: TimeWindow.AsProtobufJSON | null;
    matchingConfig: matching003.MatchingConfig.AsProtobufJSON | null;
    groups: GroupConfig.AsProtobufJSON[] | null;
    lastTouchConfig: LastTouchConfig.AsProtobufJSON | null;
    metrics: Metric.AsProtobufJSON[] | null;
    attributionEstimationConfigs:
      | AttributionEstimationConfig.AsProtobufJSON[]
      | null;
    enableDebugLogging: boolean;
    maxPiiPerImpression: number;
    campaignIds: string[];
    adgroupIds: string[];
    adIds: string[];
  }
}

/**
 * Message implementation for attribution_v2.PrivacyConfig
 */
export class PrivacyConfig implements GrpcMessage {
  static id = 'attribution_v2.PrivacyConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new PrivacyConfig();
    PrivacyConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: PrivacyConfig) {
    _instance.rho = _instance.rho || 0;
    _instance.rhoDivision = _instance.rhoDivision || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: PrivacyConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.rho = _reader.readFloat();
          break;
        case 2:
          (_instance.rhoDivision = _instance.rhoDivision || []).push(
            ...(_reader.readPackedFloat() || [])
          );
          break;
        default:
          _reader.skipField();
      }
    }

    PrivacyConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: PrivacyConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.rho) {
      _writer.writeFloat(1, _instance.rho);
    }
    if (_instance.rhoDivision && _instance.rhoDivision.length) {
      _writer.writePackedFloat(2, _instance.rhoDivision);
    }
  }

  private _rho: number;
  private _rhoDivision: number[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of PrivacyConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<PrivacyConfig.AsObject>) {
    _value = _value || {};
    this.rho = _value.rho;
    this.rhoDivision = (_value.rhoDivision || []).slice();
    PrivacyConfig.refineValues(this);
  }
  get rho(): number {
    return this._rho;
  }
  set rho(value: number) {
    this._rho = value;
  }
  get rhoDivision(): number[] {
    return this._rhoDivision;
  }
  set rhoDivision(value: number[]) {
    this._rhoDivision = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    PrivacyConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): PrivacyConfig.AsObject {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): PrivacyConfig.AsProtobufJSON {
    return {
      rho: this.rho,
      rhoDivision: (this.rhoDivision || []).slice(),
    };
  }
}
export module PrivacyConfig {
  /**
   * Standard JavaScript object representation for PrivacyConfig
   */
  export interface AsObject {
    rho: number;
    rhoDivision: number[];
  }

  /**
   * Protobuf JSON representation for PrivacyConfig
   */
  export interface AsProtobufJSON {
    rho: number;
    rhoDivision: number[];
  }
}

/**
 * Message implementation for attribution_v2.SensitivityConfig
 */
export class SensitivityConfig implements GrpcMessage {
  static id = 'attribution_v2.SensitivityConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new SensitivityConfig();
    SensitivityConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: SensitivityConfig) {
    _instance.percentile = _instance.percentile || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: SensitivityConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.percentile = _reader.readFloat();
          break;
        default:
          _reader.skipField();
      }
    }

    SensitivityConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: SensitivityConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.percentile) {
      _writer.writeFloat(1, _instance.percentile);
    }
  }

  private _percentile: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of SensitivityConfig to deeply clone from
   */
  constructor(_value?: RecursivePartial<SensitivityConfig.AsObject>) {
    _value = _value || {};
    this.percentile = _value.percentile;
    SensitivityConfig.refineValues(this);
  }
  get percentile(): number {
    return this._percentile;
  }
  set percentile(value: number) {
    this._percentile = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    SensitivityConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): SensitivityConfig.AsObject {
    return {
      percentile: this.percentile,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): SensitivityConfig.AsProtobufJSON {
    return {
      percentile: this.percentile,
    };
  }
}
export module SensitivityConfig {
  /**
   * Standard JavaScript object representation for SensitivityConfig
   */
  export interface AsObject {
    percentile: number;
  }

  /**
   * Protobuf JSON representation for SensitivityConfig
   */
  export interface AsProtobufJSON {
    percentile: number;
  }
}
