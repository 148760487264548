<app-container>
  <app-page-header title="Job Results" lastUpdated="{{ lastUpdate }}">
  </app-page-header>
  <app-message-box></app-message-box>

  <div class="sub-header">
    <div class="meta-group">
      <div class="meta-item">
        <div class="label">Name</div>
        <div class="value">{{ jobLog.metadata!.description }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Type</div>
        <div class="value">{{ headerBinaryType }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Date</div>
        <div class="value">{{ headerJobTime }}</div>
      </div>
      <div class="meta-item">
        <div class="label">State</div>
        <div class="value">{{ headerState }}</div>
      </div>
      <div class="meta-item" *ngIf="headerPubCustomerId">
        <div class="label">Publisher</div>
        <div class="value">{{ headerPubCustomerId }}</div>
      </div>
      <div class="meta-item" *ngIf="headerAdvCustomerId">
        <div class="label">Advertiser</div>
        <div class="value">{{ headerAdvCustomerId }}</div>
      </div>
      <div class="meta-item">
        <div class="label">Region</div>
        <div class="value">{{ headerRegion }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyId">
        <div class="label">Study ID</div>
        <div class="value">{{ headerStudyId }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyStartTime">
        <div class="label">Study Start Date</div>
        <div class="value">{{ headerStudyStartTime }}</div>
      </div>
      <div class="meta-item" *ngIf="headerStudyEndTime">
        <div class="label">Study End Date</div>
        <div class="value">{{ headerStudyEndTime }}</div>
      </div>
      <div class="meta-item" *ngIf="headerAdvEndTime">
        <div class="label">Conversions End Date</div>
        <div class="value">{{ headerAdvEndTime }}</div>
      </div>
    </div>
  </div>
  <br />
  <br />
  <section class="data-table-container" tabindex="0">
    <div *ngIf="isLoading" class="loader">
      <mat-spinner data-testid="datasetsSpinner" diameter="42"></mat-spinner>
    </div>

    <mat-tab-group>
      <mat-tab label="Results">
        <mat-card *ngIf="resultsUiEnabled" appearance="outlined">
          <div class="card-header">
            <div>
              <div class="stat-group">
                <h3>Pipeline stats</h3>
                <div class="stats">
                  <div
                    class="stat-card"
                    *ngFor="let stat of postProcessedData!.highlightedStats"
                  >
                    <div class="label">{{ stat.key }}</div>
                    <div class="value">{{ stat.value }}</div>
                  </div>
                </div>
              </div>

              <div *ngFor="let resultsTable of postProcessedData!.results">
                <div class="stat-group">
                  <h3>Lift Results</h3>
                  <div class="sub-header">
                    <div class="meta-group">
                      <div class="meta-item">
                        <div class="label">Label</div>
                        <div class="value-lower">{{ resultsTable.label }}</div>
                      </div>
                      <div class="meta-item">
                        <div class="label">Algorithm</div>
                        <div class="value-lower">
                          {{ resultsTable.algorithm }}
                        </div>
                      </div>
                      <div class="meta-item">
                        <div class="label">Rho</div>
                        <div class="value-lower">{{ resultsTable.rho }}</div>
                      </div>
                      <div class="meta-item">
                        <div class="label">Sensitivity percentile</div>
                        <div class="value-lower">
                          {{ resultsTable.sensitivity }}%
                        </div>
                      </div>
                      <div class="meta-item">
                        <div class="label">Match keys</div>
                        <div class="value-lower">
                          {{ resultsTable.matchKeys }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="results-table">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th *ngFor="let column of resultsTable.columns">
                            {{ column.columnName }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Test size</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.testSize }}
                          </td>
                        </tr>
                        <tr>
                          <td>Control size</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.controlSize }}
                          </td>
                        </tr>
                        <tr>
                          <td>Relative lift</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.relativeLift }}
                          </td>
                        </tr>
                        <tr>
                          <td>Significance (90%) CI</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.ciLower }} to {{ column.ciUpper }}
                          </td>
                        </tr>
                        <tr>
                          <td>Significance</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.significance }}
                          </td>
                        </tr>
                        <tr>
                          <td>Test conversions</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.testConversions }}
                          </td>
                        </tr>
                        <tr>
                          <td>Scaled control conversions</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.scaledControlConversions }}
                          </td>
                        </tr>
                        <tr>
                          <td>Incremental conversions</td>
                          <td *ngFor="let column of resultsTable.columns">
                            {{ column.incrementalConversions }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Validations">
        <mat-card *ngIf="resultsUiEnabled" appearance="outlined">
          <div class="stat-group">
            <h3>Validations</h3>
            <div class="results-table">
              <table>
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let validation of postProcessedData!.validations">
                    <td>{{ validation.description }}</td>
                    <td>
                      <span *ngIf="validation.passed" class="pass">Pass</span>
                      <span *ngIf="!validation.passed" class="fail">Fail</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-card>
      </mat-tab>
      <mat-tab label="Config">
        <mat-card appearance="outlined">
          <pre><code [highlight]="formatJson(jobLogJson)" language="json"></code></pre>
        </mat-card>
      </mat-tab>

      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
        "
        label="Debug logs"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON)
                "
                matTooltip="Download Json log file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
          )
        "
        label="Raw results"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
                  )
                "
                matTooltip="Download CSV file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
                  )
                "
                matTooltip="Download csv file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <app-csv-viewer
            [csv]="
              getContent(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [title]="
              getJobFileTypeResultLabel(
                jobResultFileTypeList.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV
              )
            "
            [displayColumnFilter]="true"
            class="viewer"
          ></app-csv-viewer>
        </mat-card>
      </mat-tab>
      <mat-tab
        *ngIf="
          isDisplayed(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON)
        "
        label="{{
          getJobFileTypeResultLabel(
            jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
          )
        }}"
      >
        <mat-card appearance="outlined">
          <div class="card-header">
            <div>
              <mat-card-header>
                <h2 class="data-users-title">
                  {{
                    getJobFileTypeResultLabel(
                      jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                    )
                  }}
                </h2>
              </mat-card-header>
            </div>
            <div>
              <button
                class="toggle-btn"
                mat-icon-button
                color="primary"
                (click)="
                  download(
                    jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON
                  )
                "
                matTooltip="Download json file."
                #tooltipTarget="matTooltip"
                (click)="tooltipTarget.show()"
              >
                <mat-icon class="download-icon">cloud_download</mat-icon>
              </button>
            </div>
          </div>
          <pre><code [highlight]="formatJson(getContent(jobResultFileTypeList.JOB_RESULT_FILE_TYPE_OUTPUT_JSON))" language="json"></code></pre>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </section>
</app-container>
