import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  GRPC_MESSAGE_POOL,
  TYPE_ID_TO_BINARY_TYPE,
} from 'app/constants/lookups';
import { ApprovalService } from 'app/services/approval.service';
import { CustomerDataService } from 'app/services/customer-data.service';
import {
  ApprovalRequestFilter,
  GetRequest,
} from 'app/services/generated/src/main/proto/api/approval-service.pb';
import { GetRequest as JobGetRequest } from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { AttributionConfig } from 'app/services/generated/src/main/proto/attribution/attribution-config.pb';
import { LiftConfig } from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { KeyService } from 'app/services/key.service';
import { LoggerService } from 'app/services/logger.service';

import { JobLogService } from '../../services/job-log.service';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  templateUrl: './job-debugger.component.html',
  styleUrls: ['./job-debugger.component.scss'],
})
export class JobDebuggerComponent implements AfterViewInit {
  isLoading = false;
  @ViewChild('jobLog') jobLog!: ElementRef;
  @ViewChild('approvalRequest') approvalRequest!: ElementRef;
  @ViewChild('advertiserCustomerDataSet')
  advertiserCustomerDataSet!: ElementRef;
  @ViewChild('publisherCustomerDataSet') publisherCustomerDataSet!: ElementRef;
  @ViewChild('advertiserKey') advertiserKey!: ElementRef;
  @ViewChild('publisherKey') publisherKey!: ElementRef;

  constructor(
    private customerDataSetService: CustomerDataService,
    private jobLogService: JobLogService,
    private keyService: KeyService,
    private approvalService: ApprovalService,
    @Inject(LOCALE_ID) public locale: string,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private route: ActivatedRoute
  ) {}

  async renderCustomerDataSet(
    id: string,
    element: ElementRef,
    keyElement: ElementRef
  ) {
    const response = await this.customerDataSetService.get(id);
    element.nativeElement.textContent = JSON.stringify(
      response.toProtobufJSON({
        messagePool: GRPC_MESSAGE_POOL,
      }),
      undefined,
      2
    );
    if (response.customerDataSet?.encryptedData?.keyId) {
      const keyResponse = await this.keyService.getKey(
        response.customerDataSet.encryptedData.keyId
      );
      keyElement.nativeElement.textContent = JSON.stringify(
        keyResponse.toProtobufJSON({
          messagePool: GRPC_MESSAGE_POOL,
        }),
        undefined,
        2
      );
    }
  }

  getJobLog(jobLogId: string) {
    this.isLoading = true;

    this.jobLogService
      .get(
        new JobGetRequest({
          jobLogId: jobLogId,
        })
      )
      .then((jobLogResponse) => {
        this.jobLog.nativeElement.textContent = JSON.stringify(
          jobLogResponse.toProtobufJSON({
            messagePool: GRPC_MESSAGE_POOL,
          }),
          undefined,
          2
        );

        const jobLog = jobLogResponse.jobLogs![0].jobLog!;
        const approvalRequestId = jobLog.binary!.approvalRequestId;
        this.approvalService
          .getAllCustomers(
            new GetRequest({
              getRequestFilter: new ApprovalRequestFilter({
                approvalRequestId: [approvalRequestId],
              }),
            })
          )
          .then((approvalRequest) => {
            this.approvalRequest.nativeElement.textContent = JSON.stringify(
              approvalRequest.toProtobufJSON({
                messagePool: GRPC_MESSAGE_POOL,
              }),
              undefined,
              2
            );
          });

        let binaryType;
        if (jobLog.binaryConfig) {
          binaryType =
            TYPE_ID_TO_BINARY_TYPE[jobLog.binaryConfig.getPackedMessageId()!];
        } else {
          binaryType = jobLog.binaryType;
        }
        switch (binaryType) {
          case BinaryType.BINARY_TYPE_ATTRIBUTION: {
            const attributionConfig =
              jobLog.binaryConfig!.unpack<AttributionConfig>(
                GRPC_MESSAGE_POOL
              )!;
            this.renderCustomerDataSet(
              attributionConfig.advertiserCustomerDataSet!.id,
              this.advertiserCustomerDataSet,
              this.advertiserKey
            );
            this.renderCustomerDataSet(
              attributionConfig.publisherCustomerDataSet!.id,
              this.publisherCustomerDataSet,
              this.publisherKey
            );
            break;
          }
          case BinaryType.BINARY_TYPE_LIFT: {
            const liftConfig =
              jobLog.binaryConfig!.unpack<LiftConfig>(GRPC_MESSAGE_POOL)!;
            this.renderCustomerDataSet(
              liftConfig.advertiserCustomerDataSet!.id,
              this.advertiserCustomerDataSet,
              this.advertiserKey
            );
            this.renderCustomerDataSet(
              liftConfig.publisherCustomerDataSet!.id,
              this.publisherCustomerDataSet,
              this.publisherKey
            );
            break;
          }
        }
      })
      .catch((error) => {
        this.messageBox.error(
          'Unable to load job data: ' + error.statusMessage
        );
        this.logger.error('Error loading job log', error);
      })
      .finally(() => (this.isLoading = false));
  }

  search(jobLogId: string) {
    this.getJobLog(jobLogId);
  }

  async ngAfterViewInit() {
    const jobId = this.route.snapshot.paramMap.get('id');
    if (jobId) {
      this.search(jobId);
    }
  }
}
