import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BinaryTypeService } from 'app/services/binary-type.service';
import { CustomerDataService } from 'app/services/customer-data.service';
import { FormatService } from 'app/services/format.service';
import {
  GetJobResultsRequest,
  GetRequest,
  JobResultFile,
  JobResultFileType,
} from 'app/services/generated/src/main/proto/api/job-log-service.pb';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import {
  LiftAlgorithm,
  LiftConfig,
  Metric,
  MetricType,
} from 'app/services/generated/src/main/proto/lift/lift-config.pb';
import { JobLog } from 'app/services/generated/src/main/proto/storage/job-log.pb';
import { JobLogService } from 'app/services/job-log.service';
import { LoggerService } from 'app/services/logger.service';
import { Highlight, HighlightAuto } from 'ngx-highlightjs';
import Papa from 'papaparse';

import {
  GRPC_MESSAGE_POOL,
  JOB_STATE_TO_STRING,
  JobResultFileTypeList,
  LocationList,
  TYPE_ID_TO_BINARY_TYPE,
} from '../../constants/lookups';
import { BinaryType } from '../../services/generated/src/main/proto/storage/binary-type.pb';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

interface LiftRow {
  lift_estimate_index: string;
  groupby_index: number;
  event_type: string;
  metric_type: string;
  lift_algorithm: string;
  num_control_publisher_records: string;
  num_treatment_publisher_records: string;
  rho_total: string;
  num_treatment_users: string;
  num_control_users: string;
  output_converter_threshold_release: string;
  output_relative_lift_ci_lower: string;
  output_relative_lift_ci_upper: string;
  output_relative_lift_release: string;
  output_test_mean_release: string;
  output_control_mean_release: string;
  output_absolute_lift_release: string;
  output_relative_lift_sigstars_release: string;
}

interface HighlightedStat {
  key: string;
  value: string;
}

interface LiftColumnData {
  columnName: string;
  testSize: string;
  controlSize: string;
  relativeLift: string;
  ciLower: string;
  ciUpper: string;
  significance: string;
  testConversions: string;
  scaledControlConversions: string;
  incrementalConversions: string;
}

interface ResultTable {
  label: string;
  algorithm: string;
  rho: string;
  sensitivity: string;
  matchKeys: string;
  columns: LiftColumnData[];
}

interface Validation {
  description: string;
  passed: boolean;
}

interface PostProcessedData {
  highlightedStats: HighlightedStat[];
  results: ResultTable[];
  validations: Validation[];
}

@Component({
  selector: 'app-admin-job-results',
  providers: [DatePipe, Highlight, HighlightAuto],
  templateUrl: './admin-job-results.component.html',
  styleUrls: ['./admin-job-results.component.scss'],
})
export class AdminJobResultsComponent implements OnInit {
  binaryTypeToName = new Map<BinaryType, string>();
  contents = new Map();
  currentFileView: JobResultFile | undefined;
  isLoading = false;
  jobId: string | null = null;
  jobResultFileTypeList = JobResultFileType;
  jobResults: JobResultFile[] | undefined;
  lastUpdate = this.formatService.getLastUpdate();
  jobResultFileTypeDisplay = new Map();
  csvRecords: any;
  header: boolean = false;
  jobLog: JobLog = new JobLog();
  headerBinaryType: string | undefined;
  headerJobTime: string | undefined;
  headerState: string | undefined;
  headerRegion: string | undefined;
  headerPubCustomerId: string | undefined;
  headerAdvCustomerId: string | undefined;
  headerStudyId: string | undefined;
  headerStudyStartTime: string | undefined;
  headerStudyEndTime: string | undefined;
  headerAdvEndTime: string | undefined;
  jobLogJson: string | undefined;
  postProcessedData: PostProcessedData | undefined;
  resultsUiEnabled: boolean = false;

  constructor(
    private customerDataSetService: CustomerDataService,
    private binaryTypeService: BinaryTypeService,
    private formatService: FormatService,
    private jobLogService: JobLogService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: ActivatedRoute
  ) {
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_LOGS_JSON,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_OUTPUT_JSON,
      false
    );
    this.jobResultFileTypeDisplay.set(
      JobResultFileType.JOB_RESULT_FILE_TYPE_PERFORMANCE_CSV,
      false
    );
  }

  async ngOnInit() {
    this.jobId = this.router.snapshot.paramMap.get('id');

    if (this.jobId) {
      this.viewResults(this.jobId);
    } else {
      this.messageBox.error(
        'The Job Id parameter is missing from the request.'
      );
    }
  }

  async viewResults(id: string) {
    this.isLoading = true;
    await this.loadJobLog(id);
    await this.loadBinaryTypes();

    try {
      const getJobsResultsRequest = new GetJobResultsRequest();
      getJobsResultsRequest.jobLogId = id;
      const response = await this.jobLogService.getJobResults(
        getJobsResultsRequest
      );
      this.jobResults = response.jobResultFiles;
      if (this.jobResults) {
        this.jobResults.forEach((result) => {
          this.jobResultFileTypeDisplay.set(result.jobResultFileType, true);
        });
      }
    } catch (error) {
      this.logger.error('Failed to load job results with error: {}', error);
    } finally {
      this.isLoading = false;
    }

    // Render the data for the header row.
    const binaryType = this.jobLog.binaryConfig
      ? TYPE_ID_TO_BINARY_TYPE[this.jobLog.binaryConfig.getPackedMessageId()!]
      : this.jobLog.binaryType;
    await this.viewHeader(binaryType);

    // Render the post-processed and formatted Lift data.
    if (binaryType == BinaryType.BINARY_TYPE_LIFT) {
      this.viewLiftResults();
      this.resultsUiEnabled = true;
    }
  }

  async loadBinaryTypes() {
    try {
      const response = await this.binaryTypeService.getBinaryTypes();
      response.binaryTypeInfos?.forEach((info) => {
        this.binaryTypeToName.set(info.binaryType, info.name);
      });
    } catch (error) {
      this.logger.error('Error loading binary types:', error);
    }
  }

  async loadJobLog(id: string) {
    const jobResponse = await this.jobLogService.get(
      new GetRequest({
        jobLogId: id,
      })
    );
    this.jobLog = jobResponse.jobLogs![0].jobLog!;
    this.jobLogJson = JSON.stringify(
      this.jobLog.toProtobufJSON({
        messagePool: GRPC_MESSAGE_POOL,
      }),
      undefined,
      2
    );
  }

  async viewHeader(binaryType: BinaryType) {
    this.headerBinaryType = this.binaryTypeToName.get(binaryType);

    this.headerJobTime = this.formatService.formatProtoDateForInput(
      this.jobLog.creationTimestamp
    )!;
    this.headerState = JOB_STATE_TO_STRING[this.jobLog.state!];
    this.headerRegion = LocationList.find(
      (l) => l.value == this.jobLog.location
    )!.name;
    if (binaryType == BinaryType.BINARY_TYPE_LIFT) {
      const liftConfig =
        this.jobLog.binaryConfig!.unpack<LiftConfig>(GRPC_MESSAGE_POOL)!;
      const pubCustomerDataSet = await this.customerDataSetService.get(
        liftConfig.publisherCustomerDataSet!.id
      );
      this.headerPubCustomerId = pubCustomerDataSet.customerDataSet?.customerId;
      const advCustomerDataSet = await this.customerDataSetService.get(
        liftConfig.advertiserCustomerDataSet!.id
      );
      this.headerAdvCustomerId = advCustomerDataSet.customerDataSet?.customerId;
      this.headerStudyId = liftConfig!.studyId;
      this.headerStudyStartTime = this.formatService.formatProtoDateForInput(
        liftConfig.timeWindow!.publisherStartTime
      )!;
      this.headerStudyEndTime = this.formatService.formatProtoDateForInput(
        liftConfig.timeWindow!.publisherEndTime
      )!;
      this.headerAdvEndTime = this.formatService.formatProtoDateForInput(
        liftConfig.timeWindow!.advertiserEndTime
      )!;
    }
  }

  viewLiftResults() {
    const liftConfig =
      this.jobLog.binaryConfig?.unpack<LiftConfig>(GRPC_MESSAGE_POOL);

    const debugLogs = JSON.parse(
      this.getContent(
        this.jobResultFileTypeList.JOB_RESULT_FILE_TYPE_LOGS_JSON
      )!
    );

    const measurements = this.getContent(
      this.jobResultFileTypeList.JOB_RESULT_FILE_TYPE_MEASUREMENTS_CSV
    );
    const result = Papa.parse<LiftRow>(measurements!, {
      header: true,
    });

    const validations: Validation[] = [];
    validations.push(
      ...liftConfig!.matchingConfig!.matchingColumns.map((key, idx) => {
        return {
          description: `Matches by ${key} are present`,
          passed:
            parseFloat(
              debugLogs[`matcher-waterfall-${idx}-${key}-conversions-matched`]
            ) > 0,
        } as Validation;
      })!
    );

    if (
      liftConfig?.eligibility?.maximumLookbackWindowSeconds &&
      liftConfig?.timeWindow?.advertiserEndTime?.seconds &&
      liftConfig?.timeWindow?.publisherStartTime?.seconds
    ) {
      validations.push({
        description: 'Lookback window covers study duration',
        passed:
          liftConfig.eligibility?.maximumLookbackWindowSeconds >
          parseInt(liftConfig.timeWindow.advertiserEndTime.seconds) -
            parseInt(liftConfig.timeWindow.publisherStartTime.seconds),
      });
    }

    validations.push({
      description: 'Date filtering removed <1% of conversions',
      passed:
        1 -
          parseFloat(
            debugLogs['cleaning-advertiser-0-event-time-filter-num-rows']
          ) /
            parseFloat(debugLogs['loading-advertiser-num-rows']) <
        0.01,
    });
    validations.push({
      description: 'Date filtering removed <1% of opportunities',
      passed:
        1 -
          parseFloat(
            debugLogs['cleaning-publisher-1-event-time-filter-num-rows']
          ) /
            parseFloat(
              debugLogs['cleaning-publisher-0-study-id-filter-num-rows']
            ) <
        0.01,
    });

    this.postProcessedData = {
      highlightedStats: [
        {
          key: 'Opportunities',
          value: this.formatScalar(
            debugLogs['matcher-stats-publisher-users-total']
          ),
        },
        {
          key: 'Conversions',
          value: this.formatScalar(
            debugLogs['matcher-stats-conversions-total']
          ),
        },
        {
          key: 'Opportunities matched',
          value: this.formatPercent(
            parseFloat(debugLogs['matcher-stats-publisher-users-matched']) /
              parseFloat(debugLogs['matcher-stats-publisher-users-total'])
          ),
        },
        {
          key: 'Conversions matched',
          value: this.formatPercent(
            parseFloat(debugLogs['matcher-stats-conversions-matched']) /
              parseFloat(debugLogs['matcher-stats-conversions-total'])
          ),
        },
      ],
      results: liftConfig!.liftEstimationConfigs!.map((lec, lec_index) => {
        return {
          label: this.privacyLabel(lec.privacyConfig!.rho!),
          algorithm: LiftAlgorithm[lec.liftAlgorithm]
            .substring('LIFT_ALGORITHM_'.length)
            .toLowerCase(),
          rho: lec.privacyConfig ? lec.privacyConfig.rho.toString() : 'n/a',
          sensitivity: lec.sensitivityConfig
            ? lec.sensitivityConfig.percentile.toString()
            : 'n/a',
          matchKeys: liftConfig!.matchingConfig!.matchingColumns.join(', '),
          columns: liftConfig!.metrics!.map((metric: Metric) => {
            const event_type = AdvertiserEventType[metric.advertiserEventType]
              .substring('ADVERTISER_EVENT_TYPE_'.length)
              .toLowerCase();
            const metric_type = MetricType[metric.metricType]
              .substring('METRIC_TYPE_'.length)
              .toLowerCase();
            const filteredData = result.data.filter(
              (row) =>
                row.lift_estimate_index == lec_index.toString() &&
                row.event_type == event_type &&
                row.metric_type == metric_type
            );
            const row = filteredData[0];

            return {
              columnName: event_type + ' ' + metric_type,
              testSize: this.formatScalar(row.num_treatment_users),
              controlSize: this.formatScalar(row.num_control_users),
              relativeLift: this.formatPercent(
                parseFloat(row.output_relative_lift_release) - 1
              ),
              ciLower: this.formatPercent(
                parseFloat(row.output_relative_lift_ci_lower) - 1
              ),
              ciUpper: this.formatPercent(
                parseFloat(row.output_relative_lift_ci_upper) - 1
              ),
              significance:
                row.output_relative_lift_sigstars_release === 'significant'
                  ? 'significant'
                  : 'insignificant',
              testConversions: this.formatScalar(
                parseFloat(row.num_treatment_users) *
                  parseFloat(row.output_test_mean_release)
              ),
              scaledControlConversions: this.formatScalar(
                parseFloat(row.num_treatment_users) *
                  parseFloat(row.output_control_mean_release)
              ),
              incrementalConversions: this.formatScalar(
                parseFloat(row.num_treatment_users) *
                  parseFloat(row.output_absolute_lift_release)
              ),
            };
          })!,
        };
      }),
      validations: [...validations],
    };
  }

  privacyLabel(rho: number) {
    switch (rho) {
      case 0:
        return 'No Privacy';
      case 10:
        return 'Low Privacy';
      case 3:
        return 'Medium Privacy';
      case 0.5:
        return 'High Privacy';
    }
    return 'Custom Privacy';
  }

  formatScalar(input: number | string) {
    input = typeof input === 'string' ? parseFloat(input) : input;
    return parseFloat(input.toFixed(2)).toLocaleString('en-US');
  }

  formatPercent(input: number | string) {
    input = typeof input === 'string' ? parseFloat(input) : input;
    return parseFloat((input * 100).toFixed(3)) + '%';
  }

  decodeFileType(jobResultFileType: JobResultFileType) {
    return JobResultFileTypeList.find(
      (fileType) => fileType.value === jobResultFileType
    )?.name;
  }

  getJobFileTypeResultLabel(selectedJobResultFileType: JobResultFileType) {
    const result = JobResultFileTypeList.find(
      (jobResultFileType) =>
        jobResultFileType.value == selectedJobResultFileType
    );
    if (result) {
      return result.name;
    } else {
      return 'Unknown';
    }
  }

  getDownloadFileName(selectedJobResultFileType: JobResultFileType) {
    const result = JobResultFileTypeList.find(
      (jobResultFileType) =>
        jobResultFileType.value == selectedJobResultFileType
    );
    if (result) {
      return result.fileName;
    } else {
      return undefined;
    }
  }

  view(jobResultFile: JobResultFile) {
    this.currentFileView = jobResultFile;
  }

  download(selectedJobResultFileType: JobResultFileType) {
    if (this.jobResults) {
      const result = this.jobResults.find(
        (jobResult) => jobResult.jobResultFileType == selectedJobResultFileType
      );
      if (result) {
        const hiddenElement = document.createElement('a');
        hiddenElement.href =
          'data:attachment/text,' + encodeURI(result.fileContents);
        hiddenElement.target = '_blank';
        const downloadFileName = this.getDownloadFileName(
          selectedJobResultFileType
        );
        if (downloadFileName) {
          hiddenElement.download = `${this.jobId}-${downloadFileName}`;
          hiddenElement.click();
        } else {
          this.messageBox.error('Filename not available for this file type.');
        }
      } else {
        this.messageBox.error('Content is not available for download.');
      }
    }
  }

  getContent(selectedJobResultFileType: JobResultFileType) {
    if (this.jobResults) {
      const result = this.jobResults.find(
        (jobResult) => jobResult.jobResultFileType == selectedJobResultFileType
      );
      if (result) {
        return result.fileContents;
      }
    }
    return undefined;
  }

  formatJson(content: string | undefined) {
    return content ? JSON.stringify(JSON.parse(content), null, 2) : '{}';
  }

  isDisplayed(jobResultFileType: JobResultFileType) {
    return this.jobResultFileTypeDisplay.get(jobResultFileType);
  }
}
