<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <form
      ngNativeValidate
      (ngSubmit)="process()"
      [formGroup]="adminStorageCredential"
      id="customerForm"
    >
      <app-customer-dropdown
        [showArchiveToggle]="false"
        [showSearchBox]="true"
        [displayAsBanner]="false"
        [displayTitle]="true"
        (selectCustomerEvent)="onCustomerSelect($event)"
      ></app-customer-dropdown>

      <div class="field-row">
        <div class="field-name-container">
          <label class="field-title">Aws role arn</label>
          <div>
            <input
              class="app-input"
              type="text"
              placeholder="AWS Assume Role ARN"
              formControlName="role_arn"
              size="40"
            />
          </div>
        </div>
      </div>

      <div class="field-row">
        <div class="field-name-container">
          <mat-checkbox formControlName="use_external_id"
            >Use External ID</mat-checkbox
          >
        </div>
      </div>
      <app-message-box></app-message-box>
    </form>
  </div>

  <div class="save-btn" mat-dialog-actions>
    <button
      mat-flat-button
      color="primary"
      [disabled]="isLoading"
      form="customerForm"
      type="submit"
      data-testid="saveBtn"
    >
      {{ isLoading ? null : 'Save' }}
      <span *ngIf="isLoading" class="spinner">
        <mat-spinner diameter="24"></mat-spinner>
      </span>
    </button>
  </div>
</div>
