import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Timestamp } from '@ngx-grpc/well-known-types';
import { AppService } from 'app/services/app.service';
import { FormatService } from 'app/services/format.service';
import { App } from 'app/services/generated/src/main/proto/storage/app.pb';
import { LoggerService } from 'app/services/logger.service';

import { AddAppModalComponent } from '../../modals/add-app/add-app-modal.component';
import { MessageBoxProvider } from '../shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-manager',
  templateUrl: './app-manager.component.html',
  styleUrls: ['./app-manager.component.scss'],
})
export class AppManagerComponent implements OnInit {
  tenantId: string | undefined;
  isLoadingApps = false;
  apps: App[] | undefined;
  keys = new Map();
  visibleKeys: string[] = [];

  constructor(
    public appService: AppService,
    private auth: Auth,
    public dialog: MatDialog,
    private formatService: FormatService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoadingApps = true;
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.tenantId = user.tenantId!;
      }
    });

    this.appService
      .getAll()
      .then((res) => {
        this.apps = res.apps?.sort((a: App, b: App) => {
          if (a.appName < b.appName) {
            return -1;
          }
          if (a.appName > b.appName) {
            return 1;
          }
          return 0;
        });
      })
      .finally(() => {
        this.isLoadingApps = false;
      });
  }

  addApp() {
    this.dialog
      .open(AddAppModalComponent)
      .afterClosed()
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  displayWelcome() {
    if (localStorage.getItem('welcome_viewed')) {
      return false;
    } else {
      return true;
    }
  }

  closeWelcome() {
    localStorage.setItem('welcome_viewed', 'true');
  }

  delete(appId: string, appName: string, etag: string | undefined) {
    if (confirm('Delete app: ' + appName + ' ?') == true) {
      if (etag) {
        this.appService
          .archive(appId, etag)
          .then(() => {
            this.messageBox.show('App deleted successfully', 'success');
          })
          .catch((error) => {
            this.logger.error('Unable to delete app: [%s]', error);
            this.messageBox.error('Unable to delete app');
          })
          .finally(() => {
            this.ngOnInit();
          });
      } else {
        this.messageBox.error('Unable to delete app, missing etag.');
      }
    }
  }

  rotateKeys(appId: string, appName: string, etag: string | undefined) {
    if (confirm('Rotate keys for app: ' + appName + ' ?') == true) {
      if (etag) {
        this.appService
          .rotateApiKey(appId, etag)
          .then((rotateApiKeyResponse) => {
            this.keys.set(appId, rotateApiKeyResponse);
            this.messageBox.show('Keys rotated successfully', 'success');
          })
          .catch((error) => {
            this.logger.error('Unable to rotate keys: [%s]', error);
            this.messageBox.error('Unable to rotate keys');
          })
          .finally(() => {
            this.ngOnInit();
          });
      } else {
        this.messageBox.error('Unable to rotate key, missing etag.');
      }
    }
  }

  showOrHideKey(appId: string) {
    if (this.visibleKeys.includes(appId)) {
      const index = this.visibleKeys.indexOf(appId, 0);
      if (index > -1) {
        this.visibleKeys.splice(index, 1);
      }
    } else {
      this.visibleKeys.push(appId);
    }
  }

  formatTimestamp(timestamp: Timestamp | undefined) {
    return timestamp ? this.formatService.formatProtoDateTime(timestamp) : '-';
  }

  onClick() {
    this.router.navigate(['documentation-view/docs/web/api/0.0.1/index.html']);
  }
}
