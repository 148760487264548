<form [formGroup]="form" class="form-section">
  <div>
    <div formArrayName="selectedDatasetGroups">
      <div
        *ngFor="let group of selectedDatasetGroups.controls; let i = index"
        [formGroupName]="i"
        class="datasets-group"
      >
        <h3>
          {{ nameForKey(this.getDatasetKeys(binaryType)[i]) }} Dataset Info
        </h3>
        <app-customer-dropdown
          class="input-half-width"
          [showArchiveToggle]="false"
          [selectedCustomer]="customers[i]"
          [showSearchBox]="false"
          [displayAsBanner]="false"
          [displayTitle]="false"
          [showCustomerId]="true"
          [displayLabel]="true"
          label="Customer ID"
          (selectCustomerEvent)="onCustomerSelect(i, $event)"
        ></app-customer-dropdown>
        <br />
        <mat-form-field class="input-half-width">
          <mat-label>First day (UTC)</mat-label>
          <input type="date" matInput formControlName="startDate" />
        </mat-form-field>
        <mat-form-field class="input-half-width">
          <mat-label>Last day (UTC)</mat-label>
          <input type="date" matInput formControlName="endDate" />
        </mat-form-field>
        <div formArrayName="selectedDatasets">
          <div
            *ngFor="
              let dataset of getSelectedDatasets(i).controls;
              let j = index
            "
          >
            <div [formGroupName]="j" class="datasets">
              <mat-form-field class="input-half-width">
                <mat-label>Customer Dataset</mat-label>
                <mat-select formControlName="datasetId">
                  <mat-option
                    *ngFor="let dataset of datasets[i] | keyvalue"
                    [value]="dataset.key"
                  >
                    {{ dataset.value.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="partitions" *ngIf="showPartitions">
                <mat-label>Partitions</mat-label>
                <input type="number" matInput formControlName="partitions" />
              </mat-form-field>
              <button
                *ngIf="isCustomerDataSetExpired(i, j)"
                class="warning-btn"
                matTooltip="This dataset is over 99 days old, data files may be missing."
              >
                <mat-icon>warning</mat-icon>
              </button>
              <button (click)="deleteDataset(i, j)" class="delete-btn">
                Delete
              </button>
            </div>
          </div>
        </div>
        <button (click)="addDataset(i)" mat-button color="primary">
          Add dataset part
        </button>
        <br />
        <br />
      </div>
    </div>
  </div>
</form>
