<nav id="top-nav" *ngIf="userName">
  <a routerLink="landing-page">
    <img id="logo" src="assets/logo.svg" />
  </a>
  <div id="widgets">
    <div class="buttons">
      <!--
      // Not available atm.
      <button mat-icon-button>
        <img class="toolbar-icon" src="./assets/search.png" />
      </button> -->
      <button
        mat-icon-button
        #notificationBtn
        [matMenuTriggerFor]="menu"
        data-testid="openNotificationPage"
      >
        <img
          class="toolbar-icon"
          src="./assets/bell-on.svg"
          *ngIf="showNotification"
        />
        <img
          class="toolbar-icon"
          src="./assets/bell-off.svg"
          *ngIf="!showNotification"
        />
      </button>

      <mat-menu #menu="matMenu" class="my-menu">
        <div class="notification-menu">
          <h3>Notifications</h3>
          <div *ngFor="let notification of notifications">
            <button
              class="button"
              (click)="
                openNotificationPage(notification.messagePayload.messageId)
              "
            >
              <div class="item">
                <div class="left">
                  <mat-icon
                    *ngIf="notification.messagePayload.notification?.icon"
                    >{{
                      notification.messagePayload.notification?.icon
                    }}</mat-icon
                  >
                  <h3>{{ notification.messagePayload.notification?.title }}</h3>
                  <p class="notification-body">
                    {{ notification.messagePayload.notification?.body }}
                  </p>
                </div>
                <div class="right">
                  <img
                    class="icon"
                    src="./assets/unread.svg"
                    *ngIf="!notification.viewed"
                  />
                </div>
              </div>
            </button>
          </div>
        </div>
      </mat-menu>
    </div>

    <hr />
    <div>{{ userName }}</div>
    <a class="user-pic" referrerpolicy="no-referrer" routerLink="account">
      <img [src]="userPhotoUrl" />
    </a>
  </div>
</nav>
