<div class="modal">
  <div mat-dialog-title>
    <h2 class="modal-title">{{ title }}</h2>
    <button
      mat-icon-button
      aria-label="close dialog"
      mat-dialog-close
      class="modal-close-btn"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div mat-dialog-content>
    <div *ngIf="data.success">
      <p>Report created successfully</p>

      <p>
        <strong>Sheet Title:</strong><br />
        {{ data.sheetTitle }}
      </p>

      <div class="link-row">
        <span>
          <strong>Link:</strong>
          <a class="link" href="{{ data.link }}" target="_blank">
            Click here to view
          </a>
        </span>
        <button
          mat-icon-button
          (click)="copyToClipboard(data.link)"
          matTooltip="Copy link"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>

      <p>
        <strong>Recipients:</strong><br />
        {{ data.recipients }}
      </p>
    </div>

    <div *ngIf="!data.success" class="error-message">
      <p>Report creation failed</p>
      <p>{{ data.error }}</p>
    </div>
  </div>
</div>
