import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LocationList } from 'app/constants/lookups';
import { AdminModule } from 'app/modules/admin/admin.module';
import { FormHelpersService } from 'app/services/form-helpers.service';
import { AdvertiserEventType } from 'app/services/generated/src/main/proto/attribution/advertiser.pb';
import { BinaryType } from 'app/services/generated/src/main/proto/storage/binary-type.pb';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { CustomerDataSet } from 'app/services/generated/src/main/proto/storage/customer-data-set.pb';
import { CustomerDataSetReferenceConfig } from 'app/services/generated/src/main/proto/storage/customer-data-set-reference.pb';
import {
  AdvertiserEvents,
  LookbackWindow,
  Project,
} from 'app/services/generated/src/main/proto/storage/project.pb';
import { LoggerService } from 'app/services/logger.service';
import { ProjectService } from 'app/services/project.service';
import { DatasetPickerComponent } from 'app/views/binary-type/common/dataset-picker/dataset-picker.component';
import { BinaryTypeDropdownComponent } from 'app/views/shared/components/binary-type-dropdown/binary-type-dropdown.component';
import { ContainerComponent } from 'app/views/shared/components/container/container.component';
import { MatchKeysSelectorComponent } from 'app/views/shared/components/match-keys-selector/match-keys-selector.component';
import { MessageBoxComponent } from 'app/views/shared/components/message-box/message-box.component';
import { MessageBoxProvider } from 'app/views/shared/components/message-box/message-box.provider';
import { PageHeaderComponent } from 'app/views/shared/components/page-header/page-header.component';

import { LocationDropdownComponent } from '../../views/shared/components/location-dropdown/location-dropdown.component';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss', '../../shared/shared.scss'],
  standalone: true,
  imports: [
    AdminModule,
    BinaryTypeDropdownComponent,
    CommonModule,
    ContainerComponent,
    DatasetPickerComponent,
    FormsModule,
    LocationDropdownComponent,
    MatchKeysSelectorComponent,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatTableModule,
    MessageBoxComponent,
    PageHeaderComponent,
    ReactiveFormsModule,
  ],
})
export class AddProjectComponent implements OnInit {
  advertiserDatasets: CustomerDataSet[] = [];
  allowedCustomerLocations: Location[] = [];
  dateLabel = 'Campaign';
  defaultLocations = LocationList;
  eventTypes: AdvertiserEventType[] = [];
  isLoading = false;
  project: Project | undefined;
  projectForm: FormGroup;
  publisherDatasets: CustomerDataSet[] = [];
  selectedAdvertiserEvents: AdvertiserEvents[] = [];
  selectedCustomerDataSetReferenceConfig:
    | CustomerDataSetReferenceConfig
    | undefined;
  selectedBinaryType: BinaryType | undefined;
  selectedLocation: Location | undefined;
  selectedMatchKeys: string[] = [];
  title = 'New project';
  time: string = new Date().toLocaleString();
  update = false;

  //Event vars
  metricsColumns: string[] = [
    'advertiserEventType',
    'amount',
    'numUnits',
    'notes',
    'actions',
  ];
  metricsDataSource = new MatTableDataSource<any>();
  metricRows = this.formBuilder.array([
    this.formBuilder.group({
      advertiserEventType: 0,
      amounts: false,
      numUnits: false,
      notes: '',
    }),
  ]);

  get metrics() {
    return this.projectForm.get('metrics') as FormArray;
  }

  constructor(
    private activatedRouter: ActivatedRoute,
    private projectService: ProjectService,
    private logger: LoggerService,
    private messageBox: MessageBoxProvider,
    private formBuilder: FormBuilder,
    private formHelper: FormHelpersService,
    private router: Router
  ) {
    this.getDefaultLocations();
    this.getEventTypes();

    this.projectForm = this.formBuilder.group({
      name: new FormControl('', {}),
      advertiserEvents: new FormControl('', {}),
      lookbackWindowClickDays: new FormControl(0, {}),
      lookbackWindowViewDays: new FormControl(0, {}),
      notes: new FormControl('', {}),
      studyId: new FormControl('', {}),
      metrics: new FormArray([]),
    });
    this.setupMetricsTable();
    this.formHelper.setForm(this.projectForm);
  }

  ngOnInit() {
    this.activatedRouter.params.subscribe(async (params: Params) => {
      if (params['id']) {
        this.title = 'Update project';
        this.update = true;
        try {
          this.isLoading = true;
          const projectResponse = await this.projectService.getProjectById(
            params['id']
          );

          this.project = projectResponse.project;

          if (!this.project) {
            return;
          }

          this.projectForm = this.formBuilder.group({
            name: new FormControl(this.project.name, {}),
            advertiserEvents: new FormControl('', {}),
            lookbackWindowClickDays: new FormControl(
              this.project.lookbackWindow?.clickDays,
              {}
            ),
            lookbackWindowViewDays: new FormControl(
              this.project.lookbackWindow?.viewDays,
              {}
            ),
            notes: new FormControl(this.project.notes, {}),
            studyId: new FormControl(this.project.studyId, {}),
            metrics: this.metricRows,
          });

          if (this.project.matchKeys) {
            this.selectedMatchKeys = this.project.matchKeys;
          }

          if (this.project.customerDataSetReferenceConfig) {
            this.selectedCustomerDataSetReferenceConfig =
              this.project.customerDataSetReferenceConfig;
          }

          this.selectedBinaryType = this.project.binaryType;
          this.selectedLocation = this.project.location;

          this.updateMetricsTable();
        } catch (error) {
          this.logger.error('Unable to load project.', error, 15);
          this.messageBox.error('Unable to load project.');
        } finally {
          this.isLoading = false;
        }
      }
    });
  }

  addEventMetric() {
    const emptyRow = this.formBuilder.group({
      advertiserEventType:
        AdvertiserEventType.ADVERTISER_EVENT_TYPE_UNSPECIFIED,
      notes: '',
      amounts: false,
      numUnits: false,
    });
    this.metrics.push(emptyRow);
    this.metricsDataSource.data = this.metrics.value;
  }

  displayLookbackWindow() {
    return this.selectedBinaryType === BinaryType.BINARY_TYPE_ATTRIBUTION;
  }

  displayStudyId() {
    return this.selectedBinaryType === BinaryType.BINARY_TYPE_LIFT;
  }

  getDefaultLocations() {
    LocationList.forEach((location) => {
      this.allowedCustomerLocations.push(location.value);
    });
  }

  getEventTypes() {
    for (const value in AdvertiserEventType) {
      if ((parseInt(value) || 0) < 1) {
        // Skip ADVERTISER_EVENT_TYPE_UNSPECIFIED
        continue;
      }
      this.eventTypes.push(parseInt(value));
    }
  }

  async process() {
    const { value } = this.projectForm;

    if (this.validate()) {
      this.isLoading = true;

      let project = new Project();

      if (this.update && this.project) {
        try {
          this.isLoading = true;
          const projectResponse = await this.projectService.getProjectById(
            this.project.id
          );
          if (projectResponse.project) {
            project = projectResponse.project;
          }
        } catch (e: any) {
          this.logger.error(e);
          this.messageBox.error(
            'Failed to retreive latest etag from project.',
            e.message
          );
        } finally {
          this.isLoading = false;
        }
      }

      if (this.selectedLocation) {
        project.location = this.selectedLocation;
      }

      if (this.selectedBinaryType) {
        project.binaryType = this.selectedBinaryType;
      }

      const lookbackWindow = new LookbackWindow();
      if (value.lookbackWindowClickDays) {
        lookbackWindow.clickDays = value.lookbackWindowClickDays;
      }

      if (value.lookbackWindowViewDays) {
        lookbackWindow.viewDays = value.lookbackWindowViewDays;
      }

      if (this.selectedMatchKeys) {
        project.matchKeys = this.selectedMatchKeys;
      }

      project.name = value.name;
      project.lookbackWindow = lookbackWindow;
      project.notes = value.notes;
      project.studyId = value.studyId;
      project.advertiserEvents = this.getAdvertiserEvents();
      project.customerDataSetReferenceConfig =
        this.selectedCustomerDataSetReferenceConfig;
      if (this.update) {
        await this.updateProject(project);
      } else {
        await this.createProject(project);
      }
    }
  }

  async createProject(project: Project) {
    try {
      await this.projectService.createProject(project);
      this.messageBox.success(
        'Project created succesfully.',
        () => {
          this.router.navigate(['projects']);
        },
        5
      );
    } catch (e: any) {
      this.logger.error(e);
      this.messageBox.error('Failed to create new project.', e.message);
    } finally {
      this.isLoading = false;
    }
  }

  async updateProject(project: Project) {
    try {
      await this.projectService.updateProject(project);
      this.messageBox.success(
        'Project updated.',
        () => {
          this.router.navigate(['projects']);
        },
        5
      );
    } catch (e: any) {
      this.logger.error(e);
      this.messageBox.error('Failed to update project.', e.message);
    } finally {
      this.isLoading = false;
    }
  }

  getAdvertiserEventName(index: number) {
    const value = AdvertiserEventType[index];
    return value.substring('ADVERTISER_EVENT_TYPE_'.length);
  }

  getAdvertiserEvents(): AdvertiserEvents[] {
    if (!this.metrics) return [];
    return this.metrics.controls
      .filter(
        (row: any) =>
          row.get('advertiserEventType')?.value !=
          AdvertiserEventType.ADVERTISER_EVENT_TYPE_UNSPECIFIED
      )
      .map((row: any) => {
        const advertiserEvent = new AdvertiserEvents();
        advertiserEvent.advertiserEventType = row.get(
          'advertiserEventType'
        )?.value;
        advertiserEvent.notes = row.get('notes')?.value || null;
        advertiserEvent.includeAmounts = row.get('amounts')?.value || null;
        advertiserEvent.includeNumUnits = row.get('numUnits')?.value || null;
        return advertiserEvent;
      });
  }

  getLocationName(location: Location | undefined): string {
    if (!location) {
      return 'Unknown';
    }

    return LocationList.find((l) => l.value === location)?.name || 'Unknown';
  }

  onBinaryTypeSelect(binaryType: BinaryType) {
    this.selectedBinaryType = binaryType;
    this.dateLabel = this.displayStudyId() ? 'Study' : 'Campaign';
  }

  onLocationSelect(location: Location) {
    this.selectedLocation = location;
  }

  onSelectDatasetsInfo(
    customerDataSetReferenceConfig: CustomerDataSetReferenceConfig
  ) {
    this.selectedCustomerDataSetReferenceConfig =
      customerDataSetReferenceConfig;
  }

  onMatchKeysEvent(matchKeys: string[]) {
    this.selectedMatchKeys = matchKeys;
  }

  setupMetricsTable() {
    this.metrics.clear();

    this.selectedAdvertiserEvents.forEach((value) => {
      const row = this.formBuilder.group({
        advertiserEventType: value.advertiserEventType,
        notes: value.notes,
        amounts: value.includeAmounts,
        numUnits: value.includeNumUnits,
      });
      this.metricRows.push(row);
    });
    this.metricsDataSource.data = this.metrics.value;
  }

  removeEventMetric(index: number) {
    this.metrics.removeAt(index);
    this.metricsDataSource.data = this.metrics.value;
  }

  updateMetricsTable(): void {
    this.metrics.clear(); // Clear existing controls

    if (this.project?.advertiserEvents?.length) {
      const controls = this.project.advertiserEvents.map((advertiserEvent) =>
        this.formBuilder.group({
          advertiserEventType: advertiserEvent.advertiserEventType,
          notes: advertiserEvent.notes,
          amounts: advertiserEvent.includeAmounts,
          numUnits: advertiserEvent.includeNumUnits,
        })
      );
      this.metrics.controls.push(...controls);
    }
    this.metricsDataSource.data = this.metrics.controls;
  }

  validate(): boolean {
    if (!this.validateBinaryType()) return false;
    return true;
  }

  private validateBinaryType(): boolean {
    if (
      this.selectedBinaryType === BinaryType.BINARY_TYPE_UNSPECIFIED ||
      this.selectedBinaryType === undefined
    ) {
      this.messageBox.error('Binary type cannot be unspecified.');
      return false;
    }
    return true;
  }
}
