<app-container>
  <app-page-header title="Users Manager"></app-page-header>
  <app-message-box></app-message-box>

  <app-customer-dropdown
    [showArchiveToggle]="true"
    [showSearchBox]="true"
    (selectCustomerEvent)="onCustomerSelect($event)"
  ></app-customer-dropdown>

  <button
    *ngIf="selectedTenantId"
    mat-flat-button
    color="primary"
    (click)="openCreateUserDialog()"
    class="add-user"
    data-testid="addUserButton"
  >
    Add user
  </button>

  <table
    mat-table
    [dataSource]="users"
    matSort
    class="table-data"
    (matSortChange)="announceSortChange($event)"
  >
    <ng-container matColumnDef="UserName">
      <th
        class="row-header"
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="displayName"
      >
        User Name
      </th>
      <td mat-cell *matCellDef="let user">
        <div class="user-name-col">
          <img class="user-avatar" src="{{ 'assets/default-profile.png' }}" />
          <span>
            <a (click)="editUser(user)" class="user-number">{{
              user.displayName
            }}</a>
          </span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Email">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="email"
        class="row-header"
      >
        Email
      </th>
      <td mat-cell *matCellDef="let user">{{ user.email }}</td>
    </ng-container>

    <ng-container matColumnDef="Role">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="roles"
        class="row-header"
      >
        Role
      </th>
      <td mat-cell *matCellDef="let user">
        <label *ngFor="let role of user.roles" class="badge">{{ role }}</label>
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header="disabled"
        class="row-header"
      >
        Enabled
      </th>
      <td mat-cell *matCellDef="let user" class="text-center">
        <input
          type="checkbox"
          name="{{ user.uid }}"
          [checked]="!user.disabled"
          [value]="user.uid"
          (change)="changeUserState(user)"
          matTooltip="Disable user"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="Delete">
      <th mat-header-cell *matHeaderCellDef class="row-header"></th>
      <td mat-cell *matCellDef="let user" class="text-center">
        <button
          (click)="deleteUser(user)"
          class="delete"
          data-testid="delete"
          matTooltip="Delete user"
        >
          <mat-icon class="delete-icon">delete</mat-icon>
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnsToDisplay"
      class="table-row"
    ></tr>
  </table>
  <div *ngIf="isLoadingUsers" class="loader">
    <mat-spinner diameter="42"></mat-spinner>
  </div>

  <div class="no-users-msg" *ngIf="!users.data.length && selectedTenantId">
    The selected customer has no users
  </div>
</app-container>
