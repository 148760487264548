import { Injectable, NgZone } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Router } from '@angular/router';
import { GrpcMetadata } from '@ngx-grpc/common';
import { Location } from 'app/services/generated/src/main/proto/storage/commons.pb';
import { firstValueFrom } from 'rxjs';

import { ApiAuthService } from './api-auth.service';
import {
  CreateRequest as CreateCustomerRequest,
  CreateResponse as CreateCustomerResponse,
  GetExternalStorageMetadataRequest,
  GetExternalStorageMetadataResponse,
  GetSelfCustomerRequest,
  GetSelfCustomerResponse,
  GetTenantIdRequest,
  GetTenantIdResponse,
  ReadCustomerRequest,
  ReadCustomerResponse,
  ReadCustomersFilter,
  ReadCustomersRequest,
  ReadCustomersResponse,
  UpdateManagedCustomerRequest,
  UpdateManagedCustomerResponse,
  UpdateRequest as UpdateCustomersRequest,
  UpdateResponse as UpdateCustomersResponse,
} from './generated/src/main/proto/api/customer-service.pb';
import { CustomerServiceClient } from './generated/src/main/proto/api/customer-service.pbsc';
import { GetPaginatedRequest } from './generated/src/main/proto/api/pagination.pb';
import { Customer } from './generated/src/main/proto/storage/customer.pb';

/**
 * Service to manage customer-related operations, including creating, updating, and retrieving customer information.
 */
@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    public router: Router,
    public ngZone: NgZone,
    public firebaseApp: FirebaseApp,
    private analytics: AngularFireAnalytics,
    private apiAuthService: ApiAuthService,
    private customerServiceClient: CustomerServiceClient
  ) {}

  /**
   * Creates a new customer.
   *
   * @param customer - The customer data to create.
   * @returns A promise that resolves to a CreateCustomerResponse containing the result of the operation.
   */
  async createCustomer(customer: Customer): Promise<CreateCustomerResponse> {
    try {
      const grpcMetaData: GrpcMetadata =
        await this.apiAuthService.getAuthenticatedRequestHeader();
      const createCustomerRequest = new CreateCustomerRequest();
      createCustomerRequest.customer = customer;
      const createCustomerResponse = await firstValueFrom(
        this.customerServiceClient.create(createCustomerRequest, grpcMetaData)
      );

      await this.readCustomer(createCustomerResponse.customerId);

      this.analytics.logEvent('create-customer');

      return new Promise((resolve) => {
        resolve(createCustomerResponse);
      });
    } catch (error) {
      return new Promise((_resolve, reject) => {
        reject(error);
      });
    }
  }

  /**
   * Retrieves the currently authenticated customer's information.
   *
   * @returns A promise that resolves to a GetSelfCustomerResponse containing the customer's information.
   */
  async getCustomer(
    skipCache: boolean = false
  ): Promise<GetSelfCustomerResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getSelfCustomerRequest = new GetSelfCustomerRequest();
    if (skipCache) {
      getSelfCustomerRequest.skipCache = true;
    }

    return firstValueFrom(
      this.customerServiceClient.getSelfCustomer(
        getSelfCustomerRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Get external file metadata for customers.
   *
   * @param customerId - The customer id.
   * @returns A promise that resolves to a GetExternalStorageMetadataResponse containing the result of the operation.
   */
  public async getExternalFileMetadata(
    customerId: string,
    location: Location
  ): Promise<GetExternalStorageMetadataResponse> {
    const getExternalStorageMetadataRequest =
      new GetExternalStorageMetadataRequest();

    getExternalStorageMetadataRequest.customerId = customerId;
    getExternalStorageMetadataRequest.location = location;

    const grpcMetaData =
      await this.apiAuthService.getAuthenticatedRequestHeader();
    return firstValueFrom(
      this.customerServiceClient.getExternalStorageMetadata(
        getExternalStorageMetadataRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Retrieves the tenant ID for a given email address.
   *
   * @param email - The email address to look up the tenant ID for.
   * @returns A promise that resolves to a GetTenantIdResponse containing the tenant ID.
   */
  async getTenantId(email: string): Promise<GetTenantIdResponse> {
    const getTenantIdRequest: GetTenantIdRequest = new GetTenantIdRequest();
    getTenantIdRequest.email = email;

    return await firstValueFrom(
      this.customerServiceClient.getTenantId(getTenantIdRequest)
    );
  }

  /**
   * Reads the information of a specified customer.
   *
   * @param customerId - The ID of the customer to retrieve.
   * @returns A promise that resolves to a ReadCustomerResponse containing the customer's information.
   */
  async readCustomer(customerId: string): Promise<ReadCustomerResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const readCustomerRequest = new ReadCustomerRequest();
    readCustomerRequest.customerId = customerId;

    return firstValueFrom(
      this.customerServiceClient.readCustomer(readCustomerRequest, grpcMetaData)
    );
  }

  /**
   * Reads the information of all customers, optionally filtered by customer type.
   *
   * @param customerType - The type of customers to filter by (default is CUSTOMER_TYPE_UNSPECIFIED).
   * @returns A promise that resolves to a ReadCustomersResponse containing the list of customers.
   */
  async readCustomers(
    readCustomersRequest: ReadCustomersRequest
  ): Promise<ReadCustomersResponse> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    return firstValueFrom(
      this.customerServiceClient.readCustomers(
        readCustomersRequest,
        grpcMetaData
      )
    );
  }

  /**
   * Reads the information of all customers, optionally filtered is they are disabled.
   *
   * @param disabled - Loads all if disabled is not defined,
   * loads all disabled customers if true, all regular customers is false.
   * @returns A promise that resolves to a ReadCustomersResponse containing the list of customers.
   */
  async readAllCustomers(
    disabled: boolean | undefined = undefined
  ): Promise<Customer[]> {
    let customers: Customer[] = [];
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    let response: ReadCustomersResponse | undefined = undefined;
    let continuationToken: string | undefined = undefined;

    do {
      const readCustomersRequest: ReadCustomersRequest =
        new ReadCustomersRequest({
          paginated: new GetPaginatedRequest({
            numRecords: 50,
            continuationToken: continuationToken,
          }),
        });

      const readCustomerFilter = new ReadCustomersFilter();
      if (disabled !== undefined) {
        readCustomerFilter.disabled = disabled;
      }
      readCustomersRequest.filter = readCustomerFilter;
      response = await firstValueFrom(
        this.customerServiceClient.readCustomers(
          readCustomersRequest,
          grpcMetaData
        )
      );

      continuationToken = response.paginatedResponse?.continuationToken;
      if (response.customers) {
        customers = customers.concat(response.customers);
      }
    } while (continuationToken);
    return customers;
  }

  /**
   * Updates the information of a specified customer.
   *
   * @param customer - The customer data to update.
   * @returns A promise that resolves to an UpdateCustomersResponse containing the result of the operation.
   */
  async updateCustomer(
    customer: Customer
  ): Promise<UpdateCustomersResponse | undefined> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const customerData: ReadCustomerResponse = await this.readCustomer(
      customer.id
    );

    const updateCustomerRequest = new UpdateCustomersRequest();
    updateCustomerRequest.customer = customer;
    updateCustomerRequest.etag = customerData.etag;
    return firstValueFrom(
      this.customerServiceClient.update(updateCustomerRequest, grpcMetaData)
    );
  }

  /**
   * Updates the managed status of a specified customer.
   *
   * @param customerId - The ID of the customer to update.
   * @param isManagedCustomer - The new managed status of the customer.
   * @returns A promise that resolves to an UpdateManagedCustomerResponse containing the result of the operation.
   */
  async updateManagedCustomer(
    customerId: string,
    isManagedCustomer: boolean
  ): Promise<UpdateManagedCustomerResponse | undefined> {
    const grpcMetaData: GrpcMetadata =
      await this.apiAuthService.getAuthenticatedRequestHeader();

    const getSelfCustomerResponse: GetSelfCustomerResponse =
      await this.getCustomer(true);

    if (getSelfCustomerResponse.customer) {
      const updateManagedCustomerRequest = new UpdateManagedCustomerRequest();
      updateManagedCustomerRequest.customerId = customerId;
      updateManagedCustomerRequest.isManagedCustomer = isManagedCustomer;
      updateManagedCustomerRequest.etag =
        getSelfCustomerResponse.customer.etag!;

      return firstValueFrom(
        this.customerServiceClient.updateManagedCustomer(
          updateManagedCustomerRequest,
          grpcMetaData
        )
      );
    } else {
      return new Promise((_resolve, reject) => {
        reject(new Error('Unable to update managed customer. etag not found.'));
      });
    }
  }
}
