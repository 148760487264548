import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { Auth, getIdTokenResult } from '@angular/fire/auth';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AddUserModalComponent } from 'app/modals/add-user-modal/add-user-modal.component';
import { ConfirmationModalComponent } from 'app/modals/confirmation-modal/confirmation.component';
import { FormatService } from 'app/services/format.service';
import { UserInfo } from 'app/services/generated/src/main/proto/api/user-service.pb';
import { LoggerService } from 'app/services/logger.service';
import { CustomerAccount } from 'types/customerAccount';

import { GrpcStatus } from '../../constants/grpc-status';
import { AnonymRoles, CustomerRoles } from '../../constants/lookups';
import { CustomerService } from '../../services/customer.service';
import { UserService } from '../../services/user.service';
import { MessageBoxProvider } from '../../views/shared/components/message-box/message-box.provider';

@Component({
  selector: 'app-settings',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
})
export class SettingsPageComponent implements OnInit, AfterViewInit {
  public showCreateUser!: boolean;
  public columnsToDisplay: string[] = [
    'UserName',
    'Email',
    'Role',
    'Enabled',
    'Delete',
  ];
  // We assume a customer is going to be in managed mode, so review mode is false.
  isInReviewMode = false;
  users: MatTableDataSource<UserInfo> = new MatTableDataSource();
  customerData: CustomerAccount | undefined;
  time: string = this.FormatService.getLastUpdate();
  isLoadingUsers = false;
  admin = false;
  customerId: string | undefined;
  @ViewChild('managedAccountSlider') managedAccountSlider:
    | MatSlideToggle
    | undefined;
  @ViewChild(MatSort)
  sort: MatSort = new MatSort();

  constructor(
    public userService: UserService,
    public customerService: CustomerService,
    @Optional() private auth: Auth,
    private analytics: AngularFireAnalytics,
    private logger: LoggerService,
    private dialog: MatDialog,
    private liveAnnouncer: LiveAnnouncer,
    private messageBox: MessageBoxProvider,
    private FormatService: FormatService
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (user !== null) {
        getIdTokenResult(user).then((result) => {
          const {
            claims: { Roles },
          } = result;
          this.showCreateUser = (Roles as string)
            .split(',')
            .includes('PartnerAdmin');
        });
      }
    });
  }

  ngAfterViewInit() {
    this.users.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this.liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this.liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit(): void {
    this.isLoadingUsers = true;
    this.customerService
      .getCustomer()
      .then(({ customer }) => {
        this.customerData = {
          avatarUrl: 'assets/default-profile.png', // TODO
          companyName: customer?.companyName ?? '',
          accountCreatedAt: this.FormatService.formatProtoDateTime(
            customer?.creationTime
          ),
          lastModifiedTimestamp: this.FormatService.formatProtoDateTime(
            customer?.lastUpdate
          ),
          numberOfUsers: 0,
        };
        if (customer) {
          this.isInReviewMode = !customer.isManagedCustomer;
          this.customerId = customer.id;
        }
      })
      .catch((error) => this.logger.error('Error loading company data', error))
      .finally(() => {
        this.isLoadingUsers = false;
      });

    this.userService.getCustomerUsers().then((response) => {
      if (response.users) {
        if (this.customerData) {
          this.customerData.numberOfUsers = response.users.length;
        }
        this.users.data = response.users;
      }
    });
  }

  changeUserState(userInfo: UserInfo): void {
    // As a V2 use an Angular modal instead of confirm statement.
    let state = 'Disable';
    if (userInfo.disabled) {
      state = 'Enable';
    }

    if (confirm(state + ' user ' + userInfo.displayName + ' ?') == true) {
      this.analytics.logEvent('disable-user');
      userInfo.disabled = !userInfo.disabled;
      this.userService
        .updateUser(userInfo, false)
        .then(() => {
          this.messageBox.success('User state changed succesfully.');
        })
        .catch((e) => {
          this.logger.error(e);
          this.messageBox.error('Unable to change user state.');
        })
        .finally(() => {
          this.isLoadingUsers = false;
          this.ngOnInit();
        });
    } else {
      this.ngOnInit();
    }
  }

  deleteUser(userInfo: UserInfo): void {
    if (confirm('Delete user ' + userInfo.displayName + ' ?') == true) {
      this.analytics.logEvent('delete-user');
      this.userService
        .deleteUser(userInfo.uid, false)
        .then(() => {
          this.messageBox.success('User deleted succesfully.');
        })
        .catch((e) => {
          switch (e.statusCode) {
            case GrpcStatus.INVALID_ARGUMENT:
              this.messageBox.error(
                'Incorrect call to delete this user. Please contact support.'
              );
              break;
            case GrpcStatus.PERMISSION_DENIED:
              this.messageBox.error(
                'Required permissions are missing to delete this user.'
              );
              break;
            default:
              this.logger.error(e);
              this.messageBox.error('Unable to delete user.');
          }
        })
        .finally(() => {
          this.isLoadingUsers = false;
          this.ngOnInit();
        });
    }
  }

  createUser(): void {
    this.analytics.logEvent('new-user-dialog');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      tenantId: this.auth.tenantId,
      isAnonym: false,
      isAdmin: false,
    };

    const dialog = this.dialog.open(AddUserModalComponent, dialogConfig);

    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  editUser(userInfo: UserInfo) {
    this.analytics.logEvent('new--user-dialog');
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      tenantId: this.auth.tenantId,
      isAnonym: false,
      isAdmin: false,
      userInfo: userInfo,
    };

    const dialog = this.dialog.open(AddUserModalComponent, dialogConfig);

    dialog.afterClosed().subscribe(() => {
      this.ngOnInit();
    });
  }

  updateManagedAccount(isInReviewMode: boolean) {
    // UI uses review mode phrasing, API still uses managed mode.
    if (!this.showCreateUser) return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;

    if (!isInReviewMode) {
      dialogConfig.data = {
        title: 'Binary Approval',
        message: `Allow Anonym to approve binaries on ${this.customerData?.companyName}'s behalf moving forward?`,
        buttonText: `Yes, allow Anonym to manage binary approval`,
      };
    } else {
      dialogConfig.data = {
        title: 'Binary Approval',
        message: `Anonym will no longer automatically approve new binaries on ${this.customerData?.companyName}'s behalf.`,
        buttonText: `Yes, ${this.customerData?.companyName} will manually approve binaries`,
      };
    }

    const dialog = this.dialog.open(ConfirmationModalComponent, dialogConfig);

    dialog.afterClosed().subscribe((response) => {
      if (response.accepted && this.customerId) {
        this.customerService
          .updateManagedCustomer(this.customerId, !isInReviewMode)
          .then(() => {
            if (isInReviewMode) {
              this.messageBox.success('Review mode enabled.');
            } else {
              this.messageBox.success('Review mode disabled.');
            }
            this.isInReviewMode = !isInReviewMode;
          })
          .catch((error) => {
            this.logger.error(error.message);
            this.messageBox.error('Error updating review mode.');
          })
          .finally(() => {
            this.ngOnInit();
          });
      } else {
        this.managedAccountSlider?.toggle();
      }
    });
  }

  filterRoles(roles: string[]): string[] {
    const result: string[] = [];
    roles.forEach((role) => {
      if (role !== 'PortalUser') {
        CustomerRoles.find((customerRole) => {
          if (customerRole.value === role) {
            result.push(customerRole.name);
          }
        });
      }
    });

    roles.forEach((role) => {
      if (role !== 'PortalUser') {
        AnonymRoles.find((anonymRole) => {
          if (anonymRole.value === role) {
            result.push(anonymRole.name);
          }
        });
      }
    });

    return result;
  }

  isButtonDisabled(userInfo: UserInfo) {
    return userInfo.roles.includes('PartnerAdmin');
  }

  isAnonymRole(roles: string[]) {
    let result = false;

    roles.forEach((role) => {
      if (role !== 'PortalUser') {
        AnonymRoles.find((anonymRole) => {
          if (anonymRole.value === role) {
            result = true;
          }
        });
      }
    });

    return result;
  }
}
