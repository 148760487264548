/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions,
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';

/**
 * Message implementation for tiktok_audience_inference.TiktokAudienceInferenceConfig
 */
export class TiktokAudienceInferenceConfig implements GrpcMessage {
  static id = 'tiktok_audience_inference.TiktokAudienceInferenceConfig';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new TiktokAudienceInferenceConfig();
    TiktokAudienceInferenceConfig.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: TiktokAudienceInferenceConfig) {
    _instance.jobId = _instance.jobId || '';
    _instance.reach = _instance.reach || 0;
    _instance.enableDebugLogging = _instance.enableDebugLogging || false;
    _instance.advertiserId = _instance.advertiserId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: TiktokAudienceInferenceConfig,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.jobId = _reader.readString();
          break;
        case 2:
          _instance.reach = _reader.readInt32();
          break;
        case 3:
          _instance.enableDebugLogging = _reader.readBool();
          break;
        case 4:
          _instance.advertiserId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    TiktokAudienceInferenceConfig.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: TiktokAudienceInferenceConfig,
    _writer: BinaryWriter
  ) {
    if (_instance.jobId) {
      _writer.writeString(1, _instance.jobId);
    }
    if (_instance.reach) {
      _writer.writeInt32(2, _instance.reach);
    }
    if (_instance.enableDebugLogging) {
      _writer.writeBool(3, _instance.enableDebugLogging);
    }
    if (_instance.advertiserId) {
      _writer.writeString(4, _instance.advertiserId);
    }
  }

  private _jobId: string;
  private _reach: number;
  private _enableDebugLogging: boolean;
  private _advertiserId: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of TiktokAudienceInferenceConfig to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<TiktokAudienceInferenceConfig.AsObject>
  ) {
    _value = _value || {};
    this.jobId = _value.jobId;
    this.reach = _value.reach;
    this.enableDebugLogging = _value.enableDebugLogging;
    this.advertiserId = _value.advertiserId;
    TiktokAudienceInferenceConfig.refineValues(this);
  }
  get jobId(): string {
    return this._jobId;
  }
  set jobId(value: string) {
    this._jobId = value;
  }
  get reach(): number {
    return this._reach;
  }
  set reach(value: number) {
    this._reach = value;
  }
  get enableDebugLogging(): boolean {
    return this._enableDebugLogging;
  }
  set enableDebugLogging(value: boolean) {
    this._enableDebugLogging = value;
  }
  get advertiserId(): string {
    return this._advertiserId;
  }
  set advertiserId(value: string) {
    this._advertiserId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    TiktokAudienceInferenceConfig.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): TiktokAudienceInferenceConfig.AsObject {
    return {
      jobId: this.jobId,
      reach: this.reach,
      enableDebugLogging: this.enableDebugLogging,
      advertiserId: this.advertiserId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): TiktokAudienceInferenceConfig.AsProtobufJSON {
    return {
      jobId: this.jobId,
      reach: this.reach,
      enableDebugLogging: this.enableDebugLogging,
      advertiserId: this.advertiserId,
    };
  }
}
export module TiktokAudienceInferenceConfig {
  /**
   * Standard JavaScript object representation for TiktokAudienceInferenceConfig
   */
  export interface AsObject {
    jobId: string;
    reach: number;
    enableDebugLogging: boolean;
    advertiserId: string;
  }

  /**
   * Protobuf JSON representation for TiktokAudienceInferenceConfig
   */
  export interface AsProtobufJSON {
    jobId: string;
    reach: number;
    enableDebugLogging: boolean;
    advertiserId: string;
  }
}
